import AppContext from 'context/Context';
import useTopProducts from 'hooks/useTopProducts';
import React, { useContext, useEffect, useState } from 'react';
import { Row, Card, Col, OverlayTrigger, Tooltip ,Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gifOrder from 'assets/img/gif_order.gif'
import CountUp from 'react-countup';
import IconButton from 'components/common/IconButton';
import { PermsContext } from 'context/PermsContext';
import Modal from 'react-bootstrap/Modal';
import useBestProductsExport from 'hooks/useBestProductsExport';
import useVendorList from 'hooks/useVendorList';
import DatePicker from  "react-datepicker";
import Button from 'react-bootstrap/Button';
import './style.css';





// Örnek renk paleti
const colorPalette = ['#0067C7', '#56AEFE', '#68C71E', '#EC8E05', '#DD5454', 'yellow', 'pink', 'gray', 'cyan', 'brown'];

const BestProducts = () => {
    const { myData, showOrderGif } = useTopProducts();

  const {myDataVendor} = useVendorList()

    const { startDateModal, setStartDateModal, endDateModal, setEndDateModal, getDataExport, showGif, setShowGif, setVendorExportId} = useBestProductsExport()

    const handleExport = ()=> {
      getDataExport(); 
      setShowGif(true);
      setShow(false)
      setStartDateModal(null);
      setEndDateModal(null);
    }


  const {perms} = useContext(PermsContext)
  function hasPermission(permission) {
    return perms?.includes(permission);
  }

  const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const onChangeModal = datesExport => {
  const [startExport, endExport] = datesExport;
  setStartDateModal(startExport);
  setEndDateModal(endExport);
};

const handleChangeVendorModal = (e)=> {
  setVendorExportId(e.target.value); 

}

    const {
        config: { isDark, isRTL },
        setConfig
      } = useContext(AppContext);

  const placeholderProducts = [
    { Title: "Yükleniyor...", image: "https://www.byambalaj.com/wp-content/uploads/2022/08/30x20x20-Tasima-Sevkiyat-Kolisi.png", product_count: 10 },
    { Title: "Yükleniyor...", image: "https://www.byambalaj.com/wp-content/uploads/2022/08/30x20x20-Tasima-Sevkiyat-Kolisi.png", product_count: 9 },
    { Title: "Yükleniyor...", image: "https://www.byambalaj.com/wp-content/uploads/2022/08/30x20x20-Tasima-Sevkiyat-Kolisi.png", product_count: 7 },
    { Title: "Yükleniyor...", image: "https://www.byambalaj.com/wp-content/uploads/2022/08/30x20x20-Tasima-Sevkiyat-Kolisi.png", product_count: 6 },
    { Title: "Yükleniyor...", image: "https://www.byambalaj.com/wp-content/uploads/2022/08/30x20x20-Tasima-Sevkiyat-Kolisi.png", product_count: 5 }
];

const processedProducts = (myData && myData.length > 0) ? myData.map(item => {
  return { ...item };
}) : placeholderProducts;

// product_count değeri null olan ürünleri filtreleme
const validProducts = processedProducts.filter(product => product.product_count !== null);

const isLoading = !myData || myData.length === 0;
const maxCount = Math.max(...validProducts.map(p => p.product_count));


    return (
    <Card style={{paddingLeft:"23px", paddingRight:"23px", paddingBottom:"20px"}}>
          <Card.Header >
        <Row className="flex-between-center g-0 mb-3 mt-2">
          <Col xs="auto">
            <h6 className="mb-0" style={{ color: isDark ? "#D8E2EF":"#05276D", fontSize:'15px' }}>En Çok Satan Ürünler
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip
                bsPrefix='my-tooltip-class'
              >Son 30 günlük satış adetleri</Tooltip>}
            >
              <span>
                <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  transform="shrink-1"
                  className="ms-1 text-400"
                  id="weeklySalesTooltip"
                />
              </span>
            </OverlayTrigger>
            </h6>
          </Col>
          <Col xs="auto">
          { hasPermission('Dashboard Sipariş Export') ?
                  <div style={{marginRight:"8px"}}>
                  <IconButton
                        variant="falcon-default"
                        size="m"
                        icon="external-link-alt"
                        transform="shrink-3"
                        onClick={handleShow}
                        className="icon-button-inside-carousel"
                      >
                        <span className="d-none d-sm-inline-block ms-1">Export</span>
                      </IconButton>
                      </div>
                        : ("")  }
            
             </Col>

        </Row>
      </Card.Header>
    <Card className="p-3" style={{backgroundColor:isDark ? "#0B1727" : "#F6FAFF", height:'555px'}}>
      
    <Card.Body >
        {validProducts?.map((product, i) => (
            <Row style={{ marginBottom: "20px", marginLeft:'5px' }}>
                <Col md={12} key={i}>
                    <Row>
                        <Col md={1} style={{ display: 'flex', alignItems: 'center', minHeight: '70px'}}>
                            <img
                                src={product.image ? product.image : 'https://www.byambalaj.com/wp-content/uploads/2022/08/30x20x20-Tasima-Sevkiyat-Kolisi.png'}
                                alt={product.Title}
                                style={{ width: '50px', height: '50px', objectFit: 'contain', borderRadius:'10px' }}
                            />
                        </Col>
                        <Col md={10}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', minHeight: '70px', position: 'relative', marginLeft: '-30px'}}>
                                <div className="product-name" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%', fontSize: '13px', marginBottom: '8px' }}>
                                    {product.Title ? (product.Title.length > 60 ? product.Title.slice(0, 60) + "..." : product.Title) : ""}
                                </div>
                                <div
                                    className="product-count-bar"
                                    style={{
                                        width: isLoading ? "85%" : `${(product.product_count / maxCount) * 100}%`,
                                        backgroundColor: colorPalette[i % colorPalette.length],
                                        height: '20px',
                                        borderBottomRightRadius: "12px",
                                        borderTopRightRadius: "12px"
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={1} style={{ display: 'flex', alignItems: 'center', minHeight: '70px' }}>
                        {
                            isLoading ? (
                                <div style={{ visibility: 'hidden' }}>10</div> 
                            ) : (
                                product.product_count
                            )
                        }
                        </Col>
                    </Row>
                </Col>
            </Row>
        ))}
    </Card.Body>
</Card>


<Modal 
      show={show} 
      onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ürün Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
    

          <div style={{display:'flex', justifyContent:'center'}} >
       
       <div style={{marginRight:'8px'}}>
                   { myDataVendor?.length> 1 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeVendorModal}>
                    <option value="">Satıcı</option>
                       { myDataVendor?.map((item, i)=>{
                             return <option key={i} value={item.Id}>{item.vendor_name}</option>
                       })}       
               </Form.Select>}
           </div>

          <div style={{marginRight:"8px", fontSize:"14px"}}>
                <DatePicker
                showIcon
                selected={startDateModal}
                onChange={onChangeModal}
                startDate={startDateModal}
                formatWeekDay={day => day.slice(0, 3)}
                endDate={endDateModal}
                selectsRange
                dateFormat="MMM dd"
                className='form-control'
                placeholderText='tarih giriniz'
                />
          </div>

          </div>

          </Modal.Body>


        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Vazgeç
          </Button>
          <Button variant="primary" onClick={handleExport}>
            İndir
          </Button>
        </Modal.Footer>
      </Modal>




</Card>


    );
  };
  

export default BestProducts;
