import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { PermsContext } from 'context/PermsContext';



export default function useVendorStoreForAnalsyt() {


 const [vendorStore, setVendorStore] = useState()
 const {myKey} = useContext(AuthContext)
 const {vendorIdContext} = useContext(PermsContext)
 

 const getData = ()=>{
  var myHeaders = new Headers();



  const params = {
    vendor_id: vendorIdContext
  };

  const request_url = Object.entries(params)
      .filter(([_, value]) => Boolean(value)) 
      .reduce(
        (url, [key, value]) => url + `&${key}=${value}`,
        `${BASE_URL}api/v1/order/vendor_store/?`
  );

  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setVendorStore(result) 
})
    .catch(error => console.log('error', error));        
}



 useEffect(() => {
    getData();

  }, [vendorIdContext]);

 return {vendorStore, getData}
}
