import React, { useContext, useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderInfo from './OrderInfo';
import OrderedProducts from './OrderedProducts';
import SoftBadge from 'components/common/SoftBadge';
import useOrderList from 'hooks/useOrderList';
import { useLocation, Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import './ProgressStyle.css'

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Button } from 'react-bootstrap';
import useOrderCancel from 'hooks/useOrderCancel';
import { PermsContext } from 'context/PermsContext';
import useOrderWorkPost from 'hooks/useOrderWorkPost';
import useRefundList from 'hooks/KolayIade/useRefundList';

const MissingDetails = () => {

  const location = useLocation()
  const {id, activePage, myData } = location.state || {};


  // const {myData} = useRefundList()
 
  const {perms} = useContext(PermsContext)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {getOrderWork}= useOrderWorkPost()


 

  function hasPermission(permission) {
    return perms?.includes(permission);
  }



const detailData = myData?.results[id]


const transformDetailData = (detailData) => {
  if (!detailData) {
    return {}; 
  }

  const date = new Date(detailData.ClaimDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const order_date = new Date(detailData.RelatedOrder.OrderDate)

  const order_year = order_date.getFullYear();
  const order_month = order_date.getMonth() + 1;
  const order_day = order_date.getDate();

  const RefundStatus = [ 15, 40, 60, 80, 80, 80 ,100]

  const smallestStatusItem = detailData.claims.reduce((prev, current) => 
  (prev.Status < current.Status) ? prev : current
);

// SmallestStatusItem'ın index'ini bulun
const progressBarIndex = Number(smallestStatusItem.Status);
const statusText = smallestStatusItem.status

  return {
    name: `${detailData.RelatedOrder.ShippingAddress.FirstName} ${detailData.RelatedOrder.ShippingAddress.LastName}`,
    order_number: detailData.OrderNumber,
    date_claim: `${day}-${month}-${year}`,
    order_date : `${order_day}-${order_month}-${order_year}`,
    address: detailData.RelatedOrder.ShippingAddress.City,
    status: statusText,
    amount: detailData.RelatedOrder.OrderAmount,
    trackingLink: detailData.RelatedOrder.ShipmentTrackingLink,
    note: detailData.WarehouseNote,
    store : detailData.RelatedOrder.VendorStore.Store.Name,
    progress_bar: RefundStatus[progressBarIndex]
  };
};

const transformedData = transformDetailData(detailData);


return (
    <>
    <div style={{display:'flex', justifyContent:'space-between', marginBottom:'10px'}} >
      
    <Link to="/missing-list" state={{ activePage }}>

      <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      </Link>

    </div>      
      <PageHeader  titleTag="h5" className="mb-3 mt-1" handleShow={handleShow} id={id}>
            <h5 style={{marrginTop:'-200px', marginBottom:'10px'}}>Talep Detayları</h5>
        <p className="fs--1 mt-1"> Sipariş Tarihi : {transformedData?.order_date}</p>
        <p className="fs--1 mt-1"> Talep Tarihi : {transformedData?.date_claim}</p>
        <p className="fs--1 mt-1"> Sipariş Numarası : {transformedData?.order_number }</p>
        <p className="fs--1 mt-1"> Mağaza : {transformedData.store }</p>
        {/* <p className="fs--1 mt-1"> Çıkış Deposu : {status?.length >0 && status[0]?.region}</p> */}
        <div>
          <strong className="me-2">Durumu: </strong>
          <SoftBadge pill bg="success" className="fs--2">
            {' '}
            {transformedData?.status }
            {/* <FontAwesomeIcon
              icon="check"
              className="ms-1"
              transform="shrink-2"
            /> */}
          </SoftBadge>          
        </div>
        <div style={{margin:"50px", marginLeft:"90px", width:"88%"}}>

        <ProgressBar percent={transformedData?.progress_bar} >
  <Step>
    {({ accomplished, index }) => (
      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
        style={{marginTop:'25px'}}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-stop-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z"/>
</svg>
      </div>

      <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', fontWeight:'bold', marginTop:'5px'}}>Oluşturuluyor</p>
      </div>

      </div>
    )}
  </Step>

  <Step>
    {({ accomplished, index }) => (
      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
        style={{marginTop:'25px'}}
      >
     
     <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
      </svg>
      </div>

        <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', fontWeight:'bold', marginLeft:'-15px', marginTop:'5px'}}>İşlem bekleniyor</p>
        </div>


      </div>
    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (

      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
        style={{marginTop:'25px'}}
      >
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-chat-text" viewBox="0 0 16 16">
        <path d="M2.678 11.894a1 1 0 0 1 .287.801 11 11 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8 8 0 0 0 8 14c3.996 0 7-2.807 7-6s-3.004-6-7-6-7 2.808-7 6c0 1.468.617 2.83 1.678 3.894m-.493 3.905a22 22 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a10 10 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105"/>
        <path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8m0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5"/>
      </svg>
      </div>
      <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', fontWeight:'bold', marginLeft:'-15px', marginTop:'5px'}}>Müşteri ile iletişim</p>
        </div>
      </div>
    )}
  </Step>

 

  <Step>
    {({ accomplished, index }) => (

      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
        style={{marginTop:'25px'}}
      >
         <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
          <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
          <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
        </svg>
      </div>

      <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', width:'90px', fontWeight:'bold', marginTop:'5px', marginLeft:'-10px'}}>Talep sonuçlandı</p>
        </div>
      </div>
    )}
  </Step>
</ProgressBar>
</div>
      </PageHeader>
      <OrderInfo detailData ={detailData}/>
      <OrderedProducts detailData={detailData}/>
      {/* {hasPermission('Sipariş Durumu: İptal Etme') && (
      <Button variant="danger" onClick={handleShow}>
        Siparişi İptal Et
      </Button>
      )} */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bilgilendirme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          İptal etmek istediğinize emin misiniz?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Hayır
          </Button>
          <Button variant="danger" >
            İptal Et
          </Button>
        </Modal.Footer>
      </Modal>
     
    </>
  );
};

export default MissingDetails;
