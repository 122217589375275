import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Card, Row, Col, Spinner, Modal } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import { MdAdd, MdDelete } from "react-icons/md";
import Form from '@rjsf/bootstrap-4';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import '../style.css';
import useIntegrationMetaDataForm from 'hooks/useIntegrationMetaDataForm';
import useIntegrationMetaDataUpdate from 'hooks/useIntegrationMetaDataUpdate';


const ArrayFieldTemplate = (props) => {
    return (
      <div className="array-field-template">
        {props.items.map((element) => (
          <div key={element.index} className="d-flex align-items-center mb-2">
            <div style={{ flex: 1 }}>
              {element.children}
            </div>
            {element.hasRemove && (
              <button 
                type="button" 
                className="btn btn-danger btn-sm ms-2" 
                style={{ alignSelf: 'center', marginTop: '25px' }}
                onClick={element.onDropIndexClick(element.index)}
              >
                <MdDelete />
              </button>
            )}
          </div>
        ))}
        {props.canAdd && (
          <div className="d-flex justify-content-end mt-2">
            <button 
              type="button" 
              className="btn btn-primary btn-sm" 
              style={{ width: '40px' }}
              onClick={props.onAddClick}
            >
              <MdAdd />
            </button>
          </div>
        )}
      </div>
    );
  };








const MetaDataSetList = () => {

    const location = useLocation();
    const { item, name } = location.state || {};
    // const { updateData } = useIntegrationUpdate();

    const [showModal, setShowModal] = useState(false);
    const [selectedMetaId, setSelectedMetaId] = useState(null);
    const { metaData, loading, getData } = useIntegrationMetaDataForm();
    const {updateData} = useIntegrationMetaDataUpdate()
    const [contetxName, setContextName] = useState()
    const [metaDataId, setMetaDataId] = useState()

    // const [formData, setFormData] = useState({});

    const schema = metaData?.schema

  const uiSchema = {
    ...metaData?.uiSchema,
    myArrayField: {
      "ui:options": {
        classNames: "custom-add-item-button" // Özel CSS sınıfı
      }
    },
    "ui:submitButtonOptions": {
      norender: true // Varsayılan submit butonunu gizliyoruz
    }
  };
  

    // Modal açma ve form verilerini alma
    const handleOpenModal = async (metaId, context) => {
        setSelectedMetaId(metaId);
        setShowModal(true);
        getData(metaId)
        setContextName(context)
        setMetaDataId(metaId)

      
    };

    const handleCloseModal = () => {
        setShowModal(false);
      };
    
      const handleSubmit = ({ formData }) => {
       
        updateData(metaDataId, formData);
        handleCloseModal();
      };




  return (

    <>
      <Link to="/e-commerce/integration-available">
        <span style={{ color: "#5E6E82", fontWeight: "500", marginBottom: "5px", fontSize: "13px" }}>
          <BsArrowLeft /> &nbsp; Geri Dön
        </span>
      </Link>
      <Card className="mb-3 mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: '5%' }}>
        <div className="mb-3 mt-3" style={{ width: "95%" }}>
          <h6>{name} Entegrasyon Meta Data Güncelleme</h6>
          <hr />
        </div>
        {!item ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          item?.metadata_set ? (
            <div>
              {item.metadata_set.map((meta) => (
                <Card 
                  className="mb-3" 
                  key={meta.id} 
                  style={{ width: '400px', margin: '0 auto' }}
                  onClick={() => handleOpenModal(meta.id, meta.context)}
                >
                  <Card.Body className="d-flex justify-content-between align-items-center"> 
                    <div style={{ flex: 0.8, fontSize: '1.2rem', fontWeight: 'bold' }}>
                      {meta.context.toUpperCase()}
                    </div>
                    <div style={{ flex: 0.2, textAlign: 'right' }}>
                      <FaEdit className="text-primary" style={{ cursor: 'pointer', fontSize: '1.5rem' }} />
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">
                      Güncellenme: {new Date(meta.created_at).toLocaleDateString()}
                    </small>
                  </Card.Footer>
                </Card>
              ))}
            </div>
          ) : (
            <p>MetaData verisi bulunamadı.</p>
          )
        )}
      </Card>

        {/* Modal */}
    <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{contetxName} data düzenle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {!metaData || !schema ? (
            <p>MetaData verisi bulunamadı veya {contetxName} görme yetkiniz yoktur.</p>
            ) : (
            <Form 
                schema={schema} 
                uiSchema={uiSchema}
                validator={validator}
                onSubmit={handleSubmit} 
                templates={{ ArrayFieldTemplate }}
            >
                <div className="d-flex justify-content-center mt-3">
                <button type="submit" className="btn btn-primary">Onayla</button>
                </div>
            </Form>
            )}
        </Modal.Body>
      </Modal>

    </>
  )
}

export default MetaDataSetList