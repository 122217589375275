import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'


export default function useCampaingList() {

 const {myKey, currentUser} = useContext(AuthContext)
 const [campaingData, setCampaingData] = useState()
 const [campaingSelectData, setCampaingSelectData] = useState()
 const [vendorId, setVendorId] = useState()




 const [vendorStore, setVendorStore] = useState()


 const getData = ()=>{
        var myHeaders = new Headers();


        const params = {
          vendor_id : vendorId,
          vendor_store : vendorStore
         
        };
      
        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/analysis/campaing/?`
      );



        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setCampaingData(result) 
            console.log("result", result)
    })
          .catch(error => console.log('error', error));        
 }

 const getDataSelect = ()=>{
  var myHeaders = new Headers();
  let request_url;

    request_url = `${BASE_URL}api/v1/analysis/campaing/?vendor_username=${currentUser}`;



  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setCampaingSelectData(result) 
      console.log("result", result)
})
    .catch(error => console.log('error', error));        
}











 useEffect(() => {
    getData();
    getDataSelect()
 
  }, [vendorStore, vendorId]);

 return {campaingData, setVendorStore, campaingSelectData, setVendorId}
}
