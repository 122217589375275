import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'


export default function useProductVendorStoreProductList() {

 const {myKey} = useContext(AuthContext)
 const [vsProductData, setVSProductData] = useState([])


 const getDataVs = (id)=>{
        var myHeaders = new Headers();

        let request_url;
        request_url = `${BASE_URL}api/v1/product/product/product_vs_product/?id=${id}`

        console.log("url", request_url)

        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setVSProductData(result.product_list) 
            console.log("result",result);            
    })
          .catch(error => console.log('error', error));        
 }


 return {vsProductData, getDataVs}
}
