import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, InputGroup, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import IconButton from 'components/common/IconButton';
import { Search } from 'react-bootstrap-icons';
import gif from 'assets/img/gif_order.gif'
import PaginationActionHistory from './PaginationActionHistory';
import ActionHistoryTableHeader from './ActionHistoryTableHeader';
import useActionHistoryList from 'hooks/ActionHistory/useActionHistoryList';
import DatePicker from  "react-datepicker";
import Select from 'react-select';
import AppContext from 'context/Context';
import useUserListActions from 'hooks/ActionHistory/useUserListActions';


const ActionHistory = () => {

  const columns = [
    {
      // accessor: 'name',
      Header: 'Id',
      disableSortBy: true,
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const {id, item} = rowData.row.original;
        return (
          <>
           <Link to="/user/actions-history/detail" state={{ item }}>
              <p style={{ fontSize: "15px" }} >{ id }</p>
          </Link>
          </>
        );
      }
    },
    {
      accessor: 'user_name',
      disableSortBy: true,
      Header: 'Kullanıcı',
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { user_name} = rowData.row.original;
        return (
          <>
            <p style={{ fontSize: "15px" }} >{ user_name }</p>
          </>
        );
      }
    },
    {
      accessor: 'type',
      disableSortBy: true,
      Header: 'Tip',
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left fs-0'
      },
      Cell: rowData => {
        const { type } = rowData.row.original;
        return (
          <>
         <p style={{ fontSize: "15px" }} >{ type }</p>
        
          </>
        )
      }
    },
    {
      accessor: 'obj',
      Header: 'Obje Adedi',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fs-0'
      },
      Cell: rowData => {
        const { obj_count } = rowData.row.original;
        return (
          <>
      
         <p style={{ fontSize: "15px" }} >{ obj_count }</p>
          </>
        )
      }
    },
    {
      accessor: 'date',
      Header: 'Tarih',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fs-0'
      },
      Cell: rowData => {
        const { date } = rowData.row.original;
        return (
          <>
            <p style={{ fontSize: "15px" }} >{ date }</p>
          </>
        )
      }
    },
  
  ];

const {myData, setPage, setSearch, setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, showGif, setStartDate, setEndDate, startDate, endDate, setType, setUser, setObjId} = useActionHistoryList()

const {dataUser} = useUserListActions()
 
const {
  config: { isDark, isRTL },
  setConfig
} = useContext(AppContext);


const users = [
  { label: 'Hepsi', value: '' },
  ...(dataUser ? dataUser.map(user => ({
    label: user.username,
    value: user.id, 
  })) : []) 
];

const [selectedOptionUser, setSelectedOptionUser] = useState(null);


const handleChangeUser = (e)=> {
  setUser(e.value); 
  setSelectedOptionUser(e)
}




const [activePage, setActivePage] = useState(1)

useEffect(() => {

  setPage(activePage)

}, [activePage])

const [isOpen, setIsOpen] = useState(false);

// Handler
const handleToggle = () => {
  setIsOpen(!isOpen);
};


const historyList = myData?.results.map((item)=>{

  function formatDateTime(dateString) {

    if (!dateString || isNaN(new Date(dateString).getTime())) {
      return "tarihi yok";
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  return {

    item : item,
    id : item.Id,
    user_name : item.user_name,
    type: item.type, 
    obj_count : item.instances?.length,
    date : formatDateTime(item.DateTime),
    
  }
})

// date filter

const handleChangeDate = event => {
  setSelectedOption(event.target.value);

  let start, end;

  switch(event.target.value) {
    case "1": // Bugün
      start = new Date();
      end = new Date();
      break;

    case "2": // Bu hafta
      end = new Date();
      start = new Date();
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
      break;   

    case "3": // Bu ay
      end = new Date();
      start = new Date();
      start.setDate(1); // Ayın ilk günü
      break;
  
    default:
      break;
  }

  setStartDate(start);
  setEndDate(end);


};

const [isOpenDate, setIsOpenDate] = useState(true);
const [selectedOption, setSelectedOption] = useState(null);


const onChange = dates => {

  const [start, end] = dates;


  if (selectedOption === "4") {
    setStartDate(start);
    setEndDate(end);
  }
  if (start && end) {
    setIsOpenDate(false);  
  }
};


useEffect(() => {
  if (selectedOption === "4") {
    setIsOpenDate(true);
  } else {
    setIsOpenDate(false);
  }
}, [selectedOption]);


const types = [
  {value: 0, label: 'Hepsi'},
  {value:1, label: 'Kargoya Verildi'},
  {value:2, label: 'Kargoya Verildi (Sadece Durum)'},
  {value:3, label: 'Sipariş İptali'},
  {value:4, label: 'Beklemeye Çekme'},
  {value:5, label: 'İş Emri Ataması'},
  {value:6, label: 'Aras Kargo Teslimatı'},
  {value:7, label: 'PackUpp Teslimatı'},
  {value:8, label: 'Carrtell Teslimatı'},
  {value:9, label: 'Net Kargo Teslimatı'},
  {value:10, label: 'ZPL Barkodları Çekme'},
  {value:11, label: 'İrsaliye V1'},
  {value:12, label: 'İrsaliye V2'},
  {value:13, label: 'İrsaliye V3'},
  {value:14, label: 'Satıcı Siparişleri CSV'},
  {value:15, label: 'Siparişleri Çekme Sıraya'},
  {value:16, label: 'Ürünleri Çekme Sıraya'},
  {value:17, label: 'Platforma Fulfillment Bilgisi'},
  {value:18, label: 'Sms Gönderme Sıraya '},
  {value:19, label: 'Çıkış Deposu Sıfırlandı'},
  {value:20, label: 'Kargo Bilgisi Sıfırlandı'},
  {value:21, label: 'hepsiJET Teslimatı'},
  {value:22, label: 'Depo ürünü sıfırlaması'},
  {value:23, label: 'Yurtiçi Teslimatı'},
  {value:24, label: 'Packupp (AGT) Teslimatı'},
  {value:25, label: 'İrsaliye V4'},
  {value:26, label: 'MNG Kargo Teslimatı'},
  {value:27, label: 'Kargoray Teslimatı'},
  {value:28, label: 'Kargola Teslimatı'},
  {value:29, label: 'Kargola AGT Teslimatı'},
  {value:30, label: 'Fatura İrsaliyesi'},
  {value:31, label: 'İş Emri Talebi'},
  {value:32, label: 'İş Emri Talebi (İPTAL EDİLMİŞ)'},
  {value:33, label: 'Printing via WebSocket'},
  {value:34, label: 'Sipariş İnceleme Gerektiriyor'},
  {value:35, label: 'ShipShack Teslimatı'},
  {value:36, label: 'Sipariş Toplaması Manuel Sonlandırma'},
  {value:37, label: 'Entegrasyon siparişlerini çekme'},
  {value:38, label: 'Entegrasyon TÜM ürünlerini çekme'},
  {value:39, label: 'Tamamlanan ya da iptal olan siparişi beklemeye çekme'},
  {value:40, label: 'Sipariş sorumlusu kaldırma'},
  {value:41, label: 'KolayGelsin teslimatı'},
  {value:42, label: 'Kargo İrsaliyesi Basımı'},
  {value:43, label: 'Yetki verme'},
  {value:44, label: 'Yetki kaldırma'},
  {value:45, label: 'Picka Outbound'},
  {value:46, label: 'Platform Ürünü - Katalog Ürünü Eşleştirme'},
  {value:47, label: 'Platform Ürünü - Katalog Ürünü Bağlantı Kaldırma'},
  ]


// select
const [selectedOptionInput, setSelectedOptionInput] = useState(null);


const handleChangeType = (e)=> {
  setType(e.value); 
  setSelectedOptionInput(e)
}

function getCustomStyles(isDark) {
  return {
    control: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: isDark ? "#0B1727" : "white",
      // width: '200px'
    }),
    option: (provided, state) => ({
      ...provided,
      color: isDark ? 'white' : 'black',
      backgroundColor: isDark ? '#0B1727' : 'white',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: isDark ? 'white' : 'black',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: isDark ? 'white' : 'black',
    }),

    menu: (provided, state) => ({
      ...provided,
      // width: '160px'
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: '150px',  
      overflow: 'auto' ,
      // width: '160px' 
    }),

  };
}



  return (
    <AdvanceTableWrapper
      columns={columns}
      data={historyList || []} 
      selection
      sortable
      pagination
      perPage={50}
    >
      <Card className="mb-3">
        <Card.Header>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <div>
            <ActionHistoryTableHeader table />
        </div>
        <div style={{display:"flex", justifyContent:'right'}}>
            <div className="mb-3" style={{marginRight:"8px"}}>
                  <InputGroup>
                    <Form.Control  type="text" placeholder="obje id..."  onChange={(e)=>setObjId(e.target.value)}/>
                    <InputGroup.Text><Search/></InputGroup.Text>
              </InputGroup>
                  </div>

      
        <Dropdown  show={isOpen} onToggle={handleToggle}>
          <Dropdown.Toggle
              bsPrefix="toggle"
              as={Link}
              //  to=""
              className={classNames('px-0 nav-link')}>
            <IconButton
              variant="falcon-default"
              size="m"
              icon="fa-sharp fa-light fa-filter-list"
              transform="shrink-3"
              style={{width:'145px', fontSize:'15px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
              </svg>
                  <span className="d-none d-sm-inline-block ms-1">Filtre</span>
            </IconButton>
        </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret" style={{minWidth: '300px', width: '300px'}}>

          <Card className="shadow-none shadow-show-xl scrollbar" style={{height:'450px'}}> 
            <Card.Header className="bg-light d-none d-xl-block">
              <h6 className="mb-0">Filtre</h6>
            </Card.Header>
              <Card.Body>
                <Form>
                <div >
                  <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Tarih Seçimi</Form.Label>

                  <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                  <option value="" disabled selected>Tarih</option>
                  <option key={1} value="1">Bugün</option>
                  <option key={2} value="2">Bu hafta</option>
                  <option key={2} value="3">Bu ay</option>
                  <option key={3} value="4">Tarih seç</option>
                              </Form.Select> 

                {selectedOption === "4" && 
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    endDate={endDate}
                    selectsRange
                    dateFormat="MMM dd"
                    className='form-control'
                    placeholderText='tarih giriniz'
                    open={isOpenDate}
                    onCalendarClose={() => setIsOpenDate(false)}
                    onCalendarOpen={() => setIsOpenDate(true)}
                  />
                }

              </div>

              <div style={{marginTop:'20px'}}>
              <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Kullanıcı</Form.Label>
                <Select
                      value={selectedOptionUser}
                      onChange={handleChangeUser}
                      options={users}
                      // styles={customStyles}
                      placeholder='Kullanıcı seçiniz..'
                      styles={getCustomStyles(isDark)}
                    />
                </div>

                
              <div style={{marginTop:'20px'}}>
              <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Tipi</Form.Label>
                <Select
                      value={selectedOptionInput}
                      onChange={handleChangeType}
                      options={types}
                      // styles={customStyles}
                      placeholder='Tipini seçiniz..'
                      styles={getCustomStyles(isDark)}
                    />
                </div>
                 
                 
              </Form>
            </Card.Body>
        </Card>
      </Dropdown.Menu>
      </Dropdown>
      </div>
    </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showGif && <img src={gif} style={{width:'50%'}} alt="loading gif" />}
          </div>

        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <PaginationActionHistory pages={totalPages} activePage={activePage} setActivePage={setActivePage} setCurrentPage={setCurrentPage}/>
        </Card.Footer>
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}>Ürün sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>

    </AdvanceTableWrapper>
  );
};

export default ActionHistory;