import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import useInventoryProductCount from 'hooks/InventoryCount/useInventoryProductCount';



const InventoryCountShelf = () => {

  const location = useLocation()
  const {id, inventory_id} = location.state || ""

  const {detail, getDetailShelf, detailShelf, getDetail} = useInventoryProductCount()
  const [shelfId, setShelfId] = useState(detail?.Shelves[0].Id)

  useEffect(() => {
    if(id){
      getDetailShelf(id, inventory_id, shelfId)
      getDetail(id,  inventory_id)
    }
  }, [id, inventory_id, shelfId])

  const shelfCodes = detail?.Shelves
  const shelfSKUs = detailShelf?.SKUs

 
  const handleChangeRegion = (e)=> {
    setShelfId(e.target.value)
  }

  return (
    <>
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
        <div style={{display:'flex', justifyContent:'space-between',marginBottom:"15px"}}>
          
        <h6>Raflar</h6>
              <div style={{display:'flex', marginTop:'3px', marginRight:'3px'}}>
                <div id="orders-actions">
                    {shelfCodes?.length> 0 &&    
                      <Form.Select  className='form-control' style={{fontSize:"15px"}} 
                          onChange={handleChangeRegion}>
                          <option value="">Raflar</option>
                              { shelfCodes?.map((item, i)=>{
                                  return <option key={i} value={item.Id}>{item.Code}</option>
                              })}       
                      </Form.Select>
                      }
                </div>
              </div>

        </div>
          <Table striped className="border-bottom">
          
          <thead className="bg-200 text-900">
                <tr>
                    <th className="border-0 text-center">Ürün Resmi</th>
                    <th className="border-0 text-center">Ürün İsmi</th>
                    <th className="border-0 text-center">SKU</th>
                    <th className="border-0 text-center">Barkod</th>
                    <th className="border-0 text-center">SKT</th>
                    <th className="border-0 text-center">Lot</th>
                    <th className="border-0 text-center">Değişim Stoğu</th>
                    <th className="border-0 text-center">Raf Stok</th>
                    <th className="border-0 text-end">Durumu</th>
                </tr>
            </thead>
            <tbody>
                {shelfSKUs?.map(sku => (
                    <tr key={sku.Id}>
                        <td className="align-middle text-center">
                            <img src={sku.image} alt={sku.Name} style={{ width: "50px" }} />
                        </td>
                        <td className="align-middle" title={sku.Name}>
                          {sku.Name.length > 30 ? `${sku.Name.substring(0, 30)}...` : sku.Name}
                        </td>
                        <td className="align-middle text-center">{sku.SKU}</td>
                        <td className="align-middle text-center">{sku.Barcode}</td>
                        <td className="align-middle text-center">
                            {sku.ExpirationDate ? new Date(sku.ExpirationDate).toLocaleDateString('tr-TR') : 'Belirtilmemiş'}
                        </td>
                        <td className="align-middle text-center">{sku.Lot || 'Belirtilmemiş'}</td>
                        <td className="align-middle text-center">{sku.sku_count ? sku?.sku_count?.Count : 'Sayım yapılmamıştır'}</td>
                        <td className="align-middle text-center">{sku.sku_count ? sku?.sku_count?.InventoryProductCount : 'Sayım yapılmamıştır'}</td>
                        <td className="align-middle text-end">
                            {sku.sku_count ? sku?.sku_count?.status : 'Sayım yapılmamıştır'}
                        </td>
                    </tr>
                ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
             
            </Col>
          </Row>
        </div>
      </Card.Body>
      <Card.Footer>
        </Card.Footer>
    </Card>
    </>
  );
};



export default InventoryCountShelf;
