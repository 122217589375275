import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, InputGroup, Row, Col, Alert } from 'react-bootstrap';
import { orderList } from 'data/ecommerce/orderList';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
// import OrdersTableHeader from './OrdersTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import useProductListAll from 'hooks/useProductListAll';
import IconButton from 'components/common/IconButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useProductExport from 'hooks/useProductExport';
import Accordion from 'react-bootstrap/Accordion';
import { Search } from 'react-bootstrap-icons';
import { useProductUpload } from 'hooks/useProductUpload';
import { useRef } from 'react';
import useProductExampleExport from 'hooks/useProductExampleExport';
import useProductExportS3 from 'hooks/useProductExportS3';
import ProductTableHeader from './ProductTableHeader';
import PaginationProduct from './PaginationProduct';
import { useNavigate } from "react-router-dom";
import { PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import useBarcodeSku from 'hooks/useBarcodeSku';
import gif from 'assets/img/gif_export_s3.gif'
import useInventoryProductAll from 'hooks/useInventoryProductAll';
import useInventoryProductExportS3 from 'hooks/useInventoryProductExportS3';
import useInventoryRegion from 'hooks/useInventoryRegion';
import useInventoryProductCount from 'hooks/InventoryCount/useInventoryProductCount';


const InventoryCount = () => {

  const columns = [
    {
      accessor: 'id',
      Header: 'Id',
      disableSortBy: true,
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },

      Cell: rowData => {
        const { id, inventory_id } = rowData.row.original;
        return (
          <>
            <Link to="/inventory-product/inventory-product-count-detail" state={{id, inventory_id }}>
              <p>#{id}</p>
            </Link>
          </>
        );
      }
    },
  
    {
      accessor: 'processedBy',
      disableSortBy: true,
      Header: 'Sayımı Yapan',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { name} = rowData.row.original;
        return (
          <>
            <p>{name}</p>
          </>
        );
      }
    },
    {
      accessor: 'inventory',
      disableSortBy: true,
      Header: 'Depo',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { inventory } = rowData.row.original;
        return (
          <>
            <p>{inventory }</p>
          </>
        )
      }
    },
    {
      accessor: 'count_time',
      Header: 'Başlangıç Tarihi',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { begin_date } = rowData.row.original;
        return (
          <>
      
         <p>{ begin_date }</p>
          </>
        )
      }
    },
    {
      accessor: 'end_date',
      Header: 'Bitiş Tarihi',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { end_date } = rowData.row.original;
        return (
          <>
            <p>{ end_date }</p>
          </>
        )
      }
    },
    ,
    { accessor: 'status',
      Header: 'Sayım Durumu',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.status}`
    },
    {
      accessor: 'shelfs',
      Header: 'Sayılan Raflar',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center wrap-text'
      },
      Cell: rowData => {
        const { shelfs } = rowData.row.original;
        return (
          <p className="wrap-text">{shelfs}</p>
        );
      }
    }
  ];

const {myData, setPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages,sortState, setInventoryId} = useInventoryProductCount()



const {regionData} = useInventoryRegion()
const navigate = useNavigate();

const {myDataVendor} = useVendorList()




useEffect(() => {
  if (regionData && regionData.length > 0) {
    const firstVendorId = regionData[1].Id;
    
    setInventoryId(firstVendorId)
  }
}, [regionData]);



const [activePage, setActivePage] = useState(1)

useEffect(() => {

  setPage(activePage)

}, [activePage])





const orderList = myData?.results?.map(item => {
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('tr-TR', {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', hour12: false
    }).replace(/\./g, '-');
  };

  return {
    id: item.Id,
    inventory_id : item.Inventory,
    name: item.ProcessedBy_username,  
    inventory: item.inventory_name,  
    begin_date: formatDate(item.DateTime),  
    end_date: item.CompleteDateTime ? formatDate(item.CompleteDateTime) : 'Devam Ediyor',  
    status: item.Completed ? 'Tamamlandı' : 'Devam Ediyor',  
    shelfs: item.Shelves.map(shelf => shelf.Code).join(', ')  
  };
});



const handleChangeRegion = (e)=> {
  setInventoryId(e.target.value); 
}



  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      selection
      sortable
      pagination
      perPage={50}
    >
      <Card className="mb-3">
        <Card.Header>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <div>
            <ProductTableHeader table />
        </div>
        <div style={{display:"flex", justifyContent:'right'}}>
           
 
            <div style={{marginRight:'8px'}}>
                    { regionData?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} 
                    onChange={handleChangeRegion}
                    >
                     <option value="" disabled>Depo Bölgeleri</option>
                        { regionData?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.name}</option>
                        })}       
                </Form.Select>}
            </div>
          </div>
        </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <PaginationProduct pages={totalPages} activePage={activePage} setActivePage={setActivePage} setCurrentPage={setCurrentPage}/>
        </Card.Footer>
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}>Ürün sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>






    </AdvanceTableWrapper>
  );
};

export default InventoryCount;