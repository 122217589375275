import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import { toast } from 'react-toastify';




export default function useDeliveryExportS3() {

  const [myDataS3, setMyDataS3] = useState()
  const {myKey, currentUser} = useContext(AuthContext)
  
  const [myDataResult, setMyDataResult] = useState()
  const [selectStatusModal, setSelectStatusModal] = useState(0)
  const [cargoModal, setCargoModal] = useState()
  const [storeModal, setStoreModal] = useState()
  const [fulfilmentModal, setFulfilmentModal] = useState()

  const [invoicesExport, setInvoicesExport] = useState()
  const [showGif, setShowGif] = useState(false);
  
  const [regionExport, setRegionExport] = useState()

  const [randomNumber, setRandomNumber] = useState()

  const [couirerModal, setCouirerModal] = useState()


  const [vendorId, setVendorId] = useState()
  const [isPreparing, setIsPreparing] = useState(false);

  const [vendorExportId, setVendorExportId] = useState()

  const [deliveryStatusModal, setDeliveryStatusModal] = useState()



  const todayDay = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const timezoneOffset = todayDay .getTimezoneOffset(); 
  const [startDateModal, setStartDateModal] = useState(new Date(todayDay.getTime() - oneDay * 7));
  const [endDateModal, setEndDateModal] = useState(new Date(todayDay.getTime() + oneDay * 8));


  const [message, setMessage] = useState("");

  let formattedStartDate = "";
  let formattedEndDate = "";

  if(startDateModal){
    formattedStartDate = new Date(startDateModal.getTime()).toISOString().slice(0, 10);
  }
  if(endDateModal){
    formattedEndDate = new Date(endDateModal.getTime() + oneDay).toISOString().slice(0, 10);
  }

  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); 
  const year = today.getFullYear();
  const formattedDate = `${day}.${month}.${year}`




 const getDataExport = async ()=>{
      var myHeaders = new Headers();


      const params = {

        cargo :cargoModal,
        status: deliveryStatusModal,
        begin_date: formattedStartDate,
        end_date: formattedEndDate,
        vendor_id: vendorExportId,
        store : storeModal,
        fulfilment : fulfilmentModal,
        invoices : invoicesExport,
        inventory_region : regionExport,
        courier_id : couirerModal,

   
      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/export_delivery_excel_threading/?`
  );


    var requestOptions = {
      method: 'GET',
      headers: {"Authorization": "Token "+myKey},
      redirect: 'follow'
    };
    fetch(request_url, requestOptions)
      .then(response => response.json())
      .then(result =>{
        setMyDataS3(result) 
        setRandomNumber(result.random_number)
        setVendorId(result.vendor)
        setShowGif(false);
        console.log("resultss3", result )

})
      .catch(error => console.log('error', error)); 
      toast.error(`Error: ${error}`);     

};


const getDataExportReturn = async () => {
  var myHeaders = new Headers();

  const params = {
    random_number: randomNumber,
    vendor_id: vendorId,
  };

  const request_url = Object.entries(params)
    .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
    .reduce(
      (url, [key, value]) => url + `&${key}=${value}`,
      `${BASE_URL}api/v1/order/export_to_delivery_excel_variable/?`
    );

  var requestOptions = {
    method: 'GET',
    headers: { Authorization: "Token " + myKey },
    redirect: 'follow'
  };

  const interval = setInterval(async () => {
    try {
      const response = await fetch(request_url, requestOptions);
      const result = await response.json();

      if (response.status === 200) {
        setMyDataResult(result);
        setIsPreparing(false);
        clearInterval(interval); // İstek başarılı olunca interval'ı durdur
      }

    } catch (error) {
      console.log('error', error);
      toast.error(`Error: ${error}`);
    }
  }, 5000); // 5 saniyede bir istek at
};




useEffect(() => {
  let interval;
  if (randomNumber && vendorId) {
    interval = getDataExportReturn();
  }

  return () => {
    clearInterval(interval);
  };
}, [randomNumber, vendorId]);







 return {myDataS3,setMyDataS3, selectStatusModal, setSelectStatusModal, startDateModal, setStartDateModal, endDateModal, setEndDateModal,  cargoModal, setCargoModal, getDataExport, setStoreModal, fulfilmentModal, setFulfilmentModal, showGif, setShowGif, setInvoicesExport, invoicesExport, setRegionExport, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorExportId, setDeliveryStatusModal, setCouirerModal}
}
