import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from '../baseUrl'
import { toast } from 'react-toastify';
import {  format } from 'date-fns';



export default function useRefundFeeCalculation() {

  const [myDataRefund, setMyDataRefund] = useState()
  const {myKey} = useContext(AuthContext)
  
  

  const [refundVendorId,setRefundVendorId] = useState()
 

  const now = new Date();
  const utcFirstDayOfMonth = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
  const [dateOfRefund, setDateOfRefund] = useState(utcFirstDayOfMonth);
  
  let formattedStartDate = "";
  
  if (dateOfRefund) {
    formattedStartDate = format(dateOfRefund, 'yyyy-MM-dd');
  }
 
 
 const getData = ()=>{
        var myHeaders = new Headers();

        if (!dateOfRefund || !refundVendorId) {
          return;
        }


        const params = {
          
          date_of_month: formattedStartDate,
          
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/vendor/${refundVendorId}/refund_fee_calculation/?`
    );

   
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result => {
            setMyDataRefund(result);
          })
          .catch(error => {
            toast.error(`Error: ${error.message}`); 
          });
  };

 



 useEffect(() => {
    getData();
  }, [dateOfRefund, refundVendorId ]);

 return {myDataRefund, dateOfRefund, setDateOfRefund,  setRefundVendorId, refundVendorId }
  
  
  
 
}
