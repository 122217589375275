import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useDebounce from './useDebounce';


export default function useProductListAll() {

 const [myData, setMyData] = useState()

 const {myKey, currentUser} = useContext(AuthContext)
 
 const [page, setPage] = useState(1)
 const [search, setSearch] = useState()
 const [stock, setStock] = useState()
 const [sortState, setSortState] = useState(3)

 const [currentPage,setCurrentPage]=useState(1)
 const [followersPerPage, setFollowersPerPage] = useState(10);
 const [totalPages, setTotalPages] = useState(0);
 const [vendorId, setVendorId ] = useState()
 const [critical, setCritical ] = useState()


 const [subProduct, setSubProduct] = useState()

 const [stokFilter, setStokFilter] =useState()

const debouncedSearch = useDebounce(search, 1500);


 
 const getData = ()=>{
        var myHeaders = new Headers();
        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;

        if (!vendorId) return;

        const params = {
          offset :offset,
          limit :limit,
          // page: page,
          // vendor_username: currentUser,
          search : search,
          count: stock, 
          sort_by_stock : sortState,
          vendor_id : vendorId,
          sub_product : subProduct,
          critical: critical
        };
    
        const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}api/v1/order/product_list/?`
        );

            
      console.log("wurl", request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            console.log("p_result", result)
            setMyData(result) 
            setTotalPages(Math.ceil(result.count / followersPerPage))
    })
          .catch(error => console.log('error', error));        
 }

 useEffect(() => {
    getData();
  }, [page, debouncedSearch, stock, currentPage, followersPerPage,sortState, vendorId, critical]);

 return {myData, setPage, setSearch, setStock, stock, currentPage,
  setCurrentPage, getData,
  followersPerPage,
  setFollowersPerPage,
  totalPages, sortState, setSortState, setVendorId, vendorId, setSubProduct, setStokFilter, setCritical}
}


