import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { ProductContext } from 'context/Context';
import { productData } from 'data/ecommerce/productData';
import { productReducer } from 'reducers/productReducer';
import product2 from 'assets/img/products/2.jpg';
import useProductList from 'hooks/useProductList';

const ProductProvider = ({ children }) => {


  // const {myData} = useProductList()

  // const productData = myData?.results.map((item)=>{
  //     return {
  //         name: item.Title,
  //         files: [
  //           {
  //             src: item.Image
  //           }
  //         ],
  //         // price : item.stock,
  //         features :[]
  //     }
  // })


  useEffect(() => {
    
}, [productData])


  const productDatax = [
    {
      name: 'Apple iMac Pro (27-inch with Retina 5K Display, 3.0GHz 10-core Intel Xeon W, 1TB SSD)',
      // category: 'Computer & Accessories',
      // id: 'TN000001',
      features: [
        // '16GB RAM',
        // '1TB SSD Hard Drive',
        // '10-core Intel Xeon',
        // 'Mac OS',
        // 'Secured'
      ],
      // price: 2399,
      // discount: 50,
      // salePrice: 1199.5,
      // shippingCost: 50,
      // rating: 4,
      // totalReview: 8,
      // favorite: 282,
      // isInStock: true,
      // isNew: true,
      // tags: ['Computer', 'Mac Book', 'Mac Book Pro', 'Laptop'],
      // shortDescription:"",
        
      // desc: "",
      // specification: {
      //   Processor: '2.3GHz quad-core Intel Core i5',
      //   Memory: '8GB of 2133MHz LPDDR3 onboard memory',
      //   'Brand Name': 'Apple',
      //   Model: 'Mac Book Pro',
      //   Display: '13.3-inch (diagonal) LED-backlit display with IPS technology',
      //   Storage: '512GB SSD',
      //   Graphics: 'Intel Iris Plus Graphics 655',
      //   Weight: '7.15 pounds',
      //   Finish: 'Silver, Space Gray'
      // },
      // reviews: [
      //   {
      //     id: 1,
      //     title: 'Awesome support, great code 😍',
      //     rating: 5,
      //     author: 'Drik Smith',
      //     date: 'October 14, 2019',
      //     text: "You shouldn't need to read a review to see how nice and polished this theme is. So I'll tell you something you won't find in the demo. After the download I had a technical question, emailed the team and got a response right from the team CEO with helpful advice."
      //   },
      //   {
      //     id: 2,
      //     title: 'Outstanding Design, Awesome Support',
      //     rating: 4.5,
      //     author: 'Liane',
      //     date: 'December 14, 2019',
      //     text: 'This really is an amazing template - from the style to the font - clean layout. SO worth the money! The demo pages show off what Bootstrap 4 can impressively do. Great template!! Support response is FAST and the team is amazing - communication is important.'
      //   }
      // ],
      files: [
        {
          // id: 1,
          src: product2
        }
      ]
    }
  ]

  const initData = {
    initProducts: productData || [],
    products: productData || [],
    cartItems: [
      // {
      //   ...productData[0],
      //   quantity: 3,
      //   totalPrice: productData[0].price * 3
      // }  
      // },
      // {
      //   ...productData[1],
      //   quantity: 3,
      //   totalPrice: productData[1].price * 3
      // },
      // { ...productData[2], quantity: 3, totalPrice: productData[2].price * 3 }
    ],
    promo: null,
    favouriteItems: [],
    cartModal: {
      show: false,
      product: {},
      quantity: 0,
      type: 'add'
    }
  };
  const [productsState, productsDispatch] = useReducer(
    productReducer,
    initData
  );

  const isInShoppingCart = id =>
    !!productsState.cartItems.find(cartItem => cartItem.id === id);
  const isInFavouriteItems = id =>
    !!productsState.favouriteItems.find(
      favouriteItem => favouriteItem.id === id
    );

  return (
    <ProductContext.Provider
      value={{
        productsState,
        productsDispatch,
        isInShoppingCart,
        isInFavouriteItems
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

ProductProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProductProvider;
