import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext, useRef } from 'react'
import {BASE_URL} from './baseUrl'
import { toast } from 'react-toastify';




export default function useVendorStoreProductLink() {


  const {myKey} = useContext(AuthContext)
  const [productId, setProductId] = useState()


 const postLink = (id)=>{
       
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${myKey}`);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

        const params = {
       
         
        };
      
        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) 
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/product/vendorstore_product/${id}/link_product/?`
        );

        var raw = JSON.stringify({
          "product_id" : productId,
          
  
  });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(request_url, requestOptions)
        .then(response => response.json().then(data => ({ ok: response.ok, body: data })))
        .then(({ ok, body }) => {
            if (ok) {
                console.log(body);
                toast.success(`${id} id numaralı ${body.message}`, { theme: 'colored' });
            } else {
                console.log("Post işlemi başarısız.");
            }
        })
        .catch(error => {
            console.log('error', error);
        });
    }


    const postUnLink = (id)=>{
       
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Token ${myKey}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");


      const params = {
       
         
      };
    
      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) 
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/product/vendorstore_product/${id}/unlink_product/?`
      );

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(request_url, requestOptions)
      .then(response => response.json().then(data => ({ ok: response.ok, body: data })))
      .then(({ ok, body }) => {
          if (ok) {
              console.log(body);
              toast.success(`${id} id numaralı ${body.message}`, { theme: 'colored' });
          } else {
              console.log("Post işlemi başarısız.");
          }
      })
      .catch(error => {
          console.log('error', error);
      });
  }




 return {postLink, setProductId, postUnLink}
}
