import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
// import team3 from 'assets/img/team/3.jpg';
import user from 'assets/img/team/avatar.png';
import { MdLogout } from "react-icons/md";
import { RiKey2Line } from "react-icons/ri";

import Avatar from 'components/common/Avatar';
import { AuthContext } from 'context/AuthContext';

const ProfileDropdown = () => {

  const {logOut} = useContext(AuthContext)
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={user} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/* <Dropdown.Item className="fw-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Go Pro</span>
          </Dropdown.Item> */}
          <Dropdown.Divider />
          {/* <Dropdown.Item href="#!">Set status</Dropdown.Item> */}
          {/* <Dropdown.Item as={Link} to="/user/profile">
            Profile &amp; account
          </Dropdown.Item> */}
          {/* <Dropdown.Item href="#!">Feedback</Dropdown.Item>
          <Dropdown.Divider /> */}
          <Dropdown.Item as={Link} to="/authentication/password-change">
            <RiKey2Line style={{marginRight:5, fontSize:18}}/>
           Parola Değiştir
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/authentication/card/logout" onClick={(e)=>logOut(e)}>
            <MdLogout style={{marginRight:5, fontSize:18}}/> 
              Çıkış Yap
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
