import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'

export default function useVendorStoreParam() {

 const [vendorStore, setVendorStore] = useState()

 const {myKey, currentUser} = useContext(AuthContext)

 const [vendorIdVendorStore, setVendorIdVendorStore] = useState()
 


 const getData = ()=>{
  var myHeaders = new Headers();



  const params = {
    vendor_id: vendorIdVendorStore,
    products : true
  };

  const request_url = Object.entries(params)
      .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
      .reduce(
        (url, [key, value]) => url + `&${key}=${value}`,
        `${BASE_URL}api/v1/store/integration/vendor_store/?`
  );

  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setVendorStore(result) 
})
    .catch(error => console.log('error', error));        
}



 useEffect(() => {
    getData();

  }, [vendorIdVendorStore]);

 return {vendorStore, getData, setVendorIdVendorStore}
}
