import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'



export default function useCourierList() {

  const [myDataCourier, setMyDataCourier] = useState()

  const {myKey, currentUser} = useContext(AuthContext)


 
 const getData = ()=>{
        var myHeaders = new Headers();

        
        const params = {
        
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/order/couriers/?`
    );
      

        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyDataCourier(result)
        
    })
          .catch(error => console.log('error', error));        
 }



 useEffect(() => {
    getData();

  }, []);

 return {myDataCourier}
}
