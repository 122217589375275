import classNames from 'classnames';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import SoftBadge from 'components/common/SoftBadge';
import { style } from 'd3';

const CollapseItems = ({ route }) => {
  const { pathname } = useLocation();

  //! navbar menunun açılmasını sağlıyor
  // const openCollapse = childrens => {
  //   const checkLink = children => {
  //     if (children.to === pathname) {
  //       return true;
  //     }
  //     return (
  //       Object.prototype.hasOwnProperty.call(children, 'children') &&
  //       children.children.some(checkLink)
  //     );
  //   };
  //   return childrens.some(checkLink);
  // };

  // navbar menünün state
  // const [open, setOpen] = useState(openCollapse(route.children));
  // asağıdaki Collapse de açılması lazım

  return (
    <Nav.Item as="li">
      <Nav.Link

      //! açılır menü için accordion işareti için
        // onClick={() => {
        //   setOpen(!open);
        // }}
        // className={classNames('dropdown-indicator cursor-pointer', {
        //   'text-500': !route.active
        // })}
        // aria-expanded={open}
        // // {...route}
       
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      {/* <Collapse 
      // in={open} 
      > */}
        <Nav className="flex-column nav" as="ul"
       
        >
          <NavbarVerticalMenu routes={route.children} />
        </Nav>
      {/* </Collapse> */}
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool
  }).isRequired
};

// const NavbarVerticalMenu = ({ routes }) => {

//   const [hover, setHover] = useState(false);

//   const {
//     config: { showBurgerMenu, isDark },
//     setConfig
//   } = useContext(AppContext);

//   const hoverStyle = {
//     color: hover ? '#5F9BEB' : (isDark ? '#C5D4EC' : '#022767'),
//     marginLeft:"9px",
   

//   };
 
//   const handleNavItemClick = () => {
//     if (showBurgerMenu) {
//       setConfig('showBurgerMenu', !showBurgerMenu);
//     }
//   };

//   return routes.map(route => {
//     if (!route.children) {
//       return (
//         <Nav.Item as="li" key={route.name} 
//         onClick={handleNavItemClick}
//         >
//           {/* alt başlık rengi */}
//           <NavLink
//             end={route.exact}
//             to={route.to}
//             state={{ open: route.to === '/authentication-modal' }}
//             className={({ isActive }) =>
//               isActive && route.to !== '#!' ? 'active nav-link' : 'nav-link'
//             }
//           > 
//           <span className="nav-link-icon"
//           style={hoverStyle}
//           onMouseEnter={() => setHover(true)}
//           onMouseLeave={() => setHover(false)}
//           >
//           {route.name}
//           </span>
          
//           {route.badge && (
//         <SoftBadge pill bg={route.badge.type} className="ms-2">
//           {route.badge.text}
//         </SoftBadge>
//       )}
//             <NavbarVerticalMenuItem route={route} />
//           </NavLink>
//         </Nav.Item>
//       );
//     }
//     return <CollapseItems route={route} key={route.name1} />;
//   });
// };


//...

const NavLinkItem = ({ route, handleNavItemClick, isDark }) => {
  const location = useLocation();  // Add this line
  const [hover, setHover] = useState(false);


  const isActive = route.to === location.pathname;  // Add this line
  const hoverStyle = {
    color: hover || isActive ? '#5F9BEB' : (isDark ? '#C5D4EC' : '#022767'),  // Modify this line
    marginLeft:"12px",
    // backgroundColor : hover ? "#F9FBFD" : "",
    // position: 'relative',
    // zIndex: hover ? 1 : 0,
    // padding: "8px",
    // borderRadius: "5px",
    fontSize : "13px"
  };

  return (
    <Nav.Item as="li" key={route.name} onClick={handleNavItemClick}>
      <NavLink
        end={route.exact}
        to={route.to}
        state={{ open: route.to === '/authentication-modal' }}
        className={({ isActive }) =>
          isActive && route.to !== '#!' ? 'active nav-link' : 'nav-link'
        }
      >
        <span
          className="nav-link-icon"
          style={hoverStyle}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {route.name}
        </span>

        {route.badge && (
          <SoftBadge pill bg={route.badge.type} className="ms-2">
            {route.badge.text}
          </SoftBadge>
        )}
        <NavbarVerticalMenuItem route={route} />
      </NavLink>
    </Nav.Item>
  );
};



const NavbarVerticalMenu = ({ routes }) => {
  const {
    config: { showBurgerMenu, isDark },
    setConfig
  } = useContext(AppContext);
  const [activePath, setActivePath] = useState('');

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  return routes.map(route => {
    if (!route.children) {
      return (
        <NavLinkItem 
          route={route} 
          handleNavItemClick={handleNavItemClick} 
          isDark={isDark} 
          activePath={activePath}
          setActivePath={setActivePath} 
        />
      );
    }
    return <CollapseItems route={route} key={route.name1} />;
  });
};




NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired
};

export default NavbarVerticalMenu;
