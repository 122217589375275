import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import { toast } from 'react-toastify';
import {BASE_URL} from './baseUrl'


export default function useIntegrationFarmazon() {

 const {myKey, currentUser} = useContext(AuthContext)
 const [formData, setFormData] = useState({
        apiKey : "",
        apiSecret: "",
        vendorId : "",
        password : ""
});


 

console.log("useForm",formData)


 const postData = ()=>{

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL+"api/v1/store/integration/integration_store_create/"
    

    var raw = JSON.stringify({
      "name" : "Farmazon",
      "api_key": formData.apiKey,
      "api_secret": formData.apiSecret,
      "key":  `API_${formData.vendorId}`,
      "value" : formData.password,
      "base_url" : "https://lab.farmazon.com.tr/api/v1"
      
 });
    
  console.log("raw", raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
    .then(response => {
      if (response.ok) {
          response.json().then(data => {
              console.log(data);
              if (data.hasOwnProperty('id')) {
                toast.success(`ID ${data.id} başarıyla gönderildi.`, { theme: 'colored' });
              } else {
                toast.error("ID değeri bulunamadı.", { theme: 'colored' });
              }
          })
      } else {
          console.log("Post işlemi başarısız.");
          throw new Error("Post işlemi başarısız.");
      }
    })
    .catch(error => {
      console.log('error', error);
      toast.error(`${error.message}`, { theme: 'colored'});
    });

}

useEffect(() => {
  if(formData.apiSecret && formData.apiKey && formData.vendorId){
    postData()
   }



}, [formData])



 return {setFormData, formData}
}
