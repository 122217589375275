import { useContext } from 'react';
import { AuthContext } from 'context/AuthContext';
import { toast } from 'react-toastify';
import { BASE_URL } from './baseUrl';
import { useNavigate } from 'react-router-dom';

const useIntegrationMetaDataUpdate = () => {
  const { myKey } = useContext(AuthContext);
  const navigate = useNavigate()

  const updateData = (id, formData) => {
    if (!formData) return; 

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");

    const request_url = `${BASE_URL}api/v1/metadata/${id}/new/`;

    const raw = JSON.stringify(formData);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(request_url, requestOptions)
      .then(response => {
        if (response.ok) {
          toast.success("İşlem başarıyla tamamlandı.", { theme: 'colored' });
          // navigate('/e-commerce/integration-metadata_list');
        } else {
          response.json().then(data => {
            toast.error(data.detail || "Bir hata meydana geldi!", { theme: 'colored' });
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        toast.error(`${error.toString()}`, { theme: 'colored' });
      });
  };

  return { updateData };
};

export default useIntegrationMetaDataUpdate;
