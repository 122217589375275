import IconButton from 'components/common/IconButton';
import { PermsContext } from 'context/PermsContext';
import useOrderList from 'hooks/useOrderList';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const OrdersTableHeader = ({ selectedRowIds }) => {


  return (
    <Row className="flex-between-center">
      

      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        <div style={{display:"flex"}}>
          <div id="orders-actions" style={{marginRight:"10px"}}>     
             {/* <Form.Select size="sm" onChange={handleChange}>
    <option value="" disabled selected>Sipariş Durumu</option>
    {orderStatus?.map((item, i)=>{
        return <option key={i} value={item.value}>{item.label}</option>
    })}
          </Form.Select>  */}
          </div>

         
    </div>
      </Col>
    </Row>
  );
};

OrdersTableHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default OrdersTableHeader;
