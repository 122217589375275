import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'

import { toast } from 'react-toastify';
import useVendorList from './useVendorList';

export default function useProductDeliveryPanel() {


  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1; 
  const date = today.getDate();

  const todayDate = `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}`;



 const {myKey, currentUser} = useContext(AuthContext)

 const [vendorId, setVendorId] = useState()

 const {myDataVendor} = useVendorList()



const [formData, setFormData] = useState({
        Inventory: '',
        ShipmentCompany: '',
        ShipmentTrackingNumber: '',
        // InboundStockRealtime: '',
        // Barcode: '',
        // Count: '',
        // Name : '',
        Products:''
    });
 

 const postData = ()=>{



    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL+"wms/api/v1/inventory_shipment/?vendor_id="+vendorId
    
    
    // var raw = JSON.stringify({...formData})
    

    var productsArray = Array.isArray(formData.Products) ? formData.Products : Object.values(formData.Products);

    console.log("productsArray", productsArray)

    var raw = JSON.stringify({
      "Inventory": formData.Inventory,
      "InventoryPackagingType": 1,
      "EstimatedArrivalDate": todayDate,
      "ShipmentTrackingNumber": formData.ShipmentTrackingNumber,
      "ShipmentCompany": formData.ShipmentCompany,
      "Status": "1",
      "User" : currentUser,
      "InventoryShipmentProducts": productsArray.map(p=>({
          "Name": p.name,
          "Barcode": p.barcode,
          "Count": Number(p.count),
          "SKU" : p.sku
        }))
    });
    
 console.log("raw",raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
  .then(response => {
    if (response.ok) {
        response.text()
        .then(result => {
            console.log(result)
            toast.success(`başarılı şekilde gönderildi.`, { theme: 'colored'});
        })
    } else {
        console.log("Post işlemi başarısız.")
    }
  })
  .catch(error => console.log('error', error));

}

useEffect(() => {
 if(formData.Inventory){
  postData()
 }

}, [formData])


 

 return {setFormData, formData, postData, setVendorId}
}
