import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Dropdown, Form, Col, InputGroup, Alert, Row} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OrdersTableHeader from './OrdersTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Pagination from './PaginationOrder';
import Button from 'react-bootstrap/Button';
import gifOrder from 'assets/img/gif_order.gif'
import {  PermsContext } from 'context/PermsContext';
import useOrderWorkList from 'hooks/useOrderWorkList';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import useOrderWorkCancel from 'hooks/useOrderWorkCancel';








const OrderWorkList = () => {

  const columns = [
    {
      accessor: 'name',
      Header: 'İş Emri Numarası',
      disableSortBy: true,
      headerProps: { className: 'text-left'},
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <>
            <Link to="/e-commerce/orders/order-work-detail" state={{id}}>
              <strong>{id}</strong>
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Tarih',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      }
    },
    
    {
      accessor: 'none',
      Header: 'Adet',
      disableSortBy: true,
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { count} = rowData.row.original;
        return (
          <p>{count}</p>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Durumu',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              success: status === 4, // delivered
              primary: status === 3,   // completed
              info: status === 2,  // processing
              warning: status === 1,   // pending
              dark : status === 0  // onhold
            })}
            className="d-block"
          >
            {status === 4 && 'Teslim Edildi'} 
            {status === 3 && 'Kargoya Verildi'}  
            {status === 2 && 'Paketlendi'} 
            {status === 1 && 'Hazırlanıyor'} 
            {status === 0 && 'Beklemede'}  
            <FontAwesomeIcon
              icon={classNames({
                check: status === 4,
                check: status === 3,
                redo: status === 2,
                stream: status === 1,
                ban: status === 0
              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      }
    },
    {
      Header: 'Aksiyon',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const {id} = rowData.row.original;
        const [isMenuOpen, setIsMenuOpen] = useState(false);
        const menuRef = useRef(null);
    
     
        const handleMenuClick = () => {
          setIsMenuOpen(!isMenuOpen);
        };
    
        const handleModalOpen = () => {
          setIsModalOpen(true);  // Ana bileşende tanımlanan setIsModalOpen
          setSelectedId(id);  // Ana bileşende tanımlanan setSelectedId
        };
    
        return (
          <div style={{display:'flex',  justifyContent: 'center'}}>
          <div
            ref={menuRef}
            onClick={handleMenuClick}
            style={{
              cursor: 'pointer',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              width: '30px',
            }}
          >
            ...
            {isMenuOpen && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '0',
                  transform: 'translate(-110%, -50%)',
                  backgroundColor: 'white',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  zIndex: 1000,
                }}
              >
                <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                  {/* <li 
                    style={{ padding: '8px', borderBottom: '1px solid #ccc' }}
                    onClick={handleModalOpen}
                  >
                    İrsaliye Yükle
                  </li> */}
                  <li
                    style={{ padding: '8px' }}
                    onClick={handleModalOpen}
                  >
                    Talebi İptal Et
                  </li>
                </ul>
              </div>
            )}
          </div>
          </div>
        );
      }
    }

    
  ];



const {myData, setPage, selectStatus, setSelectStatus, startDate, setStartDate, endDate, setEndDate, setCargo, cargo, getData, firstName, setFirstName, setStore, setSearch, setPaid, showOrderGif, currentPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, setInvoices, setInventoryRegion , sortAmount, setSortAmount, setVendorIdList} = useOrderWorkList()
 
const {getCancelData} = useOrderWorkCancel()


  const [activePage, setActivePage] = useState(1)
  const [orderList, setOrderList] = useState() 

  useEffect(() => {
    setPage(activePage)
  }, [activePage])
  


  useEffect(() => {

    const getStatusIndex = (detail) => {
      if (detail?.Count === 0) {
          return 0;  // Count 0 ise direk "beklemde" (0 indeksi) olarak dönüyoruz.
      }
  
      let progressStatusIndex = 0;
      if (detail?.DeliveredCount === detail?.Count) {
          progressStatusIndex = 4;
      } else if (detail?.ShippedCount > 0) {
          progressStatusIndex = 3;
      } else if (detail?.PackagedCount === detail?.Count) {
          progressStatusIndex = 2;
      } else if (detail?.PackagedCount > 0) {
          progressStatusIndex = 1;
      } else if (detail?.PreparingCount === detail?.Count) {
          progressStatusIndex = 1;
      } else if (detail?.PreparingCount > 0) {
          progressStatusIndex = 0;
      }
      return progressStatusIndex;
  };
  

    const newList = myData?.map((item) => {

        const date = new Date(item.DateTime);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const status = getStatusIndex(item);
        return {
            id: "#" + item.Id,
            count: item.Count,
            date: `${day}-${month}-${year}`,
            status: status  // Bu kısımda doğrudan indeks numarasını döndürdük.
        };
    });

    console.log("newList", newList)
    setOrderList(newList);

}, [myData]);

const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedId, setSelectedId] = useState(null);

const handleModalClose = () => {
  setIsModalOpen(false);
};



const handleWorkCancel = () => {
  if (selectedId) {
    getCancelData(selectedId);  // Dosyayı ve id'yi useHook'a gönder
    handleModalClose(); // Modalı kapat
  }
};




  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      // data={orderList?.length>0 && orderList} 
      selection
      sortable
      pagination
      perPage={50}
    >
     
      <Card className="mb-3">
        <Card.Header>

      <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">İş Emri Talepleri</h5>
            </div>


            <div style={{display:"flex", justifyContent:'right'}}>

            <div >
              <OrdersTableHeader table />
            </div>
    </div>     
  </div>
</Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />


        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showOrderGif && <img src={gifOrder} style={{width:'50%'}} alt="loading gif" />}
          </div>
        
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <Pagination pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage}/>
        </Card.Footer>
       
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}>Sipariş sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>


      <Modal show={isModalOpen} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bilgilendirme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          İptal etmek istediğinize emin misiniz?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Hayır
          </Button>
          <Button variant="danger" onClick={handleWorkCancel}>
            İptal Et
          </Button>
        </Modal.Footer>
      </Modal>

  
    </AdvanceTableWrapper>
  );
};

export default OrderWorkList;