import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from '../baseUrl'
import { toast } from 'react-toastify';
import useVendorList from 'hooks/useVendorList';




export default function useStoragFeesExport() {

  const [myDataS3, setMyDataS3] = useState()
  const {myKey, currentUser} = useContext(AuthContext)
  
  const [myDataResult, setMyDataResult] = useState()

  const [showGif, setShowGif] = useState(false);
 

  const [randomNumber, setRandomNumber] = useState()
  // const [vendorId, setVendorId] = useState()
  const [isPreparing, setIsPreparing] = useState(false);

  const [vendorIdExport, setVendorIdExport] = useState()
  const [inventoryExportId, setInventoryExportId] = useState()
  const {myDataVendor} = useVendorList()

  const todayDay = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const twoDay = 1000 * 60 * 60 * 24 *2;

  const [startDateModal, setStartDateModal] = useState(new Date(todayDay.getTime() - oneDay * 10));
  const [endDateModal, setEndDateModal] = useState(new Date(todayDay.getTime() + oneDay * 1));

  const [skuCurrentModal, setSkuCurrentModal] =useState()


  let formattedStartDate = "";
  let formattedEndDate = "";

 

  if(startDateModal){
    formattedStartDate = new Date(startDateModal.getTime() + oneDay).toISOString().slice(0, 10);
  }
  if(endDateModal){
    formattedEndDate = new Date(endDateModal.getTime() + twoDay).toISOString().slice(0, 10);
  }


  useEffect(() => {
    if (myDataVendor?.length === 1) {
      setVendorIdExport(myDataVendor[0].Id);
    }
  }, [myDataVendor]);



 const getDataExport = async ()=>{
      var myHeaders = new Headers();


      const params = {

       
        begin_date: formattedStartDate,
        end_date: formattedEndDate,

      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}wms/api/v1/inventory/${inventoryExportId}/export_storage_fees_excel_threading/?`
  );

  console.log("request_url_activide", request_url)


    var requestOptions = {
      method: 'GET',
      headers: {"Authorization": "Token "+myKey},
      redirect: 'follow'
    };
    fetch(request_url, requestOptions)
      .then(response => response.json())
      .then(result =>{
        setMyDataS3(result) 
        setRandomNumber(result.random_number)
        // setVendorId(result.vendor)
        setShowGif(false);
        console.log("resultss3", result )

})
    .catch(error => {
      console.log('error', error);
      toast.error(`Error: ${error}`);
    });


};


const getDataExportReturn = async () => {
  var myHeaders = new Headers();

  const params = {
    random_number: randomNumber,
    vendor_id: vendorIdExport,
  };

  const request_url = Object.entries(params)
    .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
    .reduce(
      (url, [key, value]) => url + `&${key}=${value}`,
      `${BASE_URL}wms/api/v1/inventory/export_to_storage_excel_variable/?`
    );

  var requestOptions = {
    method: 'GET',
    headers: { Authorization: "Token " + myKey },
    redirect: 'follow'
  };

  const interval = setInterval(async () => {
    try {
      const response = await fetch(request_url, requestOptions);
      const result = await response.json();

      if (response.status === 200) {
        setMyDataResult(result);
        setIsPreparing(false);
        clearInterval(interval); // İstek başarılı olunca interval'ı durdur
      }

    } catch (error) {
      console.log('error', error);
      toast.error(`Error: ${error}`);
    }
  }, 5000); // 5 saniyede bir istek at
};




useEffect(() => {
  let interval;
  if (randomNumber && vendorIdExport) {
    interval = getDataExportReturn();
  }

  return () => {
    clearInterval(interval);
  };
}, [randomNumber, vendorIdExport]);







 return {myDataS3,setMyDataS3, getDataExport, showGif, setShowGif, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorIdExport, setInventoryExportId, startDateModal, setStartDateModal, endDateModal, setEndDateModal, skuCurrentModal, setSkuCurrentModal}
}
