import React, { useContext, useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import routes from 'routes/routes';
import routesPerm from 'routes/routesPerms';
import { capitalize } from 'helpers/utils';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import PurchaseCard from './PurchaseCard';
import { PermsContext } from 'context/PermsContext';


const NavbarVertical = () => {
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
      isDark
    }
  } = useContext(AppContext);

  

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  const {perms} = useContext(PermsContext)

 

  function hasPermission(requiredPermissions) {
    if (!perms || !Array.isArray(perms)) {
      return false;
    }

    return requiredPermissions.some(permission => perms.includes(permission));
  }
  
  


  // function filterRoutes(routes) {
  //   return routes.filter(route => {
  //     // Eğer route'un bir permissions alanı varsa ve bu bir dizi ise, kontrol et
  //     if (Array.isArray(route.permissions)) {
  //       return hasPermission(route.permissions);
  //     }
  //     // Eğer permissions alanı yoksa veya bir dizi değilse, varsayılan olarak rotayı göster
  //     return true;
  //   });
  // }
  
  // // // Apply filter to the children of each route object
  // const filteredRoutes = routes.map(routeObj => {
  //   return {
  //     ...routeObj,
  //     children: filterRoutes(routeObj.children)
  //   };
  // })

  function filterRoutes(routes) {
    // Her rota için izin kontrolü yap
    return routes.reduce((filtered, route) => {
      // Eğer rota için belirli izinler tanımlanmışsa ve kullanıcının bu izinleri yoksa, rotayı dahil etme
      if (Array.isArray(route.permissions) && !hasPermission(route.permissions)) {
        return filtered;
      }
  
      // Rota'nın çocukları varsa ve bu bir dizi ise, onları da filtrele
      if (route.children && Array.isArray(route.children)) {
        route = {
          ...route,
          children: filterRoutes(route.children)
        };
      }
  
      // İzin kontrolünden geçen rotaları filtrelenmiş rotalara ekle
      return [...filtered, route];
    }, []);
  }
  
  // Önce en üst seviyedeki rotalar üzerinde çalıştır ve sonuçları al
  const filteredRoutes = filterRoutes(routes);
  



  

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
      style={{
    
      }}
    >
      {/* orjinal logo ve toggle */}
      {/* <Flex alignItems="center">
        <ToggleButton />
        <Logo at="navbar-vertical" width={40} />
      </Flex> */}
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          // navbar backdround
          backgroundImage:
                isDark
              ? `linear-gradient(89.12deg, #022767 0.46%, #0B1727 98.95%)`
              : 'linear-gradient(37.83deg, #BED6FF -29.45%, #EDF2F9 55.76%)',
          height:"100% !important",
          width:"250px",
          paddingLeft:"15px",
          
        }}
      >
        <Flex alignItems="center" >
        <ToggleButton />
        <Logo at="navbar-vertical"  />
      </Flex>
        <div className="navbar-vertical-content scrollbar">
          <Nav className="flex-column" as="ul">
          {filteredRoutes.map(route => (
              <Fragment key={route.label}>
                {!route.labelDisable && (
                  <NavbarLabel label={capitalize(route.label)} />
                )}
                <NavbarVerticalMenu routes={route.children} />
              </Fragment>
            ))}
          </Nav>

          <>
            {navbarPosition === 'combo' && (
              <div className={`d-${topNavbarBreakpoint}-none`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
                <Nav navbar>
                  <NavbarTopDropDownMenus />
                </Nav>
              </div>
            )}
            {/* <PurchaseCard /> */}
          </>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string
};

export default NavbarVertical;
