import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { PermsContext } from 'context/PermsContext';
import useInventoryRegion from './useInventoryRegion';
import { addDays, format } from 'date-fns';
import { FaSkullCrossbones } from 'react-icons/fa';


export default function useTotalStockSKU() {

 const [myData, setMyData] = useState()
 const [myDataChart, setMyDataChart] = useState()


 const {myKey, currentUser} = useContext(AuthContext)
 const {vendorIdContext} = useContext(PermsContext)
 const [inventoryId, setInventoryId] = useState()

 const [loading, setLoading] = useState(true);

 const [sku, setSKU] = useState()

 const [currentPage,setCurrentPage]=useState(1)
 const [followersPerPage, setFollowersPerPage] = useState(10);
 const [totalPages, setTotalPages] = useState(0);

 const { setInventoryContext, inventoryContext} = useContext(PermsContext)




 const date = new Date();
const oneDay = 1000 * 60 * 60 * 24;
const twoDay = 1000 * 60 * 60 * 24 *2;

const [startDate, setStartDate] = useState(new Date(date.getTime() - oneDay * 7));
const [endDate, setEndDate] = useState(new Date(date.getTime() + oneDay * 2));

let formattedStartDate = "";
let formattedEndDate = "";

if(startDate){
  formattedStartDate = new Date(startDate.getTime()  + oneDay).toISOString().slice(0, 10);
}
if(endDate){
  formattedEndDate = new Date(endDate.getTime() + twoDay).toISOString().slice(0, 10);
}



 const getData = ()=>{

        var myHeaders = new Headers();
        if (!inventoryContext && !sku) {
          return; 
        }

        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;


          const params = {
            // vendor_id: vendorIdContext

            offset :offset,
            limit :limit,

            begin_date : formattedStartDate,
            end_date   : formattedEndDate,
            sku : sku

          };
    
          const request_url = Object.entries(params)
              .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
              .reduce(
                (url, [key, value]) => url + `&${key}=${value}`,
                `${BASE_URL}wms/api/v1/inventory/${inventoryContext}/daily_sku_fees/?`
          );

        
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result.results)
            setTotalPages(Math.ceil(result.count / followersPerPage))
            setLoading(false) 
    })
          .catch(error => console.log('error', error));
        
 }


 const getDataChart = ()=>{

  var myHeaders = new Headers();
  if (!inventoryContext && !sku) {
    return; 
  }

  const offset = (currentPage - 1) * followersPerPage;
  const limit = followersPerPage;


    const params = {
      begin_date : formattedStartDate,
      end_date   : formattedEndDate,
      sku : sku

    };

    const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}wms/api/v1/inventory/${inventoryContext}/daily_sku_fees/?`
    );

    console.log("request_url", request_url)
  
  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMyDataChart(result.results)
    
})
    .catch(error => console.log('error', error));
  
}







 useEffect(() => {
    getData();
    getDataChart()
  }, [inventoryContext, endDate, startDate, currentPage, followersPerPage,sku]);

 return {myData, loading, setLoading, setInventoryId, setStartDate, setEndDate, startDate, endDate, setSKU, sku, currentPage, setCurrentPage, followersPerPage,setFollowersPerPage, totalPages, myDataChart}
}
