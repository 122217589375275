import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row, Table } from 'react-bootstrap';
// import { orderedProductsData } from 'data/ecommerce/OrderDetailsData';
import { useLocation } from 'react-router-dom';
import useOrderRefund from 'hooks/useOrderRefund';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';

const OrderedRefundProducts = () => {

  const location = useLocation()
  const {id} = location.state || ""
  const {detail, getDetail} = useOrderRefund()

  const [showModal, setShowModal] = useState({});

  const handleCloseModal = (productId) => {
    setShowModal(prevState => ({ ...prevState, [productId]: false }));
  };

  const handleOpenModal = (productId) => {
    setShowModal(prevState => ({ ...prevState, [productId]: true }));
  };


  useEffect(() => {
    if(id){
    getDetail(id.slice(1))
    }
  
  }, [id ])

  const status = () => {
    const statusList = ["", "Sağlam", "Sağlam/Kurcalanmış-Kullanılmış", "Hasarlı", "Hasarlı/Kurcalanmış-Kullanılmış"];
  
    const productsWithStatus = detail?.Products?.map(product => ({
      ...product,
      status: statusList[product.Status],
      detailStatus: product.detailed_status
    }));
  
    return {
      products: productsWithStatus
    };
  }
  

  
  const statusResult = status();





  // const subtotal = orderedProductsData?.length>0 && orderedProductsData[0]?.reduce(
  //   (acc, curr) => curr.quantity * curr.rate + acc,
  //   0
  // );
 
  // const tax = getPercentage(subtotal, 5);

  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Ürün</th>
                <th className="border-0">İsim</th>
                <th className="border-0">SKT</th>
                <th className="border-0">Lot</th>
                <th className="border-0 text-center">Adet</th>
                <th className="border-0 text-center">Durumu</th>
                <th className="border-0 text-center">Hasar Durumu</th>
                <th className="border-0 text-end">Açıklama</th>
                <th className="border-0 text-end">Resimler</th>
              </tr>
            </thead>
            <tbody>
              {statusResult?.products?.length>0 && statusResult?.products.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle">
                    <img style={{width:"60px"}}src={product?.product_info?.Image} alt="" />
                    
               
                  </td>
                  <td className="align-middle" title={product?.product_info?.Title}
                  onMouseOver={(e) => (e.currentTarget.title = product.Count)}>
                 <h6 className="mb-0 text-nowrap">
                 {/* {product?.product_info?.Title} */}
                  {product?.product_info?.Title.length > 40
                      ? product?.product_info?.Title.slice(0, 40).concat("...")
                      : product?.product_info?.Title}
                    </h6>
                  </td>
                  <td className="align-middle text-center">
                    {product?.ExpirationDate || "-"} 
                  </td> <td className="align-middle text-center">
                    {product?.Lot  || "-"}
                  </td>
                  <td className="align-middle text-center">
                    {product?.Count}
                  </td>
                  <td className="align-middle text-center">
                  {product?.status}
                  </td>

                  <td className="align-middle text-center">
                  {product?.detailStatus}
                  </td>
                  <td className="align-middle text-end">
                  {product?.Note}
                 
                  </td>
                  <td className="align-middle text-center" style={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}>
                        <div style={{ position: 'relative', height: '80px' }}>
                            {product?.photos?.length > 0 ? product.photos.map((photo, index) => (
                                <div
                                    key={photo.Id}
                                    style={{
                                        position: 'absolute',
                                        bottom: `${index * 10}px`,
                                        left: `${index * 10}px`,
                                        zIndex: product.photos.length - index,
                                    }}
                                    onClick={() => handleOpenModal(product.Id)}
                                >
                                    <img
                                        style={{
                                            border: '3px solid black',
                                            borderRadius: '5px',
                                            width: '100px',
                                            height: '60px'
                                        }}
                                        src={photo.Url}
                                        alt=""
                                    />
                                </div>
                            )) : <p style={{marginTop:'30px', marginRight:'-80px'}}> iade paket fotoğraf yüklenmemiştir.</p>}
                        </div>
                        <Modal show={showModal[product?.Id]} onHide={() => handleCloseModal(product?.Id)} centered>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <Carousel>
                                    {product?.photos?.length > 0 ? product.photos?.map((photo) => (
                                        <Carousel.Item key={photo.Id}>
                                            <img
                                                src={photo?.Url}
                                                alt=""
                                                style={{ width: '100%', height: 'auto' }}
                                            />
                                        </Carousel.Item>
                                    )) : <p style={{textAlign:"center", textJustify:"center"}}>fotoğraf yüklenmemiştir.</p>}
                                </Carousel>
                            </Modal.Body>
                        </Modal>
                        </td>
                      </tr>
                        ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  <tr>
                    {/* <th className="text-900"></th> */}
                    {/* <td className="fw-semi-bold">
                      
                      {parseFloat(subtotal).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td> */}
                  </tr>
                  <tr>
                    {/* <th className="text-900">Tax 5%:</th> */}
                    {/* <td className="fw-semi-bold">
                      $
                      {parseFloat(tax).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td> */}
                  </tr>
                  <tr className="border-top">
                    {/* <th className="text-900"></th> */}
                    {/* <td className="fw-semi-bold">
                
                      {parseFloat(subtotal).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td> */}
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
       
      </Card.Body>
    </Card>
  );
};

OrderedRefundProducts.propTypes = {};

export default OrderedRefundProducts;
