import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row, Table, Form, Modal, Button, Alert, Dropdown} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import DatePicker from  "react-datepicker";
import AppContext from 'context/Context';
import useInventoryRegion from 'hooks/useInventoryRegion';
import useSubProductList from 'hooks/useSubProductList';
import useProductActivity from 'hooks/useProductActivity';
import { PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import useProductExportS3 from 'hooks/useProductExportS3';
import useProductActivityExportS3 from 'hooks/useProductActivityExportS3';
import gif from 'assets/img/gif_export_s3.gif'
import Select from 'react-select';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PaginationProduct from './PaginationProduct';

const ProductActivity = () => {

  const {regionData} = useInventoryRegion()
  const location = useLocation()
  const {sku} = location.state || ""


  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  
  

  const {setInventoryId, dataActivity, getData, setSkuCurrent, setType, setCurrentPage, followersPerPage, setFollowersPerPage, totalPages}  = useProductActivity()

  const {getDataExport, showGif, setShowGif, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorIdExport,setInventoryExportId, startDateModal, setStartDateModal, endDateModal, setEndDateModal, setSkuCurrentModal } = useProductActivityExportS3()

  
const {perms} = useContext(PermsContext)
const {myDataVendor} = useVendorList()

const [activePage, setActivePage] = useState(1)

  useEffect(() => {
    if(sku){
      getData(sku)
      setSkuCurrent(sku)
      setSkuCurrentModal(sku)
    }
  }, [sku])


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sku = params.get('sku');
   
    if(sku){
      getData(sku)
      setSkuCurrent(sku)
      setSkuCurrentModal(sku)
    }
  }, [sku]);

  const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);



  const orderedProductsData = Array.isArray(dataActivity) 
  ? dataActivity.map((product, i) => {

    const formatDate = (dateTime) => {
      const dateObj = new Date(dateTime);
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
      const day = dateObj.getDate().toString().padStart(2, '0');
      const hours = dateObj.getHours().toString().padStart(2, '0');
      const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    
      return `${day}-${month}-${year} ${hours}:${minutes}`;
    };
  
    const TypeText = ['',
    'Bilinmeyen',
    'Manuel Değişim', 
    'Mal kabul sırasında artış',
    'Sipariş Otomatik Stok Düşmesi', 
    'Sipariş Toplaması Stok Düşmesi',
    'Mal kabul sonunda artış', 
    'İade paket kabul', 
    'Raftan rafa aktarım', 
    'Depoya teslimat onaylanması', 
    'Sipariş toplaması otomatik düşen stok geri koyma', 
    'Sipariş toplaması taratılıp düşen stok geri koyma',
    'Sipariş Toplaması Ürün Taratma Stok Düşmesi',
    'Sipariş otomatik düşmesi ANA ürün stok geri koyma',
    'Sipariş otomatik düşmesi ALT ürün stok geri koyma',
    'Sipariş otomatik alt ürün düşmesi'
  ]

      return {
          id : i+1,
          name: product.Name,
          sku: product.SKU,
          shelf_code : product.ShelfCode || '-',
          user : product.User__username || '-',
          type : TypeText[+product.Type],
          skt : product.ExpirationDate || '-',
          lot : product.Lot || '-',
          changed_stock : product.TotalChangedCount,
          total_stock : product.TotalCount,
          change_date : formatDate(product.DateTime),
          detail2 : product.Detail2 || '-' 

          
      };
  })
  : [];

  console.log("orderedProductsData", orderedProductsData)

  const handleChangeRegion = (e)=> {
    const selectedId = e.target.value;
    setInventoryId(e.target.value); 
    setInventoryExportId(e.target.value)
  
  
    const selectedItem = regionData.find(item => item.Id.toString() === selectedId);
    if (selectedItem) {
      setVendorIdExport(selectedItem.Vendor);
    }
  
  }


function hasPermission(permission) {
  return perms?.includes(permission);
}




const handleChangeVendor = (e)=> {
  setVendorIdExport(e.target.value); 
}


const handleExport = ()=> { 
  getDataExport(sku); 
  setShowGif(true);
  // setShow(false)


  setIsPreparing(true);
  

}

const handleDownload = () => {
  setIsPreparing(false);
  window.location.href = myDataResult[0]?.Value;
  setShow(false)
  setMyDataResult(false)
  setStartDateModal("")
  setEndDateModal("")
}

const onChangeModal = datesExport => {
  const [startExport, endExport] = datesExport;
  setStartDateModal(startExport);
  setEndDateModal(endExport);
};

const [isOpen, setIsOpen] = useState(false);


const [selectedOption, setSelectedOption] = useState(null);

const handleChangeType = (e)=> {
  setType(e.value); 
  setSelectedOption(e)
  setCurrentPage(1)
}


const handleToggle = () => {
  setIsOpen(!isOpen);
};



const types = [
  {value: 0, label: 'Hepsi'},
  {value: 1, label: 'Bilinmeyen'},
  {value: 2, label: 'Manuel Değişim'},
  {value: 3, label: 'Mal kabul sırasında artış'},
  {value: 4, label: 'Sipariş Otomatik Stok Düşmesi'},
  {value: 5, label: 'Sipariş Toplaması Stok Düşmesi'},
  {value: 6, label: 'Mal kabul sonunda artış'},
  {value: 7, label: 'İade paket kabul'},
  {value: 8, label: 'Raftan rafa aktarım'},
  {value: 9, label: 'Depoya teslimat onaylanması'},
  {value: 10,label: 'Sipariş toplaması otomatik düşen stok geri koyma'},
  {value: 11,label: 'Sipariş toplaması taratılıp düşen stok geri koyma'},
  {value: 12,label: 'Sipariş Toplaması Ürün Taratma Stok Düşmesi'},
  {value: 13,label: 'Sipariş otomatik düşmesi ANA ürün stok geri koyma'},
  {value: 14,label: 'Sipariş otomatik düşmesi ALT ürün stok geri koyma'},
  {value: 15,label: 'Sipariş otomatik alt ürün düşmesi'},
  ]
  
  
function getCustomStyles(isDark) {
  return {
    control: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: isDark ? "#0B1727" : "white",
      width: '200px'
    }),
    option: (provided, state) => ({
      ...provided,
      color: isDark ? 'white' : 'black',
      backgroundColor: isDark ? '#0B1727' : 'white',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: isDark ? 'white' : 'black',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: isDark ? 'white' : 'black',
    }),

    menu: (provided, state) => ({
      ...provided,
      width: '160px'
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: '150px',  
      overflow: 'auto' ,
      width: '160px' 
    }),

  };
}





  return (
    <>
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
        <div style={{display:'flex', justifyContent:'space-between',marginBottom:"15px"}}>
          
        <h6>Ürün Aktivitesi</h6>
        <div style={{display:'flex', marginTop:'3px', marginRight:'3px'}}>
                <div id="orders-actions">
                { regionData?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} 
                onChange={handleChangeRegion}
                >
                                <option value="">Depo</option>
                                    { regionData?.map((item, i)=>{
                                          return <option key={i} value={item.Id}>{item.name}</option>
                                    })}       
                            </Form.Select>}
                </div>

                <div style={{marginLeft:'8px'}}>
                    <Select

                      value={selectedOption}
                      onChange={handleChangeType}
                      options={types}
                      // styles={customStyles}
                      placeholder='Tipini seçiniz..'
                      styles={getCustomStyles(isDark)}
                    />
                  </div>

                  {hasPermission('Stok Excel Export') && (
                <div style={{marginLeft:"8px"}}>
                  <IconButton
                        variant="falcon-default"
                        size="m"
                        icon="external-link-alt"
                        transform="shrink-3"
                        onClick={handleShow}
                      >
                        <span className="d-none d-sm-inline-block ms-1">Export</span>
                      </IconButton>
                      </div>
               )} 
        </div>

        </div>
          <Table striped className="border-bottom">
          
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0 text-center">Ürün</th>
                <th className="border-0 text-center">SKU</th>
                <th className="border-0 text-center">Tip</th>
                <th className="border-0 text-center">SKT</th>
                <th className="border-0 text-center">Lot</th>
                <th className="border-0 text-center">Değişim Stoğu</th>
                <th className="border-0 text-center">Toplam Stok</th>
                <th className="border-0 text-center">Bağlam</th>
                <th className="border-0 text-end">Değişim Tarihi</th>
              </tr>
            </thead>
            <tbody>
            {dataActivity.length === 0 && (
                  <tr>
                    <td colSpan="11" className="text-center"  style={{height:'120px', textAlign:'center'}}>
                      Depo seçimi yaparak aktiviteleri görebilirsiniz.
                    </td>
                  </tr>
                )}
                {dataActivity.length > 0 && orderedProductsData.map(product => (
                  <tr key={product.id} className="border-200">
                  
                  <td className="align-middle text-left" style={{whiteSpace: 'normal'}}
                   title={product.name}
                   onMouseOver={(e) => (e.currentTarget.title = product.name)}
                  >
                
                {product.name.length > 40
                      ? product.name.slice(0, 40).concat("...")
                      : product.name}
                  </td>
                  <td className="align-middle">
                    <h6 className="mb-0 text-center">{product.sku}</h6>
                  </td>
                  <td className="align-middle text-center">
                    {product.type}
                  </td>
                  <td className="align-middle text-center">
                    {product.skt}
                  </td>
                  <td className="align-middle text-center">
                    {product.lot}
                  </td>
                  <td className="align-middle text-center  bold-text">
                      <h6 className="mb-0 text-center">{product.changed_stock}</h6>
                  </td>
                  <td className="align-middle text-center">
                    {product.total_stock}
                  </td>
                  <td className="align-middle text-center">
                    {product.detail2}
                  </td>
                  <td className="align-middle text-end">
                    {product.change_date}
                  </td>
                </tr>
                ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
             
            </Col>
          </Row>
        </div>
      </Card.Body>
      <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <PaginationProduct pages={totalPages} activePage={activePage} setActivePage={setActivePage} setCurrentPage={setCurrentPage}/>
        </Card.Footer>
    </Card>

    <Modal 
      show={show} 
      onHide={handleClose}
  
      >
        <Modal.Header closeButton>
          <Modal.Title>Ürün Aktivite Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Lütfen depo seçimi yaparak ürün listesini indiriniz.</p>
       <div style={{marginRight:'8px'}}>
              { regionData?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} 
                    onChange={handleChangeRegion}
                    >
                      <option value="">Depo</option>
                        {regionData?.map((item, i)=>{
                            return <option key={i} value={item.Id}>{item.name}</option>
                        })}       
                </Form.Select>}
            </div>

            <div style={{marginRight:"8px", fontSize:"14px", marginTop:'10px'}}>
                <DatePicker
                showIcon
                selected={startDateModal}
                onChange={onChangeModal}
                startDate={startDateModal}
                formatWeekDay={day => day.slice(0, 3)}
                endDate={endDateModal}
                selectsRange
                dateFormat="MMM dd"
                className='form-control'
                placeholderText='aktivite tarihi'
                />
            </div>

          </Modal.Body>
        <Modal.Footer>
        <Row style={{width:'100%'}}>      
  <Row>

  
    <p>Listeniz hazırlanıyor yazısından sonra tekrar Hazırla butonuna basmayınız.</p>
    {/* <p>Son 30 günlük ürün aktivitesi listelenmektedir.</p> */}


    <Col className="d-flex justify-content-end mb-3">
      <Button variant="secondary" onClick={handleClose} style={{marginRight:'10px'}}>
        Vazgeç
      </Button>

      {!isPreparing && (
      <Button variant="primary" onClick={handleExport}>
        Hazırla
      </Button>
      )}
    </Col>
  </Row>
  {isPreparing && (!myDataResult || !myDataResult.error) && 
  
  <div>
  <div>listeniz hazırlanıyor, lütfen bekleyin...</div>

  <img src={gif} style={{width:'50%'}} alt="export gif" />
  </div>
  
  }
  {myDataResult && myDataResult[0]?.Value?.length > 0  && 
  <Row className="justify-content-center mt-5" style={{width:'100%'}}>
    <Col style={{display: "flex", justifyContent: "center"}}>
    <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding:"10px", fontSize:"13px"}}>

        {/* {myDataS3?.message} */}
        Ürün listesiniz hazır indirebilirsiniz.
      </Alert>
      <Button 
  variant="primary" 
  style={{width:'150px', height:'40px'}}
  // onClick={() => window.location.href = myDataS3?.download_url}
  onClick={handleDownload}
>
  İndir
</Button>

    </Col>
  </Row>

  }
   </Row>
        </Modal.Footer>
      </Modal> 

    </>
  );
};



export default ProductActivity;
