import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { getPosition, tooltipFormatter } from 'helpers/echart-utils';
import { getColor, rgbaColor } from 'helpers/utils';
import { useBreakpoints } from 'hooks/useBreakpoints';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';




const processData = (data = []) => {
  const dates = [];
  const storeSales = {};

  data.forEach(entry => {
    const { date, store_names } = entry;
    dates.push(date);
    
    Object.keys(store_names).forEach(store => {
      if (!storeSales[store]) {
        storeSales[store] = new Array(dates.length - 1).fill(0);
      }
      storeSales[store].push(store_names[store]);
    });

    Object.keys(storeSales).forEach(store => {
      if (storeSales[store].length < dates.length) {
        storeSales[store].push(0);
      }
    });
  });

  dates.reverse();
  Object.keys(storeSales).forEach(store => {
    storeSales[store].reverse();
  });

  return { dates, storeSales };
};

const getOptions = (dates, storeSales) => {
  const colors = [getColor('primary'), getColor('warning'), getColor('success'), getColor('info'), getColor('danger')];
  const series = Object.keys(storeSales).map((store, index) => ({
    type: 'line',
    name: store,
    data: storeSales[store],
    symbolSize: 5,
    symbol: 'circle',
    smooth: false,
    lineStyle: { color: rgbaColor(colors[index % colors.length]) },
    itemStyle: {
      color: rgbaColor(colors[index % colors.length]),
      borderColor: rgbaColor(colors[index % colors.length], 0.6),
      borderWidth: 2
    },
    emphasis: {
      scale: true
    }
  }));

  return {
    color: colors,
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      }
    },
    legend: {
      left: 'left',
      top: -5,
      data: Object.keys(storeSales),
      itemWidth: 10,
      itemHeight: 10,
      icon: 'circle',
      inactiveColor: getColor('gray-400'),
      textStyle: { color: getColor('gray-700') },
      itemGap: 20
    },
    xAxis: {
      type: 'category',
      name: '',
      nameGap: 50,
      nameLocation: 'center',
      offset: 0,
      nameTextStyle: {
        color: getColor('gray-700')
      },
      data: dates,
      boundaryGap: false,
      axisPointer: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: rgbaColor('#000', 0.01),
          type: 'dashed'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-400'),
        formatter: value => dayjs(value).format('DD MMM, YY'),
        margin: 20
      }
    },
    yAxis: {
      type: 'value',
      name: 'Sales',
      nameGap: 85,
      nameLocation: 'middle',
      nameTextStyle: {
        color: getColor('gray-700')
      },
      splitNumber: 3,
      axisPointer: { show: false },
      splitLine: {
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        formatter: function (value) {
          return value;
        },
        margin: 15
      },
      axisTick: { show: false },
      axisLine: { show: false }
    },
    series,
    grid: {
      right: '5px',
      left: 45,
      bottom: 30,
      top: 30,
      containLabel: true
    }
  };
};

const DealVSGoalChart = ({ data }) => {
  const { breakpoints } = useBreakpoints();
  const [options, setOptions] = useState(getOptions([], {}));

  useEffect(() => {
    const { dates, storeSales } = processData(data);
    setOptions(getOptions(dates, storeSales));
  }, [data]);

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={options}
      style={{ height: '17.50rem' }}
      key={JSON.stringify(options)}
    />
  );
};

DealVSGoalChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      store_names: PropTypes.objectOf(PropTypes.number).isRequired
    })
  ).isRequired
};

export default DealVSGoalChart;
