import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useDebounce from './useDebounce';
import { PermsContext } from 'context/PermsContext';


export default function useInventoryProductForTotalStock() {

 const [myDataProduct, setMyDataProduct] = useState()

 const {myKey} = useContext(AuthContext)
 const [productInventoryId, setProductInventoryId] = useState()
 const { inventoryContext} = useContext(PermsContext)

 
 const getData = ()=>{
        var myHeaders = new Headers();
     
        const params = {
          inventory_id : inventoryContext,
          count : 501
        };
    
        const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}wms/api/v1/inventory_product/inventory_product_list/?`
        );

            
      console.log("wurl", request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            console.log("p_result", result)
            setMyDataProduct(result.results) 
          
    })
          .catch(error => console.log('error', error));        
 }





 useEffect(() => {
    getData();
  }, [inventoryContext] );

 return {myDataProduct, setProductInventoryId}
 
}


