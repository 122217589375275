import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'

export default function useShipment() {

 const [shipment, setShipment] = useState()
 const [shipmentCompany, setShipmentCompany] = useState([])


 const {myKey, currentUser} = useContext(AuthContext)
 
 const [vendorIdShipment, setVendorIdShipment] = useState()

 const getData = ()=>{
        var myHeaders = new Headers();

        let request_url = BASE_URL+"api/v1/order/shipment_company/"
      
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setShipment(result) 
    })
          .catch(error => console.log('error', error));        
 }


 
 useEffect(() => {
    getData();
  }, []);

 return {shipment}
}
