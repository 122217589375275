import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useDebounce from './useDebounce';



export default function useOrderInvoiceList() {

  const {myKey, currentUser} = useContext(AuthContext)
  const [myData, setMyData] = useState()
  const [page, setPage] = useState(1)


  const [showOrderGif, setShowOrderGif] = useState(false);

  const [currentPage,setCurrentPage]=useState(1)
  const [followersPerPage, setFollowersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [store, setStore] = useState()

  const [vendorId, setVendorId] = useState()
  const [ascInvoiceDetail, setAscInvoiceDetail] = useState(3)

  const [search, setSearch] = useState()
  const debouncedSearch = useDebounce(search, 1500);

  const date = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const twoDay = 1000 * 60 * 60 * 24 *2;
  
  const [startDate, setStartDate] = useState(new Date(date.getTime() - oneDay * 7));
  const [endDate, setEndDate] = useState(new Date(date.getTime() + oneDay * 2));
  
  let formattedStartDate = "";
  let formattedEndDate = "";
  
  if(startDate){
    formattedStartDate = new Date(startDate.getTime()  + oneDay).toISOString().slice(0, 10);
  }
  if(endDate){
    formattedEndDate = new Date(endDate.getTime() + twoDay).toISOString().slice(0, 10);
  }

 const [detail, setDetail] = useState()

 const getData = ()=>{
        var myHeaders = new Headers();
        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;


        const params = {
          offset :offset,
          limit :limit,
          vendor_id: vendorId,
          store : store,
          begin_date: formattedStartDate,
          end_date: formattedEndDate,
          invoice_detail : ascInvoiceDetail,
          search : search
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) 
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/order/order_invoices/?`
    );

 
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result) 
            setShowOrderGif(false);
            setTotalPages(Math.ceil(result.count / followersPerPage))
    })
          .catch(error => console.log('error', error));        
 }



 const getDetail= (id)=>{
  var myHeaders = new Headers();

console.log("detail_id", id)

  let request_url;

  request_url = `${BASE_URL}api/v1/order/order_start/?id=${id}`
 
  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setDetail(result.results) 
      console.log("detail_data", result.results)
})
    .catch(error => console.log('error', error));        
}




 useEffect(() => {
    getData();
    setShowOrderGif(true)
  }, [currentPage, followersPerPage,page,startDate, endDate, vendorId, store, ascInvoiceDetail, debouncedSearch ]);

 return {myData, getData, setMyData, showOrderGif, currentPage,
          setCurrentPage,
          followersPerPage, setAscInvoiceDetail,
          setFollowersPerPage, setStore, ascInvoiceDetail,
          totalPages, setPage, getDetail, detail, startDate, setStartDate, endDate, setEndDate, setVendorId, setSearch}

        }
