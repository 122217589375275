import React, { useEffect, useState } from 'react'
import { Form, Button, Card, Table, Row , Col, Alert} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import DatePicker from  "react-datepicker";
import IconButton from 'components/common/IconButton';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import useBarcodeSku from 'hooks/useBarcodeSku'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react';
import AppContext from 'context/Context';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import useOrderExampleExport from 'hooks/useOrderExampleExport';
import { useRef } from 'react';
import { useOrderUpload } from 'hooks/useOrderUpload';
import useInventoryList from 'hooks/useInventoryList';
import useOrderB2BAdd from 'hooks/useOrderB2BAdd';
import useOrderB2BExampleExport from 'hooks/useOrderB2BExampleExport';
import { useOrderB2BUpload } from 'hooks/useOrderB2BUpload';
import useVendorList from 'hooks/useVendorList';


const OrderB2BAdd = () => {

  const {getData, setVendorIdBarcode} = useBarcodeSku()

  const {formData, setFormData, setSameAddress, sameAddress, setVendorId } = useOrderB2BAdd()
 
  const {myData, setVendorIdInventoryList} = useInventoryList()
  const {getDataExport} = useOrderB2BExampleExport()

  const {myDataVendor} =useVendorList()

  const { uploadFile, errorMessage, setVendorIdUpload  } = useOrderB2BUpload()
  const fileInput = useRef(null);
  const [fileName, setFileName] = useState('');

// modal
  
const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const handleExport = ()=> { 
  getDataExport(); 

}



  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);


  const handleUploadClick = () => {
    if (fileInput.current.files.length > 0) {
        setFileName(fileInput.current.files[0].name);
        uploadFile(fileInput.current.files[0]);
        setShow(false)
      
    } else {
        console.log("No file chosen!");
    }
    setFileName("")
};


  const [customerName, setCustomerName] = useState()
  const [customerLastName, setCustomerLastName] = useState()
  const [customerPhone, setCustomerPhone] = useState()
  const [customerEmail, setCustomerEmail] = useState()

  const [date, setDate] = useState(new Date());
  const [orderNumber, setOrderNumber] = useState()
  const [orderNote, setOrderNote] = useState()

  const [inventoryId, setInventoryId]= useState() 

  const [addressName,setAddressName] =useState()
  const [addressLastName,setAddressLastName] =useState()
  const [addressPhone,setAddressPhone] =useState()
  const [addressEmail,setAddressEmail] =useState()
  const [addressText,setAddressText] =useState()
  const [addressCity,setAddressCity] =useState()
  const [addressDistrict,setAddressDistrict] =useState()
  const [addressCompany,setAddressCompany] =useState()
  const [addressIndentityNumber,setAddressIdentityNumber] =useState()
  const [addressTaxNumber,setAddressTaxNumber] =useState()
  const [addressTaxOffice,setAddressTaxOffice] =useState()
  const [price,setPrice] =useState(0)
  const [count,setCount] =useState()


  const [deliveryName, setDeliveryName] = useState();
  const [deliveryLastName, setDeliveryLastName] = useState();
  const [deliveryPhone, setDeliveryPhone] = useState();
  const [deliveryEmail, setDeliveryEmail] = useState();
  const [deliveryText, setDeliveryText] = useState();
  const [deliveryDistrict, setDeliveryDistrict] = useState();
  const [deliveryCity, setDeliveryCity] = useState();

  const [deliveryCompany, setDeliveryCompany] = useState();
  const [deliveryIdentityNumber, setDeliveryIdentityNumber] = useState();
  const [deliveryTaxNumber, setDeliveryTaxNumber] = useState();
  const [deliveryTaxOffice, setDeliveryTaxOffice] = useState();

  const [products, setProducts] = useState([]);
  const [barcode, setBarcode] = useState("")
  const [selectedValue, setSelectedValue] = useState("");

  const [discount, setDiscount] = useState(0);
  const [showTable, setShowTable] = useState(false);

  const handleInputChange = value => {
    setBarcode(value);
  };
 
  const handleChange = value => {
    setSelectedValue(value);
    console.log("handleChange",value)
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      ...formData, "customerName": customerName, 
                    "customerLastName": customerLastName,
                    "customerPhone": customerPhone,
                    "customerEmail": customerEmail,
                    "date": date,

                    "addressName": addressName,
                    "addressLastName": addressLastName,
                    "addressPhone": addressPhone,
                    "addressEmail": addressEmail,
                    "addressText": addressText,
                    "addressCity": addressCity,
                    "addressDistrict": addressDistrict,

                    "deliveryCompany":deliveryCompany,
                    "deliveryIdentityNumber":deliveryIdentityNumber,
                    "deliveryTaxNumber":deliveryTaxNumber,
                    "deliveryTaxOffice":deliveryTaxOffice,
                    "deliveryPhone":deliveryPhone,
                    "deliveryEmail":deliveryEmail,
                    "deliveryText":deliveryText,
                    "deliveryDistrict":deliveryDistrict,
                    "deliveryCity":deliveryCity,
                    "orderNumber" : orderNumber,
                    "OrderAmount": total,
                    "orderNote": orderNote,
                    "InventoryRegion" :inventoryId.value,
                    "Products": products,
      })  

  setCount(null);
  setSelectedValue(null)
  setShowTable(false)
  setDeliveryPhone(null)
  setDeliveryEmail(null)
  setDeliveryText(null)
  setDeliveryDistrict(null)
  setDeliveryCity(null)
  setDeliveryCompany(null)
  setDeliveryIdentityNumber(null)
  setDeliveryTaxNumber(null)
  setDeliveryTaxOffice(null)
  e.target.reset();
  setProducts([])
  setSameAddress("")
  setCustomerName(null)
  setAddressCity(null)
  setInventoryId(null)
 
}


const handleAddProduct = (e) => {
  e.preventDefault();
  if (!selectedValue) {
    return;
    }
  setProducts([...products, {name: selectedValue.Title, count: count, barcode:selectedValue.barcodes__Barcode, sku: selectedValue.SKU, image :selectedValue.Image, basePrice: price }])

  setShowTable(true);
 
  // document.getElementById("count").value = "";
  setPrice(null)
  setCount(null)
  setBarcode(null)
  setSelectedValue(null)
 
}

console.log("pro", products)

function handleDeleteProduct(index) {
  const updatedProducts = [...products];
  updatedProducts.splice(index, 1);
  setProducts(updatedProducts);
  setSelectedValue(updatedProducts)
      
  }

const handleDiscountChange = (e) => {
  setDiscount(e.target.value);
  }
    
const total = products.reduce((acc, p) => acc + (p.basePrice*p.count), 0) - discount;



const handleSameAddress = (e) => {
  setSameAddress(e.target.checked);
  }

  console.log("same", sameAddress)

  const depo = myData?.filter(item => !item.Region__Name.includes('TEST')).map((item) => {
    return {
      value: item.Id,
      label: item.Region__Name
    }
  });

// console.log("depo", inventoryId.value)

const handleChangeVendor = (e)=> {
  setVendorId(e.target.value); 
  setVendorIdUpload(e.target.value)
  // setVendorIdBarcodeOrderAddB2B(e.target.value)
  setVendorIdBarcode(e.target.value)
  setVendorIdInventoryList(e.target.value)
}


  return (
    <>
    <Link to="/e-commerce/orders/order-b2b-list">
      <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      </Link>
      
      <Card style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <div className="mb-3 mt-3" style={{width:"95%", display:"flex", flexDirection: "column"}}>
      <div style={{display:"flex", justifyContent: "space-between"}}>
        <h6>B2B Sipariş Ekle</h6>
        <div style={{marginLeft:"8px", marginRight:"8px"}}>
                <IconButton
                  variant="falcon-default"
                  size="m"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={handleShow}
                  style={{width:'195px', fontSize:'15px'}}
                >
                  <span className="d-none d-sm-inline-block ms-1">Toplu Sipariş Ekle</span>
                </IconButton>
                </div>
      </div>
      <hr />
      </div>
    <Form.Group style={{ border: "0px solid gray",  padding:"10px", borderRadius:"10px", width:"80%"}}>

    <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
  { myDataVendor?.length > 1 &&    
    <Form.Select 
      className='form-control' 
      style={{fontSize:"15px", width:"145px"}} 
      onChange={handleChangeVendor}
    
    >
      <option value="" >Satıcı</option>
      {myDataVendor?.map((item, i) => {
        return <option key={i} value={item.Id}>{item.vendor_name}</option>
      })}       
    </Form.Select>
  }
</div>
     
<Form onSubmit={handleSubmit}>
  <Form.Group controlId="formDepo" className="mb-3"  >

    <Form.Label>Firma Bilgileri</Form.Label>
    <div style={{display:'flex', justifyContent:'space-between'}}>
      <Form.Control placeholder="firma ismi" style={{ marginBottom:'10px'}}   onChange={(e)=>setCustomerName(e.target.value)}></Form.Control>
      {/* <Form.Control placeholder="müşteri soyisim"  style={{ marginBottom:'10px'}} onChange={(e)=>setCustomerLastName(e.target.value)}></Form.Control> */}
    </div>
    <div style={{display:'flex', justifyContent:'space-between'}}>
      <Form.Control placeholder="telefon" style={{marginRight:'10px'}} onChange={(e)=>setCustomerPhone(e.target.value)}></Form.Control>
      <Form.Control type="email" placeholder="name@example.com" onChange={(e)=>setCustomerEmail(e.target.value)}></Form.Control>
    </div>


    <Form.Label style={{marginTop:"15px"}}>Sipariş Tarihi</Form.Label>
<div style={{display:'flex', flexWrap:'nowrap'}}>

    <div  style={{marginRight:'10px', width:'50%'}}>
          <DatePicker
              selected={date}
              onChange={(date)=>setDate(date)}
              formatWeekDay={day => day.slice(0, 3)}
              className='form-control'
              placeholderText="Select Date"
          />
    </div>

    <div style={{width:'50%'}}>
      <Form.Control placeholder="sipariş numarası" style={{marginRight:'10px'}} onChange={(e)=>setOrderNumber(e.target.value)}></Form.Control>
    </div>

</div>
    
  </Form.Group>


  <Accordion defaultActiveKey="0" style={{marginBottom:'10px'}}>
  <Accordion.Item eventKey="1">
    <Accordion.Header>Teslimat Bilgileri</Accordion.Header>
      <Accordion.Body>
   
        <Form.Group>
          <Form.Label>Teslimat Bilgileri</Form.Label>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <Form.Control placeholder="isim" style={{marginRight:'10px', marginBottom:'10px'}} onChange={(e)=>setAddressName(e.target.value)}></Form.Control>

                <Form.Control placeholder="soyisim"  style={{ marginBottom:'10px'}} onChange={(e)=>setAddressLastName(e.target.value)}></Form.Control>
            </div>

            <div style={{display:'flex', justifyContent:'space-between'}}>
                <Form.Control placeholder="telefon" style={{marginRight:'10px', marginBottom:'10px'}} onChange={(e)=>setAddressPhone(e.target.value)}></Form.Control>

                <Form.Control type="email" placeholder="name@example.com" style={{ marginBottom:'10px'}} onChange={(e)=>setAddressEmail(e.target.value)}></Form.Control>
            </div>
        </Form.Group>

        <Form.Group>
            <Form.Label>Adres Bilgileri</Form.Label>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                  <Form.Control placeholder="adres" style={{ marginBottom:'10px'}} onChange={(e)=>setAddressText(e.target.value)}></Form.Control>
                </div>

                <div style={{display:'flex', justifyContent:'space-between'}}>
                  <Form.Control placeholder="ilçe" style={{ marginBottom:'10px',marginRight:'10px'}} onChange={(e)=>setAddressDistrict(e.target.value)}></Form.Control>

                  <Form.Control placeholder="il"  style={{  marginBottom:'10px'}} onChange={(e)=>setAddressCity(e.target.value)}></Form.Control>
                </div>

    <Form.Check type="checkbox" label="Fatura adresi aynıdır" onChange={handleSameAddress} />

        <Form.Group>
              <Form.Label>Fatura Bilgileri</Form.Label>

            <Form.Group>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                  <Form.Control placeholder="şirket Adı" style={{marginRight:'10px', marginBottom:'10px'}} onChange={(e)=>setDeliveryCompany(e.target.value)}></Form.Control>
                  <Form.Control placeholder="TC Kimlik Nu."  style={{marginBottom:'10px'}} onChange={(e)=>setDeliveryIdentityNumber(e.target.value)}></Form.Control>

                </div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                <Form.Control placeholder="vergi numarası" style={{marginRight:'10px', marginBottom:'10px'}} onChange={(e)=>setDeliveryTaxNumber(e.target.value)}></Form.Control>
                <Form.Control placeholder="vergi dairesi" style={{marginBottom:'10px'}} onChange={(e)=>setDeliveryTaxOffice(e.target.value)}></Form.Control>
                </div>
                
                </Form.Group>
                   
            <div style={{display:'flex', justifyContent:'space-between'}}>

                <Form.Control placeholder="telefon" style={{marginRight:'10px', marginBottom:'10px'}}
                //  value={sameAddress ?  addressPhone : deliveryPhone} 
                 onChange={(e)=>setDeliveryPhone(e.target.value)} 
                //  disabled={sameAddress}
                 ></Form.Control>
      
                <Form.Control type="email" placeholder="name@example.com" style={{ marginBottom:'10px'}} 
                // value={sameAddress ? addressEmail : deliveryEmail} 
                onChange={(e)=>setDeliveryEmail(e.target.value)}
                //  disabled={sameAddress}
                 ></Form.Control>

            </div>


            {sameAddress ?  ( "") : ( 
            <>
            <div style={{display:'flex', justifyContent:'space-between'}}>

                <Form.Control placeholder="adres" style={{marginBottom:'10px'}} value={sameAddress ? addressText : deliveryText} onChange={(e)=>setDeliveryText(e.target.value)} disabled={sameAddress}></Form.Control>

            </div>

        


            <div style={{display:'flex', justifyContent:'space-between'}}>

                <Form.Control placeholder="ilçe" style={{ marginBottom:'10px',marginRight:'10px'}} value={sameAddress ?  addressDistrict : deliveryDistrict} onChange={(e)=>setDeliveryDistrict(e.target.value)} disabled={sameAddress}></Form.Control>

                <Form.Control placeholder="il" style={{marginBottom:'10px'}} value={sameAddress ?  addressCity : deliveryCity} onChange={(e)=>setDeliveryCity(e.target.value)} disabled={sameAddress}></Form.Control>
            </div>
            
            </>
            
            
            
            )}

            


          </Form.Group>
      </Form.Group>
    </Accordion.Body>
  </Accordion.Item>
</Accordion>


      <Form.Group style={{marginTop:"15px"}}>
          <Form.Label>Sipariş Notu</Form.Label>
                <Form.Control placeholder="sipariş notu" onChange={(e)=>setOrderNote(e.target.value)}></Form.Control>
      </Form.Group>

      <Form.Group controlId="formDepo" className="mb-3 mt-3"  >
      <Form.Label>Depo</Form.Label>
        <Select options={depo} isSearchable={true} placeholder="depo seçimi" 
        onChange={setInventoryId} 
        
        value={inventoryId}
          styles={{control: (baseStyles) =>({
          ...baseStyles, 
          backgroundColor:isDark ? "#0B1727" : "white"
        }),}}
        />

      </Form.Group>


  <Form.Group controlId="ürün" className="mb-3 mt-3"  >
    <Form.Label>Ürün</Form.Label>
    <div style={{display:'flex',  justifyContent:'space-between'}}>

    <div style={{width:'33%'}}> 

<AsyncSelect 
        loadOptions={getData}
        placeholder="isim, barkod, sku numarası" 
        className="mb-3" 
        onInputChange={handleInputChange}
        onChange={handleChange}
        value={selectedValue}
        getOptionLabel={e => e.Title}
        getOptionValue={e => e.Barcode}
        // unstyled={true}
        styles={{control: (baseStyles) =>({
          ...baseStyles, 
          backgroundColor:isDark ? "#0B1727" : "white"
        }),}}
      /> 

      </div> 
  
    <div style={{width:'33%'}}>
    <Form.Control  value={price || ''} placeholder="fiyatı"  onChange={(e)=>setPrice(e.target.value)}/>
    </div>
    <div style={{width:'33%'}}>
    <Form.Control  value={count || ''} placeholder="ürün sayısı" onChange={(e)=>setCount(e.target.value)}/>
    </div>
    </div>
  </Form.Group>


<div>
  <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
    <Button variant="secondary" type="button" onClick={handleAddProduct} style={{marginBottom:"10px"}}>
      Ekle
    </Button>
    </div>
    {showTable && (
  <Card className="mb-3">
  <Card.Body>
    <div className="table-responsive fs--1">
      <Table striped className="border-bottom">
        <thead className="bg-200 text-900">
          <tr>
          <th className="border-0"></th>

            <th className="border-0">Ürün</th>
            <th className="border-0">İsim</th>
            <th className="border-0 text-center">Adet</th>
            <th className="border-0 text-end">Fiyat</th>

          </tr>
        </thead>
        <tbody>
        {products.map((product, i) => (
            <tr key={i} className="border-200">
              <td style={{marginRight:"0"}}>        
              <svg  onClick={() => handleDeleteProduct(i)} xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
              </td>
                <td className="align-middle" style={{marginLeft:"0"}}>
                  <img style={{width:"40px"}}src={product.image} alt="" />
                </td>

                <td className="align-middle">
                  <h6 className="mb-0 text-nowrap">{product.name}</h6>
                </td>

                <td className="align-middle text-center">
                  {product.count}
                </td>

                <td className="align-middle text-end">
                {Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(product.basePrice*product.count)}
                </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row className="g-0 justify-content-end">
        <Col xs="auto">
          <Table borderless size="sm" className="fs--1 text-end">
            <tbody>
              <tr>
                <th className="text-900">İndirim:</th>
                <td className="fw-semi-bold">
                <input type="number" value={discount} onChange={handleDiscountChange} style={{textAlign:'end',borderRadius:'5px', color:"#5E6E82", width:"50px", border:"1px solid #5E6E82"}} />
                
                </td>
              </tr>
             
              <tr className="border-top" style={{textAligin:"end"}}>
                <th className="text-900" style={{textAligin:"center"}}>Total: </th>
                <td className="fw-semi-bold">
            
                {Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(total)}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  </Card.Body>
</Card>
)}
</div>

  <Button variant="primary" type="submit" className="d-flex mx-auto mt-5 mb-2" >
    Onayla
  </Button>
</Form>
</Form.Group>
</Card>

    <Modal 
      show={show} 
      onHide={handleClose}
      size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Sipariş Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
  { myDataVendor?.length > 1 &&    
    <Form.Select 
      className='form-control' 
      style={{fontSize:"15px", width:"145px"}} 
      onChange={handleChangeVendor}
    
    >
      <option value="" >Satıcı</option>
      {myDataVendor?.map((item, i) => {
        return <option key={i} value={item.Id}>{item.vendor_name}</option>
      })}       
    </Form.Select>
  }
</div>

            <p>- İndir butonunu kullanarak örnek excel dosyasını indiriniz.</p>
            <p>- Excel dosyasında belirtilen sütunları değiştirmeyiniz.</p>
            <p>- OrderNumber sütunu zorunludur.</p>
            <p>- Bir siparişde birden farklı ürün bulunması durumunda sadece ürün bilgilerinin olduğu product sütunları doldurulması yeterlidir.</p>

        </Modal.Body>

        <Modal.Footer>
            <Form style={{width:"100%"}}>
              <Form.Group style={{ display: "flex", justifyContent: "space-between", width:"100%"}}>
                <div>
                  <Form.Control type="file" ref={fileInput} style={{border: 'none'}} />
                </div>
                <div>

                <Button variant="primary" onClick={handleExport}>
                    Örnek excel indir
                  </Button>
                  <Button style={{marginLeft:"5px"}} variant="secondary" onClick={handleClose}>
                    Vazgeç
                  </Button>
                 
                </div>
              </Form.Group>

              <div style={{ display: "flex", justifyContent: "center", marginTop:"60px" }}>
                <Button variant="primary" onClick={handleUploadClick}>Yükle</Button>
              </div>

              {fileName && <p style={{ textAlign: "center" }}>Selected file: {fileName}</p>}
            </Form>
                  {/* {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} */}
            </Modal.Footer>

      </Modal>


      </>
  )
}

export default OrderB2BAdd