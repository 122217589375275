import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import { toast } from 'react-toastify';




export default function useOrderExportS3() {

  const [myDataS3, setMyDataS3] = useState()
  const {myKey, currentUser} = useContext(AuthContext)
  
  const [myDataResult, setMyDataResult] = useState()
  const [selectStatusModal, setSelectStatusModal] = useState(0)
  const [cargoModal, setCargoModal] = useState()
  const [storeModal, setStoreModal] = useState()
  const [fulfilmentModal, setFulfilmentModal] = useState()

  const [invoicesExport, setInvoicesExport] = useState()
  const [showGif, setShowGif] = useState(false);
  
  const [regionExport, setRegionExport] = useState()

  const [randomNumber, setRandomNumber] = useState()

  const [vendorId, setVendorId] = useState()
  const [isPreparing, setIsPreparing] = useState(false);

  const [vendorExportId, setVendorExportId] = useState()

  const [paidExport, setPaidExport] = useState()
  const [workExport, setWorkExport] = useState()
  const [searchNameExport, setSearchNameExport] = useState()





  const todayDay = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const twoDay = 1000 * 60 * 60 * 24 *2;
  const timezoneOffset = todayDay .getTimezoneOffset(); 
  const [startDateModal, setStartDateModal] = useState();
  const [endDateModal, setEndDateModal] = useState();

  const [shipmentStartDateModal, setShipmentStartDateModal] = useState();
  const [shipmentEndDateModal, setShipmentEndDateModal] = useState();

  const [packedStartDateModal, setPackedStartDateModal] = useState();
  const [packedEndDateModal, setPackedEndDateModal] = useState();



  const [message, setMessage] = useState("");

  let formattedShipmentStartDate = "";
  let formattedShipmentEndDate = "";

  if(shipmentStartDateModal){
    formattedShipmentStartDate = new Date(shipmentStartDateModal.getTime() + oneDay).toISOString().slice(0, 10);
  }
  if(shipmentEndDateModal){
    formattedShipmentEndDate = new Date(shipmentEndDateModal.getTime() + twoDay ).toISOString().slice(0, 10);
  }


  let formattedStartDate = "";
  let formattedEndDate = "";

  if(startDateModal){
    formattedStartDate = new Date(startDateModal.getTime() + oneDay).toISOString().slice(0, 10);
  }
  if(endDateModal){
    formattedEndDate = new Date(endDateModal.getTime() + twoDay).toISOString().slice(0, 10);
  }



  let formattedPackedStartDate = "";
  let formattedPackedEndDate = "";

  if(packedStartDateModal){
    formattedPackedStartDate = new Date(packedStartDateModal.getTime() + oneDay).toISOString().slice(0, 10);
  }
  if(packedEndDateModal){
    formattedPackedEndDate = new Date(packedEndDateModal.getTime() + twoDay).toISOString().slice(0, 10);
  }






  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); 
  const year = today.getFullYear();
  const formattedDate = `${day}.${month}.${year}`

  const [intervalId, setIntervalId] = useState(null);




 const getDataExport = async ()=>{
      var myHeaders = new Headers();


      const params = {

        cargo :cargoModal,
        status: selectStatusModal,
        begin_date: formattedStartDate,
        end_date: formattedEndDate,
        vendor_id: vendorExportId,
        store : storeModal,
        fulfilment : fulfilmentModal,
        invoices : invoicesExport,
        inventory_region : regionExport,
        paid : paidExport,
        work_order : workExport,
        product_name : searchNameExport,
        shipment_begin_date : formattedShipmentStartDate,
        shipment_end_date : formattedShipmentEndDate,
        packed_begin_date : formattedPackedStartDate,
        packed_end_date : formattedPackedEndDate

   
      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/export_orders_excel_threading/?`
  );

  console.log("url_date", request_url)


    var requestOptions = {
      method: 'GET',
      headers: {"Authorization": "Token "+myKey},
      redirect: 'follow'
    };
    fetch(request_url, requestOptions)
      .then(response => response.json())
      .then(result =>{
        setMyDataS3(result) 
        setRandomNumber(result.random_number)
        setVendorId(result.vendor)
        setShowGif(false);
        console.log("resultss3", result )

})
      .catch(error => console.log('error', error)); 
      toast.error(`Error: ${error}`);     

};


const getDataExportReturn = async () => {


  var myHeaders = new Headers();

  const params = {
    random_number: randomNumber,
    vendor_id: vendorId,
  };

  const request_url = Object.entries(params)
    .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
    .reduce(
      (url, [key, value]) => url + `&${key}=${value}`,
      `${BASE_URL}api/v1/order/export_to_order_excel_variable/?`
    );

  var requestOptions = {
    method: 'GET',
    headers: { Authorization: "Token " + myKey },
    redirect: 'follow'
  };

  const interval = setInterval(async () => {
    try {
      const response = await fetch(request_url, requestOptions);
      const result = await response.json();

      if (response.status === 200) {
        setMyDataResult(result);
        setIsPreparing(false);
        clearInterval(interval); // İstek başarılı olunca interval'ı durdur
      }

    } catch (error) {
      console.log('error', error);
      toast.error(`Error: ${error}`);
    }
  }, 5000); // 5 saniyede bir istek at

  setIntervalId(interval);

  return () => clearInterval(interval);

};

const stopDataExport = () => {
  if (intervalId !== null) {
    clearInterval(intervalId);
    setIntervalId(null); // Interval durdurulduktan sonra ID'yi sıfırla
  }
};



useEffect(() => {
  if (randomNumber && vendorId) {
    getDataExportReturn();
  }

  return () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
    }
  };
}, [randomNumber, vendorId]);







 return {myDataS3,setMyDataS3, selectStatusModal, setSelectStatusModal, startDateModal, setStartDateModal, endDateModal, setEndDateModal,  cargoModal, setCargoModal, getDataExport, setStoreModal, fulfilmentModal, setFulfilmentModal, showGif, setShowGif, setInvoicesExport, invoicesExport, setRegionExport, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorExportId,setPaidExport, setWorkExport, setSearchNameExport, setShipmentStartDateModal, setShipmentEndDateModal,
 shipmentEndDateModal, shipmentStartDateModal, getDataExportReturn, stopDataExport, packedStartDateModal, setPackedStartDateModal,packedEndDateModal, setPackedEndDateModal
}
}
