import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form,  Modal, Button, Alert, Row,Col, InputGroup} from 'react-bootstrap';
import { orderList } from 'data/ecommerce/orderList';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import IconButton from 'components/common/IconButton';
import { useProductUpload } from 'hooks/useProductUpload';
import ProductTableHeader from './ProductTableHeader';
import { PermsContext } from 'context/PermsContext';
import useProductActivityList from 'hooks/useProductActivityList';
import useInventoryRegion from 'hooks/useInventoryRegion';
import DatePicker from  "react-datepicker";
import useProductActivityExportS3 from 'hooks/useProductActivityExportS3';
import gif from 'assets/img/gif_export_s3.gif'
import { Search } from 'react-bootstrap-icons';
import useBarcodeSku from 'hooks/useBarcodeSku';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import AppContext from 'context/Context';
import { MdClear } from "react-icons/md";
import Select from 'react-select';
import PaginationProduct from './PaginationProduct';

const ProductActivityList = () => {

  const columns = [
    {
      // accessor: 'name',
      Header: 'Depo',
      disableSortBy: true,
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },

      Cell: rowData => {
        const { inventory_name} = rowData.row.original;
        return (
          <>
            <p >
              {inventory_name}
            </p>
          </>
        );
      }
    },
    {
      // accessor: 'name',
      Header: 'Ürün',
      disableSortBy: true,
      headerProps: { 
        // className: 'pe-1'
     },
      cellProps: {
        // className: 'py-2'
      },

      Cell: rowData => {
        const { name} = rowData.row.original;
        return (
          <>
            <p title={name}>
              {name.length > 20 ? `${name.slice(0, 20)}...` : name}
            </p>
          </>
        );
      }
    },
  
    {
      accessor: 'address',
      disableSortBy: true,
      Header: <span style={{marginLeft:"15px"}}>SKU</span> ,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { sku } = rowData.row.original;
        return (
          <>
          <p>{sku}</p>
          </>
        );
      }
    },
    {
      accessor: 'status',
      disableSortBy: true,
      Header: 'Raf',
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { shelf_code } = rowData.row.original;
        return (
          <>
         <p>{ shelf_code }</p>
        
          </>
        )
      }
    },
    {
      accessor: 'user',
      Header: 'Değiştiren',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { user } = rowData.row.original;
        return (
          <>
      
         <p>{ user }</p>
          </>
        )
      }
    },
    {
      accessor: 'type',
      Header: 'Tip.',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center py-2'
      },
      Cell: rowData => {
        const { type } = rowData.row.original;
        return (
        <p >{type}</p>
        )
      }
    },
    { accessor: 'skt',
      Header: 'SKT',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.skt}`
    },
    {
      accessor: 'lot',
      Header: 'Lot',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.lot}`
    },
    {
      accessor: 'change_stock',
      Header: 'Değişim Stoğu',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { changed_count } = rowData.row.original;
        return (
          <>
      
         <p style={{ fontSize: "15px", fontWeight:'bold' }} >{ changed_count }</p>
          </>
        )
      }
    },
    {
      accessor: 'total_stock',
      Header: 'Toplam Stok',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.total_count}`
    },
    {
      accessor: 'detail_2',
      Header: 'Bağlam',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const text = rowData.row.original.detail2; 
        if (text && typeof text === 'string') {
          const parts = text.split('#');
          if (parts.length > 1) {
            return (
              <div>
                {parts[0]}
                <br />
                <span>#{parts[1]}</span> 
              </div>
            );
          }
          return text; 
        }
        return "-"; 
      }
    },
    {
      accessor: 'change_date',
      Header: 'Değişim Tarihi',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { date } = rowData.row.original;
        return (
          <>
         <p >{ date }</p>
          </>
        )
      }
    }
  ];





const {setInventoryId, dataActivity, setSkuCurrent, setStartDate, setEndDate, startDate, endDate, skuCurrent,setSearch, setType, setCurrentPage, followersPerPage, setFollowersPerPage, totalPages}  = useProductActivityList()

const {getDataExport, showGif, setShowGif, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorIdExport,setInventoryExportId, startDateModal, setStartDateModal, endDateModal, setEndDateModal, setSkuCurrentModal, skuCurrentModal } = useProductActivityExportS3()


const [isOpen, setIsOpen] = useState(false);

const {getData, setVendorIdBarcode} = useBarcodeSku()

// Handler
const handleToggle = () => {
  setIsOpen(!isOpen);
};

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const {
  config: { isDark, isRTL },
  setConfig
} = useContext(AppContext);





// modal

const {perms} = useContext(PermsContext)

function hasPermission(permission) {
  return perms?.includes(permission);
}



const {regionData} = useInventoryRegion()

const orderList = Array.isArray(dataActivity) 
? dataActivity.map((product, i) => {

  const formatDate = (dateTime) => {
    const dateObj = new Date(dateTime);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };


    const TypeText = ['',
      'Bilinmeyen',
      'Manuel Değişim', 
      'Mal kabul sırasında artış',
      'Sipariş Otomatik Stok Düşmesi', 
      'Sipariş Toplaması Stok Düşmesi',
      'Mal kabul sonunda artış', 
      'İade paket kabul', 
      'Raftan rafa aktarım', 
      'Depoya teslimat onaylanması', 
      'Sipariş toplaması otomatik düşen stok geri koyma', 
      'Sipariş toplaması taratılıp düşen stok geri koyma',
      'Sipariş Toplaması Ürün Taratma Stok Düşmesi',
      'Sipariş otomatik düşmesi ANA ürün stok geri koyma',
      'Sipariş otomatik düşmesi ALT ürün stok geri koyma',
      'Sipariş otomatik alt ürün düşmesi'
    ]



    return {
        // id : i+1,
        inventory_name : product.Inventory__Region__Name,
        name: product.Name,
        sku: product.SKU,
        // stock: product.TotalCount,
        changed_count :product.TotalChangedCount,
        shelf_code : product.ShelfCode || '-',
        skt: product.ExpirationDate || '-',
        lot: product.Lot || '-',
        type : TypeText[+product.Type],
        total_count : product.TotalCount || '-',
        date : formatDate(product.DateTime),
        user : product.User__username || '-',
        detail2 : product.Detail2 || '-'


    };
})
: [];

const handleChangeRegion = (e)=> {
  const selectedId = e.target.value;
  setInventoryId(e.target.value); 
  setInventoryExportId(e.target.value)


  const selectedItem = regionData.find(item => item.Id.toString() === selectedId);
  if (selectedItem) {
    setVendorIdExport(selectedItem.Vendor);
    setVendorIdBarcode(selectedItem.Vendor)
  }

}

const onChange = datesExport => {
  const [startExport, endExport] = datesExport;
  setStartDate(startExport);
  setEndDate(endExport);
};

const handleDownload = () => {
  setIsPreparing(false);
  window.location.href = myDataResult[0]?.Value;
  setShow(false)
  setMyDataResult(false)
  setStartDateModal("")
  setEndDateModal("")
}

const onChangeModal = datesExport => {
  const [startExport, endExport] = datesExport;
  setStartDateModal(startExport);
  setEndDateModal(endExport);
};

const handleExport = ()=> { 
  getDataExport(skuCurrent); 
  setShowGif(true);
  // setShow(false)


  setIsPreparing(true);
  

}

const [activePage, setActivePage] = useState(1)


const types = [
{value: 0, label: 'Hepsi'},
{value: 1, label: 'Bilinmeyen'},
{value: 2, label: 'Manuel Değişim'},
{value: 3, label: 'Mal kabul sırasında artış'},
{value: 4, label: 'Sipariş Otomatik Stok Düşmesi'},
{value: 5, label: 'Sipariş Toplaması Stok Düşmesi'},
{value: 6, label: 'Mal kabul sonunda artış'},
{value: 7, label: 'İade paket kabul'},
{value: 8, label: 'Raftan rafa aktarım'},
{value: 9, label: 'Depoya teslimat onaylanması'},
{value: 10,label: 'Sipariş toplaması otomatik düşen stok geri koyma'},
{value: 11,label: 'Sipariş toplaması taratılıp düşen stok geri koyma'},
{value: 12,label: 'Sipariş Toplaması Ürün Taratma Stok Düşmesi'},
{value: 13,label: 'Sipariş otomatik düşmesi ANA ürün stok geri koyma'},
{value: 14,label: 'Sipariş otomatik düşmesi ALT ürün stok geri koyma'},
{value: 15,label: 'Sipariş otomatik alt ürün düşmesi'},
]


const [selectedOption, setSelectedOption] = useState(null);


const handleChangeType = (e)=> {
  setType(e.value); 
  setSelectedOption(e)
}

function getCustomStyles(isDark) {
  return {
    control: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: isDark ? "#0B1727" : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      color: isDark ? 'white' : 'black',
      backgroundColor: isDark ? '#0B1727' : 'white',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: isDark ? 'white' : 'black',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: isDark ? 'white' : 'black',
    }),
  };
}


// async select

const [selectedValue, setSelectedValue] = useState("");
const [barcode, setBarcode] = useState("")


const handleInputChange = value => {
  setBarcode(value);

};

const handleChange = value => {
  if (value) {
    setSelectedValue(value);
    setSkuCurrentModal(value.SKU); 
    console.log("handleChange", value);
  } else {
    setSelectedValue(""); 
    setSkuCurrentModal(""); 
  }
};

const CustomClearText = props => (
  <components.ClearIndicator {...props}>
   <MdClear />
  </components.ClearIndicator>
);


function smartWrapText(text, maxCharsPerLine) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0] || '';

  words.slice(1).forEach(word => {
    if ((currentLine + ' ' + word).length <= maxCharsPerLine) {
      currentLine += ' ' + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  });

  if (currentLine) {
    lines.push(currentLine);
  }

  return lines.join('\n');
}

const MyCellComponent = ({ cell: { value } }) => {
  return <span style={{ whiteSpace: 'pre-wrap' }}>{smartWrapText(value, 30)}</span>;
}



  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      selection
      sortable
      pagination
      perPage={50}
    >
      <Card className="mb-3">
        <Card.Header>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div>
              <ProductTableHeader table />
          </div>
          <div style={{display:"flex", justifyContent:'right'}}>
            <div style={{ marginRight: '10px', width: '50%' }}>
                  {/* <Form.Label>Kullanıcı Adı</Form.Label> */}
                <Form.Control type="text" placeholder="SKU giriniz.." onChange={(e) => setSkuCurrent(e.target.value)} />
            </div>
                  {hasPermission('Stok Excel Export') && (
            <div style={{ width:'145px', marginRight:'-4px'}}>
                <IconButton
                  variant="falcon-default"
                  size="m"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={handleShow}
                >
                <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
            </div>
                     )} 
                     
        <Dropdown  show={isOpen} onToggle={handleToggle}>
          <Dropdown.Toggle
              bsPrefix="toggle"
              as={Link}
              //  to=""
              className={classNames('px-0 nav-link')}>
            <IconButton
              variant="falcon-default"
              size="m"
              icon="fa-sharp fa-light fa-filter-list"
              transform="shrink-3"
              style={{width:'145px', fontSize:'15px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
              </svg>
                  <span className="d-none d-sm-inline-block ms-1">Filtre</span>
            </IconButton>
        </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret" style={{minWidth: '300px', width: '300px'}}>

          <Card className="shadow-none shadow-show-xl scrollbar" style={{height:'450px'}}>
            <Card.Header className="bg-light d-none d-xl-block">
              <h6 className="mb-0">Filtre</h6>
            </Card.Header>
              <Card.Body>
                <Form>


                <div className="mb-3">
                    <InputGroup>
                      <Form.Control  type="text" placeholder="raf, değiştiren..."  onChange={(e)=>setSearch(e.target.value)}/>
                      <InputGroup.Text><Search/></InputGroup.Text>
                    </InputGroup>
              </div>

                <div style={{ fontSize:"14px", marginTop:'10px'}}>
                    <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    endDate={endDate}
                    selectsRange
                    dateFormat="MMM dd"
                    className='form-control'
                    placeholderText='aktivite tarihi'
                    />
              </div> 
                
                <div style={{marginTop:'10px'}}>
                        { regionData?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} 
                  onChange={handleChangeRegion}
                  >
                <option value="">Depo</option>
                  { regionData?.map((item, i)=>{
                      return <option key={i} value={item.Id}>{item.name}</option>
                    })}       
                  </Form.Select>}
                </div>


                <div style={{marginTop:'10px'}}>
                <Select
                      value={selectedOption}
                      onChange={handleChangeType}
                      options={types}
                      // styles={customStyles}
                      placeholder='Tipini seçiniz..'
                      styles={getCustomStyles(isDark)}
                    />
                </div>
              
                   
              </Form>
            </Card.Body>
        </Card>
      </Dropdown.Menu>
      </Dropdown>
    </div>
  </div>
      </Card.Header>
        <Card.Body className="p-0">
        {dataActivity && dataActivity.length > 0 ? (
    <AdvanceTable
      table
      headerClassName="bg-200 text-900 text-nowrap align-middle"
      rowClassName="align-middle white-space-nowrap"
      tableProps={{
        size: 'm',
        striped: true,
        className: 'fs--1 mb-0 overflow-hidden'
      }}
    />
  ) : (
    <p style={{textAlign:'center', marginTop:'30px', marginBottom:'20px'}}>
      
    <span style={{fontWeight:'bold'}}>SKU</span>  girerek ve <span style={{fontWeight:'bold'}}>depo</span>  seçimi yaparak aktiviteleri listeleyebilirsiniz
      
      </p>
  )}
        </Card.Body>

        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <PaginationProduct pages={totalPages} activePage={activePage} setActivePage={setActivePage} setCurrentPage={setCurrentPage}/>
        </Card.Footer>
    </Card>

    <span style={{marginRight:"5px",marginLeft:"5px"}}>Ürün sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>


      <Modal 
      show={show} 
      onHide={handleClose}
  
      >
        <Modal.Header closeButton>
          <Modal.Title>Ürün Aktivite Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Lütfen depo seçimi yaparak ve SKU girilerek ürün listesini indiriniz.</p>
       <div style={{}}>
              { regionData?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} 
                    onChange={handleChangeRegion}
                    >
                      <option value="">Depo</option>
                        {regionData?.map((item, i)=>{
                            return <option key={i} value={item.Id}>{item.name}</option>
                        })}       
                </Form.Select>}
            </div>

            <div style={{ fontSize:"14px", marginTop:'10px'}}>
                <DatePicker
                showIcon
                selected={startDateModal}
                onChange={onChangeModal}
                startDate={startDateModal}
                formatWeekDay={day => day.slice(0, 3)}
                endDate={endDateModal}
                selectsRange
                dateFormat="MMM dd"
                className='form-control'
                placeholderText='aktivite tarihi'
                />
            </div>

            <div style={{  marginTop:'8px', }}>
                  {/* <Form.Label>Kullanıcı Adı</Form.Label> */}
              
                  <AsyncSelect
                      loadOptions={getData}
                      placeholder="isim, barkod, sku numarası" 
                      className="mb-3" 
                      onInputChange={handleInputChange}
                      onChange={handleChange}
                      value={selectedValue}
                      getOptionLabel={e => e.Title}
                      getOptionValue={e => e.Barcode}
                      isClearable
                      components={{ ClearIndicator: CustomClearText }}
                      styles={{control: (baseStyles) =>({
                        ...baseStyles, 
                        backgroundColor:isDark ? "#0B1727" : "white"
                      }),}}
                  />
                </div>

          </Modal.Body>
        <Modal.Footer>
        <Row style={{width:'100%'}}>      
  <Row>

  
    <p>Listeniz hazırlanıyor yazısından sonra tekrar Hazırla butonuna basmayınız.</p>
    {/* <p>Son 30 günlük ürün aktivitesi listelenmektedir.</p> */}


    <Col className="d-flex justify-content-end mb-3">
      <Button variant="secondary" onClick={handleClose} style={{marginRight:'10px'}}>
        Vazgeç
      </Button>

      {!isPreparing && (
      <Button variant="primary" onClick={handleExport}>
        Hazırla
      </Button>
      )}
    </Col>
  </Row>
  {isPreparing && (!myDataResult || !myDataResult.error) && 
  
  <div>
  <div>listeniz hazırlanıyor, lütfen bekleyin...</div>

  <img src={gif} style={{width:'50%'}} alt="export gif" />
  </div>
  
  }
  {myDataResult && myDataResult[0]?.Value?.length > 0  && 
  <Row className="justify-content-center mt-5" style={{width:'100%'}}>
    <Col style={{display: "flex", justifyContent: "center"}}>
    <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding:"10px", fontSize:"13px"}}>

        {/* {myDataS3?.message} */}
        Ürün listesiniz hazır indirebilirsiniz.
      </Alert>
      <Button 
  variant="primary" 
  style={{width:'150px', height:'40px'}}
  // onClick={() => window.location.href = myDataS3?.download_url}
  onClick={handleDownload}
>
  İndir
</Button>

    </Col>
  </Row>

  }
   </Row>
        </Modal.Footer>
      </Modal> 



    </AdvanceTableWrapper>
  );
};

export default ProductActivityList;