import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useDebounce from './useDebounce';
import { PermsContext } from 'context/PermsContext';


export default function useBestSellerProductTable() {

 const [myData, setMyData] = useState()

 const {myKey, currentUser} = useContext(AuthContext)

 
 const [isSwitchedOn, setIsSwitchedOn] = useState(false)

 const [currentPage,setCurrentPage]=useState(1)
 const [followersPerPage, setFollowersPerPage] = useState(10);
 const [totalPages, setTotalPages] = useState(0);

const {vendorIdContext} = useContext(PermsContext)


const date = new Date();
const oneDay = 1000 * 60 * 60 * 24;
const twoDay = 1000 * 60 * 60 * 24 *2;

const [startDate, setStartDate] = useState(new Date(date.getTime() - oneDay * 7));
const [endDate, setEndDate] = useState(new Date(date.getTime() + oneDay * 2));

let formattedStartDate = "";
let formattedEndDate = "";

if(startDate){
  formattedStartDate = new Date(startDate.getTime()  + oneDay).toISOString().slice(0, 10);
}
if(endDate){
  formattedEndDate = new Date(endDate.getTime() + twoDay).toISOString().slice(0, 10);
}


 
 const getData = ()=>{
        var myHeaders = new Headers();
        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;

        const params = {
          offset :offset,
          limit :limit,
          begin_date :formattedStartDate,
          end_date:  formattedEndDate,
          // vendor_id : vendorIdContext
      
        };
    
        const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) 
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}api/v1/vendor/${vendorIdContext}/best_seller_products/?`
        );

            
      console.log("wurl", request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            console.log("p_result", result)
            setMyData(result) 
            setTotalPages(Math.ceil(result.count / followersPerPage))
    })
          .catch(error => console.log('error', error));        
 }

 useEffect(() => {
    getData();
  }, [vendorIdContext, followersPerPage, startDate, endDate, isSwitchedOn, currentPage]);

 return {myData, currentPage,
  setCurrentPage, getData,  followersPerPage, setFollowersPerPage, totalPages, startDate, endDate, setStartDate, setEndDate, isSwitchedOn, setIsSwitchedOn}
 
  
}


