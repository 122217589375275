import { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from 'context/AuthContext';
import { toast } from 'react-toastify';
import {BASE_URL} from './baseUrl'
import useVendorList from './useVendorList';

export const useProductUpload = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const {myKey} = useContext(AuthContext)
    const {myDataVendor} = useVendorList()
    const [vendorIdUplaod, setVendorIdUpload] = useState()

    useEffect(() => {
        if (myDataVendor?.length === 1) {
            setVendorIdUpload(myDataVendor[0].Id);
        }
      }, [myDataVendor]);

    const uploadFile = async (file) => {
        if (!file.type.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            setErrorMessage("Invalid file type. Please upload an Excel file.");
            toast.error("Invalid file type. Please upload an Excel file."); 
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${BASE_URL}api/v1/order/product_import/?vendor_id=${vendorIdUplaod}`, formData, {
                headers: {"Authorization": "Token "+myKey},
            });

            console.log(response.data);
            setErrorMessage("");

            const invalidSkusMessage = response.data.invalid_skus.length > 0
                ? `Başarısız olan SKU'lar: ${response.data.invalid_skus.join(", ")}`
                : ""; 

        toast.success(`Dosya yükleme işlemi başarılı! ${invalidSkusMessage}`); 
        } catch (error) {
            // Error handling
            // Similar to what we had before
            // ...

            setErrorMessage(`Error: ${error.message}`);
            toast.error(`Error: ${error.message}`); 
        }
    };

    return { uploadFile, errorMessage, setVendorIdUpload };
};
