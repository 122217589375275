import React from 'react'
import { Button,  Badge } from 'react-bootstrap';

const OrderPackaging = () => {
  return (
    <>
    <div style={{display:'flex',justifyContent:'center', marginTop:'50px'}}>
        <Button onClick={() => window.open('https://shipshack.app/wms/operation/packaging', '_blank')}>
            Toplama Sayfası
        </Button>
    </div>
    <p style={{textAlign: 'center', marginTop: '20px'}}>
  Toplama sayfasının açılması için <a href="https://shipshack.app" target="_blank" rel="noopener noreferrer">https://shipshack.app</a> adresine aynı tarayıcıdan giriş yapmanız gerekmektedir.
</p>

    </>
  )
}

export default OrderPackaging
