import { AuthContext } from 'context/AuthContext';
import React, {useContext, useEffect, useState} from 'react'
import {BASE_URL} from '../baseUrl'
import { toast } from 'react-toastify';
import {  format } from 'date-fns';



export default function useOrderFeeAdd() {

  const {myKey, currentUser} = useContext(AuthContext)

  const now = new Date();
  const utcFirstDayOfMonth = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
  const [dateOfOrder, setDateOfOrder] = useState(utcFirstDayOfMonth);
  
  let formattedStartDate = "";
  
  if (dateOfOrder) {
    formattedStartDate = format(dateOfOrder, 'yyyy-MM-dd');
  }


 
  const [vendorOrderFeeAdd, setVendorOrderFeeAdd] = useState();

  const [formData, setFormData] = useState({
    MonthlyEmployeeSalary: '',
    EmployeeNumber: '',
    FulfilledProductCount: '',
})


       
  const postOrderFee = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = `${BASE_URL}api/v1/vendor/${vendorOrderFeeAdd}/add_order_fee_calculation/?`

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "DateOfMonth": formattedStartDate,
        "MonthlyEmployeeSalary" : formData.MonthlyEmployeeSalary,
        "EmployeeNumber" : formData.EmployeeNumber ,
        "FulfilledProductCount" : formData.FulfilledProductCount
      }),
      redirect: 'follow'
    };

    fetch(request_url, requestOptions)
  .then(response => {
    if (response.ok) {
      return response.json(); 
    } else {
      console.log("Post işlemi başarısız.");
      throw new Error("Post işlemi başarısız.");
    }
  })
  .then(data => {
    console.log("dt",data);
    toast.success("hesaplama başarılı olarak kayıt edilmiştir", { theme: 'colored' }); 
  })
  .catch(error => {
    console.log('error', error);
  });

  }


  useEffect(() => {
    if(formData.MonthlyEmployeeSalary && formData.EmployeeNumber){
        postOrderFee()
    }
   
   }, [formData])
   
   
  


  return { setVendorOrderFeeAdd, setFormData, formData };
}