import useOrderList from 'hooks/useOrderList';
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import visa from '../../../../../../assets/img/icons/visa.png';
import useRefundList from 'hooks/KolayIade/useRefundList';

const OrderInfo = ({detailData}) => {


  const location = useLocation()
  const {id} = location.state || ""

const transformDetailData = (detailData) => {
  if (!detailData) {
    return {}; 
  }

  const date = new Date(detailData.ClaimDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const paymentMethods = ['', 'Kredi Kart', 'Kapıda Nakit', 'Kapıda Kredi Kart', 'Diğer', 'Havale']

  return {
    name: `${detailData.RelatedOrder.ShippingAddress.FirstName} ${detailData.RelatedOrder.ShippingAddress.LastName}`,
    order_number: detailData.OrderNumber,
    date: `${day}-${month}-${year}`,
    address : detailData.RelatedOrder.ShippingAddress.AddressText,
    city: detailData.RelatedOrder.ShippingAddress.City,
    email : detailData.RelatedOrder.ShippingAddress.Email || '--',
    phone : detailData.RelatedOrder.ShippingAddress.Phone,
    status: detailData.status,
    amount: detailData.RelatedOrder.OrderAmount,
    trackingLink: detailData.RelatedOrder.ShipmentTrackingLink,
    note: detailData.WarehouseNote,
    payment : paymentMethods[detailData.RelatedOrder.PaymentMethod],

    // invoice
    company: detailData.RelatedOrder.InvoiceAddress.Company || '--',
    taxOffice :detailData.RelatedOrder.InvoiceAddress.TaxOffice,
    taxNumber :detailData.RelatedOrder.InvoiceAddress.TaxNumber ||detailData.RelatedOrder.IdentityNumber,
    invoiceCity :detailData.RelatedOrder.InvoiceAddress.City,
    invoiceAddress :detailData.RelatedOrder.InvoiceAddress.AddressText,
    invoiceDistrict :detailData.RelatedOrder.InvoiceAddress.District,
    invoicePhone :detailData.RelatedOrder.InvoiceAddress.Phone,
    invoiceEmail :detailData.RelatedOrder.InvoiceAddress.Email,
    invoiceName :detailData.RelatedOrder.InvoiceAddress.FirstName+ " "+detailData.RelatedOrder.InvoiceAddress.LastName,
  };
};

const transformedData = transformDetailData(detailData);

console.log()


  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Teslimat Adresi</h5>
            <h6 className="mb-2">{transformedData?.name}</h6>
            <p className="mb-1 fs--1">
            {transformedData?.address} <br /> {transformedData?.city}
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Email: <a href="mailto:ricky@gmail.com">{transformedData?.email}</a>
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Telefon: <a href="tel:">{transformedData?.phone}</a>
              </strong>
            </p>
          </Col>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Fatura Bilgileri</h5>
            <h6 className="mb-2">{transformedData.invoiceName}</h6>
            <h6 className="mb-2">{transformedData.company}</h6>
           
            <p className="mb-0 fs--1">
            {transformedData.invoiceAddress} <br /> {transformedData.invoiceCity}
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Vergi Dairesi: {transformedData.taxOffice}
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Vergi Nu. / TC Nu.: <a href="tel:">{transformedData.taxNumber}</a>
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Email: <a href="mailto:">{transformedData.invoiceEmail}</a>
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Telefon: <a href="tel:">{transformedData.invoicePhone}</a>
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Fatura Numarası: {transformedData.invoiceNumber}
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Fatura Oluşturulma Tarihi: {transformedData.invoiceCreateDate}
              </strong>
            </p>
          </Col>
          <Col  md={6} lg={4}>
            <div>

            <h5 className="mb-3 fs-0">Ödeme Yöntemi</h5>
            <div className="d-flex">
              <img
                src={visa}
                width="40"
                height="30"
                className="me-3"
                alt="visa"
              />
              <div className="flex-1">
                <h6 className="mb-0">{transformedData.payment }</h6>
                {/* <p className="mb-0 fs--1">**** **** **** 9809</p> */}
              </div>
            </div>
            {/* <div>
              <p style={{fontWeight: '600', fontSize:'13px', color:'#334050', marginTop:'15px'}}>Müşteri İade Notu</p>

              <p>
                <span style={{color:"#334050"}}>"</span>
               
                {transformedData.payment}
                
                <span style={{color:"#334050"}}>"</span>
              </p>

            </div> */}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

OrderInfo.propTypes = {};

export default OrderInfo;
