import React, { useContext } from 'react';
import { Container, Row, Col, Card} from 'react-bootstrap';
import IntegrationCard from './IntegrationCard'; 
import useStoreList from 'hooks/useStoreList';
import { PermsContext } from 'context/PermsContext';
import huboo from '../store_images/huboo.png'
import netsis from '../store_images/netsis.png'




const InventoryCards = () => {

  const {myDataStore, detail, getDetail}= useStoreList()

  const {perms} = useContext(PermsContext)

  function hasPermission(permission) {
    return perms?.includes(permission);
  }

  return (

    hasPermission('Dashboard Entegrasyon Ekleme') ? (
  <>
    <Col sm={12} lg={12} style={{marginBottom:'30px'}}>
        <Card 
        style={{ backgroundColor: "#fff", color:'#5E6E82'}}
        >
          <Card.Body>
          <Card.Text style={{fontSize:'20px'}}>
            ShipShack ile e-ticaret çok kolay!
            </Card.Text>

            <Card.Text>
            Sanal Mağaza entegrasyonları ile satışlarınızı otomatik olarak ShipShack'a aktarıyoruz.
            </Card.Text>
            <Card.Text>
            Eğer aşağıdaki platformlarda mağazanız varsa entegrasyon bilgilerini girmeniz yeterli. Detaylı bilgi için ilgili mağazaya tıklayın.
            </Card.Text>
          </Card.Body>
          </Card>
    </Col>
 
      <Row>
        {myDataStore
          ?.filter(item => !item?.Name.includes("Custom"))
          .map((item) => (
            <IntegrationCard item={item} key={item.Id} id={item.Id} name={item.Name} image={item.Image} />
          ))}
      </Row>

    
    </>

) : (

  <Row className="g-4">
  <Col sm={12} lg={12} >
      <Card 
      style={{ backgroundColor: "#fff", color:'#5E6E82'}}
      >
        <Card.Body>
        <Card.Text style={{fontSize:'20px'}}>
          ShipShack ile e-ticaret çok kolay!
          </Card.Text>

          <Card.Text>
          Sanal Mağaza entegrasyonları ile satışlarınızı otomatik olarak ShipShack'a aktarıyoruz.
          </Card.Text>
          <Card.Text>
              Eğer sanal platformlarda mağazanız varsa, detaylı bilgi almak ve entegrasyon için 
              <a href="mailto:info@shipshack.com.tr" target="_blank" rel="noopener noreferrer"> iletişime </a> geçiniz.
          </Card.Text>
        </Card.Body>
        </Card>
  </Col>
 </Row>
      )

  );
};

export default InventoryCards;