import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, InputGroup, Row, Col, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import PaginationProductAll from './PaginationProductAll';
import ProductTableHeaderAll from './ProductTableHeaderAll';
import useProductListAll from 'hooks/useProductListAll';
import Button from 'react-bootstrap/Button';
import { Search } from 'react-bootstrap-icons';
import gif from 'assets/img/gif_export_s3.gif'
import useVendorList from 'hooks/useVendorList';
import DatePicker from  "react-datepicker";
import useStoreSalesDailyTable from 'hooks/useStoreSalesDailyTable';
import DealVSGoalChart from './sales-chart/DealVsGoalChart';
import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import gifOrder from 'assets/img/gif_order.gif'
import Modal from 'react-bootstrap/Modal';
import useStoreSalesDailyExportReportPage from 'hooks/useStoreSalesDailyExportReportPage';
import { PermsContext } from 'context/PermsContext';




const StoreSalesDaily = () => {

  const { myData, currentPage, setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, isSwitchedOn, setIsSwitchedOn, startDate, endDate, setStartDate, setEndDate } = useStoreSalesDailyTable();

  const { startDateModal, setStartDateModal, endDateModal, setEndDateModal, getDataExport, showGif, setShowGif} = useStoreSalesDailyExportReportPage()

  const [activePage, setActivePage] = useState(1);
  const [storeColumns, setStoreColumns] = useState([]);
  const {vendorIdContext} = useContext(PermsContext)

  useEffect(() => {
    if (myData) {
      // Tüm mağaza isimleri
      const allStores = myData.reduce((acc, item) => {
        Object.keys(item.store_names).forEach(store => {
          if (!acc.includes(store)) {
            acc.push(store);
          }
        });
        return acc;
      }, []);

      // Mağaza isimlerine göre dinamik kolonlar oluşturma
      const dynamicColumns = allStores.map(storeName => ({
        accessor: storeName,
        Header: `${storeName} ${isSwitchedOn ? '(₺)' : '(adet)'}`,
        disableSortBy: true,
        headerProps: {
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center'
        },
        Cell: rowData => {
          const storeData = rowData.row.original.store_names[storeName];
          return storeData ? (isSwitchedOn ? storeData.Price : storeData) : 0;
        }
      }));

      setStoreColumns(dynamicColumns);
    }
  }, [myData, isSwitchedOn]);




  const columns = [
    {
      accessor: 'date',
      Header: 'Tarih',
      disableSortBy: true,
      headerProps: { className: 'text-center' },
      cellProps: {
        className: 'text-center py-2'
      },
      Cell: rowData => rowData.row.original.date
    },
    ...storeColumns
  ];

  const orderList = myData?.map((item) => {
    return {
      date: item.date,
      store_names: item.store_names
    };
  }) || [];


  const handleChangeDate = event => {
    setSelectedOption(event.target.value);
  
    let start, end;
  
    switch(event.target.value) {
      case "1": // Bugün
        start = new Date();
        end = new Date();
        break;
  
      case "2": // Bu hafta
        end = new Date();
        start = new Date();
        start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
        break;   
  
      case "3": // Bu ay
        end = new Date();
        start = new Date();
        start.setDate(1); // Ayın ilk günü
        break;
    
      default:
        // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
        break;
    }
  
    setStartDate(start);
    setEndDate(end);
  
   
  };
  
  const [selectedOption, setSelectedOption] = useState(null);
  
  
  useEffect(() => {
    if (selectedOption === "4") {
      setIsOpenDate(true);
    } else {
      setIsOpenDate(false);
    }
  }, [selectedOption]);
  
  
  const [isOpenDate, setIsOpenDate] = useState(true);
  
  const onChange = dates => {
  
    const [start, end] = dates;
  
    console.log("start", start);
    console.log("end", end);
  
  
    if (selectedOption === "4") {
      setStartDate(start);
      setEndDate(end);
  
   
    }
    if (start && end) {
      setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
    }
  };

    // modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
  
    const handleExport = ()=> {
      if (!vendorIdContext) {
        return
      }
  
      getDataExport(vendorIdContext); 
      setShowGif(true);
      setShow(false)
    
    
    }

    const onChangeModal = datesExport => {
      const [startExport, endExport] = datesExport;
      setStartDateModal(startExport);
      setEndDateModal(endExport);
    };




  return (
<>
    <Card className="h-90" style={{marginBottom:'-10px'}}>
   <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
            <FalconCardHeader
                title="Mağaza Bazlı Adetler"
                titleTag="h6"
                className="py-2 mt-2"
                // endEl={<CardDropdown />}
              />
            </div>
            <div style={{ display: "flex", justifyContent: 'right' }}>

                <div style={{marginRight:"8px", marginTop:'10px'}}>
                  <IconButton
                        variant="falcon-default"
                        size="m"
                        icon="external-link-alt"
                        transform="shrink-3"
                        onClick={handleShow}
                      >
                        <span className="d-none d-sm-inline-block ms-1">Export</span>
                      </IconButton>
                </div>

                <div className="mb-2" style={{marginTop:'10px',marginRight:"8px" }}>
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                        <option value="" disabled selected>Tarih</option>
                        <option key={1} value="1">Bugün</option>
                        <option key={2} value="2">Bu hafta</option>
                        <option key={2} value="3">Bu ay</option>
                        <option key={3} value="4">Tarih seç</option>
                    </Form.Select> 

                    {selectedOption === "4" && 
                    <DatePicker
                      showIcon
                      selected={startDate}
                      onChange={onChange}
                      startDate={startDate}
                      formatWeekDay={day => day.slice(0, 3)}
                      endDate={endDate}
                      selectsRange
                      dateFormat="MMM-dd"
                      className='form-control'
                      placeholderText='tarih giriniz'
                      open={isOpenDate}
                      onCalendarClose={() => setIsOpenDate(false)}
                      onCalendarOpen={() => setIsOpenDate(true)}
                    />
                    }
               
              </div>
            </div>
          </div>
    <Card.Body>
      <DealVSGoalChart data={myData || []}   />
    </Card.Body>
   
  </Card>
    <AdvanceTableWrapper
      columns={columns}
      data={orderList}
      selection
      sortable
      pagination
      perPage={50}
    >
      <Card className="mb-3">
        <Card.Header>
         
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <PaginationProductAll
            pages={totalPages}
            activePage={activePage}
            setActivePage={setActivePage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Footer>
      </Card>

      <Modal 
        show={show} 
        onHide={handleClose}
        >
        <Modal.Header closeButton>
          <Modal.Title>Mağaza Bazlı Adetler</Modal.Title>
            </Modal.Header>
              <Modal.Body>
             
          <div style={{display:'flex', justifyContent:'center'}} >
             
                  <div style={{marginRight:"8px", fontSize:"14px"}}>
                        <DatePicker
                        showIcon
                        selected={startDateModal}
                        onChange={onChangeModal}
                        startDate={startDateModal}
                        formatWeekDay={day => day.slice(0, 3)}
                        endDate={endDateModal}
                        selectsRange
                        dateFormat="MMM dd"
                        className='form-control'
                        placeholderText='tarih giriniz'
                        />
                  </div>

          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Vazgeç
            </Button>
            <Button variant="primary" onClick={handleExport}>
              İndir
            </Button>
          </Modal.Footer>
      </Modal> 
      <span style={{ marginRight: "5px", marginLeft: "5px" }}>Ürün sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{ marginLeft: "5px" }}></span>
    </AdvanceTableWrapper>

    </>
  );
};

export default StoreSalesDaily;