import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { DateContext } from 'context/DateContext';
import { PermsContext } from 'context/PermsContext';

export default function useEcomStatPacked () {


 const [myDataPacked, setMyDataPacked] = useState() 
 const {myKey, currentUser} = useContext(AuthContext)
 const [beginDatePacked, setBeginDatePacked] = useState()
 const [endDatePacked, setEndDatePacked] = useState()
 const { startDate, endDate } = useContext(DateContext);
 const {vendorIdsContext, storeIdContext,
  regionContext} = useContext(PermsContext)
 

//paketlenen
const getDataPacked = ()=>{
  var myHeaders = new Headers();
  


      const params = {

        // begin_date: beginDatePacked,
        // end_date: endDatePacked,
        begin_date_packed: startDate,
        end_date_packed: endDate,
        // vendor_username: currentUser,
        vendor_ids : vendorIdsContext,
        store : storeIdContext,
        inventory_region : regionContext

      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/packed_orders_today_count/?`
  );





  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMyDataPacked(result) 
      setLoading(false)

})
    .catch(error => console.log('error', error));        
}





 useEffect(() => {

  
  if (startDate && endDate) {
    getDataPacked();
  }

  }, [beginDatePacked,endDatePacked, startDate, endDate, vendorIdsContext, storeIdContext, regionContext]);

 return {myDataPacked, setBeginDatePacked, setEndDatePacked}
}
