import useOrderList from 'hooks/useOrderList';
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import visa from '../../../../../assets/img/icons/visa.png';
import { FaUser } from "react-icons/fa";
import { RiBillLine } from "react-icons/ri";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { MdOutlineCancel, MdCheck } from 'react-icons/md';

const OrderInfo = () => {


  const location = useLocation()
  const {id} = location.state || ""
  const {detail, getDetail} = useOrderList()

  useEffect(() => {
    if(id){
    getDetail(id)
    }
  
  }, [id])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const activePage = params.get('activePage');

    if (id && activePage) {
      getDetail(id)
    }
  }, []);
  

const data = detail?.map(item=>{
  const InvoiceDate = new Date(item.Invoices[0]?.CreatedAt);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const paymentMethods = ['', 'Kredi Kartı','Kapıda Nakit', 'Kapıda Kredi Kart','Diğer','Havale' ]

  const formatDate = (dateTime) => {


    const dateObj = new Date(dateTime);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

   return {
    payment : paymentMethods[item.PaymentMethod],
    nameLastname : item.Customer.FirstName+ " "+item.Customer.LastName,
    addressCity : item.ShippingAddress.City,
    address : item.ShippingAddress.AddressText,
    phone : item.Customer.Phone || '--',
    email : item.Customer.Email ? item.Customer.Email : '--',

    // invoice 
    company: item.InvoiceAddress.Company || '--',
    taxOffice : item.InvoiceAddress.TaxOffice,
    taxNumber : item.InvoiceAddress.TaxNumber || item.InvoiceAddress.IdentityNumber,
    invoiceCity : item.InvoiceAddress.City,
    invoiceAddress : item.InvoiceAddress.AddressText,
    invoiceDistrict : item.InvoiceAddress.District,
    invoicePhone : item.InvoiceAddress.Phone,
    invoiceEmail : item.InvoiceAddress.Email,
    invoiceName : item.InvoiceAddress.FirstName+ " "+item.InvoiceAddress.LastName,
    invoiceNumber : item.Invoices[0]?.Details || "fatura henüz oluşturulmamıştır.",
    invoiceCreateDate : InvoiceDate.toLocaleDateString('tr-TR', options) || "fatura henüz oluşturulmamıştır.",

    waybillDate : item.WaybillPrintDate ? formatDate(item.WaybillPrintDate) : 'Henüz yazdırılmadı.',
    user : item.charged_by_detail?.username || 'Sipariş üstlenilmemiştir.'



   }
})

console.log()


  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Teslimat Adresi</h5>
            <h6 className="mb-2">{data?.length >0 && data[0]?.nameLastname}</h6>
            <p className="mb-1 fs--1">
            {data?.length >0 && data[0]?.address} <br /> {data?.length >0 && data[0]?.addressCity}
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Email: <a href="mailto:ricky@gmail.com">{data?.length >0 && data[0]?.email}</a>
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Telefon: <a href="tel:">{data?.length >0 && data[0]?.phone}</a>
              </strong>
            </p>
          </Col>
          <Col md={6} lg={4} className="mb-4 mb-lg-0">
            <h5 className="mb-3 fs-0">Fatura Bilgileri</h5>
            <h6 className="mb-2">{data?.length >0 && data[0]?.invoiceName}</h6>
            <h6 className="mb-2">{data?.length >0 && data[0]?.company}</h6>
           
            <p className="mb-0 fs--1">
            {data?.length >0 && data[0]?.invoiceAddress} <br /> {data?.length >0 && data[0]?.invoiceCity}
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Vergi Dairesi: {data?.length >0 && data[0]?.taxOffice}
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Vergi Nu. / TC Nu.: <a href="tel:">{data?.length >0 && data[0]?.taxNumber}</a>
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Email: <a href="mailto:">{data?.length >0 && data[0]?.invoiceEmail}</a>
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Telefon: <a href="tel:">{data?.length >0 && data[0]?.invoicePhone}</a>
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Fatura Numarası: {data?.length >0 && data[0]?.invoiceNumber}
              </strong>
            </p>
            <p className="mb-0 fs--1">
              <strong>
                Fatura Oluşturulma Tarihi: {data?.length >0 && data[0]?.invoiceCreateDate}
              </strong>
            </p>
          </Col>
          <Col  md={6} lg={4}>
            <div>

            <h5 className="mb-3 fs-0">Ödeme Yöntemi</h5>
            <div className="d-flex">
              <img
                src={visa}
                width="40"
                height="30"
                className="me-3"
                alt="visa"
              />
              <div className="flex-1">
                <h6 className="mb-0">{data?.length >0 && data[0]?.payment }</h6>
                {/* <p className="mb-0 fs--1">**** **** **** 9809</p> */}
              </div>
             </div>

             <div>
                
                <div className="d-flex mt-6">
                  <FaUser style={{marginRight:'5px'}}/>
                  <h6>Sipariş Sorumlusu</h6>
                </div>
                <p className="mb-1 fs--1">{data?.length >0 && data[0]?.user}</p>


                <div className="d-flex mt-6">
                  <HiOutlineNewspaper style={{fontSize:'20px', marginRight:'5px'}}/>
                  <h6 >İrsaliye Yazdrılma Durumu</h6>

                </div>
                <div>
                  {data?.length > 0 && (
                    <p className="mb-1 fs--1">
                      {data[0].waybillDate}
                      {' '}
                      {data[0].waybillDate === 'Henüz yazdırılmadı.' ? (
                        <MdOutlineCancel style={{ marginLeft: '10px', color: 'red', fontSize:'20px' }} />
                      ) : (
                        <MdCheck style={{ marginLeft: '10px', color: '#4189DB', fontSize:'20px' }} />
                      )}
                    </p>
                  )}
                </div>




               


              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

OrderInfo.propTypes = {};

export default OrderInfo;
