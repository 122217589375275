import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'




export default function useOrderCostExport() {

  const [myData, setMyData] = useState()
  const {myKey, currentUser} = useContext(AuthContext)


  const [cargoModal, setCargoModal] = useState()

  const date = new Date();

  const [startDateModal, setStartDateModal] = useState(new Date(date.setDate(date.getDate() -7)));
  const [endDateModal, setEndDateModal] = useState(new Date(date.setDate(date.getDate() +8)));

  let formattedStartDate = "";
  let formattedEndDate = "";

  if(startDateModal){
    formattedStartDate = startDateModal.toISOString().slice(0, 10);
  }
  if(endDateModal){
    formattedEndDate = endDateModal.toISOString().slice(0, 10);
  }

  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); 
  const year = today.getFullYear();
  const formattedDate = `${day}.${month}.${year}`




 const getDataExport = async ()=>{
      var myHeaders = new Headers();
      let vendor_id;
      let request_url;
      

      if(cargoModal && formattedStartDate && formattedEndDate) {
          request_url = `${BASE_URL}api/v1/order/export_order_cost_csv/?cargo=${cargoModal}&begin_date=${formattedStartDate}&end_date=${formattedEndDate}&vendor_username=${currentUser}`;
      } 
         
      else if(formattedStartDate && formattedEndDate) {
        request_url = `${BASE_URL}api/v1/order/export_order_cost_csv/?vendor_username=${currentUser}&begin_date=${formattedStartDate}&end_date=${formattedEndDate}`;
      } 

      else if(cargoModal) {
        request_url = `${BASE_URL}api/v1/order/export_order_cost_csv/?cargo=${cargoModal}&vendor_username=${currentUser}`;
      }

      else {
          request_url = `${BASE_URL}api/v1/order/export_order_cost_csv/?vendor_username=${currentUser}`;
      }
    
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.download = `Sipariş Maliyetleri-${currentUser}-${formattedDate}.csv`

    const handleSuccess = (response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        anchor.href = url;
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    };

    const handleError = (error) => {
      console.log("error", error);
    };

    var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Token "+myKey},
            redirect: 'follow'
          }
    fetch(request_url, requestOptions)
    .then(handleSuccess)
    .catch(handleError);
};


 return {myData,setMyData,  startDateModal, setStartDateModal, endDateModal, setEndDateModal,  cargoModal, setCargoModal, getDataExport}
}
