import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const IntegrationCardAvaible = ({ item, id, name, image, uuid, abv }) => {
  return (
    <Col sm={6} lg={4}  className="mb-3">
      <Card
        style={{ backgroundColor: '#fff', transition: 'background-color .2s', cursor: 'pointer' }}
        onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = '#efefef'; }}
        onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = '#fff'; }}
      >
        <Link to={`/e-commerce/integration-metadata_list`} state={{id, name, item}}>
          <Card.Body className="d-flex justify-content-center align-items-center">
            <img src={image} alt={name} width={183}  height={85}/>
          </Card.Body>
        </Link>
        <Card.Footer style={{ height: '40px', backgroundColor: '#f5f5f5',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{fontWeight:'bold',fontSize:'14px' }}>{abv}</span>-
          <span style={{fontSize:'14px'}}>{uuid}</span>
          </Card.Footer>

      </Card>
    </Col>
  );
};

export default IntegrationCardAvaible;