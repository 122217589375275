import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import { PermsContext } from 'context/PermsContext';


export default function useInventoryRegion() {

 const [regionData, setRegionData] = useState([])

 const {myKey} = useContext(AuthContext)

 
 const getData = ()=>{
        var myHeaders = new Headers();



        const params = {
        
        
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}wms/api/v1/inventory/?`
    );
      
    

        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setRegionData(result.results) 
    })
          .catch(error => console.log('error', error));        
 }

 


 useEffect(() => {
    getData();
  }, []);

 return {regionData}
}
