import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import CardDropdown from 'components/common/CardDropdown';
import TotalSalesChart from './TotalSalesChart';
import useInventoryRegion from 'hooks/useInventoryRegion';
import useInventoryProductStockChart from 'hooks/useInventoryProductStockChart';
import { PermsContext } from 'context/PermsContext';
import DatePicker from  "react-datepicker";

const ProductInventoyChart = () => {

  const chartRef = useRef(null);
  const location = useLocation()
  const {sku} = location.state || ""
  // const {myDetail, getDetail} = useProductDetail()

  
 

  const { dataActivity, getData, setStartDate, setEndDate, startDate, endDate,inventoryId, setInventoryId } = useInventoryProductStockChart();
  const {regionData} = useInventoryRegion()


  useEffect(() => {
    if(sku){
      getData(sku)
    }
  
  }, [sku, inventoryId])


  

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sku = params.get('sku');

    if(sku){
      getData(sku)
    }
  }, [ sku, inventoryId]);

  const data = {};
  data.satışTl = dataActivity?.map(item => item.EndOfDayCount)

 


  const handleLegendToggle = name => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name
    });
  };

  const handleChangeRegion = (e)=> {
    setInventoryId(e.target.value); 
  
  }

  const handleChangeDate = event => {
    setSelectedOption(event.target.value);
  
    let start, end;
  
    switch(event.target.value) {
      case "1": // Bugün
        start = new Date();
        end = new Date();
        break;
  
      case "2": // Bu hafta
        end = new Date();
        start = new Date();
        start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
        break;   
  
      case "3": // Bu ay
        end = new Date();
        start = new Date();
        start.setDate(1); // Ayın ilk günü
        break;
    
      default:
        // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
        break;
    }
  
    setStartDate(start);
    setEndDate(end);
  
   
  };
  
  const [selectedOption, setSelectedOption] = useState(null);
  
  
  useEffect(() => {
    if (selectedOption === "4") {
      setIsOpenDate(true);
    } else {
      setIsOpenDate(false);
    }
  }, [selectedOption]);
  
  
  const [isOpenDate, setIsOpenDate] = useState(true);
  
  const onChange = dates => {
  
    const [start, end] = dates;
  
    console.log("start", start);
    console.log("end", end);
  
  
    if (selectedOption === "4") {
      setStartDate(start);
      setEndDate(end);
  
   
    }
    if (start && end) {
      setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
    }
  };





  return (
    <Card>
      <Card.Header>
        <Row className="flex-between-center g-0">
          
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
              <h6 className="mb-0">Günlük Stok</h6>
            </div>

          <div style={{display:"flex", justifyContent:'right'}}> 

              <div id="orders-actions">
                  { regionData?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} 
                  onChange={handleChangeRegion}
                  value={inventoryId}
                  >
                                  <option value="">Depo</option>
                                      { regionData?.map((item, i)=>{
                                            return <option key={i} value={item.Id}>{item.name}</option>
                                      })}       
                              </Form.Select>}
                </div>
                <div style={{ marginLeft:'10px', width:'300px'}}>

                <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                  <option value="" disabled selected>Tarih</option>
                  <option key={1} value="1">Bugün</option>
                  <option key={2} value="2">Bu hafta</option>
                  <option key={2} value="3">Bu ay</option>
                  <option key={3} value="4">Tarih seç</option>
                              </Form.Select> 

                {selectedOption === "4" && 
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    endDate={endDate}
                    selectsRange
                     dateFormat="MMM-dd"
                    className='form-control'
                    placeholderText='tarih giriniz'
                    open={isOpenDate}
                    onCalendarClose={() => setIsOpenDate(false)}
                    onCalendarOpen={() => setIsOpenDate(true)}
                  />
                }
                  </div>

            </div>

            </div>
       
        </Row>
      </Card.Header>
      <Card.Body className="pe-xxl-0">
       
{dataActivity && dataActivity.length > 0 ? (
           <TotalSalesChart
           data={data}
           dataActivity={dataActivity}
           ref={chartRef}
           style={{ height: '18.625rem' }}
         />
        ) : (
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center',alignItems: 'center', height: '14rem' }}>
            Seçilen depo ve tarihde stok hesaplaması yapılmamıştır.
          </div>
        )}



      </Card.Body>
    </Card>
  );
};

// TotalSales.propTypes = {
//   data: PropTypes.shape({
//     // lastMonth: PropTypes.array,
//     // previousYear: PropTypes.array
//   })
// };

export default ProductInventoyChart;
