import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  Card, Form, OverlayTrigger, Tooltip  } from 'react-bootstrap';
import bg1 from 'assets/img/icons/spot-illustrations/corner-3.png';
import useRefundCost from 'hooks/useRefundCost';

import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import Background from 'components/common/Background';
import classNames from 'classnames';
import '../style.css'
import AppContext from 'context/Context';

const StatisticsCardsRefendCost = () => {


const {myData, setBeginDate} = useRefundCost()
const filteredMonths = ["Son 1 ay", "Son 6 ay", "Son 1 yıl"] 
const [selectedOption, setSelectedOption] = useState("Son 1 ay");


// useEffect(() => {
//   const now = new Date();
//   const beginDate = new Date();
//   beginDate.setMonth(now.getMonth() - 1);

//   setBeginDate(beginDate.toISOString().split("T")[0]);
//   setEndDate(now.toISOString().split("T")[0]);
// }, []);

// const handleChange = (e) => {
//   setSelectedOption(e.target.value);

//   let subtractAmount;

//   switch(e.target.value) {
//     case "Son 1 ay":
//       subtractAmount = 1;
//       break;
//     case "Son 6 ay":
//       subtractAmount = 6;
//       break;
//     case "Son 1 yıl":
//       subtractAmount = 12;
//       break;
//     default:
//       subtractAmount = 0;
//   }

//   const now = new Date();
//   const beginDate = new Date();
//   beginDate.setMonth(now.getMonth() - subtractAmount);

//   setBeginDate(beginDate.toISOString().split("T")[0]);
//   setEndDate(now.toISOString().split("T")[0]);
// };
  


  const statsData = [
    {
      title: 'İade Maliyeti',
      value: (myData?.total_cost || 0).toLocaleString('tr-TR', {style: 'currency', currency: 'TRY'}),
      decimal: true,
      // suffix: 'k',
      prefix: '',
      valueClassName: 'text-warning',
      badgeBg: 'warning',
      badgeText: '-0.23%',
      link: '/e-commerce/customers',
      linkText: 'See all',
      image: bg1
    }]

    console.log("statsData", statsData)

    const {
      config: { isDark, isRTL },
      setConfig
    } = useContext(AppContext);

  return (
    <>

    <Card style={{height:"165px", backgroundColor:isDark ? "#0B1727" : "#F6FAFF", marginRight:"17px"}}  >
      {/* <Background image={statsData[0]?.image} style={{pointerEvents: "none"}}/> */}

      <FalconCardHeader
        title={
          <div className="d-flex align-items-center" style={{color: isDark ? "#D8E2EF":"#025098"}}>
            {statsData[0]?.title}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip
                bsPrefix='my-tooltip-class'
              >Hasarlı iade gelen ürün maliyeti</Tooltip>}
            >
              <span>
                <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  transform="shrink-1"
                  className="ms-1 text-400"
                  id="weeklySalesTooltip"
                />
              </span>
            </OverlayTrigger>
          </div>
        }
        titleTag="h6"
        className="pb-0"
        endEl={
          <Flex>
              {/* <Form.Select  className='form-control' style={{fontSize:"17px", position:"relative", zIndex:10}}
               onChange={handleChange}
               value={selectedOption}
               size="sm"
               >
                     <option value="">Tarih</option>
                        {filteredMonths?.map((item, i)=>{
                              return <option  key={i} value={item}>{item}</option>
                        })}
                </Form.Select>  */}
          </Flex>
        }
      />
      {/* <FalconCardHeader
  title={
    <div className="d-flex align-items-center">
      {statsData[0]?.title}
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Son 30 günlük iade oranı</Tooltip>}
      >
        <span>
          <FontAwesomeIcon
            icon={['far', 'question-circle']}
            transform="shrink-1"
            className="ms-1 text-400"
            id="weeklySalesTooltip"
          />
        </span>
      </OverlayTrigger>
    </div>
  }
  titleTag="h6"
  className="pb-0 mt-2"
/> */}
      <Card.Body >
        <h6 className="mb-5">
       
        </h6>
        <div
        
        >
            <p className='mb-0' style={{fontSize:"25px",color: isDark ? "#D8E2EF":"#5E6E82"}} >{statsData[0]?.value}</p>
        </div>
     
      </Card.Body>
    </Card>

    </>
  );
};

export default StatisticsCardsRefendCost;
