import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useVendorList from './useVendorList';


export default function useInventoryList() {

 const [myData, setMyData] = useState()

 const {myKey, currentUser} = useContext(AuthContext)
 const {myDataVendor} = useVendorList()

 const [vendorIdInventoryList, setVendorIdInventoryList] = useState()


 useEffect(() => {
  if (myDataVendor?.length > 0) {
    setVendorIdInventoryList(myDataVendor[0].Id);
  }
}, [myDataVendor]);
 


 const getData = ()=>{
        var myHeaders = new Headers();

     
        const params = {
          vendor_id : vendorIdInventoryList
        };
            
          const request_url = Object.entries(params)
              .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
              .reduce(
                (url, [key, value]) => url + `&${key}=${value}`,
                `${BASE_URL}api/v1/order/inventory_list/?`
      ); 
     
      
        
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result) 
    })
          .catch(error => console.log('error', error));        
 }

 







 useEffect(() => {
    getData();
  }, [vendorIdInventoryList]);

 return {myData, setVendorIdInventoryList}
}
