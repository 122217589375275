import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from '../baseUrl'
import useDebounce from '../useDebounce';



export default function useInbountFees() {

  const [myData, setMyData] = useState()
  const [page, setPage] = useState(1)
  const {myKey} = useContext(AuthContext)
  const [detail, setDetail] = useState()
  const [search, setSearch] = useState()
  const [cargo, setCargo] = useState()
  const date = new Date();


  const [showOrderGif, setShowOrderGif] = useState(false);

  const [currentPage,setCurrentPage]=useState(1)
  const [followersPerPage, setFollowersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [vendorId,setVendorId] = useState()

  const debouncedSearch = useDebounce(search, 1500);



  const now = new Date(); // Şu anki tarih
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 2); 
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 2); 

  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(lastDayOfMonth);



  let formattedStartDate = "";
  let formattedEndDate = "";

  if(startDate){
    formattedStartDate = startDate.toISOString().slice(0, 10);
  }
  if(endDate){
    formattedEndDate = endDate.toISOString().slice(0, 10);
  }
 
 const getData = ()=>{
        var myHeaders = new Headers();

        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;

        
        const params = {
          // page: page,
          offset :offset,
          limit :limit,
          cargo :cargo,
          begin_date: formattedStartDate,
          end_date: formattedEndDate,
          // search : search,
          // vendor_username: currentUser,
          vendor_id : vendorId,

        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}wms/api/v1/inventory_shipment/inventory_inbound_fees/?`
    );
      

      console.log("order",request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result)
            setShowOrderGif(false); 
            setTotalPages(Math.ceil(result.count / followersPerPage))
    })
          .catch(error => console.log('error', error));        
 }





 useEffect(() => {
    getData();
    setShowOrderGif(true)
  }, [startDate,endDate, cargo, debouncedSearch, currentPage, followersPerPage, vendorId]);

 return {myData, setPage, detail, getData, setMyData, startDate, setStartDate, endDate, setEndDate,  cargo, setCargo, setSearch,showOrderGif, currentPage, setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, setVendorId}
  
  
  
 
}
