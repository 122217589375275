import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function useProductCriticalStockUpdate() {

const {myKey} = useContext(AuthContext)
const navigate = useNavigate();


const [formData, setFormData] = useState({
  CriticalStock: "",
  product_id : ""

    });


 const postData = ()=>{

  

  if ( !formData.CriticalStock) return;


    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url =   `${BASE_URL}api/v1/product/product/${formData.product_id}/update_product/`
    

    var raw = JSON.stringify({
       
        "CriticalStock" : formData.CriticalStock,
       
    });


    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
    .then(response => {
      if (response.ok) {
        response.json()
        .then(result => {
          console.log(result);
          toast.success(`${result.message}`, { theme: 'colored'});
       
        })
      } else {
        response.json()
        .then(result => {
          console.log("Post işlemi başarısız:", result.message);
          toast.error(`Hata: ${result.message}`, { theme: 'colored' });
        })
        .catch(error => {
          console.log('Yanıt çözümlenirken bir hata oluştu:', error);
          toast.error('Beklenmeyen bir hata oluştu.', { theme: 'colored' });
        });
      }
    })
    .catch(error => {
      console.log('fetch işlemi sırasında bir hata oluştu:', error);
      toast.error(`Beklenmeyen bir hata oluştu: ${error.message}`, { theme: 'colored' });
    });

}


useEffect(() => {
  if(formData.CriticalStock){
   postData()
  }
 
 }, [formData])
 



 return {postData, setFormData, formData}
}
