import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import useTotalSales from 'hooks/useTotalSales';
import AppContext from 'context/Context';
import TotalStockChart from './TotalStockChart';
import { Link, useLocation } from 'react-router-dom';
import useInventoryListFilter from 'hooks/useInventoryListFilter';
import useTotalStockSKU from 'hooks/useTotalStockSKU';
import useInventoryRegion from 'hooks/useInventoryRegion';
import DatePicker from  "react-datepicker";
import useInventoryProductForTotalStock from 'hooks/useInventoryProductForTotalStock';
import StockDetailTable from '../stock-detail-table/StockDetailTable';
import AsyncSelect from 'react-select/async';
import useBarcodeSku from 'hooks/useBarcodeSku';
import { MdClear } from "react-icons/md";
import { components } from 'react-select';
import { PermsContext } from 'context/PermsContext';
import IconButton from 'components/common/IconButton';
import Modal from 'react-bootstrap/Modal';
import useStockDailyExport from 'hooks/useStockDailyExport';




const TotalStockSKU = () => {



  const chartRef = useRef(null);
  const {myData, myDataChart, setInventoryId, setStartDate, setEndDate, startDate, endDate, setSKU, sku, setCurrentPage, followersPerPage, setFollowersPerPage, totalPages} = useTotalStockSKU()

  const { startDateModal, setStartDateModal, endDateModal, setEndDateModal, getDataExport, showGif, setShowGif, setInventoryIdExport, setSkuExport, skuExport} = useStockDailyExport()

  const { setInventoryContext, inventoryContext} = useContext(PermsContext)

  const {regionData} = useInventoryRegion()
  const {myDataProduct, setProductInventoryId } = useInventoryProductForTotalStock()

  const {getData, setVendorIdBarcode} = useBarcodeSku()



  useEffect(() => {
    if (myDataProduct?.length > 0) {
      setSKU(myDataProduct[0].SKU);
      setSkuExport(myDataProduct[0].SKU)
    }
  }, [myDataProduct]);


  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  const data = {}

  data.satışTl = myDataChart?.map(item => item.EndOfDayCount)

  const date = myData?.map(item => new Date(`${item.year}-${item.month}-${item.day}`).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }))

  const handleLegendToggle = name => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name
    });
  };

  const handleChangeInventory = (e) => {
    const selectedId = e.target.value;
    setInventoryContext(selectedId);
    setProductInventoryId(selectedId);
    setInventoryIdExport(selectedId)

    const selectedInventory = regionData.find(item => item.Id.toString() === selectedId.toString());
    if (selectedInventory) {
      setVendorIdBarcode(selectedInventory.Vendor);
    }
  };



const handleChangeDate = event => {
  setSelectedOption(event.target.value);

  let start, end;

  switch(event.target.value) {
    case "1": // Bugün
      start = new Date();
      end = new Date();
      break;

    case "2": // Bu hafta
      end = new Date();
      start = new Date();
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
      break;   

    case "3": // Bu ay
      end = new Date();
      start = new Date();
      start.setDate(1); // Ayın ilk günü
      break;
  
    default:
      // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
      break;
  }

  setStartDate(start);
  setEndDate(end);

 
};

const [selectedOption, setSelectedOption] = useState(null);


useEffect(() => {
  if (selectedOption === "4") {
    setIsOpenDate(true);
  } else {
    setIsOpenDate(false);
  }
}, [selectedOption]);


const [isOpenDate, setIsOpenDate] = useState(true);

const onChange = dates => {

  const [start, end] = dates;


  if (selectedOption === "4") {
    setStartDate(start);
    setEndDate(end);

 
  }
  if (start && end) {
    setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
  }
};

const [selectedValue, setSelectedValue] = useState("");

const handleInputChange = value => {
  setBarcode(value);
};



const handleChange = value => {
  setSelectedValue(value);
  console.log("handleChange",value)
  setSKU(value.SKU)
  setSkuExport(value.SKU)
}

const CustomClearText = props => (
  <components.ClearIndicator {...props}>
   <MdClear />
  </components.ClearIndicator>
);


const [barcode, setBarcode] = useState("")

  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleExport = ()=> {
    if (!skuExport) {
      return
    }

    getDataExport(); 
    setShowGif(true);
    setShow(false)
  
  
  }

  const onChangeModal = datesExport => {
    const [startExport, endExport] = datesExport;

    setStartDateModal(startExport);
    setEndDateModal(endExport);

    setStartDate(startExport);
    setEndDate(endExport);

  };





  return (
    <>
    <Card className="h-90" style={{marginBottom:'-10px'}}>
      <Card.Header>
      <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
              <h6 className="mb-0" style={{color: isDark ? "#D8E2EF":"#025098"}}>Günlük Stok</h6>
            </div>

            <div style={{display:"flex", justifyContent:'right'}}>

                  <div style={{marginRight:"8px", marginTop:'10px'}}>
                      <IconButton
                        variant="falcon-default"
                        size="m"
                        icon="external-link-alt"
                        transform="shrink-3"
                        onClick={handleShow}
                      >
                        <span className="d-none d-sm-inline-block ms-1">Export</span>
                      </IconButton>
                  </div>


              <div style={{marginTop:'10px', marginRight:'10px', width:'300px'}}>

                  <AsyncSelect 
                    loadOptions={getData}
                    placeholder="isim, barkod, sku numarası" 
                    className="mb-3" 
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                    value={selectedValue}
                    getOptionLabel={e => e.Title}
                    getOptionValue={e => e.Barcode}
                    isClearable
                    components={{ ClearIndicator: CustomClearText }}
                    styles={{control: (baseStyles) =>({
                      ...baseStyles, 
                      backgroundColor:isDark ? "#0B1727" : "white"
                    }),}}
                /> 
              </div>

            <div style={{marginTop:'10px', marginRight:'10px'}}>
                        { regionData?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} 
                  onChange={handleChangeInventory}
                  value={inventoryContext}
                  >
                <option value="">Depo</option>
                  { regionData?.map((item, i)=>{
                      return <option key={i} value={item.Id}>{item.name}</option>
                    })}       
                  </Form.Select>}
              </div>

              <div className="mb-2" style={{marginTop:'10px'}}>

                  <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                  <option value="" disabled selected>Tarih</option>
                  <option key={1} value="1">Bugün</option>
                  <option key={2} value="2">Bu hafta</option>
                  <option key={2} value="3">Bu ay</option>
                  <option key={3} value="4">Tarih seç</option>
                              </Form.Select> 

                {selectedOption === "4" && 
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    endDate={endDate}
                    selectsRange
                     dateFormat="MMM-dd"
                    className='form-control'
                    placeholderText='tarih giriniz'
                    open={isOpenDate}
                    onCalendarClose={() => setIsOpenDate(false)}
                    onCalendarOpen={() => setIsOpenDate(true)}
                  />
                }
              </div>

            </div>

      </div>   


      </Card.Header>
      <Card.Body>
      {myDataChart && myDataChart.length > 0 ? (
          <TotalStockChart
            data={data}
            myDataChart={myDataChart}
            ref={chartRef}
            style={{ height: '20rem' }}
          />
        ) : (
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center',alignItems: 'center', height: '14rem' }}>
            Seçilen depo ve tarihde stok hesaplaması yapılmamıştır.
          </div>
        )}
      </Card.Body>
    </Card>

    <Modal 
        show={show} 
        onHide={handleClose}
         size="xl"
        >
        <Modal.Header closeButton>
          <Modal.Title>Günlük Stok Adetleri</Modal.Title>
            </Modal.Header>
              <Modal.Body>
             
          <div style={{display:'flex', justifyContent:'center'}} >

              <div style={{marginRight:'10px', width:'300px'}}>
                  <AsyncSelect 
                    loadOptions={getData}
                    placeholder="isim, barkod, sku numarası" 
                    className="mb-3" 
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                    value={selectedValue}
                    getOptionLabel={e => e.Title}
                    getOptionValue={e => e.Barcode}
                    isClearable
                    components={{ ClearIndicator: CustomClearText }}
                    styles={{control: (baseStyles) =>({
                      ...baseStyles, 
                      backgroundColor:isDark ? "#0B1727" : "white"
                    }),}}
                  /> 
                  </div>

              <div style={{marginRight:'10px'}}>
                        { regionData?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} 
                  onChange={handleChangeInventory}
                  value={inventoryContext}
                  >
                <option value="">Depo</option>
                  { regionData?.map((item, i)=>{
                      return <option key={i} value={item.Id}>{item.name}</option>
                    })}       
                  </Form.Select>}
              </div>
             
                  <div style={{marginRight:"8px", fontSize:"14px"}}>
                        <DatePicker
                        showIcon
                        selected={startDateModal}
                        onChange={onChangeModal}
                        startDate={startDateModal}
                        formatWeekDay={day => day.slice(0, 3)}
                        endDate={endDateModal}
                        selectsRange
                        dateFormat="MMM dd"
                        className='form-control'
                        placeholderText='tarih giriniz'
                        />
                  </div>

          </div>
          </Modal.Body>
          <div>
          <p style={{ fontStyle: 'italic', marginLeft:'50px' }}>*Tarih, SKU ve depo seçimi yaparak excel indirebilirsiniz.</p>
          </div>
          {myDataChart && myDataChart.length > 0 ? (
            <div>
       
            </div>
              ) : (
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center',alignItems: 'center', height:'5rem'}}>
            Seçilen depo ve tarihde stok hesaplaması yapılmamıştır.
          </div>
        )}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Vazgeç
            </Button>
            <Button variant="primary" onClick={handleExport}>
              İndir
            </Button>
          </Modal.Footer>
      </Modal> 

      <StockDetailTable sku={sku} myData={myData} setCurrentPage={setCurrentPage} followersPerPage={followersPerPage}setFollowersPerPage={setFollowersPerPage} totalPages={totalPages}/> 
      
     </>
  );
};



export default TotalStockSKU;
