import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useVendorList from './useVendorList';


export default function useInventoryShipmentList() {

 const [myData, setMyData] = useState()
 const {myKey, currentUser} = useContext(AuthContext)
 const [page, setPage] = useState(1)
 const [detail, setDetail] = useState()
 const [vendorIdInventory, setVendorIdInventory] = useState()
 const [search, setSearch] = useState()

 const date = new Date();
 const [startDate, setStartDate] = useState(new Date(date.setDate(date.getDate() -7)));
 const [endDate, setEndDate] = useState(new Date(date.setDate(date.getDate() +8)));


 let formattedStartDate = "";
 let formattedEndDate = "";

 if(startDate){
   formattedStartDate = startDate.toISOString().slice(0, 10);
 }
 if(endDate){
   formattedEndDate = endDate.toISOString().slice(0, 10);
 }



 const getData = ()=>{

  if (!vendorIdInventory) return;
        var myHeaders = new Headers();

          const params = {
            vendor_id : vendorIdInventory,
            page : page,
            search : search,
            begin_date: formattedStartDate,
            end_date: formattedEndDate,
          };
              
            const request_url = Object.entries(params)
                .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
                .reduce(
                  (url, [key, value]) => url + `&${key}=${value}`,
                  `${BASE_URL}wms/api/v1/inventory_shipment/inventory_list/?`
        );  
        
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result) 
    })
          .catch(error => console.log('error', error));        
 }





 const getDetail= (id_nu)=>{
  var myHeaders = new Headers();

  let vendor_id ;

  let request_url;
  if (vendor_id) 
      request_url = `${BASE_URL}/wms/api/v1/inventory_shipment/?vendor_id=${vendor_id}&id=${id_nu}`
  else    
      request_url = `${BASE_URL}/wms/api/v1/inventory_shipment/${id_nu}/`

console.log("data url", request_url)
  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      console.log("result", result)
      setDetail(result) 
})
    .catch(error => console.log('error', error));        
}



useEffect(() => {
  if (vendorIdInventory) {
    getData();
  }
}, [vendorIdInventory, search, startDate,endDate]);

useEffect(() => {
  getData();
}, [page]);

 return {myData, setPage, detail, setDetail, getDetail, setVendorIdInventory, setSearch, startDate, setStartDate, endDate, setEndDate,}
}
