import React from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Form,
  ProgressBar
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import SimpleBarReact from 'simplebar-react';



const getTotalPrice = items => {
  const totalPrice = items
    .map(({ price }) => parseFloat(price))
    .reduce((total, currentValue) => total + currentValue, 0);
  console.log('Total Price:', totalPrice);
  return totalPrice;
};

const getTotalOrder = items => {
  const totalOrder = items
    .map(({ unit }) => unit)
    .reduce((total, currentValue) => total + currentValue, 0);
  console.log('Total Order:', totalOrder);
  return totalOrder;
};

const getProductItemCalculatedData = (price, totalPrice) => {
  const productTotalPrice = parseFloat(price);
  const percentage = ((productTotalPrice * 100) / parseFloat(totalPrice)).toFixed(2);
  console.log('Product Total Price:', productTotalPrice, 'Percentage:', percentage);
  return { productTotalPrice, percentage };
};

const BestSellingTableRow = ({ product, totalPrice, totalOrder }) => {
  const { img, title, type, unit, price } = product;
  const { productTotalPrice, percentage } = getProductItemCalculatedData(
    price,
    totalPrice
  );

  console.log('Product:', product);
  console.log('Total Price:', totalPrice, 'Product Total Price:', productTotalPrice, 'Percentage:', percentage);

  return (
    <tr className="border-top border-200">
      <td>
        <Flex alignItems="center" className="position-relative">
          <img
            className="rounded-1 border border-200"
            src={img}
            width={45}
            alt={title}
            height={45} // Bu satırı ekliyoruz
            style={{ objectFit: 'cover' }}
          />
          <div className="ms-3">
            <p className="mb-1 fw-semi-bold"
             title={title}
            
            >
              {title && title.length > 17
                ? title.slice(0, 17).concat("...")
                : title || ""}

            </p>
            <p className="fw-semi-bold mb-0 text-500">{type}</p>
          </div>
        </Flex>
      </td>
      <td className="align-middle text-center fw-semi-bold">{unit}</td>
      <td className="align-middle text-center fw-semi-bold">
        {((unit / totalOrder) * 100).toFixed(2)}%
      </td>
      <td className="align-middle text-end fw-semi-bold">
        ₺{productTotalPrice.toFixed(2)}
      </td>
      <td className="align-middle pe-x1">
        <Flex alignItems="center">
          <ProgressBar
            className="me-3 bg-200"
            now={parseFloat(percentage)} // `parseFloat` ile string'i sayıya çeviriyoruz
            style={{ width: 80, height: 5 }}
          />
          <div className="fw-semi-bold ms-2">{percentage}%</div>
        </Flex>
      </td>
    </tr>
  );
};

const BestSellingProducts = ({ products, all_products_count, all_products_price}) => {
  
  const totalPrice = getTotalPrice(products);
  const totalOrder = getTotalOrder(products);


  return (
    <Card className="h-100">
      <Card.Body className="p-0">
        <SimpleBarReact>
          <Table borderless className="mb-0 fs--1">
            <thead className="bg-light">
              <tr className="text-900">
                <th>Çok Satan Ürünler</th>
                <th className="text-center">Adet({all_products_count})</th>
                <th className="text-center">Sipariş(%)</th>
                <th className="text-end">Tutar</th>
                <th className="pe-x1 text-end" style={{ width: '8rem' }}>
                  Tutar (%)
                </th>
              </tr>
            </thead>
            <tbody>
              {products.map(product => (
                <BestSellingTableRow
                  product={product}
                  totalPrice={all_products_price}
                  totalOrder={all_products_count}
                  key={product.id}
                />
              ))}
            </tbody>
          </Table>
        </SimpleBarReact>
      </Card.Body>
      <Card.Footer className="bg-light py-2">
        <Row className="flex-between-center">
          <Col xs="auto">
            <Form.Select size="sm">
              <option>Son 7 gün</option>
              {/* <option>Son Bir Ay</option> */}
              {/* <option>Last Year</option> */}
            </Form.Select>
          </Col>
          <Col xs="auto">
            <Button variant="falcon-default" size="sm" as={Link} to="/report-bestproduct-detail">
              Detay
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

// PropTypes
BestSellingTableRow.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  totalOrder: PropTypes.number.isRequired,
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    unit: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired
  }).isRequired
};

BestSellingProducts.propTypes = {
  products: PropTypes.arrayOf(BestSellingTableRow.propTypes.product).isRequired
};

export default BestSellingProducts;

