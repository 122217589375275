import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from '../baseUrl'
import useDebounce from '../useDebounce';



export default function useStorageSKUDetail() {

  const [myData, setMyData] = useState()


  const {myKey, } = useContext(AuthContext)
 
  const [showOrderGif, setShowOrderGif] = useState(false);
  const [currentPage,setCurrentPage]=useState(1)
  const [followersPerPage, setFollowersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [search, setSearch] = useState()


 
 const getData = (inventory_id, id)=>{
        var myHeaders = new Headers(); 

        if ( !inventory_id && !id) return;

        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;

        const params = {
          // page: page,
          offset :offset,
          limit :limit,
          storage_id :id,
          sku : search
         
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}wms/api/v1/inventory/${inventory_id}/sku_storage_fees/?`
    );

   
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result.results)
            setShowOrderGif(false); 
            setTotalPages(Math.ceil(result.count / followersPerPage))
    })
          .catch(error => console.log('error', error));        
 }




 useEffect(() => {
    getData();
    setShowOrderGif(true)
  }, [ currentPage, followersPerPage, search]);

 return {myData, getData, setMyData, showOrderGif, currentPage, setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, setSearch, search}
  
  
  
 
}
