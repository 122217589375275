import BestProducts from 'components/dashboards/default/BestProducts';
import useTopProducts from 'hooks/useTopProducts';
import React, { useContext } from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import BestSellingProducts from './BestSellingProducts';
import {totalSale, saleItems} from 'data/dashboard/ecom';
import TotalSales from 'components/dashboards/e-commerce/totalsales/TotalSales';
import DealVsGoal from 'components/dashboards/crm/deal-vs-goal/DealVsGoal';
import ChartOptions from './BarMarketChart';
import useVendorList from 'hooks/useVendorList';
import { PermsContext } from 'context/PermsContext';
import TotalStock from './total_stock/TotalStock';





const ReportsPage = () => {

    const { myData } = useTopProducts();
    const {myDataVendor} =useVendorList()
    const {perms, setVendorIdContext,  vendorIdContext } = useContext(PermsContext)

    const img = "https://www.byambalaj.com/wp-content/uploads/2022/08/30x20x20-Tasima-Sevkiyat-Kolisi.png"

    const products = (Array.isArray(myData?.results) ? myData?.results : [])
    .filter(item => item.SKU !== null)
    .slice(0, 4) 
    .map((item, index) => ({
      id: index + 1,
      img: item.image || img,
      title: item.Name,
      type: item.sku,
      unit: item.total_count,
      price: parseFloat(item.total_price) 
    }));

    const all_products_count = myData ? myData?.all_products_count : 0
    const all_products_price = myData ? myData?.all_products_price : 0



   

    const handleChangeVendor = (e)=> {
      setVendorIdContext(e.target.value); 
    }

    function hasPermission(permission) {
      if (Array.isArray(perms)) {
        return perms.includes(permission);
      }
      return false;
    }

   
  
  
  return (
    <>
      <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
          {hasPermission('Dashboard Çoklu Satıcı Görme') ?  
              <Form.Select 
                className='form-control' 
                style={{fontSize:"15px", width:"145px", marginBottom:"10px"}} 
                onChange={handleChangeVendor}
                value = {vendorIdContext}
              >
                <option value="" disabled>Satıcı</option> 
                {myDataVendor?.map((item, i) => {
                  return <option key={i} value={item.Id}>{item.vendor_name}</option>
                })}       
              </Form.Select> : ""
            }
      </div>
        {/* <div style={{marginBottom:"80px"}}></div> */}
      <Row className="d-flex align-items-stretch">
          <Col md={6} xxl={6}>
            <BestSellingProducts 
            products={products} 
            all_products_count={all_products_count} 
            all_products_price={all_products_price} 
            />
          </Col>
          <Col md={6} xxl={6}>
            <TotalStock />
          </Col>
      </Row>

      <Row className="d-flex align-items-stretch" style={{ marginTop: "20px" }}>
        <Col md={6} xxl={6}>
          <DealVsGoal />
        </Col>
        <Col md={6} xxl={6}>
          <ChartOptions />
        </Col>
      </Row>
  </>
   
  )
}

export default ReportsPage