import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { PermsContext } from 'context/PermsContext';

export default function useEcomStatStockCount() {

 const [myDataStockCount, setMyDataStockCount] = useState()

 
 const {myKey} = useContext(AuthContext)
 const {vendorIdsContext} = useContext(PermsContext)

 

 //stok adedi
 const getDataStockCount = ()=>{
  var myHeaders = new Headers();
  if (!vendorIdsContext) return;


      const params = {
       
      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/vendor/${vendorIdsContext}/total_stock_count/?`
  );

  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMyDataStockCount(result) 
})
    .catch(error => console.log('error', error));        
}



 useEffect(() => {
  getDataStockCount();

  }, [vendorIdsContext]);

 return {myDataStockCount}
}
