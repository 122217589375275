import { AuthContext } from 'context/AuthContext';
import React, {useContext, useEffect, useState} from 'react'
import {BASE_URL} from '../baseUrl'
import { toast } from 'react-toastify';
import {  format } from 'date-fns';



export default function useStorageFeeAdd() {

  const {myKey} = useContext(AuthContext)

  const now = new Date();
  const utcFirstDayOfMonth = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
  const [dateOfStorage, setDateOfStorage] = useState(utcFirstDayOfMonth);
  
  let formattedStartDate = "";
  
  if (dateOfStorage) {
    formattedStartDate = format(dateOfStorage, 'yyyy-MM-dd');
  }


  const [vendorStorageFeeAdd, setVendorStorageFeeAdd] = useState();

  const [formDataStorage, setFormDataStorage] = useState({

          TotalVolume: '',
          WarehouseRent: '',
          OtherFee: '',
})


       
  const postStorageFee = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = `${BASE_URL}api/v1/vendor/${vendorStorageFeeAdd}/add_storage_fee_calculation/?`

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "DateOfMonth": formattedStartDate,
        "TotalVolume" : formDataStorage.TotalVolume,
        "WarehouseRent" : formDataStorage.WarehouseRent ,
        "OtherFee" : formDataStorage.OtherFee
      }),
      redirect: 'follow'
    };

    fetch(request_url, requestOptions)
  .then(response => {
    if (response.ok) {
      return response.json(); 
    } else {
      console.log("Post işlemi başarısız.");
      throw new Error("Post işlemi başarısız.");
    }
  })
  .then(data => {
    console.log("dt",data);
    toast.success("hesaplama başarılı olarak kayıt edilmiştir", { theme: 'colored' }); 
  })
  .catch(error => {
    console.log('error', error);
  });

  }


  useEffect(() => {
    if(formDataStorage.TotalVolume && formDataStorage.WarehouseRent){
        postStorageFee()
    }
   
   }, [formDataStorage])
   
   
  


  return { setVendorStorageFeeAdd, setFormDataStorage, formDataStorage };
}