import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from '../baseUrl'
import useDebounce from '../useDebounce';
import useInventoryRegion from 'hooks/useInventoryRegion';


export default function useInventoryProductCount() {

 const [myData, setMyData] = useState()
 const [detail, setDetail] = useState()

 const [detailShelf, setDetailShelf] = useState()

 const {myKey} = useContext(AuthContext)

 
 
 const [page, setPage] = useState(1)


 const [currentPage,setCurrentPage]=useState(1)
 const [followersPerPage, setFollowersPerPage] = useState(10);
 const [totalPages, setTotalPages] = useState(0);


 const [inventoryId, setInventoryId ] = useState()



 
 const getData = ()=>{
        var myHeaders = new Headers();
        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;

        const params = {
          offset :offset,
          limit :limit,
          // page: page,
          // vendor_username: currentUser,
         
          // count: stock, 
          // sort_by_stock : sortState,
     
        };
    
        const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}/wms/api/v1/inventory/${inventoryId}/list_inventory_count/?`
        );

        console.log("request_url", request_url)

            
      console.log("wurl", request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            console.log("p_result", result)
            setMyData(result) 
            setTotalPages(Math.ceil(result.count / followersPerPage))
    })
          .catch(error => console.log('error', error));        
 }


 const getDetail= (id, inventory_id)=>{
  var myHeaders = new Headers();

      if (!id && !inventory_id) return;

      let request_url;

      request_url = `${BASE_URL}wms/api/v1/inventory/${inventory_id}/get_inventory_count/?count_id=${id}`

      console.log("request_url_detail", request_url)

  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setDetail(result) 
})
    .catch(error => console.log('error', error));        
}



const getDetailShelf= (id, inventory_id, shelf_id)=>{
  var myHeaders = new Headers();

      if (!id && !inventory_id && !shelf_id) return;

      let request_url;

      request_url = `${BASE_URL}wms/api/v1/inventory/${inventory_id}/get_inventory_shelf_count/?count_id=${id}&shelf_id=${shelf_id}`

  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setDetailShelf(result) 
})
    .catch(error => console.log('error', error));        
}



 useEffect(() => {
    getData();
  }, [page, currentPage, followersPerPage, inventoryId] );

 return {myData, setPage, setCurrentPage, followersPerPage,setFollowersPerPage, totalPages, setInventoryId, getDetail, detail, setDetail, inventoryId, getDetailShelf, detailShelf, setDetailShelf}
 
}


