import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Pagination from './Pagination';
import DatePicker from  "react-datepicker";
import useShipmentCompany from 'hooks/useShipmentCompany';
import Accordion from 'react-bootstrap/Accordion';
import useOrderReturned from 'hooks/useOrderReturned';
import { Search } from 'react-bootstrap-icons';
import IconButton from 'components/common/IconButton';
import gifOrder from 'assets/img/gif_order.gif'
import gif from 'assets/img/gif_export.gif'
import useOrderRefund from 'hooks/useOrderRefund';
import useOrderRefundList from 'hooks/useOrderRefundList';
import Button from 'react-bootstrap/Button';
import { PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import Modal from 'react-bootstrap/Modal';
import useRefundExport from 'hooks/useRefundExport';
import useInventoryShipmentList from 'hooks/useInventoryShipmentList';
import useInbountFees from 'hooks/Costs/useInbountFees';





const InboundFees = () => {

  const columns = [
    {
      accessor: 'region',
      Header: 'Depo',
      disableSortBy: true,
      headerProps: { className: 'text-center'},
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { region } = rowData.row.original;
        return (
          <>
            <p>{region}</p>
           
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Tarih',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      }
    },
    {
      accessor: 'product_count',
      Header: 'Ürün Sayısı',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const {product_count } = rowData.row.original;
        return (
          <p>{product_count}</p>
        );
      }
    },
    {
      accessor: 'sku_count',
      Header: 'SKU Sayısı',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { sku_count} = rowData.row.original;
        return (
          <>
              {sku_count}
          </>
        );
      }
    },
    {
      accessor: 'price',
      Header: 'Birim Fiyatı',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.per_amount}`
    },
    {
      accessor: 'total_price',
      Header: 'Toplam Fiyat',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.total_fee}`
    },
    
  ];

const {myData, setPage, startDate, setStartDate, endDate, setEndDate, setCargo, firstName, setSearch, showOrderGif, currentPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, setVendorId, setStatusRefund} = useInbountFees()

const { startDateModal, setStartDateModal, endDateModal, setEndDateModal, getDataExport, showGif, setShowGif, setVendorExportId} = useRefundExport()


  const {shipmentCompany} = useShipmentCompany()
  // const [followersPerPage]=useState(10)
  // const [currentPage,setCurrentPage]=useState(1)
  const [activePage, setActivePage] = useState(1)
  const [orderList, setOrderList] = useState() 

  const {perms} = useContext(PermsContext)
  const {myDataVendor} = useVendorList()


  // useEffect(() => {
  //   setPage(activePage)
  // }, [activePage])


  const location = useLocation();

  useEffect(() => {
    console.log("Active page from state:", activePage); // Debug için
    const savedActivePage = location.state?.activePage;
    if (savedActivePage) {
      console.log("Saved active page:", savedActivePage); // Debug için
      setActivePage(savedActivePage);
      setCurrentPage(savedActivePage);
    } else {
      setActivePage(1);
      setCurrentPage(1);
    }
  }, [location]);





  
  const [isOpen, setIsOpen] = useState(false);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };


const handleChangeCargo = (e)=> {
  setCargo(e.target.value); 
}



// modal

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);


const handleExport = ()=> {
  getDataExport(); 
  setShowGif(true);
  setShow(false)

  setStartDateModal(null);
  setEndDateModal(null);
}

const onChangeModal = datesExport => {
  const [startExport, endExport] = datesExport;
  setStartDateModal(startExport);
  setEndDateModal(endExport);
};






useEffect(() => {
     
  const newList = myData?.results.map(item => {
  

    const totalFee = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(item.InboundFee || 0);
    const perFee = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(item.inbound_fee_detail.inbound_per_unit_fee || 0);

    return {
      id : "#"+item.Id,
      region: item.region_name || "-", 
      date: item.EstimatedArrivalDate,
      product_count : item.inbound_fee_detail.total_count,
      sku_count : item.inbound_fee_detail.sku_count,
      per_amount : perFee,
      total_fee : totalFee
    }
  });
  setOrderList(newList)

}, [myData, firstName])

function hasPermission(permission) {
  return perms?.includes(permission);
}

useEffect(() => {
  if (myDataVendor && myDataVendor.length > 0) {
    const firstVendorId = myDataVendor[0].Id;
    
    setVendorId(firstVendorId)
  }
}, [myDataVendor]);


const handleChangeVendor = (e)=> {
  
  setVendorId(e.target.value)
}

const handleChangeVendorModal = (e)=> {
  setVendorExportId(e.target.value); 

}




const [selectedOption, setSelectedOption] = useState(null);

const handleChangeDate = event => {
  setSelectedOption(event.target.value);

  let start, end;

  switch(event.target.value) {
    case "1": // Bugün
      start = new Date();
      end = new Date();
      break;

    case "2": // Bu hafta
      end = new Date();
      start = new Date();
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
      break;   

    case "3": // Bu ay
      end = new Date();
      start = new Date();
      start.setDate(1); // Ayın ilk günü
      break;
  
    default:
      // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
      break;
  }

  setStartDate(start);
  setEndDate(end);
};

const [isOpenDate, setIsOpenDate] = useState(false);

useEffect(() => {
  if (selectedOption === "4") {
    setIsOpenDate(true);
  } else {
    setIsOpenDate(false);
  }
}, [selectedOption]);

const onChange = dates => {

  const [start, end] = dates;

  if (selectedOption === "4") {
    setStartDate(start);
    setEndDate(end);
  }
  if (start && end) {
    setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
  }
};
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      // data={orderList?.length>0 && orderList} 
      selection
      sortable
      pagination
      perPage={50}
    >
      <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showOrderGif && <img src={gifOrder} style={{width:'50%'}} alt="loading gif" />}
          </div>

          <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showGif && <img src={gifOrder} style={{width:'50%'}} alt="export gif" />}
          </div>
     
      <Card className="mb-3">
        <Card.Header>

      <div style={{display:"flex", justifyContent:"space-between"}}>  
        <div>
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Mal Kabul Maliyetleri</h5>
        </div>

        <div style={{display:"flex", justifyContent:'right'}}>

            {/* <div className="mb-3" style={{marginRight:"10px"}}>
                  <InputGroup>
                    <Form.Control  type="text" placeholder="isim, numarası.."  onChange={(e)=>setSearch(e.target.value)}/>
                    <InputGroup.Text><Search/></InputGroup.Text>
              </InputGroup>
                  </div> */}

                  {/* { hasPermission('Dashboard Sipariş Export') ?
                  <div style={{marginRight:"8px"}}>
                  <IconButton
                        variant="falcon-default"
                        size="m"
                        icon="external-link-alt"
                        transform="shrink-3"
                        onClick={handleShow}
                      >
                        <span className="d-none d-sm-inline-block ms-1">Export</span>
                      </IconButton>
                      </div>
                        : ("") } */}
   
        <Dropdown  show={isOpen} onToggle={handleToggle}>
        <Dropdown.Toggle
           bsPrefix="toggle"
           as={Link}
          //  to=""
           className={classNames('px-0 nav-link')}>
     
            <IconButton
              variant="falcon-default"
              size="m"
              icon="fa-sharp fa-light fa-filter-list"
              transform="shrink-3"
              style={{width:'145px', fontSize:'15px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
              </svg>
                  <span className="d-none d-sm-inline-block ms-1">Filtre</span>
            </IconButton>

        </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret" style={{minWidth: '300px', width: '300px'}}>

          <Card className="shadow-none shadow-show-xl scrollbar" style={{height:'450px'}}>
            <Card.Header className="bg-light d-none d-xl-block">
              <h6 className="mb-0">Filtre</h6>
            </Card.Header>
              <Card.Body>
                <Form>
                  <div className="mb-2">
                    <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Tarih Seçimi</Form.Label>


                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                  <option value="" disabled selected>Tarih</option>
                  <option key={1} value="1">Bugün</option>
                  <option key={2} value="2">Bu hafta</option>
                  <option key={2} value="3">Bu ay</option>
                  <option key={3} value="4">Tarih seç</option>
                              </Form.Select> 

                {selectedOption === "4" && 
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    endDate={endDate}
                    selectsRange
                    dateFormat="MMM dd"
                    className='form-control'
                    placeholderText='tarih giriniz'
                    open={isOpenDate}
                    onCalendarClose={() => setIsOpenDate(false)}
                    onCalendarOpen={() => setIsOpenDate(true)}
                  />
                }
                  </div>

                  { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                  <div style={{marginBottom:'10px'}}>
                  <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Satıcı</Form.Label>
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                     <option value="" disabled>Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
            : "" }
                  
                 

              
              </Form>
            </Card.Body>
        </Card>
      </Dropdown.Menu>
      </Dropdown>
      </div>
      </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <Pagination pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage}/>
        </Card.Footer>
      </Card>


      <Modal 
      show={show} 
      onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>İade Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
    

          <div style={{display:'flex', justifyContent:'center'}} >
       
       <div style={{marginRight:'8px'}}>
                   { myDataVendor?.length> 1 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeVendorModal}>
                    <option value="">Satıcı</option>
                       { myDataVendor?.map((item, i)=>{
                             return <option key={i} value={item.Id}>{item.vendor_name}</option>
                       })}       
               </Form.Select>}
           </div>

          <div style={{marginRight:"8px", fontSize:"14px"}}>
                <DatePicker
                showIcon
                selected={startDateModal}
                onChange={onChangeModal}
                startDate={startDateModal}
                formatWeekDay={day => day.slice(0, 3)}
                endDate={endDateModal}
                selectsRange
                dateFormat="MMM dd"
                className='form-control'
                placeholderText='tarih giriniz'
                />
          </div>

          </div>

          </Modal.Body>


        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Vazgeç
          </Button>
          <Button variant="primary" onClick={handleExport}>
            İndir
          </Button>
        </Modal.Footer>
      </Modal> 





      <span style={{marginRight:"5px",marginLeft:"5px"}}>Sipariş sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>
    </AdvanceTableWrapper>
  );
};

export default InboundFees;





