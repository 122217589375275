import { StockControl } from '@amcharts/amcharts5/.internal/charts/stock/toolbar/StockControl';
import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'


export default function useProductInventory() {

 const [myData, setMyData] = useState()

 const {myKey, currentUser} = useContext(AuthContext)

 

 const getData = (sku,barcode)=>{
        var myHeaders = new Headers();
        if (!sku && !barcode) return

        const params = {
          // vendor_username: currentUser,
          barcode  : barcode,
          sku: sku
    
        };
    
        const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}api/v1/product/product/product_list/?`
        );
    
console.log("detay_url", request_url)
        
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            console.log("usedata", result)
            setMyData(result) 
    })
          .catch(error => console.log('error', error));        
 }

//  useEffect(() => {
//     getData();
//   }, []);

 return {myData, getData}
}


