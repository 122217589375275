import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useInventoryRegion from './useInventoryRegion';
import { toast } from 'react-toastify';
import useDebounce from './useDebounce';
import { PermsContext } from 'context/PermsContext';


export default function useInventoryProductStockChart() {

const todayDay = new Date();
const oneDay = 1000 * 60 * 60 * 24;

const {myKey, currentUser} = useContext(AuthContext)
const {regionData} = useInventoryRegion()

const [dataActivity, setDataActivity] = useState([])
const [skuCurrent, setSkuCurrent] = useState(); 
const [startDate, setStartDate] = useState(new Date(todayDay.getTime() - oneDay * 10))
const [endDate, setEndDate] = useState(new Date(todayDay.getTime() + oneDay * 1))
const [search, setSearch] = useState()
const [type, setType] = useState()

const [inventoryId, setInventoryId] = useState()


const [currentPage,setCurrentPage]=useState(1)
const [followersPerPage, setFollowersPerPage] = useState(10);
const [totalPages, setTotalPages] = useState(0);

const debouncedSearch = useDebounce(search, 1500);
const debouncedSkuCurrent = useDebounce(skuCurrent, 1500);



setCurrentPage, followersPerPage, setFollowersPerPage, totalPages

let formattedStartDate = "";
let formattedEndDate = "";

if(startDate){
  formattedStartDate = new Date(startDate.getTime()).toISOString().slice(0, 10);
}
if(endDate){
  formattedEndDate = new Date(endDate.getTime() + oneDay).toISOString().slice(0, 10);
}



useEffect(() => {
  if (regionData?.length > 0) {
    setInventoryId(regionData[0].Id);
  }
}, [regionData]);



 const getData = (sku)=>{
         if (!sku && !inventoryId) return;

        var myHeaders = new Headers();

        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;


        const params = {
        

          begin_date: formattedStartDate,
          end_date: formattedEndDate,
          sku :  sku,
          
        };

        const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}wms/api/v1/inventory/${inventoryId}/daily_sku_fees/?`
    );
      

      

        console.log("request_url", request_url)

                  
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result => {
            setDataActivity(result.results);
            setTotalPages(Math.ceil(result.count / followersPerPage))
            if (result.length === 0) {
              // Eğer sonuç boş bir array ise, toast mesajı göster
              toast.warn("Seçilen ürün aktivitesi bulunmadı.", { theme: 'colored' });
            }
          })
          .catch(error => {
            console.log('error', error);
           
          });
      
      }

 


 useEffect(() => {
  
    getData();

  }, [inventoryId, endDate, startDate] );

 return {setInventoryId, dataActivity, getData, setSkuCurrent, setStartDate, setEndDate, startDate, endDate, skuCurrent, setSearch, setType, setCurrentPage, followersPerPage,setFollowersPerPage, totalPages, inventoryId}
}
