import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'


export default function useSubProductList() {

 const {myKey, currentUser} = useContext(AuthContext)
 const [subProductData, setSubProductData] = useState()
 const [campaingSelectData, setCampaingSelectData] = useState()
 const [subVendorId, setSubVendorId]=useState() 






 const getDataSub = (id)=>{
        var myHeaders = new Headers();

       
        // const params = {

        //   // barcode: barcode,
        //   vendor_id : subVendorId
  
        // };

        let request_url;

   
        request_url = `${BASE_URL}api/v1/product/sub-products/?product_id=${id}`
  
    //     const request_url = Object.entries(params)
    //         .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
    //         .reduce(
    //           (url, [key, value]) => url + `&${key}=${value}`,
    //           `${BASE_URL}api/v1/product/sub-products/?barcode=${barcode}&vendor_id=${subVendorId}`
    // );

   console.log("request_urlSUB", request_url)

        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setSubProductData(result) 
            console.log("result", result)
    })
          .catch(error => console.log('error', error));        
 }


//  useEffect(() => {
//   getDataSub();
// }, [subVendorId]);




 return {subProductData, getDataSub, setSubVendorId}
}
