import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { DateContext } from 'context/DateContext';
import { PermsContext } from 'context/PermsContext';

export default function useEcomStatFulfilled() {


 const {myKey, currentUser} = useContext(AuthContext)
 const [myDataFulfilled, setMyDataFulfilled] = useState()
 const [beginDateFulfiled, setBeginDateFulfiled] = useState()
 const [endDateFulfiled, setEndDateFulfiled] = useState()

 const { startDate, endDate } = useContext(DateContext);
 const {vendorIdsContext, storeIdContext, regionContext} = useContext(PermsContext)
 

//gönderilen
const getDataFulfilled = ()=>{
  var myHeaders = new Headers();


      const params = {

        begin_date_fulfilled: startDate,
        end_date_fulfilled: endDate,
        // vendor_username: currentUser,
        vendor_ids: vendorIdsContext,
        store : storeIdContext,
        inventory_region : regionContext

      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/fulfilled_orders_today_count/?`
      );

      console.log("request_url", request_url)


  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMyDataFulfilled(result) 
      setLoading(false)

})
    .catch(error => console.log('error', error));        
}


 useEffect(() => {
 
  if (startDate && endDate) {
    getDataFulfilled();
  }
  }, [startDate, endDate, vendorIdsContext, storeIdContext, regionContext ]);

 return {myDataFulfilled, setEndDateFulfiled, setBeginDateFulfiled}
}
