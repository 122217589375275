import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'



export default function useOrderReturned() {

  const [myData, setMyData] = useState()
  const [page, setPage] = useState(1)
  const {myKey, currentUser} = useContext(AuthContext)
  const [detail, setDetail] = useState()
  const [firstName, setFirstName] = useState()
  const [search, setSearch] = useState()
  const [cargo, setCargo] = useState()
  const date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.setDate(date.getDate() -7)));
  const [endDate, setEndDate] = useState(new Date(date.setDate(date.getDate() +8)));

  const [showOrderGif, setShowOrderGif] = useState(false);

  let formattedStartDate = "";
  let formattedEndDate = "";

  if(startDate){
    formattedStartDate = startDate.toISOString().slice(0, 10);
  }
  if(endDate){
    formattedEndDate = endDate.toISOString().slice(0, 10);
  }
 
 const getData = ()=>{
        var myHeaders = new Headers();

        
        const params = {
          page: page,
          cargo :cargo,
          begin_date: formattedStartDate,
          end_date: formattedEndDate,
          search : search
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/order/order_returned/?`
    );
      

      console.log("order",request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result)
            setShowOrderGif(false); 
    })
          .catch(error => console.log('error', error));        
 }






 const getDetail= (id)=>{
  var myHeaders = new Headers();

  let vendor_id ;

  let request_url;
  if (vendor_id) 
      request_url = `${BASE_URL}api/v1/order/order_returned/?vendor_id=${vendor_id}&id=${id}`
  else    
      request_url = `${BASE_URL}api/v1/order/order_returned/?vendor_username=${currentUser}&id=${id}`

console.log("data url", request_url)
  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setDetail(result.results) 
})
    .catch(error => console.log('error', error));        
}





 useEffect(() => {
    getData();
    setShowOrderGif(true)
  }, [page, startDate,endDate, cargo, search]);

 return {myData, setPage, detail, getDetail,  getData, setMyData, startDate, setStartDate, endDate, setEndDate,  cargo, setCargo, setSearch,showOrderGif}
}
