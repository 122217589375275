import { AuthContext } from 'context/AuthContext';
import React, {useContext, useState} from 'react'
import {BASE_URL} from './baseUrl'
import { toast } from 'react-toastify';



export default function useOrderCargoShip() {

  const {myKey, currentUser} = useContext(AuthContext)

  const [orderCargoId, setOrderCargoId] = useState()


       
  const postOrderCargoShip = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL + 'api/v1/order/ship_orders/';

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "order_ids": [id]
      }),
      redirect: 'follow'
    };

    fetch(request_url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        console.log("Post işlemi başarısız.");
        throw new Error("Post işlemi başarısız.");
      }
    })
    .then(data => {
      console.log(data.messages);
      const messagesString = data.messages.join('\n');
      toast.success(messagesString, { theme: 'colored' });
    })
    .catch(error => {
      console.log('error', error);
      toast.error(`Bir hata oluştu: ${error.message}`, { theme: 'red' });
    });

  }

  return { postOrderCargoShip, setOrderCargoId };
}