import { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from 'context/AuthContext';
import { toast } from 'react-toastify';
import {BASE_URL} from './baseUrl'
import useVendorList from './useVendorList';

export const useOrderPdfUpload = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const {myKey} = useContext(AuthContext)

    const {myDataVendor} = useVendorList()

    const [vendorIdUpload, setVendorIdUpload] = useState()

    useEffect(() => {
        if (myDataVendor?.length === 1) {
            setVendorIdUpload(myDataVendor[0].Id);
        }
      }, [myDataVendor]);


      const uploadPDF = async (file,  id) => {
        if (!file.type.startsWith('application/pdf')) {
            setErrorMessage("Invalid file type. Please upload a PDF file.");
            toast.error("Invalid file type. Please upload a PDF file."); 
            return;
        }
        console.log("id", id)

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${BASE_URL}api/v1/order/${id.slice(1)}/upload_document/`, formData, {
                headers: {"Authorization": "Token "+myKey},
            });

            console.log("PDF Upload Response:", response.data);
            setErrorMessage("");
            toast.success("PDF yükleme işlemi başarılı!"); 
        } catch (error) {
            setErrorMessage(`Error: ${error.message}`);
            toast.error(`Error: ${error.message}`); 
        }
    };

    return { uploadPDF, errorMessage };
};
