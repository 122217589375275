import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { DateContext } from 'context/DateContext';
import { PermsContext } from 'context/PermsContext';

export default function useEcomStatCompany() {


 const [myData, setMyData] = useState()
 const {myKey, currentUser} = useContext(AuthContext)
 const { startDate, endDate } = useContext(DateContext);
 
 const {vendorIdsContext, storeIdContext, regionContext} = useContext(PermsContext)


//gönderilen firma detaylı
const getDataCompany = ()=>{
  var myHeaders = new Headers();
 
      const params = {

        begin_date: startDate,
        end_date: endDate,
        // vendor_username: currentUser,
        vendor_ids :vendorIdsContext,
        store : storeIdContext,
        inventory_region : regionContext

      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/fulfilled_orders_today_count_company/?`
      );




  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMyData(result) 
})
    .catch(error => console.log('error', error));        
}




 useEffect(() => {
 
  getDataCompany();
  }, [startDate, endDate, vendorIdsContext, storeIdContext, regionContext]);

 return {myData}
}