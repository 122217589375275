import { AuthContext } from 'context/AuthContext';
import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { BASE_URL } from './baseUrl';

export default function useOrderWorkStatusWaiting() {
  const { myKey } = useContext(AuthContext);
 

  const postDataWaiting = (ids) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL + "api/v1/order/status_waiting/";

    console.log("ids_waiting",  ids);
    
    var raw = JSON.stringify({ "order_ids": ids });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then(err => {
            throw new Error(err.error || "Bir hata oluştu");
          });
        }
      })
      .then(data => {
        
        toast.success(data.message || "İşlem başarıyla tamamlandı.", { theme: 'colored' });
      })
      .catch(error => {
        console.log('error', error);
        toast.error(error.message || "Bir hata meydana geldi.", { theme: 'colored' });
      });
  }

  return {postDataWaiting };
}
