import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { DateContext } from 'context/DateContext';
import { PermsContext } from 'context/PermsContext';

export default function useRefundCost() {


 const {myKey, currentUser} = useContext(AuthContext)
 const [myData, setMyData] = useState()
 const [beginDate, setBeginDate] = useState()
//  const [endDate, setEndDate] = useState()
 const { startDate, endDate } = useContext(DateContext);
 const {vendorIdsContext} = useContext(PermsContext)
 

//gönderilen
const getData = ()=>{
  var myHeaders = new Headers();


      const params = {

        begin_date: startDate,
        end_date: endDate,
        // vendor_username: currentUser,
        vendor_ids: vendorIdsContext

      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/refund/refund_cost/?`
      );


  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMyData(result) 
      setLoading(false)

})
    .catch(error => console.log('error', error));        
}


 useEffect(() => {
  getData();
  }, [beginDate, startDate, endDate, vendorIdsContext]);

 return {myData, setBeginDate }
}
