import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row, Table, Button } from 'react-bootstrap';
// import { orderedProductsData } from 'data/ecommerce/OrderDetailsData';
import { getPercentage } from 'helpers/utils';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import useProductDetail from 'hooks/useProductDetail';
import useProductInventory from 'hooks/useProductInventory';
import useInventoryProductCount from 'hooks/InventoryCount/useInventoryProductCount';
import InventoryCountShelf from './InventoryCountShelf';




const InventoryCountDetail = () => {


  const {myData, getData} = useProductInventory()

  const {detail, getDetail} = useInventoryProductCount()

  const location = useLocation()
  const {id, inventory_id} = location.state || ""


  useEffect(() => {
    if(id){
      getDetail(id, inventory_id)
     
    }
  }, [id, inventory_id])

  const formatDate = (dateStr) => {
    return dateStr ? new Date(dateStr).toLocaleString('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    }) : 'Devam Ediyor';
};

const shelfCodes = detail?.Shelves.map(shelf => shelf.Code).join(', ');




 
const navigate = useNavigate();

const handleBackClick = () => {
  
    navigate('/inventory-product/inventory-product-count');
};
 


  return (
    <>
      <span onClick={handleBackClick} style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
    
    <Card className="mb-3 mt-1">
   
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
                <tr>
                    <th>ID</th>
                    <th>Sayımı Yapan</th>
                    <th>Depo</th>
                    <th>Başlangıç Tarihi</th>
                    <th>Bitiş Tarihi</th>
                    <th>Durum</th>
                    <th>Raflar</th>
                </tr>
              </thead>
              <tbody>

                <tr>
                    <td>{detail?.Id}</td>
                    <td>{detail?.ProcessedBy_username}</td>
                    <td>{detail?.inventory_name}</td>
                    <td>{formatDate(detail?.DateTime)}</td>
                    <td>{formatDate(detail?.CompleteDateTime)}</td>
                    <td>{detail?.Completed ? 'Tamamlandı' : 'Devam Ediyor'}</td>
                    <td>{shelfCodes}</td>
                </tr>
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>

      < InventoryCountShelf/>

    </>
  );
};

InventoryCountDetail.propTypes = {};

export default InventoryCountDetail;
