import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, InputGroup, Row, Col, Button,Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { orderList } from 'data/ecommerce/orderList';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
// import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import useOrderList from 'hooks/useOrderList';
import InventoryTableHeader from './InventoryTableHeader';
import PaginationProduct from './PaginationInventory';
import useInventoryShipmentList from 'hooks/useInventoryShipmentList';
import useVendorList from 'hooks/useVendorList';
import { PermsContext } from 'context/PermsContext';
import IconButton from 'components/common/IconButton';
import useInventoryMap from 'hooks/useInventoryMap';
import { Search } from 'react-bootstrap-icons';
import DatePicker from  "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import useInventoryExampleExport from 'hooks/useInventoryExampleExport';
import useInventoryExportS3 from 'hooks/useInventoryExportS3';
import gif from 'assets/img/gif_export_s3.gif'






const InventoryList = () => {

  const columns = [
    {
      accessor: 'name',
      Header: 'Teslimat Id',
      // disableSortBy: true,
      headerProps: { className: 'text-center' },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const {id_nu, note} = rowData.row.original;
        const renderTooltip = (props) => (
          <Tooltip id_nu={`orderNote-${id_nu}`} {...props}
          bsPrefix='my-tooltip-class'
          >
            {note}
          </Tooltip>
        );


        return (
          <>
            {note ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <span>
                 
                  <FontAwesomeIcon
                    icon={['far', 'question-circle']}
                    transform="shrink-1"
                    className="icon-red"
                    style={{  marginRight: '5px' }}
                    
                  />
                     <Link to="/e-commerce/invoice" state={{id_nu}}>
              <strong>#{id_nu}</strong>
            </Link>
                </span>
              </OverlayTrigger>
            ) : (
              <Link to="/e-commerce/invoice" state={{id_nu}}>
              <strong>#{id_nu}</strong>
            </Link>
            )}



          
          </>
        );
      }
    },
    {
      accessor: 'amount',
      Header: 'Tarih',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.date}`
    },
  
    {
      accessor: 'address',
      Header: 'Depo',
      disableSortBy: true,
      headerProps: {
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center py-2'
        },
      Cell: rowData => {
        const { depo, date } = rowData.row.original;
        return (
          <>
              {depo}
              {/* <p className="mb-0 text-500">{date}</p> */}
        
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Durumu',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { status} = rowData.row.original;
        return (
          <>
      
         <p style={{ fontSize: "15px" }} >{ status }</p>
          </>
        )
      }
    },
    // {
    //   accessor: 'status',
    //   Header: 'Teslimat Durumu',
    //   headerProps: {
    //     className: 'text-center'
    //   },
    //   cellProps: {
    //     className: 'text-center'
    //   },
    //   Cell: rowData => {
    //     const { product_status} = rowData.row.original;
    //     return (
    //       <>
    //               { product_status }
  
        
    //       </>
    //     )
    //   }
    // },
    
    {
      // accessor: 'name',
      Header: 'Adet',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fw-medium py-2'
      },
      Cell: rowData => {
        const { stock} = rowData.row.original;
        return (
          <>
          {stock}
          </>
        );
      }
    },
    {
      // accessor: 'none',
      Header: 'Platform Stok Güncellemesi',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fw-medium py-2'
      },
      Cell: rowData => {
        const {inventoryAdjustment} = rowData.row.original;
        return (
          <>
          {inventoryAdjustment}
          </>
        );
      }
    }
  ];

const {myData, setPage, setVendorIdInventory, setSearch, startDate, setStartDate, endDate, setEndDate} = useInventoryShipmentList()
const {myDataVendor} = useVendorList()
const {perms} = useContext(PermsContext)
const [ currentPage, setCurrentPage] = useState()
const [followersProductPage] = useState(30)
const totalPages = Math.ceil(myData?.count/followersProductPage)
const [activePage, setActivePage] = useState(1)
const navigate = useNavigate();
const {setVendorIdMap} = useInventoryMap()



  function hasPermission(permission) {
    return perms?.includes(permission);
  }

  useEffect(() => {
    if (myDataVendor && myDataVendor.length > 0) {
      const firstVendorId = myDataVendor[0].Id;
      
      setVendorIdInventory(firstVendorId)
    }
  }, [myDataVendor]);



  const handleChangeVendor = (e)=> {
    setVendorIdInventory(e.target.value)
    setVendorIdMap(e.target.value)
  }


  const {myDataS3,setMyDataS3, getDataExport, showGif, setShowGif, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorUsername, setVendorIdExport, setStartDateModal, setEndDateModal, startDateModal, endDateModal, } = useInventoryExportS3()

  const handleExport = ()=> { 
    getDataExport(); 
    setShowGif(true);
    // setShow(false)
  
    setIsPreparing(true);
  }

  const handleChangeVendorModal = (e)=> {
    // setVendorUsername(e.target.value); 
    setVendorIdExport(e.target.value)
  }

  const handleDownload = () => {
    setIsPreparing(false);
    window.location.href = myDataResult[0]?.Value;
    setShow(false)
    setMyDataResult(false)
  }


// modal

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);



useEffect(() => {
  setPage(activePage)
}, [activePage])


const [isOpen, setIsOpen] = useState(false);

// Handler
const handleToggle = () => {
  setIsOpen(!isOpen);
};

const orderList = myData?.results.map((item)=>{

    const getStatus = ['','Beklemede', 'Onaylandı', 'İptal', 'Başarısız', 'Devam Ediyor']
    const productStatus = ['Tam Sayıda Ürün', 'Eksik Ürün', 'Fazla Ürün', 'Hem Eksik Hem Fazla Ürün']


  return {
    id_nu: item.Id, 
    shipmentCompany : item.ShipmentCompany?.Name,
    trackingNumber : item.ShipmentTrackingNumber,
    status: getStatus[item.Status],
    stock: item.InventoryShipmentProducts?.map(item=>item.Count).reduce((acc, currentValue) => acc + currentValue, 0),
    date : item.EstimatedArrivalDate,
    depo : item.Inventory?.Region.Name,
    product_status : productStatus[item.ProductCountStatus] || "-",
    inventoryAdjustment : item.ExternalInventoryAdjustment ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/></svg>,
    note : item.Note
  }
  })

const [selectedOption, setSelectedOption] = useState(null);

const handleChangeDate = event => {
  setSelectedOption(event.target.value);

  let start, end;

  switch(event.target.value) {
    case "1": // Bugün
      start = new Date();
      end = new Date();
      break;

    case "2": // Bu hafta
      end = new Date();
      start = new Date();
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
      break;   

    case "3": // Bu ay
      end = new Date();
      start = new Date();
      start.setDate(1); // Ayın ilk günü
      break;
  
    default:
      // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
      break;
  }

  setStartDate(start);
  setEndDate(end);
};

const [isOpenDate, setIsOpenDate] = useState(false);

useEffect(() => {
  if (selectedOption === "4") {
    setIsOpenDate(true);
  } else {
    setIsOpenDate(false);
  }
}, [selectedOption]);

const onChange = dates => {

  const [start, end] = dates;

  if (selectedOption === "4") {
    setStartDate(start);
    setEndDate(end);
  }
  if (start && end) {
    setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
  }
};

const onChangeModal = datesExport => {
  const [startExport, endExport] = datesExport;
  setStartDateModal(startExport);
  setEndDateModal(endExport);
};



  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      selection
      sortable
      pagination
      perPage={50}
    >
      <Card className="mb-3">
        <Card.Header>

        <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
               <InventoryTableHeader table />
            </div>





           <div style={{display:"flex"}}>


                <div className="mb-3" style={{marginRight:"10px"}}>
                  <InputGroup>
                    <Form.Control  type="text" placeholder="sku, barkod, isim..."  onChange={(e)=>setSearch(e.target.value)}/>
                    <InputGroup.Text><Search/></InputGroup.Text>
              </InputGroup>
                  </div>

                  {hasPermission('Dashboard Inventory Shipment Export') && (
                <div style={{marginRight:"8px"}}>
                  <IconButton
                        variant="falcon-default"
                        size="m"
                        icon="external-link-alt"
                        transform="shrink-3"
                        onClick={handleShow}
                      >
                        <span className="d-none d-sm-inline-block ms-1">Export</span>
                      </IconButton>
                      </div>
                     )} 



          {hasPermission("Can add Depoya Teslimat") && (
                <div id="orders-actions" style={{marginRight:'8px'}}>
                  <IconButton
                    variant="falcon-default"
                    size="m"
                    icon="plus"
                    transform="shrink-3"
                    onClick = {()=>navigate("/e-commerce/customers")}
                  >
                    <span className="ms-1">Depo Teslimatı Oluştur</span>
                  </IconButton>
                  </div>
           )}

  



<Dropdown  show={isOpen} onToggle={handleToggle}>
        <Dropdown.Toggle
           bsPrefix="toggle"
           as={Link}
          //  to=""
           className={classNames('px-0 nav-link')}>
     
            <IconButton
              variant="falcon-default"
              size="m"
              icon="fa-sharp fa-light fa-filter-list"
              transform="shrink-3"
              style={{width:'145px', fontSize:'15px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
              </svg>
                  <span className="d-none d-sm-inline-block ms-1">Filtre</span>
            </IconButton>

        </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret" style={{minWidth: '300px', width: '300px'}}>

          <Card className="shadow-none shadow-show-xl scrollbar" style={{height:'450px'}}>
            <Card.Header className="bg-light d-none d-xl-block">
              <h6 className="mb-0">Filtre</h6>
            </Card.Header>
              <Card.Body>
                <Form>
                  <div className="mb-2">
                    <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Tarih Seçimi</Form.Label>


                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                  <option value="" disabled selected>Tarih</option>
                  <option key={1} value="1">Bugün</option>
                  <option key={2} value="2">Bu hafta</option>
                  <option key={2} value="3">Bu ay</option>
                  <option key={3} value="4">Tarih seç</option>
                              </Form.Select> 

                {selectedOption === "4" && 
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    endDate={endDate}
                    selectsRange
                    dateFormat="MMM dd"
                    className='form-control'
                    placeholderText='tarih giriniz'
                    open={isOpenDate}
                    onCalendarClose={() => setIsOpenDate(false)}
                    onCalendarOpen={() => setIsOpenDate(true)}
                  />
                }
                  </div>

                  { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                  <div >
                   <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Satıcı</Form.Label>
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                    
                     <option value="" disabled>Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
            : "" }
                  
  
              
              </Form>
            </Card.Body>
        </Card>
      </Dropdown.Menu>
      </Dropdown>
        </div>
    </div>

        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <PaginationProduct pages={totalPages} activePage={activePage} setActivePage={setActivePage} setCurrentPage={setCurrentPage}/>
        </Card.Footer>
      </Card>

      <Modal 
      show={show} 
      onHide={handleClose}
  
      >
        <Modal.Header closeButton>
          <Modal.Title>Depoya Teslimatlar Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <p>Lütfen Satıcı seçimi yaparak ürün listesini indiriniz.</p>
        <div style={{display:'flex'}}>
        <div style={{marginRight:'8px'}}>
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeVendorModal}>
                     <option value="">Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>

            <div style={{marginRight:"8px", fontSize:"14px"}}>
                <DatePicker
                showIcon
                selected={startDateModal}
                onChange={onChangeModal}
                startDate={startDateModal}
                formatWeekDay={day => day.slice(0, 3)}
                endDate={endDateModal}
                selectsRange
                dateFormat="MMM dd"
                className='form-control'
                placeholderText='tarih giriniz'
                />
          </div>

          </div>
          </Modal.Body>
        <Modal.Footer>
        <Row style={{width:'100%'}}>      
  <Row>

  
    <p>Depoya teslimatlar hazırlanıyor yazısından sonra tekrar Hazırla butonuna basmayınız.</p>

    <Col className="d-flex justify-content-end mb-3">
      <Button variant="secondary" onClick={handleClose} style={{marginRight:'10px'}}>
        Vazgeç
      </Button>
      <Button variant="primary" onClick={handleExport}>
        Hazırla
      </Button>
    </Col>
  </Row>
  {isPreparing && (!myDataResult || !myDataResult.error) && 
  
  <div>
  <div>Depoya teslimatlar listeniz hazırlanıyor, lütfen bekleyin...</div>

  <img src={gif} style={{width:'50%'}} alt="export gif" />
  </div>
  
  }
  {myDataResult && myDataResult[0]?.Value?.length > 0  && 
  <Row className="justify-content-center mt-5" style={{width:'100%'}}>
    <Col style={{display: "flex", justifyContent: "center"}}>
    <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding:"10px", fontSize:"13px"}}>

        {/* {myDataS3?.message} */}
        Listesiniz hazır indirebilirsiniz.
      </Alert>
      <Button 
  variant="primary" 
  style={{width:'150px', height:'40px'}}
  // onClick={() => window.location.href = myDataS3?.download_url}
  onClick={handleDownload}
>
  İndir
</Button>

    </Col>
  </Row>

  }
   </Row>
        </Modal.Footer>
      </Modal> 



    </AdvanceTableWrapper>
  );
};

export default InventoryList;