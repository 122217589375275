import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function useUserPasswordChange() {

const {myKey} = useContext(AuthContext)
const navigate = useNavigate();



 const postData = (formData)=>{

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL+"api/v1/users/user/change_password/"
    

    var raw = JSON.stringify({
        "old_password" : formData.oldPassword,
        "new_password" : formData.newPassword,
        "confirm_password" : formData.confirmPassword
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
    .then(response => {
      if (response.ok) {
        response.json()
        .then(result => {
          console.log(result);
          toast.success(`${result.message}`, { theme: 'colored'});
          navigate("/dashboard");
        })
      } else {
        response.json()
        .then(result => {
          console.log("Post işlemi başarısız:", result.message);
          toast.error(`Hata: ${result.message}`, { theme: 'colored' });
        })
        .catch(error => {
          console.log('Yanıt çözümlenirken bir hata oluştu:', error);
          toast.error('Beklenmeyen bir hata oluştu.', { theme: 'colored' });
        });
      }
    })
    .catch(error => {
      console.log('fetch işlemi sırasında bir hata oluştu:', error);
      toast.error(`Beklenmeyen bir hata oluştu: ${error.message}`, { theme: 'colored' });
    });

}




 return {postData}
}
