import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'



export default function useVendorStoreList() {

  const [myDataStore, setMyDataStore] = useState()
  const [detail, setDetail] = useState()

  const {myKey, currentUser} = useContext(AuthContext)

  const [vendorId, setVendorId] = useState()


 
 const getData = ()=>{
        var myHeaders = new Headers();

        
        const params = {
          vendor_id: vendorId
        
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/store/integration/?`
    );
      
      console.log("order",request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyDataStore(result.results)
        
    })
          .catch(error => console.log('error', error));        
 }

 const getDetail= (id)=>{
  var myHeaders = new Headers();

  let request_url;

      request_url = `${BASE_URL}/api/v1/store/${id}/`

  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setDetail(result.results) 
})
    .catch(error => console.log('error', error));        
}



 useEffect(() => {
    getData();

  }, [vendorId]);

 return {myDataStore, detail, getDetail, setVendorId}
}
