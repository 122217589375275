import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import TotalSalesChart from './TotalSalesChart';
import useTotalSales from 'hooks/useTotalSales';
import AppContext from 'context/Context';

const TotalSales = () => {
  const chartRef = useRef(null);

  const {myData} = useTotalSales()

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  const data = {}

  data.satışTl = myData?.map(item => item.total)

  const date = myData?.map(item => new Date(`${item.year}-${item.month}-${item.day}`).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }))

  const handleLegendToggle = name => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name
    });
  };

  return (
    <Card className="h-90" style={{backgroundColor:isDark ? "#0B1727" : "#F6FAFF"}}>

      <Card.Header>
        <Row className="flex-between-center g-0">
          <Col xs="auto">
            <h6 className="mb-0" style={{color: isDark ? "#D8E2EF":"#025098"}}>Günlük Satış (&#8378;/gün)</h6>
          </Col>
          {/* <Col xs="auto" className="d-flex">
            <Form.Check
              type="checkbox"
              id="ecommerceLastMonth"
              className="mb-0 d-flex"
            >
              <Form.Check.Input
                type="checkbox"
                className="form-check-input-primary"
                onClick={() => handleLegendToggle('lastMonth')}
                defaultChecked
              />
              <Form.Check.Label className="ps-2 fs--2 text-600 mb-0">
                Last Month:
                <span className="text-dark d-none d-md-inline">
                  {' '}
                  $32,502.00
                </span>
              </Form.Check.Label>
            </Form.Check>
            <Form.Check
              type="checkbox"
              id="ecommercePrevYear"
              className="mb-0 d-flex ps-0 ps-md-3"
            >
              <Form.Check.Input
                type="checkbox"
                className="ms-2 form-check-input-warning opacity-75"
                onClick={() => handleLegendToggle('previousYear')}
                defaultChecked
              />
              <Form.Check.Label className="ps-2 fs--2 text-600 mb-0">
                Prev Year:
                <span className="text-dark d-none d-md-inline">
                  {' '}
                  $46,018.00
                </span>
              </Form.Check.Label>
            </Form.Check>
          </Col> */}
          {/* <Col xs="auto">
            <CardDropdown />
          </Col> */}
        </Row>
      </Card.Header>
      <Card.Body>
        <TotalSalesChart
          data={data}
          myData={myData}
          ref={chartRef}
          style={{ height: '29rem' }}
        />
      </Card.Body>
    </Card>
  );
};

// TotalSales.propTypes = {
//   data: PropTypes.shape({
//     // lastMonth: PropTypes.array,
//     // previousYear: PropTypes.array
//   })
// };

export default TotalSales;
