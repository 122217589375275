import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from '../baseUrl'
import { toast } from 'react-toastify';
import {  format } from 'date-fns';



export default function useStorageVolume() {

  const [myDataStorageVolume, setMyDataStorageVolume] = useState()
  const {myKey} = useContext(AuthContext)
  
  

  const [storageVolumeVendorId,setStorageVolumeVendorId] = useState()
 

  const now = new Date();
  const utcFirstDayOfMonth = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
  const [dateOfStorageVolume, setDateOfStorageVolume] = useState(utcFirstDayOfMonth);
  
  let formattedStartDate = "";
  
  if (dateOfStorageVolume) {
    formattedStartDate = format(dateOfStorageVolume, 'yyyy-MM-dd');
  }
 
 
 const getData = ()=>{
        var myHeaders = new Headers();

        if (!dateOfStorageVolume || !storageVolumeVendorId) {
          return;
        }


        const params = {
          
          date_of_month: formattedStartDate,
          
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/vendor/${storageVolumeVendorId}/month_storage_volume/?`
    );

   
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result => {
            setMyDataStorageVolume(result);
           

          })
          .catch(error => {
            toast.error(`Error: ${error.message}`); 
          });
  };

 



 useEffect(() => {
    getData();
  }, [dateOfStorageVolume, storageVolumeVendorId ]);

 return {myDataStorageVolume, getData, dateOfStorageVolume, setDateOfStorageVolume,  storageVolumeVendorId,setStorageVolumeVendorId }
  
  
  
 
}
