import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row, Table, Form} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import useSubProductList from 'hooks/useSubProductList';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AsyncSelect from 'react-select/async';
import useBarcodeSku from 'hooks/useBarcodeSku';
import useSubProductAdd from 'hooks/useSubProductAdd';
import AppContext from 'context/Context';
import useVendorList from 'hooks/useVendorList';
import { PermsContext } from 'context/PermsContext';

const SubProductList = () => {

 const {setFormData, formData, setVendorIdSubProduct} = useSubProductAdd()


  const {getData, getDataBarcode, myBarcode, setVendorIdBarcode} = useBarcodeSku()
  const [showTable, setShowTable] = useState(false);
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState();
  const [barcodeSelect, setBarcodeSelect] = useState("")
  const [selectedValue, setSelectedValue] = useState("");

  const {subProductData, getDataSub, setSubVendorId} = useSubProductList()
  const location = useLocation()
  const {id, barcode} = location.state || ""

  const {myDataVendor} = useVendorList()
  const {perms} = useContext(PermsContext)
  
    function hasPermission(permission) {
      return perms?.includes(permission);
    }

    const handleChangeVendor = (e)=> {
  
      setVendorIdBarcode(e.target.value)
      setVendorIdSubProduct(e.target.value)
      setSubVendorId(e.target.value)
    }


  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);




  useEffect(() => {
    if(barcode){
     
      getDataBarcode(barcode)
    }
  
  }, [barcode ])

  
 



  const handleInputChange = value => {
    setBarcodeSelect(value);
  };
 
  const handleChange = value => {
    setSelectedValue(value);

  }


  const handleAddProduct = (e) => {
    e.preventDefault();
    // if (!selectedValue) {
    //   return;
    //   }
    setProducts([...products, {name: selectedValue.Title, count: count, barcode:selectedValue.barcodes__Barcode, sku: selectedValue.SKU, image :selectedValue.Image, Id : selectedValue.Id }])
  
    setShowTable(true);
    setSelectedValue(null)
    setCount(null)
   
  }



  const [dataUpdated, setDataUpdated] = useState(false);
  const params = new URLSearchParams(location.search);
  const id_param = params.get('id');


  useEffect(() => {
    if (id) {
      getDataSub(id);
      setDataUpdated(false);
    }
  }, [dataUpdated, id]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');

    if (id) {
      getDataSub(id);
      setDataUpdated(false);
    }
  }, [dataUpdated, id ]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataArray = products.map((product) => {
      return {
        "Count": product.count,
        "SetProduct": id || id_param,
        "SubProduct": product.Id
      };
    });
  
    setFormData(formDataArray);
  
    setDataUpdated(true);
  
    handleClose(false);
    setSelectedValue(null);
    setCount(null);
  }
  




// modal
const [show, setShow] = useState(false);

const handleClose = () => {
  setShow(false);
  setSelectedValue(null)
  setCount(null)
  handleDeleteProduct()

}
const handleShow = () => setShow(true);



function handleDeleteProduct(index) {
  const updatedProducts = [...products];
  updatedProducts.splice(index, 1);
  setProducts(updatedProducts);
  setSelectedValue(updatedProducts)
      
  }


const orderedProductsData = subProductData?.results.map((product, i) =>{

  return {
  id : i+1,
  name: product.SubProduct.Title,
  image: product.SubProduct.Image,
  sku: product.SubProduct.SKU,
  stock: product.Count,
}});



  return (
    <>
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">


        <div style={{display:'flex', justifyContent:'space-between',marginBottom:"15px"}}>
          
          <div>
              <h6 >Alt Ürünler</h6>
          </div>
       



        <div style={{display:'flex'}}>
      
          <div id="orders-actions">
           <IconButton
              variant="falcon-default"
              size="m"
              icon="plus"
              transform="shrink-3"
              onClick={handleShow}
            >
              <span className="ms-1">Alt Ürün Ekle</span>
            </IconButton>
          </div>
       

  
          
        </div>
      </div>
          <Table striped className="border-bottom">
          
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Ürün</th>
                <th className="border-0">İsim</th>
                <th className="border-0">SKU</th>
                <th className="border-0 text-center">Stok</th>
              </tr>
            </thead>
            <tbody>
              {orderedProductsData?.length>0 && orderedProductsData?.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle">
                  <img style={{width:"40px"}}src={product.image} alt="" />
                 
                  </td>

                  <td className="align-middle text-left" style={{whiteSpace: 'normal'}}
                   title={product.name}
                   onMouseOver={(e) => (e.currentTarget.title = product.name)}
                  >
                
                {product.name.length > 40
                      ? product.name.slice(0, 40).concat("...")
                      : product.name}
                  </td>
                  <td className="align-middle">
                  
                    <h6 className="mb-0 text-nowrap">{product.sku}</h6>
                  </td>
                
                  <td className="align-middle text-center">
                    
                    {product.stock}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
             
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
   



    <Modal 
      size="lg"
      show={show} 
      onHide={handleClose}
      >
        <Modal.Header closeButton>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
          <Modal.Title>Ürün Ekle</Modal.Title>
          </div>
          { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                  <div style={{marginLeft:'480px'}}>
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                     <option value="" >Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
        
            </div>
            
            : "" }

</div>
        </Modal.Header>
        <Modal.Body>
 
<Form  onSubmit={handleSubmit}>

            <div style={{display:"flex"}}>
             <div style={{width:'65%'}}>
                  <AsyncSelect
                      loadOptions={getData}
                      placeholder="barkod numarası" 
                      className="mb-3" 
                      onInputChange={handleInputChange}
                      onChange={handleChange}
                      value={selectedValue}
                      getOptionLabel={e => e.Title}
                      getOptionValue={e => e.Barcode}
                      styles={{control: (baseStyles) =>({
                        ...baseStyles, 
                        backgroundColor:isDark ? "#0B1727" : "white"
                      }),}}
                  />
             </div>


               <div style={{width:'20%', marginLeft:'15px'}}>
                <Form.Control placeholder="adet"
                onChange={(e)=>setCount(e.target.value)}
                value={count || ''}
                >
                </Form.Control>
                </div>


               <div style={{marginLeft:'15px'}}>
                <Button variant="primary" onClick={handleAddProduct}>
                  Ekle
                </Button>
                </div>
                </div>
{showTable && (

<Card className='mb-3'>
  <Card.Body>
<div>
<Table striped className="border-bottom">
        <thead className="bg-200 text-900">
          <tr>
          <th className="border-0"></th>

            <th className="border-0">Ürün</th>
            <th className="border-0">İsim</th>
            <th className="border-0 text-center">Adet</th>


          </tr>
        </thead>
        <tbody>
        {products?.map((product, i) => (
            <tr key={i} className="border-200">
              <td style={{marginRight:"0"}}>        
              <svg  onClick={() => handleDeleteProduct(i)} xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
              </td>
                <td className="align-middle" style={{marginLeft:"0"}}>
                  <img style={{width:"40px"}}src={product.image} alt="" />
                </td>

                <td className="align-middle"  title={product.name}>
                  {product.name.length > 40
                      ? product.name.slice(0, 40).concat("...")
                      : product.name}
                </td>

                <td className="align-middle text-center">
                  {product.count}
                </td>

            </tr>
          ))}
        </tbody>
      </Table>
     
</div>

<Button variant="primary" 
type="submit" className="d-flex mx-auto mt-5 mb-2" 

>
    Onayla
  </Button>
  </Card.Body>
</Card>

)}

</Form>

          </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Vazgeç
          </Button>

        </Modal.Footer>
      </Modal> 

    </>
  );
};

SubProductList.propTypes = {};

export default SubProductList;
