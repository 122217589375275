import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'



export default function useDeliveryList() {

  const {myKey, currentUser} = useContext(AuthContext)
  const [myData, setMyData] = useState()
  const [page, setPage] = useState(1)
  const [detail, setDetail] = useState()
  const [detailWork, setDetailWork] = useState()

  const [firstName, setFirstName] = useState()
  const [store, setStore] = useState()
  const [selectStatus, setSelectStatus] = useState(0)
  const [cargo, setCargo] = useState()
  const [search, setSearch] = useState()
  const [paid, setPaid] = useState()
  const [invoices, setInvoices] = useState()
  const [sortAmount, setSortAmount] = useState(3)
  const [work, setWork] = useState()

  const [courier, setCourier] = useState()
  const [deliveryStatus, setDeliveryStatus] = useState()




  const [vendorIdList, setVendorIdList] = useState()


  const [inventoryRegion, setInventoryRegion] = useState()

  const [showOrderGif, setShowOrderGif] = useState(false);

  const [currentPage,setCurrentPage]=useState(1)
  const [followersPerPage, setFollowersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
 
const date = new Date();
const oneDay = 1000 * 60 * 60 * 24;
const [startDate, setStartDate] = useState(new Date(date.getTime() - oneDay * 3));
const [endDate, setEndDate] = useState(new Date(date.getTime() + oneDay * 2));

let formattedStartDate = "";
let formattedEndDate = "";

if(startDate){
  formattedStartDate = new Date(startDate.getTime()).toISOString().slice(0, 10);
}
if(endDate){
  formattedEndDate = new Date(endDate.getTime() + oneDay).toISOString().slice(0, 10);
}
const [isLoading, setIsLoading] = useState(false);

console.log("tarih", formattedStartDate,formattedEndDate)


 const getData = ()=>{
 
        var myHeaders = new Headers();
        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;


        const params = {
          offset :offset,
          limit :limit,
          // page: page,
          cargo :cargo,
          status: selectStatus,
          begin_date: formattedStartDate,
          end_date: formattedEndDate,
          // vendor_username: currentUser,
          first_name: firstName,
          store : store,
          search : search,
          paid : paid,
          invoices : invoices,
          inventory_region : inventoryRegion,
          sort_amount : sortAmount,
          vendor_id : vendorIdList,
          work_order : work,
          courier_id : courier,
          status : deliveryStatus
        };

        
        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/order/deliveries/?`
    );

 
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result) 
            setShowOrderGif(false);
            setIsLoading(false);
            setTotalPages(Math.ceil(result.count / followersPerPage))
    })
          .catch(error => console.log('error', error));        
 }




 const getDetail= (id)=>{
  var myHeaders = new Headers();

  let request_url;

      request_url = `${BASE_URL}api/v1/order/deliveries/?id=${id}`

  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setDetail(result.results) 
})
    .catch(error => console.log('error', error));        
}

const getDetailWork= (id)=>{
  var myHeaders = new Headers();
  let request_url;
      request_url = `${BASE_URL}api/v1/order/order_start/?id=${id}&work_order=false`

  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };

  console.log('Fetching data...');
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setDetailWork(result.results) 

})
    .catch(error => console.log('error', error));      
    
}

useEffect(() => {
  console.log("Inside useEffect, isLoading is:", isLoading);  // Debug için
  if (isLoading) {
    console.log("isLoading is true, calling getData");  // Debug için
    getData();
    setIsLoading(false);
  }
}, [isLoading]);



 useEffect(() => {
    // getData();
    // getDetail()
    console.log('Current page:', currentPage); 
    setIsLoading(true);
    setShowOrderGif(true)
  }, [ currentPage,followersPerPage,page,selectStatus, startDate,endDate, cargo, firstName, store, search, paid, invoices, inventoryRegion, sortAmount, vendorIdList, work, courier, deliveryStatus]);




 return {myData, setPage, detail, getDetail,  getData, setMyData, selectStatus, setSelectStatus, startDate, setStartDate, endDate, setEndDate,  cargo, setCargo, firstName, setFirstName,  setStore, setSearch, setPaid, showOrderGif, currentPage,
  setCurrentPage,
  followersPerPage,
  setFollowersPerPage,
  totalPages, setInvoices, setInventoryRegion, sortAmount, setSortAmount, setVendorIdList, setWork, getDetailWork, detailWork, setIsLoading, isLoading, courier, setCourier, setDeliveryStatus}
}
