import React, { useContext, useEffect, useState } from 'react'
import { Form, Button, Card, Table, Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';

import useBarcodeSku from 'hooks/useBarcodeSku'
import useInventoryList from 'hooks/useInventoryList'
import useShipmentCompany from 'hooks/useShipmentCompany';
import useProductDeliveryPanel from 'hooks/useProductDeliveryPanel';
import AppContext from 'context/Context';
import { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import useInventoryExampleExport from 'hooks/useInventoryExampleExport';
import { useInventoryUpload } from 'hooks/useInventoryUpload';
import IconButton from 'components/common/IconButton';
import useVendorList from 'hooks/useVendorList';
import useProductAdd from 'hooks/useProductAdd';
import useProductCategory from 'hooks/useProductCategory';
import useProductExampleExport from 'hooks/useProductExampleExport';
import { useProductUpload } from 'hooks/useProductUpload';
import { toast } from 'react-toastify';




const ProductCreate = () => {

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);




  const {myDataVendor} =useVendorList()
  const {formData, setFormData, postData} = useProductAdd()
  
  const {categoryData,setVendorIdCategory } = useProductCategory()

  const [vendorId, setVendorId] = useState()
  const [title, setTitle] = useState()
  const [sku, setSKU] = useState()
  const [brand, setBrand] = useState()
  const [category, setCategory] = useState()
  const [volume, setVolume] = useState()
  const [weight, setWeight] =useState()
  const [price, setPrice] = useState()
  const [image, setImage] = useState()
  const [barcode, setBarcode] = useState()
  const [newCategory, setNewCategory] = useState()




  const {getDataInventoryExport} = useInventoryExampleExport()

  const { uploadFile, errorMessage, setVendorIdUpload } = useProductUpload()
  const fileInput = useRef(null);
  const [fileName, setFileName] = useState('');

  


// modal
  
const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);



const handleUploadClick = () => {
  if (fileInput.current.files.length > 0) {
      setFileName(fileInput.current.files[0].name);
      uploadFile(fileInput.current.files[0]);
      setShow(false)
    
  } else {
      console.log("No file chosen!");
  }
  setFileName("")
};

const {getDataProductExport} = useProductExampleExport()

const handleProductExport = () => {
  getDataProductExport()

}

const handleCategoryChange = selectedOption => {
  setCategory(selectedOption);
  setNewCategory(""); 
};

const handleNewCategoryChange = event => {
  setNewCategory(event.target.value);
  if (event.target.value) {
    setCategory(null); 
  }
};


const handleSubmit = (e) => {
    e.preventDefault();

    if (category && newCategory) {
      toast.warn('Lütfen sadece bir kategori seçin veya girin.');
    } else if (!category && !newCategory) {
      toast.warn('Lütfen bir kategori seçin veya girin.');
    } else {
      console.log('Form gönderildi', { category, newCategory });
      setFormData({
        ...formData, "Title": title,
                      "SKU": sku,
                      "Brand": brand,
                      "category_name": category ? category.value : '',
                      "Volume": volume,
                      "Weight": weight,
                      "Price": price,
                      "Image": image,
                      "Barcode": barcode,
                      "new_category":  newCategory,
                      "vendorId" : vendorId
                      
        })  
    }




  
// postData()
setCategory(null);
setSKU(null);
setTitle(null);
setBrand(null);
setVolume(null)
setWeight(false)
 e.target.reset();
 setPrice(null)
 setImage(null)
 setBarcode(null)
}


    const categoryList = categoryData?.map((item)=>{
      return{
          value: item.Name,
          label: item.Name
      }
  })

    const handleChangeVendor = (e)=> {
      setVendorId(e.target.value); 
      setVendorIdUpload(e.target.value)
      setVendorIdCategory(e.target.value)
    }

  return (
    <>
     <Link to="/e-commerce/product/product-all">
      <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      </Link>
    <Card className="mb-3 mt-1" style={{display: "flex", justifyContent: "center", alignItems: "center" }}>
    <div className="mb-3 mt-3" style={{width:"95%", display:"flex", flexDirection: "column"}}>
      <div style={{display:"flex", justifyContent: "space-between"}}>
        <h6>Katalog Ürünü Oluşturma</h6>
        <div style={{marginLeft:"8px", marginRight:"8px"}}>
                <IconButton
                  variant="falcon-default"
                  size="m"
                  icon="plus"
                  transform="shrink-3"
                  onClick={handleShow}
                  style={{width:'200px', fontSize:'15px'}}
                >
                  <span className="d-none d-sm-inline-block ms-1">Toplu Ürün Ekle</span>
                </IconButton>
                </div>
      </div>
      <hr />
      </div>

    <Form.Group style={{ border: "0px solid gray",  padding:"10px", borderRadius:"10px", width:"80%" }} className="card-bg-dark">
       

    <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
  { myDataVendor?.length > 0 &&    
    <Form.Select 
      className='form-control' 
      style={{fontSize:"15px", width:"145px"}} 
      onChange={handleChangeVendor}
    
    >
      <option value="" >Satıcı</option>
      {myDataVendor?.map((item, i) => {
        return <option key={i} value={item.Id}>{item.vendor_name}</option>
      })}       
    </Form.Select>
  }
</div>

<Form onSubmit={handleSubmit}>
  <Form.Group controlId="formDepo" className="mb-3"  >
    <Form.Group controlId="formDepo" className=""  >
            <Form.Label>Ürün İsmi*</Form.Label>
            <Form.Control type="text"  required placeholder="Ürün ismi"  onChange={(e)=>setTitle(e.target.value)}/>
        </Form.Group>

      <div style={{display:'flex', flexWrap:'nowrap', marginTop:'15px'}}>
        
        <div  style={{marginRight:'10px', width:'50%'}}>
        <Form.Label>SKU*</Form.Label>
        <Form.Control type="text"  required placeholder="SKU"  onChange={(e)=>setSKU(e.target.value)}/>
        </div>
      
        <div  style={{ width:'50%'}}>
        <Form.Label>Barkod* </Form.Label>
        <Form.Control type="text" required placeholder="Barkod"  onChange={(e)=>setBarcode(e.target.value)}/>
        </div>
    </div>

    <div style={{display:'flex', flexWrap:'nowrap', marginTop:'15px'}}>
        
        <div  style={{marginRight:'10px', width:'50%'}}>
        <Form.Label>Marka</Form.Label>
        <Form.Control type="text" placeholder="Markası"  onChange={(e)=>setBrand(e.target.value)}/>
        </div>
      
        <div  style={{width:'50%'}}>
        <Form.Label>Fiyat</Form.Label>
        <Form.Control type="text" placeholder="Fiyat"  onChange={(e)=>setPrice(e.target.value)}/>
        </div>
    </div>

    <div style={{display:'flex', flexWrap:'nowrap', marginTop:'15px'}}>
        
        <div  style={{marginRight:'10px', width:'50%'}}>
        <Form.Label>Ağırlık</Form.Label>
        <Form.Control type="text" placeholder="ağırlık gram"  onChange={(e)=>setWeight(e.target.value)}/>
        </div>
      
        <div  style={{width:'50%'}}>
        <Form.Label>Hacim</Form.Label>
        <Form.Control type="text" placeholder="desi bilgisi"  onChange={(e)=>setVolume(e.target.value)}/>
        </div>
    </div>

    <Form.Group controlId="formDepo" className="mt-3 mb-3"  >
            <Form.Label>Ürün resimi</Form.Label>
            <Form.Control type="text" placeholder="URL linki (max 300 karakter)"  onChange={(e)=>setImage(e.target.value)}/>
        </Form.Group>

        <div style={{display:'flex', flexWrap:'nowrap', marginTop:'15px'}}>

            <div  style={{width:'50%', marginRight:'10px',}}>
                <Form.Label>Mevcut Kategoriler</Form.Label>
                  <Select options={categoryList} 
                  isSearchable={true} 
                  placeholder="kategori seçimi"
                  onChange={handleCategoryChange} 
                  value={category}
                  isClearable={true}
                  styles={{control: (baseStyles) =>({
                        ...baseStyles, 
                        backgroundColor:isDark ? "#0B1727" : "white"
                      }),}}
                      />
            </div>    


            <div  style={{width:'50%'}}>
                <Form.Label>Kategori 
                <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>Kategori listesinde kategoriyi bulamadıysanız buradan girebilirsiniz...</Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>
                </Form.Label>
                <Form.Control type="text"  value={newCategory}  placeholder="kategori bulunamadıysa"  onChange={handleNewCategoryChange}/>
            </div>

       </div>

  </Form.Group>


  <Button variant="primary" type="submit" className="d-flex mx-auto mt-5 mb-2" >
    Onayla
  </Button>

      </Form>
      </Form.Group>

</Card>
<Modal 
      show={show} 
      onHide={handleClose}
      size="lg"
      >
        <Modal.Header closeButton>
        <Modal.Title>Ürün Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
  { myDataVendor?.length > 1 &&    
    <Form.Select 
      className='form-control' 
      style={{fontSize:"15px", width:"145px"}} 
      onChange={handleChangeVendor}
    
    >
      <option value="">Satıcı</option>
      {myDataVendor?.map((item, i) => {
        return <option key={i} value={item.Id}>{item.vendor_name}</option>
      })}       
    </Form.Select>
  }
</div>

            <p>- İndir butonunu kullanarak örnek excel dosyasını indiriniz.</p>
            <p>- Excel dosyasında belirtilen sütunları değiştirmeyiniz.</p>
            <p>- Title, SKU ve Barcode sütunu zorunludur.</p>
            <p>- Image sütununa ürün resimi için URL girilmelidir(max. 300 karakter).</p>
            <p>- Ağırlık "gram", Hacim "desi" birimi olarak girilmelidir..</p>
            {myDataVendor?.length > 1 ?  <p>- Satıcı seçimi zorunludur.</p> : ""}


        </Modal.Body>

        <Modal.Footer>
            <Form style={{width:"100%"}}>
              <Form.Group style={{ display: "flex", justifyContent: "space-between", width:"100%"}}>
                <div>
                  <Form.Control type="file" ref={fileInput} style={{border: 'none'}} />
                </div>
                <div>

                <Button variant="primary" onClick={handleProductExport}>
                    Örnek excel indir
                  </Button>
                  <Button style={{marginLeft:"5px"}} variant="secondary" onClick={handleClose}>
                    Vazgeç
                  </Button>
                 
                </div>
              </Form.Group>

              <div style={{ display: "flex", justifyContent: "center", marginTop:"60px" }}>
                <Button variant="primary" onClick={handleUploadClick}>Yükle</Button>
              </div>

              {fileName && <p style={{ textAlign: "center" }}>Selected file: {fileName}</p>}
            </Form>
                  {/* {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} */}
            </Modal.Footer>

      </Modal>




      </>
  )
}

export default ProductCreate