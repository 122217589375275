import React, { useContext, useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import PropTypes from 'prop-types';
import { Card, Row, Col, Table, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import useEcomStat from 'hooks/useEcomStatPrepare';
import useTotalOrderCount from 'hooks/useTotalOrderCount';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { TbHandClick } from "react-icons/tb";
import CountUp from 'react-countup';
import useEcomStatWait from 'hooks/useEcomStatWait';
import useEcomStatFulfilled from 'hooks/useEcomStatFulfilled';
import useEcomStatPacked from 'hooks/useEcomStatPacked ';
import useEcomStatPrepare from 'hooks/useEcomStatPrepare';
import useEcomStatWaitAll from 'hooks/useEcomStatWaitAll';
import useEcomStatOrderDaily from 'hooks/useEcomStatOrderDaily';
import useEcomStatPackedAll from 'hooks/useEcomStatPackedAll';
import useEcomStatSalesTotal from 'hooks/useEcomStatSalesTotal';
import MarketShare from '../default/MarketShare';
import CargoShare from '../default/CargoShare';
import {totalSale, saleItems} from 'data/dashboard/ecom';
import {
  marketShare,
  totalOrder,
  totalSales,
  weeklySalesData,
  weather,
  products,
  storageStatus,
  files,
  users,
  topProducts,
  runningProjects,
 
} from 'data/dashboard/default';
import StatisticsCardsRefend from '../saas/stats-cards-refund/StatisticsCardsRefend';
import StatisticsCardsRefendRate from '../saas/stats-cards-refund-rate/StatisticsCardsRefendRate';
import StatisticsCardsRefendCost from '../saas/stats-cards-refund-cost/StatisticsCardsRefendCost';
import { DateContext } from 'context/DateContext';
import AppContext from 'context/Context';
import './style.css';
import { PermsContext } from 'context/PermsContext';
import StatisticsCards from '../saas/stats-cards/StatisticsCards';
import BestProducts from '../default/BestProducts';
import TotalSalesCount from '../default/TotalSalesCount';
import TotalSales from './totalsales/TotalSales';

import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { BiSolidChevronLeft, BiSolidChevronRight} from "react-icons/bi";
import DatePicker from  "react-datepicker";
import { GiCancel } from "react-icons/gi";
import useVendorStore from 'hooks/useVendorStore';
import useVendorStoreForAnalsyt from 'hooks/useVendorStoreForAnalsyt';
import useInventoryListFilter from 'hooks/useInventoryListFilter';



const EcomStatItem = ({ stat }) => {

  const {loading} = useEcomStat()
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  const {perms} = useContext(PermsContext)

  function hasPermission(permission) {
    return perms?.includes(permission);
  }



  return (

  <>
     
    <Col xs={12} md={3} 
    className={stat.className}  // card üzerindeki çizgiler
    style={{width: hasPermission('Dashboard Analiz Sayfası Full') ?"16%":"20%", cursor:"pointer"}} 
    >
      <p 
       style={{cursor:"pointer", color: isDark ? "#D8E2EF":"#025098", fontSize:"14px", fontWeight:"600"}}
      >{stat.title}  
      </p>
      <span style={{marginRight:'5px', fontSize: '25px' }}>{stat.span}</span>
      <CountUp
          start={0}
          end={stat.amount}
          duration={0.55}
          // className="text-700"
          // separator=","
          style={{ fontSize: '25px' }}
        />
      {/* <p className="font-sans-serif lh-1 mb-1 fs-2 text-center">{stat.amount}</p> */}
    </Col>
</> 

  );
};

EcomStatItem.propTypes = {
  stat: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    style : PropTypes.string,
  }),
  index: PropTypes.number,
  lastIndex: PropTypes.number
};

const EcomStat = () => {


const {myData} = useTotalOrderCount()
const {myDataWait} = useEcomStatWait()
const {myDataPacked} = useEcomStatPacked()
const {myDataFulfilled} = useEcomStatFulfilled()
const {myDataSales} = useEcomStatSalesTotal()
const {myDataPrepare} = useEcomStatPrepare()

const {vendorStore} = useVendorStoreForAnalsyt()

const {setStoreIdContext, setRegionContext} = useContext(PermsContext)
const {myDataRegion} = useInventoryListFilter()

const {perms} = useContext(PermsContext)

  function hasPermission(permission) {
    return perms?.includes(permission);
  }



  const [data, setData] = useState([]);



  useEffect(() => {
    const newData = hasPermission('Dashboard Analiz Sayfası Full') 
      ? [
          {
            title: 'Toplam Satış',
            amount: myDataSales?.OrderAmount__sum,
            span: '₺',
            type: 'warning',
            className: 'border-200 border-md-200 border-bottom border-md-end pb-3 ps-1'
          },
          {
            title: 'Sipariş Sayısı',
            amount: myData?.total_count,
            className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-2'
          },
          {
            title: 'Bekleyen Siparişler',
            amount: myDataWait?.waiting,
            className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-2'
          },
          {
            title: 'Hazırlanan Siparişler',
            amount: myDataPrepare?.preparing,
            className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-2'
          },
          {
            title: 'Paketlenen Siparişler',
            amount: myDataPacked?.packed,
            className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-3'
          },
          {
            title: 'Gönderilen Siparişler',
            amount: myDataFulfilled?.fulfilled,
            className: 'border-200 border-bottom border-end border-md-end-0 pb-4 pt-4 pt-md-0'
          }
        ]
      : [
          {
            title: 'Sipariş Sayısı',
            amount: myData?.total_count,
            className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-2'
          },
          {
            title: 'Bekleyen Siparişler',
            amount: myDataWait?.waiting,
            className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-2'
          },
          {
            title: 'Hazırlanan Siparişler',
            amount: myDataPrepare?.preparing,
            className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-2'
          },
          {
            title: 'Paketlenen Siparişler',
            amount: myDataPacked?.packed,
            className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-3'
          },
          {
            title: 'Gönderilen Siparişler',
            amount: myDataFulfilled?.fulfilled,
            className: 'border-200 border-bottom border-end border-md-end-0 pb-4 pt-4 pt-md-0'
          }
        ];
  
    setData(newData);
  }, [myData, myDataWait, myDataPacked, myDataFulfilled, myDataSales, myDataPrepare]);
  

const { setStartDate, setEndDate, startDate, endDate } = useContext(DateContext);
const [activeButton, setActiveButton] = useState('today');


const setDates = (start, end = new Date()) => {
  setStartDate(start.toLocaleDateString('fr-CA')); 
  setEndDate(end.toLocaleDateString('fr-CA')); 
};

const handleThisMonthClick = () => {
  setActiveButton('month');

  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth()); 
  const firstDayOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)
  
  setDates(firstDayOfMonth, firstDayOfNextMonth);
};


const handleThisWeekClick = () => {
  setActiveButton('week');

  const now = new Date();
  const dayOfWeek = now.getDay();
  const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; 

  now.setDate(now.getDate() + diffToMonday);
  const startOfWeek = new Date(now);

  const startOfNextWeek = new Date(startOfWeek);
  startOfNextWeek.setDate(startOfWeek.getDate() + 7);

  setDates(startOfWeek, startOfNextWeek);
};

const handleTodayClick = () => {
  setActiveButton('today');
  
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  setDates(today, tomorrow);
};

useEffect(() => {
  if(!startDateCustom && !endDateCustom){
    handleTodayClick();
  }
  
}, []);



const date = new Date();
const oneDay = 1000 * 60 * 60 * 24;
const twoDay = 1000 * 60 * 60 * 24 *2;

const [startDateCustom, setStartDateCustom] = useState();
const [endDateCustom, setEndDateCustom] = useState();



const handleCustomClick = () => {
  setActiveButton('custom');
};

const handleStoreClick = () => {
  setActiveButton('store');
};

const handleRegionClick = () => {
  setActiveButton('region');
};



const [isOpenDate, setIsOpenDate] = useState(true);

const onChange = dates => {
  const [start, end] = dates;

  setStartDateCustom(start);

  if (end) {
    setEndDateCustom(end);

    const endPlusOneDay = new Date(end);
    endPlusOneDay.setDate(endPlusOneDay.getDate() + 1);

    setIsOpenDate(false);

    setStartDate(start.toLocaleDateString('fr-CA'));
    setEndDate(endPlusOneDay.toLocaleDateString('fr-CA')); 
  } else {
    setEndDateCustom(null);
    setStartDate(null);

    setIsOpenDate(true);
  }
};




const handleCalendarClick = () => {
  setIsOpenDate(true);
};


const handleChangeStore = (e)=> {
  setStoreIdContext(e.target.value)
}


const handleChangeRegion = (e)=> {
  setRegionContext(e.target.value)
}






const {
  config: { isDark, isRTL },
  setConfig
} = useContext(AppContext);

  return (
    hasPermission('Dashboard Analiz Sayfası Full') ? (
    <>
    <Card className="py-3 mb-2 h-100" style={{paddingLeft:'10px', paddingRight:'10px', height:'3558px' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
      <div >
        <h6 className="mb-0 mt-2" style={{marginLeft:"20px", color: isDark ? "#D8E2EF":"#05276D", fontSize:'15px'}}>
         Performans Özeti
          </h6>
          </div>
        <div className="mt-2" style={{ display: 'flex', justifyContent: 'end', marginRight:"20px", fontSize:"13px" }}>
        <div 
          onClick={handleTodayClick} 
          style={{ 
            backgroundColor: activeButton === 'today' ? 'rgb(232,240,252)' : 'transparent',
            cursor: 'pointer', // Buton gibi davranması için cursor'u pointer olarak ayarlıyoruz
            // borderRadius:"5px",
            borderBottom: isDark ? "none" : "1px solid #EDF2F8",
            paddingBottom: '4px',
            paddingTop: "0px",
            paddingRight:"10px",
            paddingLeft:"10px", 
            // color: (isDark && activeButton === 'today') ? "black":"#D8E2EF"
            color: isDark ? (activeButton === 'today' ? 'black' : '#D8E2EF') : (activeButton === 'today' ? '#025098' : '#025098')
          }}
        >
          Bugün
        </div>
        <div 
          onClick={handleThisWeekClick} 
          style={{ 
            backgroundColor: activeButton === 'week' ? 'rgb(232,240,252)' : 'transparent',
            cursor: 'pointer', // Buton gibi davranması için cursor'u pointer olarak ayarlıyoruz
            // borderRadius:"5px",
            borderBottom: isDark ? "none" : "1px solid #EDF2F8",
            borderRight: isDark ? "none" : "1px solid #EDF2F8",
            paddingBottom: '4px',
            paddingTop: "0px",
            paddingRight:"10px",
            paddingLeft:"10px", 
            // color: (isDark && activeButton === 'week') ? "black":"#D8E2EF"
            color: isDark ? (activeButton === 'week' ? 'black' : '#D8E2EF') : (activeButton === 'week' ? '#025098' : '#025098')

          }}
        >
          Bu hafta
        </div>
      
        <div 
          onClick={handleThisMonthClick} 
          style={{ 
            backgroundColor: activeButton === 'month' ? 'rgb(232,240,252)' : 'transparent',
            paddingBottom: '4px',
            paddingTop: "0px",
            paddingRight:"10px",
            paddingLeft:"10px", 
            cursor: 'pointer',
            // borderRadius:"5px",
            borderBottom: isDark ? "none" : "1px solid #EDF2F8",
            borderRight: isDark ? "none" : "1px solid #EDF2F8",
            // color: (isDark && activeButton === 'month') ? "black":"#D8E2EF"
            color: isDark ? (activeButton === 'month' ? 'black' : '#D8E2EF') : (activeButton === 'month' ? '#025098' : '#025098')
       
          }}
        >
          Bu ay
        </div>

        <div 
          onClick={handleCustomClick} 
          style={{ 
            backgroundColor: activeButton === 'custom' ? 'rgb(232,240,252)' : 'transparent',
            paddingBottom: '4px',
            paddingTop: "0px",
            paddingRight:"10px",
            paddingLeft:"10px", 
            cursor: 'pointer',
            // borderRadius:"5px",
            borderBottom: isDark ? "none" : "1px solid #EDF2F8",
            borderRight: isDark ? "none" : "1px solid #EDF2F8",
            // color: (isDark && activeButton === 'month') ? "black":"#D8E2EF"
            color: isDark ? (activeButton === 'custom' ? 'black' : '#D8E2EF') : (activeButton === 'custom' ? '#025098' : '#025098')
       
          }}
        >
          Tarih Seç
        </div>

        <div 
          onClick={handleStoreClick} 
          style={{ 
            backgroundColor: activeButton === 'store' ? 'rgb(232,240,252)' : 'transparent',
            paddingBottom: '4px',
            paddingTop: "0px",
            paddingRight:"10px",
            paddingLeft:"10px", 
            cursor: 'pointer',
            // borderRadius:"5px",
            borderBottom: isDark ? "none" : "1px solid #EDF2F8",
            borderRight: isDark ? "none" : "1px solid #EDF2F8",
            // color: (isDark && activeButton === 'month') ? "black":"#D8E2EF"
            color: isDark ? (activeButton === 'store' ? 'black' : '#D8E2EF') : (activeButton === 'store' ? '#025098' : '#025098')
       
          }}
        >
          Mağaza
        </div>

        <div 
          onClick={handleRegionClick} 
          style={{ 
            backgroundColor: activeButton === 'region' ? 'rgb(232,240,252)' : 'transparent',
            paddingBottom: '4px',
            paddingTop: "0px",
            paddingRight:"10px",
            paddingLeft:"10px", 
            cursor: 'pointer',
            // borderRadius:"5px",
            borderBottom: isDark ? "none" : "1px solid #EDF2F8",
            borderRight: isDark ? "none" : "1px solid #EDF2F8",
            // color: (isDark && activeButton === 'month') ? "black":"#D8E2EF"
            color: isDark ? (activeButton === 'region' ? 'black' : '#D8E2EF') : (activeButton === 'region' ? '#025098' : '#025098')
       
          }}
        >
          Depo
        </div>

          </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight:'25px' }}>
        <div style={{marginTop:'5px', width:'17%'}}>
        {activeButton === "custom" &&
               <div style={{ display: 'flex'}}>
                  <DatePicker
                      showIcon
                      selected={startDateCustom}
                      onChange={onChange}
                      startDate={startDateCustom}
                      formatWeekDay={day => day.slice(0, 3)}
                      endDate={endDateCustom}
                      selectsRange
                      dateFormat="MMM dd"
                      className='form-control datepicker-custom-font-size'
                      placeholderText='tarih giriniz'
                      open={isOpenDate}
                      onCalendarClose={() => setIsOpenDate(false)}
                      onCalendarOpen={() => setIsOpenDate(true)}
                      onClickOutside={() => setIsOpenDate(false)}
                      onFocus={handleCalendarClick}
                   />
                   <GiCancel  style={{fontSize:'25px', marginLeft:'5px',marginTop:'5px', cursor:'pointer'}} 
                   onClick={()=>setActiveButton('')}
                   />
                   
                   </div> }

                   {activeButton === "store" &&
               <div style={{ display: 'flex'}}>
                   <div className="mb-2">
                    { vendorStore?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeStore}>
                            <option value="" >Mağaza</option>
                                { vendorStore?.map((item, i)=>{
                                      return <option key={i} value={item.Store__Name}>{item.Store__Name}</option>
                                })}       
                        </Form.Select>}

                  </div>
                  <GiCancel  style={{fontSize:'25px', marginLeft:'5px',marginTop:'5px', cursor:'pointer'}} 
                   onClick={()=>setActiveButton('')}
                   />
                  </div> }

                  {activeButton === "region" &&
               <div style={{ display: 'flex'}}>
                   <div className="mb-2">
                   { myDataRegion?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeRegion}>
                            <option value="" >Depo</option>
                                { myDataRegion?.map((item, i)=>{
                                      return <option key={i} value={item.Region__Name}>{item.Region__Name}</option>
                                })}       
                        </Form.Select>}

                  </div>
                  <GiCancel  style={{fontSize:'25px', marginLeft:'5px',marginTop:'5px', cursor:'pointer'}} 
                   onClick={()=>setActiveButton('')}
                   />
                  </div> }
        </div>
      </div>
    <Card  style={{backgroundColor:isDark ? "#0B1727" : "#F6FAFF", marginBottom:"20px", marginTop:'30px', marginLeft:"20px", marginRight:"20px", paddingTop:"20px"}}>
      <Card.Body >
        <Row className="g-5" style={{textAlign:'center', alginItem:'center', justifyContent:'center', marginLeft:'1px'}}>
          {data.map((stat, index) => (
            <EcomStatItem
              key={stat.title}
              stat={stat}
              index={index}
              lastIndex={data.length - 1}
          
            />
          ))}
        </Row>
      </Card.Body>
      </Card>

   
   <Row className="g-3 mb-3" style={{paddingLeft:'10px', paddingRight:'10px'}}>
       

        <Col md={6} xxl={6} style={{marginRight:'-14px'}}>
          <MarketShare data={marketShare} radius={['100%', '87%']} />
        </Col>
        <Col md={6} xxl={6}>
          <CargoShare data={marketShare} radius={['100%', '87%']} />
        </Col>
    
      </Row>

      <Row className="g-3 mb-3" style={{paddingLeft:'10px', paddingRight:'10px'}}>
              <Col md={4} xxl={4}>
              <StatisticsCardsRefend />
              </Col>

              <Col md={4} xxl={4}>
                  <StatisticsCardsRefendRate/>
              </Col>
        
              <Col md={4} xxl={4}>
                <StatisticsCardsRefendCost/>
              </Col>
      </Row>
    </Card>
    </>
    ) : hasPermission('Dashboard Analiz Sayfası Partial') ? (
      <>
      <Card className="py-3 mb-2" >
  
         <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <h6 className="mb-0 mt-2" style={{marginLeft:"20px", color: isDark ? "#D8E2EF":"#05276D", fontSize:'15px'}}>
           Performans Özeti
            </h6>
            </div>
          <div className="mt-2" style={{ display: 'flex', justifyContent: 'end', marginBottom:"30px", marginRight:"20px", fontSize:"13px" }}>
          <div 
            onClick={handleTodayClick} 
            style={{ 
              backgroundColor: activeButton === 'today' ? 'rgb(232,240,252)' : 'transparent',
              cursor: 'pointer', // Buton gibi davranması için cursor'u pointer olarak ayarlıyoruz
              // borderRadius:"5px",
              borderBottom: isDark ? "none" : "1px solid #EDF2F8",
              paddingBottom: '4px',
              paddingTop: "0px",
              paddingRight:"10px",
              paddingLeft:"10px", 
              // color: (isDark && activeButton === 'today') ? "black":"#D8E2EF"
              color: isDark ? (activeButton === 'today' ? 'black' : '#D8E2EF') : (activeButton === 'today' ? '#025098' : '#025098')
            }}
          >
            Bugün
          </div>
          <div 
            onClick={handleThisWeekClick} 
            style={{ 
              backgroundColor: activeButton === 'week' ? 'rgb(232,240,252)' : 'transparent',
              cursor: 'pointer', // Buton gibi davranması için cursor'u pointer olarak ayarlıyoruz
              // borderRadius:"5px",
              borderBottom: isDark ? "none" : "1px solid #EDF2F8",
              borderRight: isDark ? "none" : "1px solid #EDF2F8",
              paddingBottom: '4px',
              paddingTop: "0px",
              paddingRight:"10px",
              paddingLeft:"10px", 
              // color: (isDark && activeButton === 'week') ? "black":"#D8E2EF"
              color: isDark ? (activeButton === 'week' ? 'black' : '#D8E2EF') : (activeButton === 'week' ? '#025098' : '#025098')
  
            }}
          >
            Bu hafta
          </div>
        
          <div 
            onClick={handleThisMonthClick} 
            style={{ 
              backgroundColor: activeButton === 'month' ? 'rgb(232,240,252)' : 'transparent',
              paddingBottom: '4px',
              paddingTop: "0px",
              paddingRight:"10px",
              paddingLeft:"10px", 
              cursor: 'pointer',
              // borderRadius:"5px",
              borderBottom: isDark ? "none" : "1px solid #EDF2F8",
              borderRight: isDark ? "none" : "1px solid #EDF2F8",
              // color: (isDark && activeButton === 'month') ? "black":"#D8E2EF"
              color: isDark ? (activeButton === 'month' ? 'black' : '#D8E2EF') : (activeButton === 'month' ? '#025098' : '#025098')
         
            }}
          >
            Bu ay
          </div>
        </div>
        </div>
        <Card  style={{backgroundColor:isDark ? "#0B1727" : "#F6FAFF", marginBottom:"20px", marginLeft:"20px", marginRight:"20px", paddingTop:"20px"}}>
        <Card.Body >
          <Row className="g-5" style={{textAlign:'center', alginItem:'center', justifyContent:'center', marginLeft:'1px'}}>
            {data.map((stat, index) => (
              <EcomStatItem
                key={stat.title}
                stat={stat}
                index={index}
                lastIndex={data.length - 1}
            
              />
            ))}
          </Row>
        </Card.Body>
        </Card>
  
     
     <Row className="g-3 mb-3" style={{paddingLeft:'10px', paddingRight:'10px'}}>
         
  
          <Col md={6} xxl={6} style={{marginRight:'-14px'}}>
            <MarketShare data={marketShare} radius={['100%', '87%']} />
          </Col>
          <Col md={6} xxl={6}>
            <CargoShare data={marketShare} radius={['100%', '87%']} />
          </Col>
      
        </Row>
  
        <Row className="g-3 mb-3" style={{paddingLeft:'10px', paddingRight:'10px'}}>
                <Col md={4} xxl={4}>
                <StatisticsCardsRefend />
                </Col>
  
                <Col md={4} xxl={4}>
                    <StatisticsCardsRefendRate/>
                </Col>
                <Col md={4} xxl={4}>
                  <StatisticsCards />
                </Col>
        </Row>
  
  
      </Card>
      </>
    ) : hasPermission('Dashboard Analiz Sayfası Low') ? (
      <>
      <Card className="py-3 mb-2" >
  
         <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <h6 className="mb-0 mt-2" style={{marginLeft:"20px", color: isDark ? "#D8E2EF":"#05276D", fontSize:'15px'}}>
           Performans Özeti
            </h6>
            </div>
          <div className="mt-2" style={{ display: 'flex', justifyContent: 'end', marginBottom:"30px", marginRight:"20px", fontSize:"13px" }}>
          <div 
            onClick={handleTodayClick} 
            style={{ 
              backgroundColor: activeButton === 'today' ? 'rgb(232,240,252)' : 'transparent',
              cursor: 'pointer', // Buton gibi davranması için cursor'u pointer olarak ayarlıyoruz
              // borderRadius:"5px",
              borderBottom: isDark ? "none" : "1px solid #EDF2F8",
              paddingBottom: '4px',
              paddingTop: "0px",
              paddingRight:"10px",
              paddingLeft:"10px", 
              // color: (isDark && activeButton === 'today') ? "black":"#D8E2EF"
              color: isDark ? (activeButton === 'today' ? 'black' : '#D8E2EF') : (activeButton === 'today' ? '#025098' : '#025098')
            }}
          >
            Bugün
          </div>
          <div 
            onClick={handleThisWeekClick} 
            style={{ 
              backgroundColor: activeButton === 'week' ? 'rgb(232,240,252)' : 'transparent',
              cursor: 'pointer', // Buton gibi davranması için cursor'u pointer olarak ayarlıyoruz
              // borderRadius:"5px",
              borderBottom: isDark ? "none" : "1px solid #EDF2F8",
              borderRight: isDark ? "none" : "1px solid #EDF2F8",
              paddingBottom: '4px',
              paddingTop: "0px",
              paddingRight:"10px",
              paddingLeft:"10px", 
              // color: (isDark && activeButton === 'week') ? "black":"#D8E2EF"
              color: isDark ? (activeButton === 'week' ? 'black' : '#D8E2EF') : (activeButton === 'week' ? '#025098' : '#025098')
  
            }}
          >
            Bu hafta
          </div>
        
          <div 
            onClick={handleThisMonthClick} 
            style={{ 
              backgroundColor: activeButton === 'month' ? 'rgb(232,240,252)' : 'transparent',
              paddingBottom: '4px',
              paddingTop: "0px",
              paddingRight:"10px",
              paddingLeft:"10px", 
              cursor: 'pointer',
              // borderRadius:"5px",
              borderBottom: isDark ? "none" : "1px solid #EDF2F8",
              borderRight: isDark ? "none" : "1px solid #EDF2F8",
              // color: (isDark && activeButton === 'month') ? "black":"#D8E2EF"
              color: isDark ? (activeButton === 'month' ? 'black' : '#D8E2EF') : (activeButton === 'month' ? '#025098' : '#025098')
         
            }}
          >
            Bu ay
          </div>
        </div>
        </div>
        <Card  style={{backgroundColor:isDark ? "#0B1727" : "#F6FAFF", marginBottom:"20px", marginLeft:"20px", marginRight:"20px", paddingTop:"20px"}}>
        <Card.Body >
          <Row className="g-5" style={{textAlign:'center', alginItem:'center', justifyContent:'center', marginLeft:'1px'}}>
            {data.map((stat, index) => (
              <EcomStatItem
                key={stat.title}
                stat={stat}
                index={index}
                lastIndex={data.length - 1}
            
              />
            ))}
          </Row>
        </Card.Body>
        </Card>
 
          <Row className="g-3 mb-3" style={{paddingLeft:'10px', paddingRight:'10px'}}>
                <Col md={6} xxl={6}>
                <StatisticsCardsRefend />
                </Col>
                <Col md={6} xxl={6}>
              <StatisticsCards />
            </Col>
  
        </Row>
  
  
      </Card>
      </>
    ) : null
  );
};

EcomStat.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default EcomStat;