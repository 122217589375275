import IconButton from 'components/common/IconButton';
import { PermsContext } from 'context/PermsContext';
import useOrderList from 'hooks/useOrderList';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const OrdersTableHeader = ({ selectedRowIds }) => {

  const navigate = useNavigate()

  const {perms} = useContext(PermsContext)



  function hasPermission(permission) {
    return perms?.includes(permission);
  }

  return (
    <Row className="flex-between-center">
      

      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        <div style={{display:"flex"}}>
          <div id="orders-actions" style={{marginRight:"10px"}}>     
             {/* <Form.Select size="sm" onChange={handleChange}>
    <option value="" disabled selected>Sipariş Durumu</option>
    {orderStatus?.map((item, i)=>{
        return <option key={i} value={item.value}>{item.label}</option>
    })}
          </Form.Select>  */}
          </div>

          {hasPermission("Can add Sipariş") && (
            <div id="orders-actions" style={{marginRight:"8px"}} >  
          <IconButton
              variant="falcon-default"
              icon="plus"
              size="m"
              transform="shrink-3"
              onClick = {()=>navigate("/e-commerce/orders/order-add")}
              style={{width:'141px',  fontSize:'15px'}}
            >
              <span className="d-none d-sm-inline-block ms-1">Ekle</span>
            </IconButton>
            </div>
          )}
    </div>
      </Col>
    </Row>
  );
};

OrdersTableHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default OrdersTableHeader;
