import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from '../baseUrl'
import { toast } from 'react-toastify';
import {  format } from 'date-fns';



export default function useOrderFeeCalculation() {

  const [myDataOrder, setMyDataOrder] = useState()
  const {myKey} = useContext(AuthContext)
  
  

  const [orderVendorId,setOrderVendorId] = useState()
 

  const now = new Date();
  const utcFirstDayOfMonth = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
  const [dateOfOrder, setDateOfOrder] = useState(utcFirstDayOfMonth);
  
  let formattedStartDate = "";
  
  if (dateOfOrder) {
    formattedStartDate = format(dateOfOrder, 'yyyy-MM-dd');
  }
 
 
 const getData = ()=>{
        var myHeaders = new Headers();

        if (!dateOfOrder || !orderVendorId) {
          return;
        }


        const params = {
          
          date_of_month: formattedStartDate,
          
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/vendor/${orderVendorId}/order_fee_calculation/?`
    );

   
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result => {
            setMyDataOrder(result);
            console.log("results", result);
            console.log("results_data", myDataOrder);

          })
          .catch(error => {
            console.error('error', error);
            toast.error(`Error: ${error.message}`); 
          });
  };

 



 useEffect(() => {
    getData();
  }, [dateOfOrder, orderVendorId ]);

 return {myDataOrder, getData, dateOfOrder, setDateOfOrder,  setOrderVendorId, orderVendorId }
  
  
  
 
}
