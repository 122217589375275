import React, { useContext, useEffect, useState } from 'react'
import { Form, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from  "react-datepicker";
import useCampaingAdd from 'hooks/useCampaingAdd';
import useVendorStore from 'hooks/useVendorStore';
import { useNavigate } from 'react-router-dom';
import AppContext from 'context/Context';




const CampaingAddPage = () => {


  const {formData, setFormData, postData} = useCampaingAdd()
  const {vendorStore} = useVendorStore()

  const [campaingName, setCampaingName] = useState()
  const [vendorStoreId, setVendorStoreId] = useState()
  const [discount, setDiscount] = useState()
  const [date, setDate] = useState();
  const [endDate, setEndDate] = useState();

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);


  const vendor_store = vendorStore?.map((item)=>{
    const label = `${item.Vendor__Name}-${item.Store__Name} `;
    return{
        value: item.Id,
        label: label
    }
})

const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,  "Name": campaingName,
                    "DiscountAmount": discount,
                    "EndDate": endDate,
                    "StartDate": date,
                    "VendorStore" : vendorStoreId.value
      })  


setCampaingName(null);
setDiscount(null);
setDate("");
setEndDate("");
setVendorStoreId("")
e.target.reset();

}


  return (
    <>
     <Link to="/dashboard/analytics/campaing/list">
      <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      </Link>
    <Card className="mb-3 mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <div className="mb-3 mt-3" style={{width:"95%"}}>
      <h6 >Kampanya Tahminleme Modeli</h6>
      <hr />
      </div>
    <Form.Group style={{ border: "0px solid gray",  padding:"10px", borderRadius:"10px", width:"80%" }}>
        
    <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formDepo" className="mb-3"  >
            <Form.Label>Mağaza</Form.Label>
            <Select isSearchable={true} placeholder="mağaza" 
            onChange={setVendorStoreId} value={vendorStoreId} options={vendor_store}
            styles={{control: (baseStyles) =>({
              ...baseStyles, 
              backgroundColor:isDark ? "#0B1727" : "white"
            }),}}
            />
        </Form.Group>

        <div style={{display:'flex', flexWrap:'nowrap'}}>
    
    <div  style={{marginRight:'10px', width:'50%'}}>
    <Form.Label>Kampanya İsmi</Form.Label>
    <Form.Control type="text" placeholder="kampanya ismi"  onChange={(e)=>setCampaingName(e.target.value)}/>

    </div>
    
    <div  style={{marginRight:'10px', width:'50%'}}>
    <Form.Label>Kampanya Yüzdesi</Form.Label>
    <Form.Control type="text" placeholder="15 gibi.."  onChange={(e)=>setDiscount(e.target.value)}/>
    </div>

</div>


        <Form.Label style={{marginTop:"15px"}}>Kampanya Tarihleri</Form.Label>
<div style={{display:'flex', flexWrap:'nowrap'}}>

    <div  style={{marginRight:'10px', width:'50%'}}>
          <DatePicker
              selected={date}
              onChange={(date)=>setDate(date)}
              formatWeekDay={day => day.slice(0, 3)}
              className='form-control'
              placeholderText="başlama tarihi"
          />
    </div>

    <div  style={{marginRight:'10px', width:'50%'}}>
          <DatePicker
              selected={endDate}
              onChange={(date)=>setEndDate(date)}
              formatWeekDay={day => day.slice(0, 3)}
              className='form-control'
              placeholderText="bitiş tarihi"
          />
    </div>

</div>

  <Button variant="primary" type="submit" className="d-flex mx-auto mt-5 mb-2" >
    Onayla
  </Button>
</Form>
</Form.Group>
</Card>
</>
  )
}

export default CampaingAddPage