import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, InputGroup, Row, Col, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import PaginationProductAll from './PaginationProductAll';
import ProductTableHeaderAll from './ProductTableHeaderAll';
import useProductListAll from 'hooks/useProductListAll';
import Button from 'react-bootstrap/Button';
import { Search } from 'react-bootstrap-icons';
import gif from 'assets/img/gif_export_s3.gif'
import useVendorList from 'hooks/useVendorList';
import useBestSellerProductTable from 'hooks/useBestSellerProductTable';
import DatePicker from  "react-datepicker";
import IconButton from 'components/common/IconButton';
import gifOrder from 'assets/img/gif_order.gif'
import Modal from 'react-bootstrap/Modal';
import useBestSellerProductExportReportPage from 'hooks/useBestSellerProductExportReportPage';
import { PermsContext } from 'context/PermsContext';




const BestSellerProduct = () => {
  const { myData, currentPage, setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, isSwitchedOn, setIsSwitchedOn, startDate, endDate, setStartDate, setEndDate } = useBestSellerProductTable();

  const { startDateModal, setStartDateModal, endDateModal, setEndDateModal, getDataExport, showGif, setShowGif} = useBestSellerProductExportReportPage()

  const {vendorIdContext} = useContext(PermsContext)


  const [activePage, setActivePage] = useState(1);
  const [storeColumns, setStoreColumns] = useState([]);

  useEffect(() => {
    if (myData?.results?.length > 0) {
      const allStores = myData?.results.reduce((acc, item) => {
        item.stores.forEach(store => {
          if (!acc.includes(store.store)) {
            acc.push(store.store);
          }
        });
        return acc;
      }, []);

      // const totalPrices = myData.reduce((sum, item) => sum + parseFloat(item.total_price), 0);
      // const totalCounts = myData.reduce((sum, item) => sum + item.total_count, 0);

      const dynamicColumns = allStores.map(storeName => ({
        accessor: storeName,
        Header: `${storeName} ${isSwitchedOn ? '(₺)' : '(adet)'}`,
        disableSortBy: true,
        headerProps: {
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center'
        },
        Cell: ({ row }) => {
          const store = row.original.stores.find(s => s.store === storeName);
          if (!store) return 0;

        
          const total_price = row.original.price_2
          const total_count = row.original.stock || 0;
      
          if (isSwitchedOn) {
            const pricePercentage = ((store.Price / total_price) * 100).toFixed(2);
            return (
              <>
                <span>{store.Price.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}</span> - <span style={{fontWeight:'bold'}}>{pricePercentage}%</span>
              </>
            )
            
          } else {
            const countPercentage = ((store.Count / total_count) * 100).toFixed(2);
            console.log("countPercentage", countPercentage)
            return (
              <>
                <span>{store.Count}</span> - <span style={{fontWeight:'bold'}}>{countPercentage}%</span>
              </>
            )
          }
        }
      }));

      setStoreColumns(dynamicColumns);
    }
  }, [myData, isSwitchedOn]);

  const columns = [
    {
      Header: 'Ürün',
      disableSortBy: true,
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { image } = rowData.row.original;
        return (
          <>
            <img
              style={{ width: "40px", maxWidth: "100%", transition: "transform 0.5s" }}
              src={image}
              alt=""
              onMouseOver={(e) => e.target.style.transform = "scale(1.2)"}
              onMouseOut={(e) => e.target.style.transform = "scale(1)"}
            />
          </>
        );
      }
    },
    {
      accessor: 'title',
      disableSortBy: true,
      Header: <span style={{ marginLeft: "15px" }}>İsim</span>,
      Cell: rowData => {
        const { title } = rowData.row.original;
        return (
          <td style={{ color: "#637386" }} title={title}>
            {title ? (title.length > 60 ? title.slice(0, 60).concat("...") : title) : ""}
          </td>
        );
      }
    },
    {
      accessor: 'sku',
      Header: 'SKU',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.sku}`
    },
    {
      accessor: 'price',
      Header: isSwitchedOn ? 'Toplam Satış' : 'Toplam Adet',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
       Cell: rowData => isSwitchedOn ? `${rowData.row.original.price}` : `${rowData.row.original.stock}`
    },
    {
      accessor: 'price-percent',
      Header: isSwitchedOn ? 'Toplam Satış(%)' : 'Toplam Adet(%)',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => isSwitchedOn ? 
        <span style={{ fontWeight: 'bold' }}>
          {((rowData.row.original.price / rowData.row.original.all_products_price) * 100).toFixed(2)}
        %</span> : 
        <span style={{ fontWeight: 'bold' }}>
          {((rowData.row.original.stock / rowData.row.original.all_products_count) * 100).toFixed(2)}
        %</span>
  
    }

   ,
    ...storeColumns 
  ];



  const orderList = myData?.results?.map((item) => {
    const priceInTL = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(item.total_price);

   const all_products_count = myData?.all_products_count
   const all_products_price = myData?.all_products_price


    const storesData = item.stores?.reduce((acc, store) => {
      acc[store.store] = store.Count;
      return acc;
    }, {});

    return {
      id: item.SKU,
      title: item.Name,
      sku: item.SKU,
      stock: Number(item.total_count) || 0,
      image: item.image,
      price: item.total_price ? priceInTL : 0,
      price_2: item.total_price ? parseFloat(item.total_price) : 0,
      stores: item.stores, 
      all_products_count : all_products_count,
      all_products_price : all_products_price,
      ...storesData 
    };
  }) || [];



  const handleSwitchChange = () => {
    setIsSwitchedOn(!isSwitchedOn);
  };


  const handleChangeDate = event => {
    setSelectedOption(event.target.value);
  
    let start, end;
  
    switch(event.target.value) {
      case "1": // Bugün
        start = new Date();
        end = new Date();
        break;
  
      case "2": // Bu hafta
        end = new Date();
        start = new Date();
        start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
        break;   
  
      case "3": // Bu ay
        end = new Date();
        start = new Date();
        start.setDate(1); // Ayın ilk günü
        break;
    
      default:
        // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
        break;
    }
  
    setStartDate(start);
    setEndDate(end);
  
   
  };
  
  const [selectedOption, setSelectedOption] = useState(null);
  
  
  useEffect(() => {
    if (selectedOption === "4") {
      setIsOpenDate(true);
    } else {
      setIsOpenDate(false);
    }
  }, [selectedOption]);
  
  
  const [isOpenDate, setIsOpenDate] = useState(true);
  
  const onChange = dates => {
  
    const [start, end] = dates;
  
    console.log("start", start);
    console.log("end", end);
  
  
    if (selectedOption === "4") {
      setStartDate(start);
      setEndDate(end);
  
   
    }
    if (start && end) {
      setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
    }
  };

  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleExport = ()=> {
    if (!vendorIdContext) {
      return
    }

    getDataExport(vendorIdContext); 
    setShowGif(true);
    setShow(false)
  
  
  }



  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList}
      selection
      sortable
      pagination
      perPage={50}
    >
      <Card className="mb-3">
        <Card.Header>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <ProductTableHeaderAll table />
            </div>
            <div style={{ display: "flex", justifyContent: 'right' }}>
              <div className="mb-3" style={{ marginRight: "8px" }}>
                <div className="mb-2" style={{marginTop:'10px'}}>
                    {/* <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                    <option value="" disabled selected>Tarih</option>
                    <option key={1} value="1">Bugün</option>
                    <option key={2} value="2">Bu hafta</option>
                    <option key={2} value="3">Bu ay</option>
                    <option key={3} value="4">Tarih seç</option>
                                </Form.Select> 

                    {selectedOption === "4" && 
                    <DatePicker
                      showIcon
                      selected={startDate}
                      onChange={onChange}
                      startDate={startDate}
                      formatWeekDay={day => day.slice(0, 3)}
                      endDate={endDate}
                      selectsRange
                      dateFormat="MMM-dd"
                      className='form-control'
                      placeholderText='tarih giriniz'
                      open={isOpenDate}
                      onCalendarClose={() => setIsOpenDate(false)}
                      onCalendarOpen={() => setIsOpenDate(true)}
                    />
                    } */}
                  </div>
                  <div style={{marginRight:"8px"}}>
                  <IconButton
                        variant="falcon-default"
                        size="m"
                        icon="external-link-alt"
                        transform="shrink-3"
                        onClick={handleShow}
                      >
                        <span className="d-none d-sm-inline-block ms-1">Export</span>
                      </IconButton>
                      </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <div>
            <Form.Check 
                type="switch"
                id="custom-switch"
                label="Satış Tutarı"
                checked={isSwitchedOn}
                onChange={handleSwitchChange}
                className="float-end"
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />

      <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showGif && <img src={gifOrder} style={{width:'50%'}} alt="export gif" />}
          </div>
        </Card.Body>
        <Card.Footer>
          <PaginationProductAll
            pages={totalPages}
            activePage={activePage}
            setActivePage={setActivePage}
            setCurrentPage={setCurrentPage}
          />
        </Card.Footer>
      </Card>

      <Modal 
        show={show} 
        onHide={handleClose}
        >
        <Modal.Header closeButton>
          <Modal.Title>Çok Satan Ürün Listesi</Modal.Title>
            </Modal.Header>
              <Modal.Body>
              Çok Satan Ürün Listesi
          <div style={{display:'flex', justifyContent:'center'}} >
              {/* <div style={{marginRight:'8px'}}>
                { myDataVendor?.length> 1 &&    
                   < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeVendorModal}>
                    <option value="">Satıcı</option>
                       { myDataVendor?.map((item, i)=>{
                             return <option key={i} value={item.Id}>{item.vendor_name}</option>
                })}       
                  </Form.Select>}
              </div> */}
                  {/* <div style={{marginRight:"8px", fontSize:"14px"}}>
                        <DatePicker
                        showIcon
                        selected={startDateModal}
                        onChange={onChangeModal}
                        startDate={startDateModal}
                        formatWeekDay={day => day.slice(0, 3)}
                        endDate={endDateModal}
                        selectsRange
                        dateFormat="MMM dd"
                        className='form-control'
                        placeholderText='tarih giriniz'
                        />
                  </div> */}

          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Vazgeç
            </Button>
            <Button variant="primary" onClick={handleExport}>
              İndir
            </Button>
          </Modal.Footer>
      </Modal> 








      <span style={{ marginRight: "5px", marginLeft: "5px" }}>Ürün sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{ marginLeft: "5px" }}></span>
    </AdvanceTableWrapper>
  );
};

export default BestSellerProduct;