import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, Col, InputGroup, Row, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Pagination from './Pagination';
import DatePicker from  "react-datepicker";
import useShipmentCompany from 'hooks/useShipmentCompany';
import Accordion from 'react-bootstrap/Accordion';
import useOrderReturned from 'hooks/useOrderReturned';
import { Search } from 'react-bootstrap-icons';
import IconButton from 'components/common/IconButton';
import gifOrder from 'assets/img/gif_order.gif'
import gif from 'assets/img/gif_export_s3.gif'
import useOrderRefund from 'hooks/useOrderRefund';
import useOrderRefundList from 'hooks/useOrderRefundList';
import Button from 'react-bootstrap/Button';
import { PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import Modal from 'react-bootstrap/Modal';
import useRefundExport from 'hooks/useRefundExport';
import useStorageList from 'hooks/Costs/useStorageList';
import useInventoryListFilter from 'hooks/useInventoryListFilter';
import useStoragFeesExport from 'hooks/Costs/useStoragFeesExport';
import useInventoryRegion from 'hooks/useInventoryRegion';





const StorageFees = () => {

  const columns = [
    
    {
      accessor: 'id',
      Header: 'Id',
      disableSortBy: true,
      headerProps: { className: 'text-left'},
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { id, inventory_id , daily_storage} = rowData.row.original;
        return (
          <>
           <Link to="/costs-storage-detail" state={{ id, inventory_id, daily_storage }}>
              <p>{"#"+id}</p>
           </Link>
          </>
        );
      }
    },
    {
      accessor: 'name',
      Header: 'Depo',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { inventory_name } = rowData.row.original;
        return (
          <>
              <p>{inventory_name}</p>
          </>
        );
      }
    },
    {
      accessor: 'begin_date',
      Header: 'Başlangıç Tarihi',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { begin_date } = rowData.row.original;
        return (
          <>
              <p>{begin_date}</p>
          </>
        );
      }
    },
    {
      accessor: 'end_date',
      Header: 'Bitiş Tarihi',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { end_date } = rowData.row.original;
        return (
          <>
            <p>{end_date}</p>
          </>
        );
      }
    },
    
    {
      accessor: 'cargo',
      Header: 'Birim Desi Fiyatı',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { StorageFeePerDesi} = rowData.row.original;
        return (
          <>
              {StorageFeePerDesi}
          </>
        );
      }
    },
    {
      accessor: 'amount',
      Header: 'Toplam Desi (günlük)',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.TotalVolumeByDailySum}`
    },
    {
      accessor: 'status',
      Header: 'Toplam Maliyet',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.total}`
    },
    
  ];

const {myData, setPage, startDate, setStartDate, endDate, setEndDate, setCargo, firstName, setSearch, showOrderGif, currentPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, setVendorId, setStatusRefund, setInventoryRegion, inventoryRegion} = useStorageList()



const {getDataExport, showGif, setShowGif, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorIdExport,setInventoryExportId, startDateModal, setStartDateModal, endDateModal, setEndDateModal, setSkuCurrentModal, skuCurrentModal } = useStoragFeesExport()


  const {shipmentCompany} = useShipmentCompany()
  const [activePage, setActivePage] = useState(1)
  const [orderList, setOrderList] = useState([]) 
  const {perms} = useContext(PermsContext)
  const {myDataVendor} = useVendorList()
  const {regionData} = useInventoryRegion()


  const {myDataRegion, setVendorIdRegion} = useInventoryListFilter()



  function hasPermission(permission) {
    return perms?.includes(permission);
  }


  const handleChangeRegion = (e)=> {
    console.log(e)
    setInventoryRegion(e.target.value); 
  }



  useEffect(() => {
    if (myDataRegion && myDataRegion.length > 0) {
      const firstVendorId = myDataRegion[0].Id;
      setInventoryRegion(firstVendorId)
    }
  }, [myDataRegion]);




  const [isOpen, setIsOpen] = useState(false);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };


  const handleChangeRegionExport = (e)=> {
    const selectedId = e.target.value;
    setInventoryExportId(e.target.value)
  
  
    const selectedItem = regionData.find(item => item.Id.toString() === selectedId);
    if (selectedItem) {
      setVendorIdExport(selectedItem.Vendor);
      setVendorIdBarcode(selectedItem.Vendor)
    }
  
  }




// modal

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);


const handleExport = ()=> {
  getDataExport(); 
  setShowGif(true);

  setIsPreparing(true);



  setStartDateModal(null);
  setEndDateModal(null);
}

const onChangeModal = datesExport => {
  const [startExport, endExport] = datesExport;
  setStartDateModal(startExport);
  setEndDateModal(endExport);
};




console.log("myData", myData)

useEffect(() => {

  const regionList = myData?.length > 0 && myData?.map(item => {

    const StorageFeePerDesiInTL = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(item.StorageFeePerDesi);
 
    const totalInTL = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(item.TotalStorageFee);
   
    return {
      id : item.Id,
      inventory_name: item.inventory_name || "-", 
      begin_date: item.BeginDate,
      end_date : item.EndDate,
      StorageFeePerDesi : StorageFeePerDesiInTL ,
      TotalVolumeByDailySum : item.TotalVolumeByDailySum || "-",
      total : totalInTL,
      sku_count : item.SkuStorageFees?.length,
      inventory_id : item.Inventory,
      daily_storage : item.DailyStorageFees
    }
  }) || [];

  setOrderList(regionList)

}, [myData, inventoryRegion])




useEffect(() => {
  if (myDataVendor && myDataVendor.length > 0) {
    const firstVendorId = myDataVendor[0].Id;
    
    setVendorId(firstVendorId)
    setVendorIdRegion(firstVendorId)
  }
}, [myDataVendor]);

const handleDownload = () => {
  setIsPreparing(false);
  window.location.href = myDataResult[0]?.Value;
  setShow(false)
  setMyDataResult(false)
  setStartDateModal("")
  setEndDateModal("")
}



const handleChangeVendor = (e)=> {
  setVendorId(e.target.value)
  setVendorIdRegion(e.target.value);
}




const [selectedOption, setSelectedOption] = useState(null);

const handleChangeDate = event => {
  setSelectedOption(event.target.value);

  let start, end;

  switch(event.target.value) {
    case "1": // Bugün
      start = new Date();
      end = new Date();
      break;

    case "2": // Bu hafta
      end = new Date();
      start = new Date();
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
      break;   

    case "3": // Bu ay
      end = new Date();
      start = new Date();
      start.setDate(1);
      break;
  
    default:
      break;
  }

  setStartDate(start);
  setEndDate(end);
};

const [isOpenDate, setIsOpenDate] = useState(false);

useEffect(() => {
  if (selectedOption === "4") {
    setIsOpenDate(true);
  } else {
    setIsOpenDate(false);
  }
}, [selectedOption]);

const onChange = dates => {

  const [start, end] = dates;

  if (selectedOption === "4") {
    setStartDate(start);
    setEndDate(end);
  }
  if (start && end) {
    setIsOpenDate(false); 
  }
};
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      selection
      sortable
      pagination
      perPage={50}
    >
      <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showOrderGif && <img src={gifOrder} style={{width:'50%'}} alt="loading gif" />}
          </div>

          <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showGif && <img src={gifOrder} style={{width:'50%'}} alt="export gif" />}
          </div>
     
      <Card className="mb-3">
        <Card.Header>

      <div style={{display:"flex", justifyContent:"space-between"}}>  
        <div>
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Depolama Maliyetleri</h5>
        </div>

        <div style={{display:"flex", justifyContent:'right'}}>

            {/* <div className="mb-3" style={{marginRight:"10px"}}>
                  <InputGroup>
                    <Form.Control  type="text" placeholder="isim, numarası.."  onChange={(e)=>setSearch(e.target.value)}/>
                    <InputGroup.Text><Search/></InputGroup.Text>
              </InputGroup>
                  </div> */}

                  { hasPermission('Dashboard Sipariş Export') ?
                  <div style={{marginRight:"8px"}}>
                  <IconButton
                        variant="falcon-default"
                        size="m"
                        icon="external-link-alt"
                        transform="shrink-3"
                        onClick={handleShow}
                      >
                        <span className="d-none d-sm-inline-block ms-1">Export</span>
                      </IconButton>
                      </div>
                        : ("") }
   
        <Dropdown  show={isOpen} onToggle={handleToggle}>
        <Dropdown.Toggle
           bsPrefix="toggle"
           as={Link}
          //  to=""
           className={classNames('px-0 nav-link')}>
     
            <IconButton
              variant="falcon-default"
              size="m"
              icon="fa-sharp fa-light fa-filter-list"
              transform="shrink-3"
              style={{width:'145px', fontSize:'15px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
              </svg>
                  <span className="d-none d-sm-inline-block ms-1">Filtre</span>
            </IconButton>

        </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret" style={{minWidth: '300px', width: '300px'}}>

          <Card className="shadow-none shadow-show-xl scrollbar" style={{height:'450px'}}>
            <Card.Header className="bg-light d-none d-xl-block">
              <h6 className="mb-0">Filtre</h6>
            </Card.Header>
              <Card.Body>
                <Form>
                  <div className="mb-2">
                    <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Tarih Seçimi</Form.Label>


                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                  <option value="" disabled selected>Tarih</option>
                  <option key={1} value="1">Bugün</option>
                  <option key={2} value="2">Bu hafta</option>
                  <option key={2} value="3">Bu ay</option>
                  <option key={3} value="4">Tarih seç</option>
                              </Form.Select> 

                {selectedOption === "4" && 
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    endDate={endDate}
                    selectsRange
                    dateFormat="MMM dd"
                    className='form-control'
                    placeholderText='tarih giriniz'
                    open={isOpenDate}
                    onCalendarClose={() => setIsOpenDate(false)}
                    onCalendarOpen={() => setIsOpenDate(true)}
                  />
                }
                  </div>

                  { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                  <div style={{marginBottom:'10px'}}>
                  <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Satıcı</Form.Label>
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                     <option value="" disabled>Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
            : "" }

                  <div className="mb-3">
                    <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Depolar</Form.Label>
                    { myDataRegion?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeRegion}>
                            <option value="" >Depo</option>
                                { myDataRegion?.map((item, i)=>{
                                      return <option key={i} value={item.Id}>{item.Region__Name}</option>
                                })}       
                        </Form.Select>}
                  </div>

              </Form>
            </Card.Body>
        </Card>
      </Dropdown.Menu>
      </Dropdown>
      </div>
      </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <Pagination pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage}/>
        </Card.Footer>
      </Card>


      <Modal 
      show={show} 
      onHide={handleClose}
  
      >
        <Modal.Header closeButton>
          <Modal.Title>Depolama Maliyeti Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Lütfen depo seçimi yaparak ve tarih girilerek listesini indiriniz.</p>
       <div style={{}}>
              { regionData?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} 
                    onChange={handleChangeRegionExport}
                    >
                      <option value="">Depo</option>
                        {regionData?.map((item, i)=>{
                            return <option key={i} value={item.Id}>{item.name}</option>
                        })}       
                </Form.Select>}
            </div>

            <div style={{ fontSize:"14px", marginTop:'10px'}}>
                <DatePicker
                showIcon
                selected={startDateModal}
                onChange={onChangeModal}
                startDate={startDateModal}
                formatWeekDay={day => day.slice(0, 3)}
                endDate={endDateModal}
                selectsRange
                dateFormat="MMM dd"
                className='form-control'
                placeholderText='aktivite tarihi'
                />
            </div>


          </Modal.Body>
        <Modal.Footer>
        <Row style={{width:'100%'}}>      
  <Row>

  
    <p>Listeniz hazırlanıyor yazısından sonra tekrar Hazırla butonuna basmayınız.</p>


    <Col className="d-flex justify-content-end mb-3">
      <Button variant="secondary" onClick={handleClose} style={{marginRight:'10px'}}>
        Vazgeç
      </Button>

      {!isPreparing && (
      <Button variant="primary" onClick={handleExport}>
        Hazırla
      </Button>
      )}
    </Col>
  </Row>
  {isPreparing && (!myDataResult || !myDataResult.error) && 
  
  <div>
  <div>listeniz hazırlanıyor, lütfen bekleyin...</div>

  <img src={gif} style={{width:'50%'}} alt="export gif" />
  </div>
  
  }
  {myDataResult && myDataResult[0]?.Value?.length > 0  && 
  <Row className="justify-content-center mt-5" style={{width:'100%'}}>
    <Col style={{display: "flex", justifyContent: "center"}}>
    <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding:"10px", fontSize:"13px"}}>

        {/* {myDataS3?.message} */}
      Listesiniz hazır indirebilirsiniz.
      </Alert>
      <Button 
  variant="primary" 
  style={{width:'150px', height:'40px'}}
  // onClick={() => window.location.href = myDataS3?.download_url}
  onClick={handleDownload}
>
  İndir
</Button>

    </Col>
  </Row>

  }
   </Row>
        </Modal.Footer>
      </Modal> 


      <Button style={{fontSize:'12px'}} variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button style={{fontSize:'12px'}} variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button style={{fontSize:'12px'}} variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
    </AdvanceTableWrapper>
  );
};

export default StorageFees;





