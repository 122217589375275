import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row, Table } from 'react-bootstrap';
// import { orderedProductsData } from 'data/ecommerce/OrderDetailsData';
import { useLocation } from 'react-router-dom';
import useInventoryShipmentList from 'hooks/useInventoryShipmentList';

const InventoryProduct = () => {

  const location = useLocation()
  const {id_nu} = location.state || ""
  const {detail, getDetail} = useInventoryShipmentList()

  useEffect(() => {
    if(id_nu){
    getDetail(id_nu)
    }
  
  }, [id_nu,comparisonsData ])



  
  function translateStatus(status) {
    switch (status) {
        case 'missing':
            return 'eksik';
        case 'complete':
            return 'tam';
        case 'extra':
            return 'fazla';
        default:
            return status;
    }
}

  const comparisonsData = detail?.Comparisons?.map((product, i) => ({

    id : 1 +i,
    name: product.name,
    quantity: product.declared,
    barcode: product.barcode,
    image: product.image,
    checked : product.checked,
    difference : product.difference,
    status :translateStatus(product.status),
    adjusted_stores : product.adjusted_stores || "-"
  }));

  const inventoryShipmentProductsData = detail?.InventoryShipmentProducts?.map((product, i) => ({

    id : 1 +i,
    name: product.Name,
    quantity: "-",
    barcode: product.Barcode,
    image: product?.product.image,
    checked : product.Count,
    difference : "-",
    status : "-",
    adjusted_stores :  "-"
  }));


  const renderProductRows = (products) => (
    products?.map((product) => (
      <tr key={product.id} className="border-200">
        <td className="align-middle">
          <img style={{ width: "40px" }} src={product?.image} alt="" />
        </td>
        <td className="align-middle">
          <h6 className="mb-0 text-nowrap">{product?.name}</h6>
        </td>
        <td className="align-middle text-center">
          {product?.barcode}
        </td>
        <td className="align-middle text-center">
          {product?.status}
        </td>
        <td className="align-middle text-center">
          {product?.quantity}
        </td>
        <td className="align-middle text-center">
          {product?.adjusted_stores}
        </td>
        <td className="align-middle text-end">
          {product?.checked}
        </td>
      </tr>
    ))
  );


 
  const subtotal = comparisonsData && comparisonsData.length > 0 
  ? comparisonsData.reduce((acc, curr) => acc + (curr.checked || 0), 0)
  : (inventoryShipmentProductsData && inventoryShipmentProductsData.length > 0 
      ? inventoryShipmentProductsData.reduce((acc, curr) => acc + (curr.checked || 0), 0)
      : 0);


 
  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Ürün</th>
                <th className="border-0">İsim</th>
                <th className="border-0 text-center">Barkodu</th>
                <th className="border-0 text-center">Durumu</th>
                <th className="border-0 text-center">Beyan Edilen Adet</th>
                <th className="border-0 text-center">Güncellenen Platformlar</th>
                <th className="border-0 text-end">Gelen Adet</th>
              
              </tr>
            </thead>
              <tbody>
                {comparisonsData?.length > 0 ? renderProductRows(comparisonsData) : renderProductRows(inventoryShipmentProductsData)}
              </tbody>

          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  <tr>
             
                  
                  </tr>
                  <tr>
               
                    <td className="fw-semi-bold">
                      {/* $
                      {parseFloat(tax).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })} */}
                    </td>
                  </tr>
                  <tr className="border-top">
                    <th className="text-900">Toplam:</th>
                    <td className="fw-semi-bold">
                
                      {subtotal}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

// OrderedProducts.propTypes = {};

export default InventoryProduct;
