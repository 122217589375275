import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'




export default function useBestProductsExport() {

  const [myData, setMyData] = useState()
  const {myKey, currentUser} = useContext(AuthContext)

  const [vendorExportId, setVendorExportId] = useState()

  const [showGif, setShowGif] = useState(false);

 


  const todayDay = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const timezoneOffset = todayDay .getTimezoneOffset(); 
  const [startDateModal, setStartDateModal] = useState(new Date(todayDay.getTime() - oneDay * 7));
  const [endDateModal, setEndDateModal] = useState(new Date(todayDay.getTime() + oneDay * 8));




  let formattedStartDate = "";
  let formattedEndDate = "";

  if(startDateModal){
    formattedStartDate = new Date(startDateModal.getTime()).toISOString().slice(0, 10);
  }
  if(endDateModal){
    formattedEndDate = new Date(endDateModal.getTime() + oneDay).toISOString().slice(0, 10);
  }





  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); 
  const year = today.getFullYear();
  const formattedDate = `${day}.${month}.${year}`




 const getDataExport = async ()=>{
      var myHeaders = new Headers();

    const params = {

    
        begin_date: formattedStartDate,
        end_date: formattedEndDate,
        // vendor_username: currentUser,
        vendor_id : vendorExportId

   
      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/product_count_export/?`
  );






    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.download = `Cok satanlar-${currentUser}-${formattedDate}.xlsx`

    const handleSuccess = (response) => {
      response.blob().then((blob) => {
        setShowGif(false); // GIF'in kapatılması
        const url = window.URL.createObjectURL(blob);
        anchor.href = url;
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    };

    const handleError = (error) => {
      console.log("error", error);
    };

    var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Token "+myKey},
            redirect: 'follow'
          }
    fetch(request_url, requestOptions)
    .then(handleSuccess)
    .catch(handleError);
};


 return {myData,setMyData,  startDateModal, setStartDateModal, endDateModal, setEndDateModal, getDataExport, showGif, setShowGif, setVendorExportId}
}