import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from '../baseUrl'
import { toast } from 'react-toastify';
import {  format } from 'date-fns';



export default function useInboundCount() {

  const [myDataInboundCount, setMyDataInboundCount] = useState()
  const {myKey} = useContext(AuthContext)
  
  

  const [inbountCountVendorId,setInbountCountVendorId] = useState()
 

  const now = new Date();
  const utcFirstDayOfMonth = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
  const [dateOfInboundCount, setDateOfInboundCount] = useState(utcFirstDayOfMonth);
  
  let formattedStartDate = "";
  
  if (dateOfInboundCount) {
    formattedStartDate = format(dateOfInboundCount, 'yyyy-MM-dd');
  }
 
 
 const getData = ()=>{
        var myHeaders = new Headers();

        if (!dateOfInboundCount || !inbountCountVendorId) {
          return;
        }


        const params = {
          
          date_of_month: formattedStartDate,
          
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/vendor/${inbountCountVendorId}/inbound_product_count/?`
    );

   
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result => {
            setMyDataInboundCount(result);
           

          })
          .catch(error => {
            toast.error(`Error: ${error.message}`); 
          });
  };

 



 useEffect(() => {
    getData();
  }, [dateOfInboundCount, inbountCountVendorId ]);

 return {myDataInboundCount, setDateOfInboundCount, setInbountCountVendorId }
  
  
  
 
}
