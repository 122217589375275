import React, { useContext, useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import PaginationProductAll from './PaginationProductAll';
import ProductTableHeaderAll from './ProductTableHeaderAll';
import { PermsContext } from 'context/PermsContext';
import useTaskRulusList from 'hooks/useTaskRulusList';





const TaskRuleList = () => {


  const columns = [
    {
      // accessor: 'name',
      Header: 'Id',
      disableSortBy: true,
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { item, id } = rowData.row.original;
        const state = {item, id}
        return (
          <>
    
          <Link 
            to={`/integration/task-rules/detail?id=${state.id}`}
            state={state}>
             <p>{id}</p>
            </Link>
          </>
        );
      }
    },
  
    
    {
      accessor: 'task_name',
      disableSortBy: true,
      Header: 'Task Name',
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left fs-0'
      },
      Cell: rowData => {
        const { task_name } = rowData.row.original;
        return (
          <>
         <p style={{ fontSize: "15px" }} >{ task_name }</p>
        
          </>
        )
      }
    },
    {
      accessor: 'register_name',
      disableSortBy: true,
      Header: 'Task Register Name',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fs-0'
      },
      Cell: rowData => {
        const { task_register_name } = rowData.row.original;
        return (
          <>
         <p style={{ fontSize: "15px" }} >{ task_register_name }</p>
        
          </>
        )
      }
    },
    {
      accessor: 'queue',
      Header: 'Queue',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fs-0'
      },
      Cell: rowData => {
        const { queue } = rowData.row.original;
        return (
          <>
      
         <p style={{ fontSize: "15px" }} >{ queue }</p>
          </>
        )
      }
    }
  ];

const {myData, setPage, currentPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages} = useTaskRulusList()


const {perms} = useContext(PermsContext)

const navigate = useNavigate()



  function hasPermission(permission) {
    return perms?.includes(permission);
  }




const [activePage, setActivePage] = useState(1)

useEffect(() => {

  setPage(activePage)

}, [activePage])




const orderList = myData?.results.map((item)=>{


  return {
    item: item,
    id : item.id,
    task_name: item.task_name, 
    task_register_name : item.task_register_name,
    queue: item.queue,
    

  }
})





  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      selection
      sortable
      pagination
      perPage={50}
    >
      <Card className="mb-3">
        <Card.Header>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <div>
            <ProductTableHeaderAll table />
        </div>
        
    </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
           
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <PaginationProductAll pages={totalPages} activePage={activePage} setActivePage={setActivePage} setCurrentPage={setCurrentPage}/>
        </Card.Footer>
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}></span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>


    </AdvanceTableWrapper>
  );
};

export default TaskRuleList;
