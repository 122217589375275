import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import SimpleBarReact from 'simplebar-react';
import { Link } from 'react-router-dom';
import CardDropdown from 'components/common/CardDropdown';
import { addDays, format, subMonths } from 'date-fns';
import {
    Button,
    Card,
    Col,
    Row,
    Form
  } from 'react-bootstrap';
import useStoreSalesReport from 'hooks/useStoreSalesReport';
import FalconCardHeader from 'components/common/FalconCardHeader';

echarts.use([BarChart, GridComponent, TooltipComponent, TitleComponent, CanvasRenderer]);

function BarMarketChart() {
  const { myData, setStartDate, setEndDate } = useStoreSalesReport();
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    if (Array.isArray(myData) && myData.length > 0) {
      const sorted = [...myData].sort((a, b) => b.total - a.total);
      setSortedData(sorted);
    }
  }, [myData]);

  const storeName = sortedData?.map(item => item.VendorStore__Store__Name);
  const storePrice = sortedData?.map(item => item.total);

  const colors = [
    getColor('primary'), getColor('success'),
    getColor('warning'), getColor('info'), getColor('secondary'),
    getColor('dark'), getColor('primary'), getColor('secondary')
  ];


  const today = new Date();

  const handleDateChange = (event) => {
    const selectedOption = event.target.value;
    if (selectedOption === '7days') {
      setStartDate(format(addDays(today, -7), 'yyyy-MM-dd'));
    } else if (selectedOption === '1month') {
      setStartDate(format(subMonths(today, 1), 'yyyy-MM-dd'));
    }
    setEndDate(format(today, 'yyyy-MM-dd'));
  };

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: (params) => {
        const param = params[0]; 
        return `${param.name}: ${param.value.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}`;
      },
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'category',
      data: storeName,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'solid'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-400'),
        margin: 15
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 15
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 600
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        data: storePrice,
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          color: (params) => colors[params.dataIndex % colors.length],
          borderRadius: [3, 3, 0, 0]
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      }
    ],
    grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
  });

  return (
    <Card className="h-lg-100 overflow-hidden">
       <FalconCardHeader
        title="Mağaza Bazlı Satışlar"
        titleTag="h6"
        className="py-2 mt-2"
        // endEl={<CardDropdown />}
      />
      <Card.Body className="p-0" style={{marginTop:'20px'}}>
        <SimpleBarReact>
          <ReactEChartsCore
            echarts={echarts}
            option={getOption()}
            style={{ height: '18.75rem' }}
          />
        </SimpleBarReact>
      </Card.Body>
      <Card.Footer className="bg-light py-2">
        <Row className="flex-between-center">
          <Col xs="auto">
              <Form.Select size="sm" onChange={handleDateChange}>
                  <option value="7days">Son 7 gün</option>
                  <option value="1month">Son Bir Ay</option>
                </Form.Select>
          </Col>
          <Col xs="auto">
            <Button variant="falcon-default" size="sm" as={Link} to="/store-sales-total-detail">
              Detay
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
}

export default BarMarketChart;
