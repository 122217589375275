import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from '../baseUrl'



export default function useInvoiceList() {

  const {myKey} = useContext(AuthContext)
  const [myData, setMyData] = useState()
  const [page, setPage] = useState(1)
  const [store, setStore] = useState()
  const [cargo, setCargo] = useState()
  const [search, setSearch] = useState()
  const [vendorIdList, setVendorIdList] = useState()
  const [showOrderGif, setShowOrderGif] = useState(false);
  const [currentPage,setCurrentPage]=useState(1)
  const [followersPerPage, setFollowersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [citySelect, setCitySelect] = useState();
  const [reason, setReason] = useState();
  const [status, setStatus] = useState();


 
const date = new Date();
const oneDay = 1000 * 60 * 60 * 24;
const twoDay = 1000 * 60 * 60 * 24 *2;

const [startDate, setStartDate] = useState(new Date(date.getTime() - oneDay * 7));
const [endDate, setEndDate] = useState(new Date(date.getTime() + oneDay * 2));

let formattedStartDate = "";
let formattedEndDate = "";

if(startDate){
  formattedStartDate = new Date(startDate.getTime()  + oneDay).toISOString().slice(0, 10);
}
if(endDate){
  formattedEndDate = new Date(endDate.getTime() + twoDay).toISOString().slice(0, 10);
}


const [isLoading, setIsLoading] = useState(false);



 const getData = ()=>{
 
        var myHeaders = new Headers();
        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;


        const params = {
          offset :offset,
          limit :limit,
          type : 3,
          cargo :cargo,
          begin_date: formattedStartDate,
          end_date: formattedEndDate,
          store : store,
          search : search,
          vendor_id : vendorIdList,
          city: citySelect,
          reason : status,
          reason_product: reason
        };

        
        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/refund/?`
    );

    console.log("url", request_url)
 
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            console.log("result", result)
            setMyData(result) 
            setShowOrderGif(false);
            setIsLoading(false);
            setTotalPages(Math.ceil(result.count / followersPerPage))
    })
          .catch(error => console.log('error', error));        
 }








useEffect(() => {
  console.log("Inside useEffect, isLoading is:", isLoading);  // Debug için
  if (isLoading) {
    console.log("isLoading is true, calling getData");  // Debug için
    getData();
    setIsLoading(false);
  }
}, [isLoading]);


 useEffect(() => {
   
    setIsLoading(true);
    setShowOrderGif(true)
  }, [ currentPage,followersPerPage,page, startDate,endDate, cargo, store, search, vendorIdList, citySelect, reason, status]);




 return {myData, setPage,  getData, setMyData,  startDate, setStartDate, endDate, setEndDate,  cargo, setCargo, setStore, setSearch, showOrderGif, currentPage, setCurrentPage, followersPerPage,setFollowersPerPage,
  totalPages, setVendorIdList, setIsLoading, isLoading, setCitySelect, setReason, setStatus, status}
  
}
