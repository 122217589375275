import React from 'react'
import { Navigate, Outlet } from 'react-router'

const PrivateRouter = () => {

 const username = localStorage.getItem("username")

return username? <Outlet/>:<Navigate to="/"/>

}

export default PrivateRouter