import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, InputGroup, Row, Col, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import PaginationProductAll from './PaginationProductAll';
import ProductTableHeaderAll from './ProductTableHeaderAll';
import useStoreSalesTotalTable from 'hooks/useStoreSalesTotalTable';
import BarMarketChart from '../../BarMarketChart';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import SimpleBarReact from 'simplebar-react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import DatePicker from  "react-datepicker";





echarts.use([BarChart, GridComponent, TooltipComponent, TitleComponent, CanvasRenderer]);


const StoreSalesTotal = () => {
  const { myData = [], startDate, endDate, setStartDate, setEndDate } = useStoreSalesTotalTable();
  const [storeColumns, setStoreColumns] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpenDate, setIsOpenDate] = useState(false);

  useEffect(() => {
    if (Array.isArray(myData) && myData.length > 0) {
      const allStores = myData.map(item => item.VendorStore__Store__Name);

      const dynamicColumns = allStores.map(storeName => ({
        accessor: storeName,
        Header: storeName,
        disableSortBy: true,
        headerProps: {
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center'
        },
        Cell: ({ row }) => {
          const rowData = row.original;
          if (rowData.type === 'count') {
            return rowData[storeName] ? rowData[storeName].count : 0;
          } else if (rowData.type === 'total') {
            return rowData[storeName] ? 
              new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(rowData[storeName].total) 
              : 0;
          }
        }
      }));

      setStoreColumns(dynamicColumns);
    }
  }, [myData]);

  const columns = [
    {
      accessor: 'type',
      Header: 'Veri',
      disableSortBy: true,
      headerProps: { className: 'text-center' },
      cellProps: {
        className: 'text-center py-2'
      },
      Cell: ({ row }) => row.original.type === 'count' ? 'Satış Adedi' : 'Satış Tutarı'
    },
    ...storeColumns
  ];

  const orderList = [
    { type: 'count', ...myData.reduce((acc, item) => {
      acc[item.VendorStore__Store__Name] = { count: item.count };
      return acc;
    }, {}) },
    { type: 'total', ...myData.reduce((acc, item) => {
      acc[item.VendorStore__Store__Name] = { total: item.total };
      return acc;
    }, {}) }
  ];

  const handleChangeDate = event => {
    setSelectedOption(event.target.value);

    let start, end;

    switch(event.target.value) {
      case "1": // Bugün
        start = new Date();
        end = new Date();
        break;
      case "2": // Bu hafta
        end = new Date();
        start = new Date();
        start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
        break;
      case "3": // Bu ay
        end = new Date();
        start = new Date();
        start.setDate(1); // Ayın ilk günü
        break;
      default:
        // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
        break;
    }

    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (selectedOption === "4") {
      setIsOpenDate(true);
    } else {
      setIsOpenDate(false);
    }
  }, [selectedOption]);

  const onChange = dates => {
    const [start, end] = dates;
    if (selectedOption === "4") {
      setStartDate(start);
      setEndDate(end);
    }
    if (start && end) {
      setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
    }
  };


  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    if (Array.isArray(myData) && myData.length > 0) {
      const sorted = [...myData].sort((a, b) => b.total - a.total);
      setSortedData(sorted);
    }
  }, [myData]);

  const storeName = sortedData?.map(item => item.VendorStore__Store__Name);
  const storePrice = sortedData?.map(item => item.total);

  const colors = [
    getColor('primary'), getColor('success'),
    getColor('warning'), getColor('info'), getColor('secondary'),
    getColor('dark'), getColor('primary'), getColor('secondary')
  ];

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: (params) => {
        const param = params[0]; 
        return `${param.name}: ${param.value.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}`;
      },
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'category',
      data: storeName,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'solid'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-400'),
        margin: 15
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 15
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 600
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        data: storePrice,
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          color: (params) => colors[params.dataIndex % colors.length],
          borderRadius: [3, 3, 0, 0]
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      }
    ],
    grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
  });

  return (

    <>
    <Card className="h-lg-100 overflow-hidden" style={{marginBottom:'-10px'}}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
            <FalconCardHeader
            title="Mağaza Bazlı Satışlar"
            titleTag="h6"
            className="py-2 mt-2"
            // endEl={<CardDropdown />}
          />
        </div>
        <div>
            <div className="mb-2 me-2" style={{ marginTop: '10px' }}>
                  <Form.Select className='form-control' style={{ fontSize: "15px" }} onChange={handleChangeDate}>
                    <option value="" disabled selected>Tarih</option>
                    <option key={1} value="1">Bugün</option>
                    <option key={2} value="2">Bu hafta</option>
                    <option key={2} value="3">Bu ay</option>
                    <option key={3} value="4">Tarih seç</option>
                  </Form.Select>
                  {selectedOption === "4" &&
                    <DatePicker
                      showIcon
                      selected={startDate}
                      onChange={onChange}
                      startDate={startDate}
                      formatWeekDay={day => day.slice(0, 3)}
                      endDate={endDate}
                      selectsRange
                      dateFormat="MMM-dd"
                      className='form-control'
                      placeholderText='tarih giriniz'
                      open={isOpenDate}
                      onCalendarClose={() => setIsOpenDate(false)}
                      onCalendarOpen={() => setIsOpenDate(true)}
                    />}
                </div>
        </div>
      </div>
      <Card.Body className="p-0" style={{marginTop:'20px'}}>
        <SimpleBarReact>
          <ReactEChartsCore
            echarts={echarts}
            option={getOption()}
            style={{ height: '18.75rem' }}
          />
        </SimpleBarReact>
      </Card.Body>
      <Card.Footer>
        
      </Card.Footer>
    </Card>

  <AdvanceTableWrapper
      columns={columns}
      data={orderList}
      selection
      sortable
      pagination
      perPage={50}
    >
      <Card className="mb-3">
        <Card.Header>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {/* <ProductTableHeaderAll table /> */}
            </div>
            <div style={{ display: "flex", justifyContent: 'right' }}>
              <div className="mb-3" style={{ marginRight: "8px" }}>
                <div className="mb-2" style={{ marginTop: '10px' }}>
                  
                </div>
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
         
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>

    </>
  );
};

export default StoreSalesTotal;