import { AuthContext } from 'context/AuthContext';
import React, { useContext, useState } from 'react';
import { BASE_URL } from './baseUrl';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function useTaskRulePost() {
  const { myKey } = useContext(AuthContext);  
  const navigate = useNavigate(); 
const [loading, setLoading] = useState(false);


  const postData = (id, formData) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");

    const request_url = `${BASE_URL}api/v1/task/${id}/execute/`;

    const raw = JSON.stringify({
      params: {
        ...formData 
      }
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    
    fetch(request_url, requestOptions)
      .then(response => {
        if (response.ok) {
          response.json().then(result => {
            console.log(result);
            toast.success(`Başarıyla çalıştırıldı: ${result.message}`, { theme: 'colored' });
            navigate("/integration/task-rules"); 
            setLoading(false) 
          });
        } else {
          response.json().then(result => {
            console.log("Post işlemi başarısız:", result.error);
            toast.error(`Hata: ${result.error}`, { theme: 'colored' });
          }).catch(error => {
            console.log('Yanıt çözümlenirken bir hata oluştu:', error);
            toast.error('Beklenmeyen bir hata oluştu.', { theme: 'colored' });
          });
        }
      })
      .catch(error => {
        console.log('fetch işlemi sırasında bir hata oluştu:', error);
        toast.error(`Beklenmeyen bir hata oluştu: ${error.error}`, { theme: 'colored' });
      });
  };

  return { postData, setLoading, loading };
}
