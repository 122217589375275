import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import { DateContext } from 'context/DateContext';
import { PermsContext } from 'context/PermsContext';



export default function useStoreSales() {

 const [myData, setMyData] = useState()
 const {myKey, currentUser} = useContext(AuthContext)
 const [loading, setLoading] = useState(true);
 const { startDate, endDate } = useContext(DateContext);
 const {vendorIdsContext, storeIdContext, regionContext} = useContext(PermsContext)

 const getData = ()=>{

        var myHeaders = new Headers();
      

          const params = {

            // begin_date: beginDateWait,
            // end_date: endDateWait,
            begin_date: startDate,
            end_date: endDate,
            // vendor_username: currentUser,
            vendor_ids : vendorIdsContext,
            store : storeIdContext,
            inventory_region : regionContext
    
          };
    
          const request_url = Object.entries(params)
              .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
              .reduce(
                (url, [key, value]) => url + `&${key}=${value}`,
                `${BASE_URL}api/v1/order/store_sales/?`
      );


        myHeaders.append("Authorization", `Token ${myKey}`);    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result)
            setLoading(false);  
    })
          .catch(error => console.log('error', error));
 }
 useEffect(() => {

  if (startDate && endDate) {
    getData();
  }
    
  }, [startDate, endDate, vendorIdsContext, storeIdContext, regionContext]);

 return {myData, loading, setLoading}
}


