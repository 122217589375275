import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Card, Col, Row, Form } from 'react-bootstrap';
import {marketShare} from 'data/dashboard/default'

import BasicECharts from 'components/common/BasicEChart';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import useStoreSales from 'hooks/useStoreSales';
import AppContext from 'context/Context';



echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const getOptions = (data, radius, myData, selectedValue) => ({
  color: data?.map(item=>getColor(item?.color)),

  tooltip: {
    padding: [7, 10],
    transitionDuration: 0,
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: params =>
      `<strong>${params.data.name}:</strong> ${params.percent}%`
  },
  series: [
    {
      // name: myData?.map((item) => item.total).reduce((accumulator, currentValue) => accumulator + currentValue, 0).toLocaleString('tr-TR', {style: 'currency', currency: 'TRY', maximumFractionDigits: 2}),
      name: selectedValue === '2' ? myData?.map((item) => item.count).reduce((accumulator, currentValue) => accumulator + currentValue, 0) : myData?.map((item) => item.total).reduce((accumulator, currentValue) => accumulator + currentValue, 0).toLocaleString('tr-TR', {style: 'currency', currency: 'TRY', maximumFractionDigits: 2}),
      type: 'pie',
      radius,
      avoidLabelOverlap: false,
      emphasis: {
        scale: false
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: true,
        position: 'center',
        formatter: '{a}',
        fontSize: 14,
        color: getColor('dark')
      },
      // data: data.length ? data : []
      data
    }
  ]
});

const MarketShareItem = ({ item, index, total, selectedValue }) => {





  const { name, color, value } = item;
  const percentage = ((value * 100) / total).toFixed(0);

  const countShare = value


  return (
//     <Flex
//     alignItems="center"
//     justifyContent="flex-start"
//     className={`fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
// >
//     <p className="mb-1" style={{ minWidth: '200px' }}>
//         <FontAwesomeIcon
//             icon="circle"
//             className={`me-2 text-${color ? (color.split('-')[1] || color || 'text-info') : 'text-info'}`}
//         />
//         {name}
//     </p>
//     <div  style={{ marginRight: '10px', }}>{percentage}%</div>
//     {selectedValue === '2' ? 
    
//     ( <div style={{ marginLeft: '10px', display: 'flex', justifyContent: 'flex-end' }}>
//     {countShare}
//   </div>):
   
//     ( <div style={{ marginLeft: '10px', display: 'flex', justifyContent: 'flex-end' }}>
//     {countShare}₺
//   </div>
    
//    )


// }
// </Flex>

<Flex
  alignItems="center"
  justifyContent="flex-start"
  className={`fw-semi-bold fs--2 ${index === 0 && 'mt-3'}`}
  style={{ maxWidth: '300px' }}
>
  <p className="mb-1" style={{ minWidth: '100px' }}>
    <FontAwesomeIcon
      icon="circle"
      className={`me-2 text-${color ? (color.split('-')[1] || color || 'text-info') : 'text-info'}`}
    />
    {name}
  </p>
  <div style={{ flexGrow: 1, textAlign: 'right' }}>
    {percentage}%
  </div>
  {selectedValue === '2' ? 
    <div style={{ minWidth: '90px', textAlign: 'right' }}>
      {countShare} adt
    </div>
    : 
    <div style={{ minWidth: '90px', textAlign: 'right' }}>
      {countShare}₺
    </div>
  }
</Flex>





  );
};

const MarketShare = ({radius }) => {

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);


  const {myData, setMyData, loading, setLoading} = useStoreSales()


  const colors = ['primary', 'info', 'warning', 'success', 'danger', 'secondary', 'dark'];



  const [selectedValue, setSelectedValue] = useState('2');

const handleChange = (event) => {
  setSelectedValue(event.target.value);
};
  

  const data = myData?.map((item, index) => {
     
      return ({
          id : index + 1,
          // value: item.total,
          value: selectedValue === '2' ? item.count : item.total,
          name: item.VendorStore__Store__Name,
          color: colors[index]
      })
    }
  ) ?? marketShare


  const filtered =  [
    {value:"2", label:"Satış Adedi"},
    {value:"1", label:"Satış Tutarı"},
  ]


// const total = myData?.map((item) => item.total).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
const total = selectedValue === '2' 
  ? myData?.map((item) => item.count).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  : myData?.map((item) => item.total).reduce((accumulator, currentValue) => accumulator + currentValue, 0);




  return (
    <Card className="h-md-100" style={{padding:'10px',   paddingRight:'15px', marginRight:"10px", backgroundColor:isDark ? "#0B1727" : "#F6FAFF", marginLeft:"10px"}}>
      <Card.Body >
      <div style={{display:"flex", justifyContent:"space-between", marginBottom:"10px"}}>
            <div>
            <h6 className="mt-1" style={{color: isDark ? "#D8E2EF":"#025098"}}>Mağazalar</h6>
            </div>
                  <div className="mb-2">
                    {/* <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Sipariş Durumu</Form.Label> */}
                    <Form.Select  className='form-control' style={{fontSize:"11px"}} 
                    onChange={handleChange}
                    >
                                {filtered?.map((item, i)=>{
                                      return <option  key={i} value={item.value}>{item.label}</option>
                                })}
                        </Form.Select> 
                  </div>

                  </div>
     
        <Row className="justify-content-between g-0">
          <Col xs={5} sm={6} xxl className="pe-2">
         
            <div style={{ filter: loading ? 'blur(2px)' : 'none' }}>
            
        {loading ? 

          data?.map((item, index) => (
              <MarketShareItem
                item={item} // id, name, value, total
                index={index}
                key={index}
                total={total}
                selectedValue={selectedValue}
              />
            ))   
          :
          data?.map((item, index) => (
            <MarketShareItem
              item={item} // id, name, value, total
              index={index}
              key={index}
              total={total}
              selectedValue={selectedValue}
            />
          ))     
        
      }
      </div>
          </Col>
          <Col xs="auto">
          <div style={{ 
               filter: loading ? 'blur(2px)' : 'none'
            }}>
         
        {loading ? 

            <div className="ps-0">
              <BasicECharts
                echarts={echarts}
                options={getOptions(data, radius, myData, selectedValue)}
                style={{ width: '7.2rem', height: '7.2rem' }}
              />
            </div>
              :
            <div className="ps-0">
            <BasicECharts
              echarts={echarts}
              options={getOptions(data, radius, myData, selectedValue)}
              style={{ width: '7.2rem', height: '7.2rem' }}
            />
          </div>
          }
          </div>
          </Col>
        </Row>
   
      </Card.Body>
    </Card>
  );
};

MarketShareItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }),
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

MarketShare.propTypes = {
  // data: PropTypes.array.isRequired,
  radius: PropTypes.array.isRequired
};

export default MarketShare;
