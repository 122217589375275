import React, { useContext, useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { useLocation, Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import './ProgressStyle.css'

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Button } from 'react-bootstrap';
import useOrderCancel from 'hooks/useOrderCancel';
import { PermsContext } from 'context/PermsContext';
import useOrderWorkPost from 'hooks/useOrderWorkPost';
import OrderedDeliveryProducts from './OrderedDeliveryProducts';
import OrderDeliveryInfo from './OrderDeliveryInfo';
import useDeliveryList from 'hooks/useDeliveryList';

const OrderDeliveryDetails = () => {

  const location = useLocation()
  const {id, activePage } = location.state || {};


  const {detail, getDetail,  getDetailWork, detailWork} = useDeliveryList()
 

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {getOrderWork}= useOrderWorkPost()




  
useEffect(() => {
  if(id){
  getDetail(id.slice(1))
  getDetailWork(id.slice(1))

  }

}, [id])

const handleWork = () => {
  getOrderWork(id.slice(1)); 
};



const status = detail?.map((item)=>{
  const orderDate = new Date(item.Order.OrderDate);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const OrderStatus = ["", 50,100]
  
  const OrderStatuses = ["", 'Kargoya verildi', 'Teslim Edildi']
 
  const shippmentDate = new Date(item.StartedAt)
  const deliveryDate = new Date(item.EndedAt)
  const statusInteger = item.IsCompleted ? 2 : 1

  return {
   
    order_Date : orderDate.toLocaleDateString('tr-TR', options),
    order_status_bar : OrderStatus[statusInteger],
    shippment_date : shippmentDate.toLocaleDateString('tr-TR', options),
    statusText: OrderStatuses[statusInteger],
    delivery_date : item.EndedAt ?  deliveryDate.toLocaleDateString('tr-TR', options) :  "Teslim edilmemiştir.",
    order_number : item.Order.OrderNumber || "--",
    store : item.Order.VendorStore.Store.Name,
    region : item.Order.InventoryRegion?.Name,
    courier : item.CourierUsername


  }
})

console.log("status", status)



return (
    <>
    <div style={{display:'flex', justifyContent:'space-between', marginBottom:'10px'}} >
      
    <Link to="/e-commerce/orders/order-delivery" state={{ activePage }}>

      <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      </Link>


    </div>      
      <PageHeader  titleTag="h5" className="mb-3 mt-1" handleShow={handleShow} id={id}>
            <h5 style={{marrginTop:'-200px', marginBottom:'10px'}}>Teslimat Detayları :{id}</h5>
        <p className="fs--1 mt-1"> Sipariş Tarihi : {status?.length >0 && status[0]?.order_Date }</p>
        <p className="fs--1 mt-1"> Sipariş Numarası : {status?.length >0 && status[0]?.order_number }</p>
        <p className="fs--1 mt-1"> Mağaza : {status?.length >0 && status[0]?.store }</p>
        <p className="fs--1 mt-1"> Çıkış Deposu : {status?.length >0 && status[0]?.region}</p>
        <p className="fs--1 mt-1"> Kurye İsmi : {status?.length >0 && status[0]?.courier}</p>

        <div>
          <strong className="me-2">Durumu: </strong>
          <SoftBadge pill bg="success" className="fs--2">
            {' '}
            {status?.length >0 && status[0]?.statusText }
            {/* <FontAwesomeIcon
              icon="check"
              className="ms-1"
              transform="shrink-2"
            /> */}
          </SoftBadge>          
        </div>
        <div style={{margin:"50px", marginLeft:"90px", width:"85%"}}>

<ProgressBar percent={status?.length >0 && status[0]?.order_status_bar} >
  

  
  

  <Step>
    {({ accomplished, index }) => (

      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
        style={{marginTop:'45px', marginLeft:'10px'}}
      >
         <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-truck" viewBox="0 0 16 16">
  <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
</svg>
      </div>

      <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', width:'90px', fontWeight:'bold'}}>Kargoya Verildi</p>
        <p style={{fontSize:'10px', marginTop:'0px', width:'120px'}}>{status?.length > 0 && status[0]?.shippment_date || 'henüz kargoya verilmedi'}</p>
        </div>
      </div>
    )}
  </Step>

  <Step>
    {({ accomplished, index }) => (

      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
        style={{marginTop:'45px'}}
      >
         <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-house-check" viewBox="0 0 16 16">
  <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.708L8 2.207l-5 5V13.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 2 13.5V8.207l-.646.647a.5.5 0 1 1-.708-.708L7.293 1.5Z"/>
  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.707l.547.547 1.17-1.951a.5.5 0 1 1 .858.514Z"/>
</svg>
      </div>

      <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', width:'90px', fontWeight:'bold'}}>Teslim Edildi</p>
        <p style={{fontSize:'10px', marginTop:'0px', width:'120px'}}>{status?.length > 0 &&  status[0]?.delivery_date || 'henüz teslim edilmedi'}</p>
        </div>
      </div>
    )}
  </Step>

</ProgressBar>


</div>
      </PageHeader>
      <OrderDeliveryInfo />
      <OrderedDeliveryProducts />

  
    </>
  );
};

export default OrderDeliveryDetails;
