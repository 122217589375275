import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Dropdown, Form, Col, InputGroup, Alert, Row, Popover, Overlay, Tooltip, OverlayTrigger,  ButtonGroup, Button, Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import OrdersTableHeader from './OrdersTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Pagination from './PaginationOrder';
import DatePicker from  "react-datepicker";
import useShipmentCompany from 'hooks/useShipmentCompany';
import IconButton from 'components/common/IconButton';
import useVendorStore from 'hooks/useVendorStore';
import { Search } from 'react-bootstrap-icons';
import gifOrder from 'assets/img/gif_order.gif'
import useOrderExportS3 from 'hooks/useOrderExportS3';
import useInventoryListFilter from 'hooks/useInventoryListFilter';
import {  PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import gif_s3 from 'assets/img/gif_export_s3.gif'
import { useOrderPdfUpload } from 'hooks/useOrderPdfUpload';
import useOrderCancel from 'hooks/useOrderCancel';
import useOrderWorkPost from 'hooks/useOrderWorkPost';
import './style.css'
import useRefundList from 'hooks/KolayIade/useRefundList';
import useReasonList from 'hooks/KolayIade/useReasonList';
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdOutlineCancelPresentation } from "react-icons/md";
import useInvoiceList from 'hooks/KolayIade/useInvoiceList';



const InvoiceList = () => {

const {getOrderWork}= useOrderWorkPost()

const {perms} = useContext(PermsContext)

const {myData, startDate, setStartDate, endDate, setEndDate, setCargo,setStore, setSearch, showOrderGif,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, setInvoices, setVendorIdList, setIsLoading,   setSearchName, setCitySelect, setReason, setStatus} = useInvoiceList()


  const columns = [
    {
      accessor: 'name',
      Header: 'Sipariş',
      disableSortBy: true,
      headerProps: { className: 'text-left'},
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { id, name, order_number, note } = rowData.row.original;
        const renderTooltip = (props) => (
          <Tooltip id={`orderNote-${id}`} {...props}
          bsPrefix='my-tooltip-class'
          >
            {note}
          </Tooltip>
        );

        return (
          <>
            {note ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <span>
                 
                  <FontAwesomeIcon
                    icon={['far', 'question-circle']}
                    transform="shrink-1"
                    className="icon-red"
                    style={{  marginRight: '5px' }}
                    
                  />
                   {/* <Link to="/refund-detail" state={{ id, activePage }}>
                    <strong>{id}</strong>
                  </Link> */}
                </span>
              </OverlayTrigger>
            ) : (
              // <Link to="/refund-detail" state={{id, activePage}} >
              //   <strong>{id}</strong>
              // </Link>
              ""
            )}
            <strong>{name}</strong> <br />
            {/* <Link to="/refund-detail" state={{id, activePage}} >
              <a href="">{order_number}</a>
            </Link> */}
            <p>{order_number}</p>
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Tarih',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      }
    },
    {
      accessor: 'address',
      Header: 'Şehir ve Kargo Bilgileri',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { address, shippingType, trackingLink } = rowData.row.original;
        return (
          <>
            {address}
            {trackingLink ? (
              <p>
             <a href={trackingLink} target="_blank" rel="noopener noreferrer" className="mb-0 text-500" >
             <strong style={{color:"#2C7AE5"}} >{shippingType}</strong>
                </a></p>
            ) : (
              <p className="mb-0 text-500" onClick={() => alert('Şu an takip linki oluşmamışdır.')}>
                {shippingType}
              </p>
            )}
          </>
        );
      }
      
    },
    {
      accessor: 'status',
      Header: 'Durumu',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              // success: status === '6', // delivered
              danger: status === 'Talep yerine getirildi', // delivered
              light: status === 'Müşteri ile iletişim',   // returned
              primary: status === 'Onaylandı',   // completed
              info: status === 'Reddedildi',  // processing
              warning: status === 'Satıcıdan işlem bekleniyor',   // pending
              dark : status === 'Müşteri tarafından oluşturuluyor' ,
              info: status === 'Birden Fazla Talep Durumu',  // processing
                // onhold
            })}
            className="d-block"
          >
            {status === 'Talep yerine getirildi' && 'Talep yerine getirildi'} 
            {status === 'Müşteri ile iletişim' && 'Müşteri ile iletişim'} 
            {status === 'Onaylandı' && 'Onaylandı'}  
            {status === 'Reddedildi' && 'Reddedildi'} 
            {status === 'Satıcıdan işlem bekleniyor' && 'Satıcıdan işlem bekleniyor'} 
            {status === 'Müşteri tarafından oluşturuluyor' && 'Müşteri tarafından oluşturuluyor'}  
            {status === 'Birden Fazla Talep Durumu' && 'Birden Fazla Talep Durumu'}  

            <FontAwesomeIcon
              icon={classNames({
                check: status === 'Talep yerine getirildi',
                check: status === 'Müşteri ile iletişim',
                redo: status === 'Onaylandı',
                ban: status === 'Reddedildi',
                redo: status === 'Satıcıdan işlem bekleniyor',
                stream: status === 'Birden Fazla Talep Durumu'

              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: 'Mağaza',
      disableSortBy: true,
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { store} = rowData.row.original;
        return (
          <p>{store}</p>
        );
      }
    },
    {
      accessor: 'item',
      Header: 'İade Ürün',
      headerProps: {
            className: 'text-center'
          },
      disableSortBy: true,
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { item_count} = rowData.row.original;
        return (
          <p>{item_count}</p>
        );
      }
    },
    {
      accessor: 'itemTotal',
      Header: 'İade Ürün Tutarı',
      headerProps: {
            className: 'text-center'
          },
      disableSortBy: true,
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { totalClaimAmount} = rowData.row.original;
        return (
          <p>{totalClaimAmount}</p>
        );
      }
    },
    {
      accessor: 'payment',
      Header: 'Ödeme Yöntemi',
      headerProps: {
            className: 'text-center'
          },
      disableSortBy: true,
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { payment} = rowData.row.original;
        return (
          <p>{payment}</p>
        );
      }
    },
    {
  
      Header: 'Fatura',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const {invoice} = rowData.row.original;
        return (
          <p style={{marginRight:"5px"}}>{invoice}</p>
        );
      }
    },
// aksiyon column
{
  Header: 'Aksiyon',
  disableSortBy: true,
  headerProps: {
    className: 'text-center'
  },
  cellProps: {
    className: 'text-center'
  },
  Cell: rowData => {
    const {id} = rowData.row.original;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
          setIsMenuOpen(false);
        }
 
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const handleMenuClick = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    const handleModalOpen = () => {
      setIsModalOpen(true);  
      setSelectedId(id);  
    };

    const handleModalOpenCancel = () => {
      setShowCancel(true);  
      setCancelId(id.slice(1));  
    };

    const handleWork = () => {
      getOrderWork(id.slice(1)); 
    };

   
    return (
      <div style={{display:'flex',  justifyContent: 'center'}}>
      <div
        ref={menuRef}
        onClick={handleMenuClick}
        style={{
          cursor: 'pointer',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: '30px',
        }}
      >
        ...
        {isMenuOpen && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '0',
              transform: 'translate(-110%, -50%)',
              backgroundColor: 'white',
              border: '1px solid #ccc',
              borderRadius: '5px',
              zIndex: 1000,
            }}
          >
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              <li 
                style={{ padding: '8px', borderBottom: '1px solid #ccc' }}
                onClick={handleModalOpen}
              >
                 <IoMdCheckboxOutline size={20} /> Faturayı Yükle
              </li>

  
            </ul>
          </div>
        )}
      </div>
      </div>
    );
  }
}
];



const {setSelectStatusModal, setStartDateModal, setEndDateModal, setCargoModal, getDataExport, startDateModal, endDateModal, setStoreModal, fulfilmentModal, setFulfilmentModal, showGif, setShowGif, setInvoicesExport, invoicesExport, myDataS3,setMyDataS3, setRegionExport, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorExportId, setPaidExport, setWorkExport, setSearchNameExport, setShipmentStartDateModal, setShipmentEndDateModal, shipmentEndDateModal, shipmentStartDateModal, getDataExportReturn, stopDataExport} = useOrderExportS3()

const {myDataVendor} = useVendorList()

const {vendorStore, setVendorIdVendorStore} = useVendorStore()
const {myDataRegion, setVendorIdRegion} = useInventoryListFilter()
const {shipmentCompany, setVendorIdShipment} = useShipmentCompany()
const {myDataReason, setVendorIdReason} = useReasonList()



  const [orderList, setOrderList] = useState() 
  const [activePage, setActivePage] = useState(1)



  const location = useLocation();

  useEffect(() => {
    console.log("Active page from state:", activePage); // Debug için
    const savedActivePage = location.state?.activePage;
    if (savedActivePage) {
      console.log("Saved active page:", savedActivePage); // Debug için
      setActivePage(savedActivePage);
      setCurrentPage(savedActivePage);
    } else {
      setActivePage(1);
      setCurrentPage(1);
    }
  }, [location]);
  

  const [isOpen, setIsOpen] = useState(false);

  function hasPermission(permission) {
    return perms?.includes(permission);
  }
 
  // Handler
  const handleToggle = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };
  
  const [showFast, setShowFast] = useState(false);

  // modal
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setMyDataResult(false)
    stopDataExport();
    setIsPreparing(false);
  }

  const handleCloseFast = () => {
    setShowFast(false)
  }


  const handleShow = () => setShow(true);

  // const handleShowFast = () => setShowFast(true);

  const onChangeModal = datesExport => {
    const [startExport, endExport] = datesExport;
    setStartDateModal(startExport);
    setEndDateModal(endExport);
  };

  


  const onChangeShipmentModal = datesShipmentExport => {
    const [startShipmentExport, endShipmenttExport] = datesShipmentExport;
    setShipmentStartDateModal(startShipmentExport);
    setShipmentEndDateModal(endShipmenttExport);
  };

  useEffect(() => {
    if (myDataVendor && myDataVendor.length > 0) {
      const firstVendorId = myDataVendor[0].Id;
      setVendorIdVendorStore(firstVendorId);
      setVendorIdRegion(firstVendorId);
      setVendorIdShipment(firstVendorId);
      setVendorIdList(firstVendorId)
      setVendorExportId(firstVendorId)
      setVendorIdReason(firstVendorId)

    }
  }, [myDataVendor]);

  const handleChangeVendor = (e)=> {
    setVendorIdVendorStore(e.target.value); 
    setVendorIdRegion(e.target.value)
    setVendorIdShipment(e.target.value)
    setVendorIdList(e.target.value)
    
  }


  const handleChangeModal = (e)=> {
    setSelectStatusModal(+e.target.value); 
    console.log("type", typeof e.target.value)
  }

  const handleChangeCargoModal = (e)=> {
    setCargoModal(e.target.value); 
  }

  const handleChangeStoreModal = (e)=> {
    setStoreModal(e.target.value); 
  }
  
  const handleChangeVendorModal = (e)=> {
    setVendorExportId(e.target.value); 
    setVendorIdVendorStore(e.target.value); 
    setVendorIdRegion(e.target.value)
    setVendorIdShipment(e.target.value)
  }

  

  const handleExport = ()=> { 
    getDataExport(); 
    setShowGif(true);
    // setShow(false)
    
  }

  const handleDownload = () => {
    setIsPreparing(false);
    window.location.href = myDataResult[0]?.Value;
    setShow(false)
    setMyDataResult(false)
  }






const city = [
  {value:"istanbul", label:"İstanbul"},
  {value:"istanbul_disi", label:"İstanbul Dışı"},
  {value:"ankara", label:"Ankara"},
  {value:"izmir", label:"İzmir"},
  {value:"diger_sehirler", label:"Diğer Şehirler"},
]


const handleChangeCity = (e)=> {
  setCitySelect(e.target.value); 
}



const handleChangeReason= (e)=> {
    setReason(e.target.value); 
}

const handleChangeCargo = (e)=> {
  setCargo(e.target.value);
  setCargoModal(e.target.value) 
}

const handleChangeStore = (e)=> {
  setStore(e.target.value);
  setStoreModal(e.target.value) 
}




useEffect(() => {
     
  const newList = myData?.results.map((item, index)=>{

    const date = new Date(item.ClaimDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const claimAmount = item.claims.reduce((claimTotal, claim) => {
        const productTotal = claim.SubjectOrderProducts.reduce((productTotal, product) => {
          return productTotal + (product.Count * product.BasePrice);
        }, 0);
        return claimTotal + productTotal;
      }, 0);

      const formattedClaimAmount = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(claimAmount);

      const paymentMethods = ['', 'Kredi Kart', 'Kapıda Nakit', 'Kapıda Kredi Kart', 'Diğer', 'Havale']
      
      
    return {


      id : index,
      name: item.RelatedOrder.ShippingAddress.FirstName+ " "+item.RelatedOrder.ShippingAddress.LastName, 
      order_number : item.OrderNumber === null ? "Sipariş No: -" : "Sipariş No: " + item.OrderNumber,
      date: `${day}-${month}-${year}`,
      address : item.RelatedOrder.ShippingAddress.City,
      shippingType: ((item.ShipmentCompanyText && item.ShipmentCompanyText.includes("Marketplace")) ? item.ShipmentCompanyText.replace('Marketplace', '').trim() : item.ShipmentCompanyText ? item.ShipmentCompanyText : "Kargolamayı bekliyor") + " " + (item.ShippingTrackingNumber ? item.ShippingTrackingNumber : "Henüz Kargolanmadı"),
      status: item.general_status,
      amount : item.OrderAmount,
      store : item.RelatedOrder.VendorStore.Store.Name,
      trackingLink :item.ShipmentTrackingLink,
      item_count : item.claims.length,
      note : item.Note,
      totalClaimAmount : formattedClaimAmount,
      payment : paymentMethods[item.RelatedOrder.PaymentMethod]
  
    }
  })
  setOrderList(newList)

}, [myData])


const handleChangeDate = event => {
  setSelectedOption(event.target.value);

  let start, end;

  switch(event.target.value) {
    case "1": // Bugün
      start = new Date();
      end = new Date();
      break;

    case "2": // Bu hafta
      end = new Date();
      start = new Date();
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
      break;   

    case "3": // Bu ay
      end = new Date();
      start = new Date();
      start.setDate(1); // Ayın ilk günü
      break;
  
    default:
      // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
      break;
  }

  setStartDate(start);
  setEndDate(end);

  setStartDateModal(start);
  setEndDateModal(end);

};

const [isOpenDate, setIsOpenDate] = useState(true);

const onChange = dates => {

  const [start, end] = dates;

  if (selectedOption === "4") {
    setStartDate(start);
    setEndDate(end);

    setStartDateModal(start);
    setEndDateModal(end);
  }
  if (start && end) {
    setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
  }
};




const [selectedOption, setSelectedOption] = useState(null);


useEffect(() => {
  if (selectedOption === "4") {
    setIsOpenDate(true);
  } else {
    setIsOpenDate(false);
  }
}, [selectedOption]);


const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedId, setSelectedId] = useState(null);
const [selectedFile, setSelectedFile] = useState(null);
const { uploadPDF, errorMessage } = useOrderPdfUpload();  // useHook

const handleModalClose = () => {
  setIsModalOpen(false);
};

const handleFileChange = (e) => {
  setSelectedFile(e.target.files[0]);
};

const handleFileUpload = () => {
  if (selectedFile) {
    uploadPDF(selectedFile, selectedId);  // Dosyayı ve id'yi useHook'a gönder
    handleModalClose(); // Modalı kapat
  }
};

const [showCancel, setShowCancel] = useState(false);

const handleCloseCancel = () => setShowCancel(false);
// const handleShowCancel  = () => setShowCancel(true);

const [cancelId, setCancelId] = useState()
const {getCancelData}  = useOrderCancel()


const handleCancel = () => {
  getCancelData(cancelId)

}
const handleChangeName = (e) => {
  setSearchName(e.target.value)
  setSearchNameExport(e.target.value)
}

const [selectedStatus, setSelectedStatus] = useState("");

const statuses = [
  { value: '', label: 'Hepsi' },
  { value: '0', label: 'Müşteri tarafından oluşturuluyor' },
  { value: '1', label: 'Satıcıdan işlem bekleniyor' },
  { value: '2', label: 'Reddedildi' },
  { value: '3', label: 'Onaylandı' },
  { value: '4', label: 'Müşteri ile iletişim' },
  { value: '5', label: 'Talep yerine getirildi' }

];

const handleButtonClick = (statusValue) => {
  setSelectedStatus(statusValue)
  setStatus(statusValue);
  console.log("statusValue",statusValue)

};




  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      selection
      sortable
      pagination
      perPage={50}
    >
     
      <Card className="mb-3">
        <Card.Header>

      <div style={{display:"flex", justifyContent:"space-between"}}>


            <div>
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Fatura Talepleri</h5>
            
            </div>


            <div style={{display:"flex", justifyContent:'right'}}>

            <div className="mb-3" style={{marginRight:"15px"}}>
                    <InputGroup>
                      <Form.Control  type="text" placeholder="ara..."  onChange={(e)=>setSearch(e.target.value)}/>
                      <InputGroup.Text><Search/></InputGroup.Text>
                    </InputGroup>
            </div>

            <div >
              <OrdersTableHeader table />
            </div>

            { hasPermission('Dashboard Sipariş Export') ?
            <div style={{ marginRight:"8px"}}>
                <IconButton
                  variant="falcon-default"
                  size="m"
                  icon="external-link-alt"
                  transform="shrink-3"
                  // onClick={handleShow}
                  style={{width:'145px', fontSize:'15px'}}
                  // disabled={true}
                >
                  <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
                </div>
          : ("") }
                

      <Dropdown  show={isOpen} onToggle={handleToggle}>
        <Dropdown.Toggle
           bsPrefix="toggle"
           as={Link}
          //  to=""
          onClick={handleToggle}
           className={classNames('px-0 nav-link')}>
     
            <IconButton
              variant="falcon-default"
              size="m"
              icon="fa-sharp fa-light fa-filter-list"
              transform="shrink-3"
              style={{width:'145px', fontSize:'15px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
              </svg>
                  <span className="d-none d-sm-inline-block ms-1">Filtre</span>
            </IconButton>
        </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret" style={{minWidth: '300px', width: '300px'}}>
          <Card className="shadow-none shadow-show-xl scrollbar">
            <Card.Header className="bg-light d-none d-xl-block">
              <h6 className="mb-0">Filtre</h6>
            </Card.Header>
              <Card.Body>
                <Form>

              
                  <div className="mb-2">
                  <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Tarih Seçimi</Form.Label>

                  <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                  <option value="" disabled selected>Tarih</option>
                  <option key={1} value="1">Bugün</option>
                  <option key={2} value="2">Bu hafta</option>
                  <option key={2} value="3">Bu ay</option>
                  <option key={3} value="4">Tarih seç</option>
                              </Form.Select> 

                {selectedOption === "4" && 
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    endDate={endDate}
                    selectsRange
                    dateFormat="MMM dd"
                    className='form-control'
                    placeholderText='tarih giriniz'
                    open={isOpenDate}
                    onCalendarClose={() => setIsOpenDate(false)}
                    onCalendarOpen={() => setIsOpenDate(true)}
                  />
                }

                  </div>
                  { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                  <div >
                  <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Satıcı</Form.Label>
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                     <option value="" disabled>Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
            : "" }
                 
                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Şehir</Form.Label>
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeCity}>
                            <option value=""  disabled selected>Şehir</option>
                                {city?.map((item, i)=>{
                                      return <option  key={i} value={item.value}>{item.label}</option>
                                })}
                        </Form.Select> 
                  </div>
                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Kargo Firması</Form.Label>
                    { shipmentCompany?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeCargo}>
                            <option value="" >Kargo</option>
                                { shipmentCompany?.map((item, i)=>{
                                      return <option key={i} value={item.ShipmentCompanyText}>{item.ShipmentCompanyText}</option>
                                })}       
                        </Form.Select>}
                  </div>
                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Mağazalar</Form.Label>
                    { vendorStore?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeStore}>
                            <option value="" >Mağaza</option>
                                { vendorStore?.map((item, i)=>{
                                      return <option key={i} value={item.Store__Name}>{item.Store__Name}</option>
                                })}       
                        </Form.Select>}
                  </div>
                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>İade Nedeni</Form.Label>
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeReason}>
                            <option value=""  >İade Nedeni</option>
                                {myDataReason?.map((item, i)=>{
                                      return <option  key={i} value={item.claims__Reason__Name}>{item.claims__Reason__Name}</option>
                                })}
                        </Form.Select> 
                  </div>
                 
              </Form>
            </Card.Body>
        </Card>
      </Dropdown.Menu>
      </Dropdown>
    </div>  
  </div>
        <div style={{marginTop:'10px'}}>
          <ButtonGroup size="sm" style={{ borderRadius:'0'}}>
            {statuses.map((status) => (
              <Button
                key={status.value}
                variant="outline-secondary"
                className="custom-btn"
                onClick={() => handleButtonClick(status.value)}
                style={{ 
                  fontSize: '0.8em', 
                  border: 'none', 
                  boxShadow: 'none',
                  borderRadius: '0',
                  borderBottom: selectedStatus === status.value ? '3px solid blue' : 'none',
                }}
              >
                {status.label}
              </Button>
            ))}
          </ButtonGroup>

          </div>
</Card.Header>

        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />


        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showOrderGif && <img src={gifOrder} style={{width:'50%'}} alt="loading gif" />}
          </div>
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showGif && <img src={gifOrder} style={{width:'50%'}} alt="export gif" />}
          </div>
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <Pagination pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage} setIsLoading={setIsLoading}/>
        </Card.Footer>
       
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}>Sipariş sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>


      <Modal 
      show={show} 
      onHide={handleClose}
      size="xl"
      
      >
        <Modal.Header closeButton>
          <Modal.Title>
          <div style={{display:'flex', justifyContent:'space-between'}} > 
          <div>Sipariş Listesi</div>
            

          

            </div>
          </Modal.Title>
            </Modal.Header>
              <Modal.Body>
          
        
          
        <div style={{display:'flex', justifyContent:'center'}} >
       
        <div style={{marginRight:'8px'}}>
                    { myDataVendor?.length> 1 &&    
                    
                    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeVendorModal}>
                  
                     <option value="">Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
   
          <div style={{marginRight:"8px", fontSize:"14px", width:"190px"}}>

                <DatePicker
                showIcon
                selected={startDateModal}
                onChange={onChangeModal}
                startDate={startDateModal}
                formatWeekDay={day => day.slice(0, 3)}
                endDate={endDateModal}
                selectsRange
                dateFormat="MMM dd"
                className='form-control'
                placeholderText='sipariş tarihi'
                />
          </div>

          <div style={{marginRight:"8px", fontSize:"14px", width:"190px"}}>
      
                <DatePicker
                showIcon
                selected={shipmentStartDateModal}
                onChange={onChangeShipmentModal}
                startDate={shipmentStartDateModal}
                formatWeekDay={day => day.slice(0, 3)}
                endDate={shipmentEndDateModal}
                selectsRange
                dateFormat="MMM dd"
                className='form-control'
                placeholderText='kargo tarihi'
                />
          </div>

          <div style={{marginRight:'8px'}}>
                    { vendorStore?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeStoreModal}>
                     <option value="" >Mağaza</option>
                        { vendorStore?.map((item, i)=>{
                              return <option key={i} value={item.Store__Name}>{item.Store__Name}</option>
                        })}       
                </Form.Select>}
            </div>

          <div style={{marginRight:"8px"}}>
                  { shipmentCompany?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"112px"}} onChange={handleChangeCargoModal}>
                  <option value="" >Kargo</option>
                      { shipmentCompany?.map((item, i)=>{
                            return <option key={i} value={item.ShipmentCompanyText}>{item.ShipmentCompanyText}</option>
                      })}       
              </Form.Select>}
          </div>
          
          </div>
               
          </Modal.Body>
          <Modal.Footer>
          <Row style={{width:'100%'}}>      
  <Row>
  { myDataVendor?.length> 1 ? <p>Satıcı ismi seçilmesi ZORUNLUDUR!</p> : ""}
    <p>Tarih aralığı geniş sipariş listesinin indirilmesi 1-2 dk bulabilir.</p>
    <p>Siparişiniz hazırlanıyor yazısından sonra tekrar Hazırla butonuna basmayınız.</p>

    <Col className="d-flex justify-content-end mb-3">
      <Button variant="secondary" onClick={handleClose} style={{marginRight:'10px'}}>
        Vazgeç
      </Button>

{!isPreparing && (
  <Button variant="primary" onClick={handleExport}>
        Hazırla
      </Button>


) }
      

      
    </Col>
  </Row>
  {isPreparing && (!myDataResult || !myDataResult.error) && 
  
  <div>
  <div>Sipariş listeniz hazırlanıyor, lütfen bekleyin...</div>
  <img src={gif_s3} style={{width:'50%'}} alt="export gif" />
  </div>
  
  }
  {myDataResult && myDataResult[0]?.Value?.length > 0  && 
  <Row className="justify-content-center mt-5" style={{width:'100%'}}>
    <Col style={{display: "flex", justifyContent: "center"}}>
    <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        {/* {myDataS3?.message} */}
        Sipariş listesiniz hazır indirebilirsiniz.
      </Alert>
      <Button 
  variant="primary" 
  style={{width:'150px', height:'40px'}}
  // onClick={() => window.location.href = myDataS3?.download_url}
  onClick={handleDownload}
>
  İndir
</Button>

    </Col>
  </Row>

  }
   </Row>
      </Modal.Footer>
      </Modal>


      <Modal 
      show={showFast} 
      onHide={handleCloseFast}
      size="l"
      >
        <Modal.Header closeButton>
          <Modal.Title>Sipariş Listesi</Modal.Title>
          </Modal.Header>
            <Modal.Body>
            </Modal.Body>
          <Modal.Footer>
          <Row style={{width:'100%'}}>      
  <Row>
  { myDataVendor?.length> 1 ? <p>Satıcı ismi seçilmesi ZORUNLUDUR!</p> : ""}
    <p>Tarih aralığı geniş sipariş listesinin indirilmesi 1-2 dk bulabilir.</p>
    <p>Siparişiniz hazırlanıyor yazısından sonra tekrar Hazırla butonuna basmayınız.</p>

    <Col className="d-flex justify-content-end mb-3">
      <Button variant="secondary" onClick={handleCloseFast} style={{marginRight:'10px'}}>
        Vazgeç
      </Button>
      <Button variant="primary" onClick={handleExport}>
        Hazırla
      </Button>
    </Col>
  </Row>
  {isPreparing && (!myDataResult || !myDataResult.error) && 
  
  <div>
  <div>Sipariş listeniz hazırlanıyor, lütfen bekleyin...</div>
  <img src={gif_s3} style={{width:'50%'}} alt="export gif" />
  </div>
  
  }
  {myDataResult && myDataResult[0]?.Value?.length > 0  && 
  <Row className="justify-content-center mt-5" style={{width:'100%'}}>
    <Col style={{display: "flex", justifyContent: "center"}}>
    <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        {/* {myDataS3?.message} */}
        Sipariş listesiniz hazır indirebilirsiniz.
      </Alert>
      <Button 
  variant="primary" 
  style={{width:'150px', height:'40px'}}
  // onClick={() => window.location.href = myDataS3?.download_url}
  onClick={handleDownload}
>
  İndir
</Button>

    </Col>
  </Row>

  }
   </Row>
      </Modal.Footer>
      </Modal>



      <Modal show={isModalOpen} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>PDF Yükle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" accept="application/pdf" onChange={handleFileChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Kapat
          </Button>
          <Button variant="primary" onClick={handleFileUpload}>
            Yükle
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCancel} onHide={handleCloseCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Bilgilendirme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          İptal etmek istediğinize emin misiniz?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCancel}>
            Hayır
          </Button>
          <Button variant="danger" onClick={handleCancel}>
            İptal Et
          </Button>
        </Modal.Footer>
      </Modal>
    
    </AdvanceTableWrapper>
  );
};

export default InvoiceList;
