import React, { useContext, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row, Table, Modal } from 'react-bootstrap';
// import { orderedProductsData } from 'data/ecommerce/OrderDetailsData';
import { getPercentage } from 'helpers/utils';
import { useLocation } from 'react-router-dom';
import useOrderList from 'hooks/useOrderList';
import { MdOutlineCancel, MdCheck } from 'react-icons/md';
import { PermsContext } from 'context/PermsContext';

const OrderedProducts = () => {

  const {perms} = useContext(PermsContext)

  const location = useLocation()
  const {id} = location.state || ""
  const {detail, getDetail} = useOrderList()

  useEffect(() => {
    if(id){
    getDetail(id)
    }
  
  }, [ ])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const activePage = params.get('activePage');

    if (id && activePage) {
      getDetail(id)
    }
  }, []);

  function hasPermission(permission) {
    return perms?.includes(permission);
  }


const orderedProductsData = detail?.map(item => item.OrderProducts?.map((product, i) => ({
  id : i+1,
  name: product.name || "",
  quantity: product.Count || "",
  rate : product.BasePrice || "",
  image: product.product_image || "",
  barcode : product.Barcode || "",
  SKU : product.SKU || "",
  Checked : product.Checked ,
  shelf_code : product.shelf_code ,
  })));




  const subtotal = orderedProductsData?.length>0 && orderedProductsData[0]?.reduce(
    (acc, curr) => curr.quantity * curr.rate + acc,
    0
  );
 
  // const tax = getPercentage(subtotal, 5);

  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const handleImageClick = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Ürün</th>
                <th className="border-0">İsim</th>
                <th className="border-0">Barkodu</th>
                <th className="border-0">SKU</th>
                {hasPermission('Dashboard Çoklu Satıcı Görme') && (
                  <>
                    <th className="border-0 text-center">Doğrulanma Durumu</th>
                    <th className="border-0">Raf</th>
                  </>
                )}
                
                <th className="border-0 text-center">Adet</th>
                <th className="border-0 text-end">Fiyat</th>
                <th className="border-0 text-end">Tutar</th>
              </tr>
            </thead>
            <tbody>
              {orderedProductsData?.length>0 && orderedProductsData[0]?.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle">
                    <img style={{width:"40px", cursor: 'pointer'}}src={product.image} alt="" 
                     onClick={() => handleImageClick(product.image)}
                     />
                 
                  </td>
                  <td className="align-middle">
                      <h6 className="mb-0" style={{ maxWidth: '350px', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                        {product.name}
                      </h6>
                    </td>
                  <td className="align-middle">
                    {product.barcode}
                  </td>
                  <td className="align-middle">
                    {product.SKU}
                  </td>
                
                  {hasPermission('Dashboard Çoklu Satıcı Görme') && (
                      <>
                      <td className="align-middle text-center">
                        {product.Checked ? (
                            <MdCheck style={{ marginLeft: '10px', color: '#4189DB', fontSize:'20px' }} />
                          ) : (
                            <MdOutlineCancel style={{color: 'red', fontSize:'20px' }} />
                          )}
                      </td>
                      <td className="align-middle">
                        {product.shelf_code || "--"
                      }
                      </td>
                      </>
                    )}
                  
                  <td className="align-middle text-center">
                    {product.quantity}
                  </td>
                  <td className="align-middle text-end">
                    
                    {parseFloat(product.rate).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </td>
                  <td className="align-middle text-end">
                    
                    {parseFloat(product.quantity * product.rate).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  <tr>
                    {/* <th className="text-900">Subtotal:</th> */}
                    {/* <td className="fw-semi-bold">
                      
                      {parseFloat(subtotal).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td> */}
                  </tr>
                  <tr>
                    {/* <th className="text-900">Tax 5%:</th> */}
                    <td className="fw-semi-bold">
                      {/* $
                      {parseFloat(tax).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })} */}
                    </td>
                  </tr>
                  <tr className="border-top">
                    <th className="text-900">Toplam:</th>
                    <td className="fw-semi-bold">
                
                      {parseFloat(subtotal).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
       
      </Card.Body>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ürün Resmi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={modalImage} alt="Product" style={{ width: '100%' }} />
        </Modal.Body>
      </Modal>
    </Card>
  );
};

OrderedProducts.propTypes = {};

export default OrderedProducts;
