import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CartNotification from 'components/navbar/top/CartNotification';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import NineDotMenu from './NineDotMenu';
import { AuthContext } from 'context/AuthContext';

const TopNavRightSideNavItem = () => {

  const {currentUser} = useContext(AuthContext)



   
    const capitalizeFirstLetter = (string) => {
      if (typeof string !== 'string') {
        console.error('capitalizeFirstLetter fonksiyonuna string olmayan bir veri gönderildi:', string);
        return '';
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    
  
    const capitalizedUser = capitalizeFirstLetter(currentUser);

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
     
    >
        <div style={{color:"#2d58ff", marginRight:"5px", fontWeight:"400"}}>{capitalizedUser}</div>
        
      <Nav.Item as={'li'}>
     
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
          style={{ 
            marginBottom:'3px'
          }}
        >
        
          <OverlayTrigger
            key="left"
            style={{ marginBottom: '100px' }} 
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
         
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      
      {/* <CartNotification /> */}
      {/* <NotificationDropdown /> */}
      {/* <NineDotMenu /> */}
      <ProfileDropdown />
     
    </Nav>
  );
};

export default TopNavRightSideNavItem;
