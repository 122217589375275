import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, Col, InputGroup, Alert, Row } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import useOrderList from 'hooks/useOrderList';
import Pagination from './PaginationOrder';
import Button from 'react-bootstrap/Button';
import { Search } from 'react-bootstrap-icons';
import gif from 'assets/img/gif_export.gif'
import gifOrder from 'assets/img/gif_order.gif'
import useOrderInvoiceList from 'hooks/useOrderInvoiceList';
import IconButton from 'components/common/IconButton';
import useVendorList from 'hooks/useVendorList';
import { PermsContext } from 'context/PermsContext';
import DatePicker from  "react-datepicker";
import useVendorStore from 'hooks/useVendorStore';
import useOrderPicking from 'hooks/useOrderPicking';
import Modal from 'react-bootstrap/Modal';
import useOrderPickingExportS3 from 'hooks/useOrderPickingExportS3';
import useUserRegionList from 'hooks/useUserRegionList';
import useInventoryRegion from 'hooks/useInventoryRegion';
import gif_s3 from 'assets/img/gif_export_s3.gif'
import { toast } from 'react-toastify';




const OrderPicking = () => {

  const columns = [
    {
      accessor: 'order',
      Header: 'Sipariş',
      disableSortBy: true,
      headerProps: { className: 'text-left'},
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { order_number, vendor_store } = rowData.row.original;
        return (
          <>
         
              <strong>{vendor_store}</strong>
              <p>{order_number}</p>

          </>
        );
      }
    },
    {
      accessor: 'inventory',
      Header: 'Depo',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { inventory_name } = rowData.row.original;
        return (
          <>
            {inventory_name}
          </>
        );
      }
    },
    {
      accessor: 'user',
      Header: 'Sorumlusu',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const {username} = rowData.row.original;
        return (
          <>
        {username}
          </>
        );
      }
    },
    {
      accessor: 'start_date',
      Header: 'Başlama Zamanı',
      disableSortBy: true,
      headerProps: {
          className: 'text-left'
        },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { started_date, started_time} = rowData.row.original;
        return (
          <>
          <p style={{marginBottom:'-5px'}}>{started_date}</p>
          <strong>{started_time}</strong>
          </>
          

        );
      }
    },
    {
      accessor: 'end_date',
      Header: 'Bitiş Zamanı',
      disableSortBy: true,
      headerProps: {
          className: 'text-left'
        },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { completed_date, completed_time} = rowData.row.original;
        return (
          <>
           <p style={{marginBottom:'-5px'}}>{completed_date}</p>
           <strong>{completed_time}</strong>
          </>
         
        );
      }
    },
    {
      accessor: 'scount',
      Header: 'Toplanan Adet',
      disableSortBy: true,
      headerProps: {
          className: 'text-center'
        },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { count} = rowData.row.original;
        return (
          <p>{count}</p>
        );
      }
    },
   ,
  ];

const {myData, setPage, showOrderGif, currentPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, startDate, setStartDate, endDate, setEndDate,  inventoryId, userId, setUserId, setInventoryId} = useOrderPicking()

const {myDataS3,setMyDataS3,  startDateModal, setStartDateModal, endDateModal, setEndDateModal,getDataExport, showGif, setShowGif, myDataResult, isPreparing, setIsPreparing, setMyDataResult, getDataExportReturn, stopDataExport, setInventoryIdExport, setUserIdExport, inventoryIdExport} = useOrderPickingExportS3()


const {regionData} = useInventoryRegion()
 
const [orderList, setOrderList] = useState() 
const {myDataVendor} = useVendorList()
const {perms} = useContext(PermsContext)
const {vendorStore, setVendorIdVendorStore} = useVendorStore()
const {dataUser} = useUserRegionList()


function hasPermission(permission) {
  return perms?.includes(permission);
}



const [activePage, setActivePage] = useState(1)

  useEffect(() => {
    setPage(activePage)
  }, [activePage])


  

  useEffect(() => {
    const newList = myData?.results.map((item) => {
  
      const formatDate = (dateString) => {
        if (!dateString) {
          return { date: "", time: "" }; 
        }
        const date = new Date(dateString);
        const formattedDate = date.toISOString().split('T')[0]; 
        const time = date.toTimeString().slice(0, 5); 
        return { date: formattedDate, time: time };
      };
  
      const startedAt = formatDate(item.StartedAt);
      const completedAt = formatDate(item.CompletedAt); 
  
      return {
        order_number: "#" + item.Order.OrderNumber,
        inventory_name: item.inventory_name,
        username: item.username,
        count: item.total_count,
        vendor_store: item.Order.vendor_store,
        started_date: startedAt.date,
        started_time: startedAt.time,
        completed_date: completedAt.date || "-", 
        completed_time: completedAt.time  || "-"
      };
    });
    
    setOrderList(newList);
  
 
  
  }, [myData]);
  

const [isOpen, setIsOpen] = useState(false);

const handleToggle = (event) => {
  // event.preventDefault();
  setIsOpen(!isOpen);
};


const handleChangeDate = event => {
  setSelectedOption(event.target.value);

  let start, end;

  switch(event.target.value) {
    case "1": // Bugün
      start = new Date();
      end = new Date();
      break;

    case "2": // Bu hafta
      end = new Date();
      start = new Date();
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
      break;   

    case "3": // Bu ay
      end = new Date();
      start = new Date();
      start.setDate(1); // Ayın ilk günü
      break;
  
    default:
      // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
      break;
  }

  setStartDate(start);
  setEndDate(end);


};

const [isOpenDate, setIsOpenDate] = useState(true);

const onChange = dates => {

  const [start, end] = dates;

  if (selectedOption === "4") {
    setStartDate(start);
    setEndDate(end);

  }
  if (start && end) {
    setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
  }
};

const [selectedOption, setSelectedOption] = useState(null);


useEffect(() => {
  if (selectedOption === "4") {
    setIsOpenDate(true);
  } else {
    setIsOpenDate(false);
  }
}, [selectedOption]);


useEffect(() => {
  if (regionData?.length > 0) {
    setInventoryId(regionData[0]?.Id);
  }
}, []);




const handleChangeStore = (e)=> {
  setStore(e.target.value);
}


const handleExport = ()=> { 

  if (inventoryIdExport) {
    getDataExport(); 
    setShowGif(true);
    // setShow(false)
    setIsPreparing(true);
  
  } else {
    toast.error(`Lütfen bir depo seçiniz`);
  }
 
  
}

const handleShow = () => setShow(true);

const [show, setShow] = useState(false);

const handleClose = () => {
  setShow(false)
  setMyDataResult(false)
  stopDataExport();
  setIsPreparing(false);
}
const onChangeModal = datesExport => {
  const [startExport, endExport] = datesExport;
  setStartDateModal(startExport);
  setEndDateModal(endExport);
};

const handleChangeUser = (e)=> {
  setUserIdExport(e.target.value); 
}

const handleChangeRegion = (e)=> {
  setInventoryId(e.target.value); 


}

const handleChangeRegionExport = (e) => {
  setInventoryIdExport(e.target.value); 
}


const handleUser = (e)=> {
  setUserId(e.target.value); 
}

const handleRegion = (e)=> {
  setInventoryId(e.target.value); 
}

const handleDownload = () => {
  setIsPreparing(false);
  window.location.href = myDataResult[0]?.Value;
  setShow(false)
  setMyDataResult(false)
  setInventoryIdExport()
}



  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      // data={orderList?.length>0 && orderList} 
      selection
      sortable
      pagination
      perPage={50}
    >
     
      <Card className="mb-3">
        <Card.Header>

        <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Sipariş Toplamaları</h5>
            </div>


           
          <div style={{display:"flex", justifyContent:'right'}}>

          { hasPermission('Dashboard Sipariş Export') ?
            <div style={{ marginRight:"8px"}}>
                <IconButton
                  variant="falcon-default"
                  size="m"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={handleShow}
                  style={{width:'145px', fontSize:'15px'}}
                >
                  <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
                </div>
          : ("") }
           

              <Dropdown  show={isOpen} onToggle={handleToggle}>
                <Dropdown.Toggle
                  bsPrefix="toggle"
                  as={Link}
                  //  to=""
                  onClick={handleToggle}
                  className={classNames('px-0 nav-link')}>
            
                    <IconButton
                      variant="falcon-default"
                      size="m"
                      icon="fa-sharp fa-light fa-filter-list"
                      transform="shrink-3"
                      style={{width:'145px', fontSize:'15px'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                        <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
                      </svg>
                          <span className="d-none d-sm-inline-block ms-1">Filtre</span>
                    </IconButton>
                </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret" style={{minWidth: '300px', width: '300px'}}>
                  <Card className="shadow-none shadow-show-xl scrollbar">
                    <Card.Header className="bg-light d-none d-xl-block">
                      <h6 className="mb-0">Filtre</h6>
                    </Card.Header>
                      <Card.Body>
                        <Form>

                       
                          <div className="mb-3">
                          <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Tarih Seçimi</Form.Label>

                          <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                          <option value="" disabled selected>Tarih</option>
                          <option key={1} value="1">Bugün</option>
                          <option key={2} value="2">Bu hafta</option>
                          <option key={2} value="3">Bu ay</option>
                          <option key={3} value="4">Tarih seç</option>
                                      </Form.Select> 

                        {selectedOption === "4" && 
                          <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            formatWeekDay={day => day.slice(0, 3)}
                            endDate={endDate}
                            selectsRange
                            dateFormat="MMM dd"
                            className='form-control'
                            placeholderText='tarih giriniz'
                            open={isOpenDate}
                            onCalendarClose={() => setIsOpenDate(false)}
                            onCalendarOpen={() => setIsOpenDate(true)}
                          />
                        }
                    </div>

                        <div style={{ marginBottom: '15px' }}>
                          <Form.Label className="mb-1 fs--1" style={{ color: "#5E6E82" }}>
                            Depo Seçimi
                          </Form.Label>
                          {regionData?.length > 0 && (
                            <Form.Select
                              className="form-control"
                              style={{ fontSize: "15px" }}
                              onChange={handleRegion}
                              value={inventoryId} // Seçili değeri buraya bağlıyoruz
                            >
                              <option value="">Depo</option>
                              {regionData?.map((item, i) => {
                                return (
                                  <option key={i} value={item.Id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          )}
                        </div>

                    <div style={{marginTop:'15px', marginBottom:'55px'}}>
                    <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Personel Seçimi</Form.Label>
                          { dataUser?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleUser}>
                          <option value="" >Personel</option>
                              { dataUser?.map((item, i)=>{
                                    return <option key={i} value={item.id}>{item.username}</option>
                              })}       
                      </Form.Select>}
                  </div>
                    </Form>
                    </Card.Body>
                </Card>
                </Dropdown.Menu>
              </Dropdown>

              </div>
        </div>

    </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />


        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showOrderGif && <img src={gifOrder} style={{width:'50%'}} alt="loading gif" />}
          </div>
        
        </Card.Body>
        <Card.Footer>
          <Pagination pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage}/>
        </Card.Footer>
       
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}>sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>


      <Modal 
              show={show} 
              onHide={handleClose}
              size="xl"
              
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                  <div style={{display:'flex', justifyContent:'space-between'}} > 
                    <div>Sipariş Toplaması Listesi</div>
                  </div>
                  </Modal.Title>
                    </Modal.Header>
                      <Modal.Body>
                  
                
                  
                <div style={{display:'flex', justifyContent:'center'}} >
              
                <div style={{marginRight:'8px'}}>
                        { regionData?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} 
                  onChange={handleChangeRegionExport}
                  >
                <option value="">Depo</option>
                  { regionData?.map((item, i)=>{
                      return <option key={i} value={item.Id}>{item.name}</option>
                    })}       
                  </Form.Select>}
                </div>
          
                  <div style={{marginRight:"8px", fontSize:"14px", width:"190px"}}>
                        <DatePicker
                        showIcon
                        selected={startDateModal}
                        onChange={onChangeModal}
                        startDate={startDateModal}
                        formatWeekDay={day => day.slice(0, 3)}
                        endDate={endDateModal}
                        selectsRange
                        dateFormat="MMM dd"
                        className='form-control'
                        placeholderText='sipariş tarihi'
                        />
                  </div>
                  <div style={{marginRight:"8px"}}>
                          { dataUser?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"190px"}} onChange={handleChangeUser}>
                          <option value="" >Personel</option>
                              { dataUser?.map((item, i)=>{
                                    return <option key={i} value={item.id}>{item.username}</option>
                              })}       
                      </Form.Select>}
                  </div>
                 
        </div>
                      
                  </Modal.Body>
                  <Modal.Footer>
                  <Row style={{width:'100%'}}>      
          <Row>
            <p>Lütfen Depo seçimi yapınız!</p>

            <Col className="d-flex justify-content-end mb-3">
              <Button variant="secondary" onClick={handleClose} style={{marginRight:'10px'}}>
                Vazgeç
              </Button>

        {!isPreparing && (
          <Button variant="primary" onClick={handleExport}>
                Hazırla
              </Button>


        ) }
              

              
            </Col>
          </Row>
          {isPreparing && (!myDataResult || !myDataResult.error) && 
          
          <div>
          <div>Listeniz hazırlanıyor, lütfen bekleyin...</div>
          <img src={gif_s3} style={{width:'50%'}} alt="export gif" />
          </div>
          
          }
          {myDataResult && myDataResult[0]?.Value?.length > 0  && 
          <Row className="justify-content-center mt-5" style={{width:'100%'}}>
            <Col style={{display: "flex", justifyContent: "center"}}>
            <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                {/* {myDataS3?.message} */}
                Listesiniz hazır indirebilirsiniz.
              </Alert>
              <Button 
          variant="primary" 
          style={{width:'150px', height:'40px'}}
          // onClick={() => window.location.href = myDataS3?.download_url}
          onClick={handleDownload}
        >
          İndir
        </Button>

            </Col>
          </Row>

          }
          </Row>
              </Modal.Footer>
      </Modal>


    </AdvanceTableWrapper>
  );
};

export default OrderPicking;
