import React from 'react';
import { Card, Col, Row, Table, Button, Form, Tab, Nav } from 'react-bootstrap';
import { useLocation, Link, useNavigate } from 'react-router-dom';



const StorageFeesSKUDayDetail = () => {

  const location = useLocation()
  const { daily_fees, id, inventory_id, daily_storage } = location.state || {};




  const detailData = daily_fees?.map(item => ({
    id: item.Id,
    date: item.Date,
    sku : item.SKU,
    EndOfDayCount: item.EndOfDayCount || "-",
    EndOfDayVolume: item.EndOfDayVolume || "-",
    ProductVolume : item.ProductVolume
  
  })) || [];


 


const navigate = useNavigate();

const handleBackClick = () => {
 
    // navigate('/costs-storage-detail');
    navigate('/costs-storage-detail', { state: { id, inventory_id, daily_storage } })
 
};


  return (
    <>
      <span onClick={handleBackClick} style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
    
    <Card className="mb-3 mt-1">
      <div style={{marginLeft:'18px', marginTop:'15px'}}>
        <h6>SKU Gün Sonları</h6>
      </div>
   
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Tarih</th>
                <th className="border-0">SKU</th>
                <th className="border-0 text-center">Gün Sonu Adet</th>
                <th className="border-0 text-center">Ürün Desi</th>
                <th className="border-0 text-center">Gün Sonu Hacim</th>
              </tr>
            </thead>
            <tbody>
              {detailData?.length>0 && detailData?.map(data => (
                <tr key={data.id} className="border-200">
                 

                  <td className="align-middle">
                    {data.date}
                  </td>

                  <td className="align-middle text-center">
                    {data.sku}
                  </td>

                  <td className="align-middle text-center">
                    {data.EndOfDayCount}
                  </td>

                  <td className="align-middle text-center">
                    {data.ProductVolume}
                  </td>

                  <td className="align-middle text-center">
                    {data.EndOfDayVolume}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  <tr>
                    <td className="fw-semi-bold">
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>

      </Card.Body>
    </Card>
    </>
  );
};

StorageFeesSKUDayDetail.propTypes = {};

export default StorageFeesSKUDayDetail;
