import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Form, Button, Modal, Table, OverlayTrigger, Tooltip, InputGroup} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import gifOrder from 'assets/img/gif_order.gif'
import {  PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import PaginationOrder from './PaginationOrder';
import AppContext from 'context/Context';
import ProductsTableHeader from './ProductsTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import useVendorStoreProduct from 'hooks/useVendorStoreProduct';
import useVendorStoreParam from 'hooks/useVendorStoreParam';
import useBarcodeSku from 'hooks/useBarcodeSku';
import { MdClear } from "react-icons/md";
import { RiArrowLeftRightFill, RiDeleteBin2Line } from "react-icons/ri";
import { BiPlus } from "react-icons/bi";
import Select from 'react-select';
import useProductCategory from 'hooks/useProductCategory';
import useProductAdd from 'hooks/useProductAdd';
import { toast } from 'react-toastify';
import { Search } from 'react-bootstrap-icons';
import useVendorStoreProductLink from 'hooks/useVendorStoreProductLink';
import { TfiPackage } from "react-icons/tfi";
import { BsThreeDots } from "react-icons/bs";
import useProductAddVendorStore from 'hooks/useProductAddVendorStore';



const VendorStoreProduct = () => {

  const columns = [
    {
      accessor: 'image',
      Header: 'Ürün',
      disableSortBy: true,
      headerProps: { className: 'text-left'},
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { image } = rowData.row.original;
        return (
          <>
             <img style={{width:"40px", maxWidth:"100%",  transition:"transform 0.5s"}} 
            src={image} alt=""
            
            />
          </>
        );
      }
    },
    {
      accessor: 'name',
      disableSortBy: true,
      Header: <span style={{marginLeft:"15px"}}>İsim</span> ,
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <>
  
             <td style={{width:"750px", color:"#637386"}}
                    title={name}
                    onMouseOver={(e) => (e.currentTarget.title = name)}
                    >
                {name.length > 60
                        ? name.slice(0, 60).concat("...")
                        : name}
            
        </td>
      
          </>
        );
      }
    },
    {
      accessor: 'sku',
      // Header: 'Tutar',
      Header: () => (
        <div 
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
          className='text-center' 
         
        >
         SKU  
          
        </div>
      ),
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.sku}`
    },
  
    {
      accessor: 'status',
      Header: 'Durumu',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              success: status === '1', 
              primary: status === '0',  
            
            })}
            className="d-block"
          >
            {status === '0' && 'Eşleşmemiş'} 
            {status === '1' && 'Eşleşmiş'}  
            <FontAwesomeIcon
              icon={classNames({
                check: status === '1',
                ban: status === '0',
               
              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      }
    },
    
    {
      Header: 'Aksiyon',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const {id, name, sku, image, barcode, price, brand, category, product} = rowData.row.original;
        const [isMenuOpen, setIsMenuOpen] = useState(false);
        const menuRef = useRef(null);
    
        useEffect(() => {
          const handleClickOutside = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
              setIsMenuOpen(false);
            }
          };

          document.addEventListener('mousedown', handleClickOutside);
          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
          };
    
      
        }, []);
    
        const handleMenuClick = () => {
          setIsMenuOpen(!isMenuOpen);
        };
    
      
        const modalMatchCatalogProduct = () => {
          setShowCancel(true);  
          setVendorStoreProductId(id); 
          setNameMatch(name);
          setImageMatch(image);
          setSKUMatch(sku)
        };
    
        const modalNewCatalogProduct = () => {
          setIsModalOpen(true);
          // getOrderWork(id.slice(1)); 
    
          setTitle(name)
          setSKU(sku)
          setBarcodes(barcode)
          setPrice(price)
          setImage(image)
          setBrand(brand)
          setNewCategory(category)
          setVSProductId(id);
        };

        const modalCatalogProduct = () => {
          setIsModelCatalog(true)
          setCatalogProduct(product)
          setVendorStoreProductId(id); 
        }
    
      
    
        return (
          <div style={{display:'flex',  justifyContent: 'center'}}>
          <div
            ref={menuRef}
            onClick={handleMenuClick}
            style={{
              cursor: 'pointer',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              width: '30px',
              // fontSize:'25px'
            }}
          >
              <BsThreeDots style={{fontSize:'20px'}}/>
            {isMenuOpen && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '0',
                  transform: 'translate(-110%, -50%)',
                  backgroundColor: 'white',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  zIndex: 1000,
                }}
              >
              <ul style={{ listStyle: 'none', padding: 0, margin: 0, width:'215px'}}>
              {product ? (
                    <li className="listItem" onClick={modalCatalogProduct}>
                      <TfiPackage style={{ marginRight: '10px', fontSize: '20px' }} />
                      Eşleşen Katalog Ürünü
                    </li>
                  ) : hasPermission('Platform Ürünü Eşleme') ? (
                    <>
                      <li className="listItem" onClick={modalMatchCatalogProduct}>
                        <RiArrowLeftRightFill style={{ marginRight: '10px', fontSize: '20px' }} />
                        Mevcut Ürün ile Eşleştir
                      </li>
                      <li className="listItem" onClick={modalNewCatalogProduct}>
                        <BiPlus style={{ marginRight: '10px', fontSize: '20px' }} />
                        Yeni Katalog Ürünü Oluştur
                      </li>
                    </>
                  ) : (
                    <li className="listItem">
                      Aksiyon Bulunmamaktadır
                    </li>
                  )}
              </ul>
              </div>
            )}
          </div>
          </div>
        );
      }
    }
    
  ];

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);



const {perms} = useContext(PermsContext)


const {myData,setMyData,setVendorId, showGif, currentPage,
  setCurrentPage, totalPages, getDataProduct, setVendorStore, vendorId, setTotalPages, setPage, setSearchName, setClick, setStatus}  = useVendorStoreProduct()

 const {postLink, setProductId, postUnLink}= useVendorStoreProductLink()

const {myDataVendor} = useVendorList()

const {vendorStore, setVendorIdVendorStore} = useVendorStoreParam()
const [activePage, setActivePage] = useState(1)
const {getData, setVendorIdBarcode} = useBarcodeSku()
const {categoryData,setVendorIdCategory } = useProductCategory()



  function hasPermission(permission) {
    return perms?.includes(permission);
  }

  console.log("hasPermission", hasPermission('Platform Ürünü Eşleme'))
 
 

 

  useEffect(() => {
    if (myDataVendor && myDataVendor.length > 0) {
      const firstVendorId = myDataVendor[0].Id;
      setVendorId(firstVendorId)
      setVendorIdVendorStore(firstVendorId)
      setVendorIdCategory(firstVendorId)
      setVendorIdBarcode(firstVendorId)
      setVendorIdNewProduct(firstVendorId)
    }
  }, [myDataVendor]);



  const handleChangeVendor = (e)=> {
    setVendorId(e.target.value)
    setVendorIdVendorStore(e.target.value)
    setVendorIdCategory(e.target.value)
    setVendorIdBarcode(e.target.value)
    setVendorIdNewProduct(e.target.value)
  }

  const handleVendorStore = (e) => {
    setVendorStore(e.target.value)
  }



  const newList = myData?.results ? myData?.results.map((item)=>{
    return {
      id : item.Id,
      name: item.Name, 
      image : item.Image,
      sku : item.SKU,
      barcode: item.barcodes[0],
      price: item.Price,
      brand: item.Brand,
      category : item?.Category?.Name,
      product : item.Product,
      status : item.Product ? "1" : "0"
     
      
    }
  }) : [];



const handleSubmit = () => {

  getDataProduct()

};

// modals match

const [showCancel, setShowCancel] = useState(false);
const handleCloseCancel = () => {
  setShowCancel(false);
  setSelectedValue(null)
  setShowTable(false)
}
const [showTable, setShowTable] = useState(false);

const handleLink = async () => {
    postLink(vendorStoreProductId)

    setShowCancel(false);
    setShowTable(false)

  
}



const [products, setProducts] = useState([]);

const [vendorStoreProductId, setVendorStoreProductId] = useState()
const [nameMatch, setNameMatch] = useState()
const [imageMatch, setImageMatch] = useState()
const [skuMatch, setSKUMatch] = useState()

const handleAddProduct = (e) => {
  e.preventDefault();
  
  setProducts([{name: selectedValue.Title, barcode:selectedValue.barcodes__Barcode, sku: selectedValue.SKU, image :selectedValue.Image, Id : selectedValue.Id }])

  setShowTable(true);
  setSelectedValue(null)
}


const [isModalOpen, setIsModalOpen] = useState(false);

const handleModalClose = () => {
  setIsModalOpen(false);
 
};


// modal catalog

const [isModalCatalog, setIsModelCatalog ] = useState(false)
const [catalogProduct, setCatalogProduct]=useState()

console.log("catalogProduct", catalogProduct)


const handleCatalogClose =  ()=>{
   setIsModelCatalog(false)
}

const handleUnlink =  async () => {
   postUnLink(vendorStoreProductId)
   setIsModelCatalog(false)
 
  
}



// modals new product

const CustomClearText = props => (
  <components.ClearIndicator {...props}>
   <MdClear />
  </components.ClearIndicator>
);

// async select

const [selectedValue, setSelectedValue] = useState("");
const [barcode, setBarcode] = useState("")

const handleInputChange = value => {
  setBarcode(value);

};

const handleChange = value => {
  if (value) {
    setSelectedValue(value);
    setProductId(value.Id); 
    console.log("handleChange", value);
  } else {
    setSelectedValue(""); 
    setProductId(""); 
  }
};


// new catalog add
const {formData, setFormData, postData, postLink2, setVSProductId} = useProductAddVendorStore()


const handleNewCategoryChange = event => {
  setNewCategory(event.target.value);
  if (event.target.value) {
    setCategory(null); 
  }
};


const categoryList = categoryData?.map((item)=>{
  return{
      value: item.Name,
      label: item.Name
  }
})

const handleCategoryChange = selectedOption => {
  setCategory(selectedOption);
  setNewCategory(""); 
};

const [vendorIdNewProduct, setVendorIdNewProduct] = useState()
const [title, setTitle] = useState()
const [sku, setSKU] = useState()
const [brand, setBrand] = useState()
const [category, setCategory] = useState()
const [volume, setVolume] = useState()
const [weight, setWeight] =useState()
const [price, setPrice] = useState()
const [image, setImage] = useState()
const [barcodes, setBarcodes] = useState()
const [newCategory, setNewCategory] = useState()





const handleSubmitNewProduct = (e) => {
  e.preventDefault();

  if (category && newCategory) {
    toast.warn('Lütfen sadece bir kategori seçin veya girin.');
  } else if (!category && !newCategory) {
    toast.warn('Lütfen bir kategori seçin veya girin.');
  } else {
    console.log('Form gönderildi', { category, newCategory });
    setFormData({
      ...formData, "Title": title,
                    "SKU": sku,
                    "Brand": brand,
                    "category_name": category ? category.value : '',
                    "Volume": volume,
                    "Weight": weight,
                    "Price": price,
                    "Image": image,
                    "Barcode": barcode,
                    "new_category":  newCategory,
                    "vendorId" :  vendorIdNewProduct
                    
      })  
  }

// postLink2(vendorStoreProductId)
setCategory(null);
setSKU(null);
setTitle(null);
setBrand(null);
setVolume(null)
setWeight(false)
e.target.reset();
setPrice(null)
setImage(null)
setBarcode(null)

}

const handleChangeName = (e) => {
  setSearchName(e.target.value)
}


const productStatus = [
  {value : "true", label : "Eşleşmiş"},
  {value : "false", label : "Eşleşmemiş"}
]

const handleChangeMatch = (e)=> {
  setStatus(e.target.value); 
 
}

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={newList || []} 
      // onSelectedOrderIdsChange={handleSelectedOrderIdsChange}
      selection
      sortable
      pagination
      perPage={50}
      // selectedCount
  
    >
      <Card className="mb-3">
<div style={{display:"flex", justifyContent:"space-between", marginTop:'15px', marginLeft:'15px', marginBottom:'30px'}}>
{ hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                  <div style={{marginRight:"10px"}}>
                    { myDataVendor?.length> 1 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                     <option value="" >Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
            : "" }
      </div>

        <Card.Header>

      <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Platform Ürünleri</h5>
            </div>

            <div style={{display:"flex", width:'100%', justifyContent: 'flex-end'}}>

            { < Form.Select  className='form-control' style={{fontSize:"15px", width:'25%'}} onChange={handleVendorStore} >
                 <option value="" >Platformlar</option>
                     { vendorStore?.map((item, i)=>{
                           return <option key={i} value={item.Id}>{item.Store__Name}</option>
                     })}       
              </Form.Select>}

             <Button
              onClick={handleSubmit}
             style={{marginLeft:'5px'}}> 
              Ürünleri Listele
             </Button>
            </div>
      </div>


{myData && 
      <div style={{display:'flex' , justifyContent: 'flex-end', marginTop:'30px'}}>
          <div className="mb-3" style={{width:'22.5%', marginRight:'5px'}} >
                    <InputGroup>
                      <Form.Control  type="text" placeholder="ürün ismi, sku, barkod..."  
                      onChange={handleChangeName}
                     />
                      <InputGroup.Text><Search/></InputGroup.Text>
                      </InputGroup>
            </div>
            <div>
                    <Form.Select  className='form-control' style={{fontSize:"14px"}} onChange={handleChangeMatch}>
                            <option value=""  >Durumu</option>
                                {productStatus?.map((item, i)=>{
                                      return <option  key={i} value={item.value}>{item.label}</option>
                                })}
                        </Form.Select> 
            </div>
      </div>

}
</Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />


        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showGif && <img src={gifOrder} style={{width:'50%'}} alt="loading gif" />}
          </div>
        
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
  <PaginationOrder pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage}/>
        </Card.Footer>
       
      </Card>
      

        {/* Katalog Ürünü Oluştur */}
      <Modal show={isModalOpen} onHide={handleModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Katalog Ürünü Oluştur</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form onSubmit={handleSubmitNewProduct}>
  <Form.Group controlId="formDepo" className="mb-3"  >
     <Form.Group controlId="formDepo" className=""  >
            <Form.Label>Ürün İsmi</Form.Label>
            <Form.Control type="text"  value={title} placeholder="Ürün ismi"  onChange={(e)=>setTitle(e.target.value)}/>
        </Form.Group>

      <div style={{display:'flex', flexWrap:'nowrap', marginTop:'15px'}}>
        
        <div  style={{marginRight:'10px', width:'50%'}}>
        <Form.Label>SKU</Form.Label>
        <Form.Control type="text" value={sku} placeholder="SKU"  onChange={(e)=>setSKU(e.target.value)}/>
        </div>
      
        <div  style={{ width:'50%'}}>
        <Form.Label>Barkod</Form.Label>
        <Form.Control type="text" placeholder="Barkod"  onChange={(e)=>setBarcode(e.target.value)}/>
        </div>
    </div>

    <div style={{display:'flex', flexWrap:'nowrap', marginTop:'15px'}}>
        
        <div  style={{marginRight:'10px', width:'50%'}}>
        <Form.Label>Marka</Form.Label>
        <Form.Control type="text" placeholder="Markası" value={brand}  onChange={(e)=>setBrand(e.target.value)}/>
        </div>
      
        <div  style={{width:'50%'}}>
        <Form.Label>Fiyat</Form.Label>
        <Form.Control type="text" placeholder="Fiyat" value={price} onChange={(e)=>setPrice(e.target.value)}/>
        </div>
    </div>

    <div style={{display:'flex', flexWrap:'nowrap', marginTop:'15px'}}>
        
        <div  style={{marginRight:'10px', width:'50%'}}>
        <Form.Label>Ağırlık</Form.Label>
        <Form.Control type="text" placeholder="ağırlık gram"  onChange={(e)=>setWeight(e.target.value)}/>
        </div>
      
        <div  style={{width:'50%'}}>
        <Form.Label>Hacim</Form.Label>
        <Form.Control type="text" placeholder="desi bilgisi"  onChange={(e)=>setVolume(e.target.value)}/>
        </div>
    </div>

    <Form.Group controlId="formDepo" className="mt-3 mb-3"  >
            <Form.Label>Ürün resimi</Form.Label>
            <Form.Control type="text" placeholder="URL linki (max 300 karakter)" value={image}  onChange={(e)=>setImage(e.target.value)}/>
        </Form.Group>

        <div style={{display:'flex', flexWrap:'nowrap', marginTop:'15px'}}>

            <div  style={{width:'50%', marginRight:'10px',}}>
                <Form.Label>Mevcut Kategoriler</Form.Label>
                  <Select options={categoryList} 
                  isSearchable={true} 
                  placeholder="kategori seçimi"
                  onChange={handleCategoryChange} 
                  value={category}
                  isClearable={true}
                  styles={{control: (baseStyles) =>({
                        ...baseStyles, 
                        backgroundColor:isDark ? "#0B1727" : "white"
                      }),}}
                      />
            </div>    


            <div  style={{width:'50%'}}>
                <Form.Label>Kategori 
            
                </Form.Label>
                <Form.Control type="text"  value={newCategory}  placeholder="kategori bulunamadıysa"  onChange={handleNewCategoryChange}/>
            </div>

       </div>

  </Form.Group>


  <Button variant="primary" type="submit" className="d-flex mx-auto mt-5 mb-2" >
    Onayla
  </Button>

      </Form>
   

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Kapat
          </Button>
         
        </Modal.Footer>
      </Modal> 

        {/* Mevcut Ürün ile Eşleştir */}
    <Modal show={showCancel} onHide={handleCloseCancel} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:'16px'}}>Mevcut Ürün ile Eşleştir</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Table striped className="border-bottom">
          
          <thead className="bg-200 text-900">
            <tr>
              <th className="border-0">Ürün</th>
              <th className="border-0">İsim</th>
              <th className="border-0 text-center">SKU</th>
            </tr>
          </thead>
          <tbody>
          <tr  className="border-200">
              <td className="align-middle">
                <img style={{width:"40px"}}src={imageMatch} alt="" />
              </td>

              <td className="align-middle text-left" style={{whiteSpace: 'normal'}}
                  title={nameMatch}
              >
                {nameMatch?.length > 40
                      ? nameMatch?.slice(0, 40).concat("...")
                      : nameMatch}
              </td>
              <td className="align-middle text-center">
                  {skuMatch}
              </td>
          </tr>
          </tbody>
          </Table>

          <Form.Group controlId="tek çeşit ürünler" className="mb-3 mt-6"  >
              <Form.Label style={{fontSize:'16px', color:'#334050'}}>Katalog Ürünü Seçiniz</Form.Label>
          <div style={{display:"flex"}}>
             <div style={{width:'90%'}}>
              <AsyncSelect 
                    loadOptions={getData}
                    placeholder="isim, barkod, sku numarası" 
                    className="mb-3" 
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                    value={selectedValue}
                    getOptionLabel={e => e.Title}
                    getOptionValue={e => e.Barcode}
                    isClearable
                    components={{ ClearIndicator: CustomClearText }}
                    styles={{control: (baseStyles) =>({
                      ...baseStyles, 
                      backgroundColor:isDark ? "#0B1727" : "white"
                    }),}}
                /> 
              </div>

              <div style={{marginLeft:'15px'}}>
                  <Button variant="primary" onClick={handleAddProduct}>
                    Bul
                  </Button>
                </div>
            </div>


          </Form.Group>

          {showTable && (


          <div>
          <Table striped className="border-bottom">
                  <thead className="bg-200 text-900">
                    <tr>
                    <th className="border-0"></th>
                      <th className="border-0">Ürün</th>
                      <th className="border-0">İsim</th>
                      <th className="border-0 text-center">SKU</th>
                    </tr>
                  </thead>
                  <tbody>
                 
                  {products?.map((product, i) => (
                      <tr key={i} className="border-200">
                        <td style={{marginRight:"0"}}>        
                        
                    
                        </td>
                          <td className="align-middle" style={{marginLeft:"0"}}>
                            <img style={{width:"40px"}}src={product.image} alt="" />
                          </td>

                          <td className="align-middle"  title={product.name}>
                            {product.name.length > 40
                                ? product.name.slice(0, 40).concat("...")
                                : product.name}
                          </td>

                          <td className="align-middle text-center">
                            {product.sku}
                          </td>

                      </tr>
                    ))}
                      
                  </tbody>
                </Table>
              
          </div>
        )}

      </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseCancel}>
            Vazgeç
        </Button>
          <Button variant="success" onClick={handleLink}>
            Eşleştir
          </Button>
        </Modal.Footer>
      </Modal>


      {/* eşleşmeyi kaldırma */}
      <Modal show={isModalCatalog} onHide={handleCatalogClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:'16px'}}>Eşleştirilmiş Katalog Ürünü</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div>
          <Table striped className="border-bottom">
                  <thead className="bg-200 text-900">
                    <tr>
                    <th className="border-0"></th>
                      <th className="border-0">Ürün</th>
                      <th className="border-0">İsim</th>
                      <th className="border-0 text-center">SKU</th>
                      <th className="border-0 text-center">Eşleşmeyi Kaldır</th>

                    </tr>
                  </thead>
                  <tbody>
                  <tr className="border-200">
                        <td style={{marginRight:"0"}}>        
                      
                        </td>
                          <td className="align-middle" style={{marginLeft:"0"}}>
                            <img style={{width:"40px"}}src={catalogProduct?.Image} alt="" />
                          </td>

                          <td className="align-middle"  title={catalogProduct?.Title}>
                            {catalogProduct?.Title.length > 40
                                ? catalogProduct?.Title.slice(0, 40).concat("...")
                                : catalogProduct?.Title}
                          </td>

                          <td className="align-middle text-center">
                            {catalogProduct?.SKU}
                          </td>

                          <td className="align-middle text-center">
                          <RiDeleteBin2Line  style={{fontSize:'20px'}} onClick={handleUnlink}/>
                          </td>

                      </tr>
               
                  </tbody>
                </Table>
              
          </div>
      </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={handleCatalogClose}>
            Vazgeç
        </Button>
        </Modal.Footer>
      </Modal>





    </AdvanceTableWrapper>
  );
};

export default VendorStoreProduct;





