import { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from 'context/AuthContext';
import { toast } from 'react-toastify';
import {BASE_URL} from './baseUrl'
import useVendorList from './useVendorList';

export const useInventoryUpload = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const {myKey} = useContext(AuthContext)

    const {myDataVendor} = useVendorList()

    const [vendorIdUpload, setVendorIdUpload] = useState()

    useEffect(() => {
        if (myDataVendor?.length === 1) {
            setVendorIdUpload(myDataVendor[0].Id);
        }
      }, [myDataVendor]);


    const uploadFile = async (file) => {
        if (!file.type.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            setErrorMessage("Invalid file type. Please upload an Excel file.");
            toast.error("Invalid file type. Please upload an Excel file."); 
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${BASE_URL}wms/api/v1/inventory_shipment/inventory_import/?vendor_id=${vendorIdUpload}`, formData, {
                headers: {"Authorization": "Token "+myKey},
            });

            console.log(response.data);
            setErrorMessage("");
            toast.success("Dosya yükleme işlemi başarılı!"); 
        } catch (error) {
            // Error handling
            // Similar to what we had before
            // ...

            setErrorMessage(`Error: ${error.message}`);
            toast.error(`Error: ${error.message}`); 
        }
    };

    return { uploadFile, errorMessage, setVendorIdUpload };
};
