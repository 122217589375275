import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import useRefundStatistics from 'hooks/useRefundStatistics';
import { Card, Row, Form } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import Background from 'components/common/Background';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from 'context/Context';


const StatisticsCardsRefend = () => {


const {myData} = useRefundStatistics()
const filteredMonths = ["Bugün", "Bu Hafta", "Bu Ay"] 
const [selectedOption, setSelectedOption] = useState("Bugün");

// useEffect(() => {
//   const now = new Date();
  
//   setBeginDate(now.toISOString().split("T")[0]);
//   setEndDate(now.toISOString().split("T")[0]);
// }, []); 

// const handleChange = (e) => {
//   setSelectedOption(e.target.value);

//   const now = new Date();
//   let beginDate = new Date();

//   switch(e.target.value) {
//     case "Bugün":
//       // Begin and end dates are both 'now'
//       break;
//     case "Bu Hafta":
//       const dayOfWeek = now.getDay(); // 0 (Sunday) through 6 (Saturday)
//       const diff = now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6:1); // last Monday
//       beginDate.setDate(diff);
//       break;
//     case "Bu Ay":
//       beginDate.setDate(1); // First day of current month
//       break;
//     default:
//       beginDate = now; // By default, begin and end dates are 'now'
//   }

//   setBeginDate(beginDate.toISOString().split("T")[0]);
//   setEndDate(now.toISOString().split("T")[0]);
// };



  const statsData = [
    {
      title: 'İade Ürün Sayısı',
      value: (myData?.refund_count || 0),
      // value: myDataStock?.total_stock_value,
      decimal: true,
      // suffix: 'k',
      prefix: '',
      valueClassName: 'text-warning',
      badgeBg: 'warning',
      badgeText: '-0.23%',
      link: '/e-commerce/customers',
      linkText: 'See all',
      image: bg1
    }]
    const {
      config: { isDark, isRTL },
      setConfig
    } = useContext(AppContext);


  return (
    <>
    <Card style={{height:"165px", backgroundColor:isDark ? "#0B1727" : "#F6FAFF", marginLeft:"10px"}}>
      {/* <Background image={statsData[0]?.image} /> */}

      <FalconCardHeader
        title=   {
          
            <div>
            <h6 className="mt-1" style={{color: isDark ? "#D8E2EF":"#025098"}}>{statsData[0]?.title}</h6>
            </div>

          
        }
        titleTag="h6"
        className="pb-0"
    
        endEl={
          <Flex>
              {/* <Form.Select  className='form-control' style={{fontSize:"17px", position:"relative", zIndex:10}}
               onChange={handleChange}
               value={selectedOption}
               size="sm"
               >
                     <option value="">Tarih</option>
                        {filteredMonths?.map((item, i)=>{
                              return <option  key={i} value={item}>{item}</option>
                        })}
                </Form.Select>  */}
          </Flex>
        }
      />
      <Card.Body >
        <h6 className="mb-5">
       
        </h6>
        <div>
        
            <p className='mb-0' 
             style={{fontSize:"25px", color: isDark ? "#D8E2EF":"#5E6E82"}}
            >{statsData[0]?.value}</p>
        </div>
     
      </Card.Body>
    </Card>
    </>
  );
};

export default StatisticsCardsRefend;
