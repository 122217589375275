import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'



export default function useForecastExport() {


  const {myKey, currentUser} = useContext(AuthContext)

  const date = new Date();

  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); 
  const year = today.getFullYear();
  const formattedDate = `${day}.${month}.${year}`




 const getDataExport = async ()=>{
      var myHeaders = new Headers();
      let vendor_id;
      let request_url;
      

     
      request_url = `${BASE_URL}api/v1/order/export_forecasting_csv/?vendor_username=${currentUser}`;

    
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.download = `Tahminler-${currentUser}-${formattedDate}.csv`

    const handleSuccess = (response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        anchor.href = url;
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    };

    const handleError = (error) => {
      console.log("error", error);
    };

    var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Token "+myKey},
            redirect: 'follow'
          }
    fetch(request_url, requestOptions)
    .then(handleSuccess)
    .catch(handleError);
};


 return {getDataExport}
}
