import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'



export default function useVendorStoreListAuthorized() {

  const [myDataVendorStore, setMyDataVendorStore] = useState()

  const {myKey, } = useContext(AuthContext)
  const [vendorIdVS, setVendorIdVS] = useState()


 
 const getData = ()=>{
        var myHeaders = new Headers();

        
        const params = {
          vendor_id: vendorIdVS,
          store_name : "Custom"
        
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/store/integration/list_authorized/?`
    );
      
      console.log("order",request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyDataVendorStore(result)
        
    })
          .catch(error => console.log('error', error));        
 }



 useEffect(() => {
    getData();

  }, [vendorIdVS]);

 return {myDataVendorStore, setVendorIdVS, vendorIdVS}
}
