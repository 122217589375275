import useUserPasswordChange from 'hooks/useUserPasswordChange';
import React, { useState } from 'react';
import { Card, Form, Button, Container, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EyeSlash, Eye } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function PasswordInputField({ label, value, name, onChange, isInvalid, errorMessage, showTooltip = false }) {
    const [showPassword, setShowPassword] = useState(false);
  
    const toggleShowPassword = () => setShowPassword(!showPassword);

    const passwordTooltip = (
        <Tooltip>
          <ul className="m-0 p-0" style={{ listStyleType: "none" }}>
            <li>- En az 8 karakter uzunluğunda olmalıdır.</li>
            <li>- Yaygın olarak kullanılan şifreler listesinde yer almamalıdır.</li>
            <li>- Yalnızca sayılardan oluşmamalıdır.</li>
          </ul>
        </Tooltip>
      );
  
    return (
      <Form.Group className="mb-3">
        <Form.Label>{label}
        {showTooltip && (
          <OverlayTrigger placement="right" overlay={passwordTooltip}>
          
            <span>
                <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  transform="shrink-1"
                  className="ms-1 text-400"
                  id="weeklySalesTooltip"
                />
              </span>
          </OverlayTrigger>
        )}
        </Form.Label>
        <InputGroup>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            // placeholder={label}
            name={name}
            value={value}
            onChange={onChange}
            isInvalid={isInvalid}
            required
          />
          <Button variant="outline-secondary" onClick={toggleShowPassword} 
            style={{ borderRadius: '0 4px 4px 0', border: '1px solid #D8E2EF', borderLeft: 'none' }}>
              {showPassword ? <EyeSlash /> : <Eye />}
          </Button>
         
          {isInvalid && <Form.Control.Feedback type="invalid">
            {errorMessage}
          </Form.Control.Feedback>}
        </InputGroup>
      </Form.Group>
    );
}

function VendorInfoCreate() {



 const {postData} = useUserPasswordChange()
 const navigate = useNavigate();

 const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });


  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    // Hataları temizle
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      setErrors({ confirmPassword: 'Parolalar eşleşmiyor.' });
    } else {
      if(formData) {
        postData(formData);
      }
    }
  };


  return (
    <Container className="d-flex justify-content-center" style={{ height: '60vh' }}>
    <Card style={{ width: '800px', marginTop:'40px' }}>
        <Card.Header>
        <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Satıcı Bilgileri</h5>
            </div>
        </div>
            
            </Card.Header>
      <Card.Body style={{padding:'70px',}}>
          <Form onSubmit={handleSubmit}>
            


          <div style={{display:'flex', justifyContent:'space-between', marginBottom:'20px'}}>

            <Form.Control required placeholder="isim*" style={{marginRight:'10px', marginBottom:'10px'}}   
              // onChange={(e)=>setCustomerName(e.target.value)}
              // value={customerName || ''}
            ></Form.Control>

            <Form.Control required placeholder="soyisim*"  style={{ marginBottom:'10px'}} 
              // onChange={(e)=>setCustomerLastName(e.target.value)}
              // value={customerLastName || ''}
            ></Form.Control>
            </div>

            <div style={{display:'flex', justifyContent:'space-between', marginBottom:'20px'}}>

                <Form.Control required placeholder="kullanıcı adı*" style={{marginRight:'10px', marginBottom:'10px'}}   
                  // onChange={(e)=>setCustomerName(e.target.value)}
                  // value={customerName || ''}
                ></Form.Control>

                <Form.Control required type="email" placeholder="name@example.com"  style={{ marginBottom:'10px'}} 
                  // onChange={(e)=>setCustomerLastName(e.target.value)}
                  // value={customerLastName || ''}
                ></Form.Control>
                </div>



            <PasswordInputField
              label="Parola"
              value={formData.newPassword}
              name="newPassword"
              onChange={handleChange}
              showTooltip={true} 
            />
            <PasswordInputField
              label="Parola Tekrar"
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleChange}
              isInvalid={!!errors.confirmPassword}
              errorMessage={errors.confirmPassword}
            />

            <div style={{display:'flex', justifyContent:'center', marginTop:'50px'}}>
            <Button variant="primary" type="submit">
              Kayıt Oluştur
            </Button>
            </div>

          </Form>
          </Card.Body>
      </Card>
    </Container>
       
  );
}

export default VendorInfoCreate;
