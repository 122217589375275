import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { PermsContext } from 'context/PermsContext';


export default function useTotalSales() {

 const [myData, setMyData] = useState()

 const {myKey, currentUser} = useContext(AuthContext)
 const {vendorIdContext} = useContext(PermsContext)

 const [loading, setLoading] = useState(true);
 const getData = ()=>{

        var myHeaders = new Headers();


          const params = {
            vendor_id: vendorIdContext
          };
    
          const request_url = Object.entries(params)
              .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
              .reduce(
                (url, [key, value]) => url + `&${key}=${value}`,
                `${BASE_URL}api/v1/order/sales_total_daily/?`
          );
        
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result)
            setLoading(false) 
    })
          .catch(error => console.log('error', error));
        
 }

 useEffect(() => {
    getData();
  }, [vendorIdContext]);

 return {myData, loading, setLoading}
}
