import { AuthContext } from 'context/AuthContext';
import React, {useContext, useEffect, useState} from 'react'
import {BASE_URL} from './baseUrl'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';




export default function useOrderWorkListPost() {

  const {myKey, currentUser} = useContext(AuthContext)

  const [orderId, setOrderId] = useState([])
  const navigate = useNavigate();


       
  const getOrderWork = (selectedList) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL + 'api/v1/order/work_order/';

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "order_ids": selectedList
      }),
      redirect: 'follow'
    };



    fetch(request_url, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          console.log("Post işlemi başarısız.");
          throw new Error("Post işlemi başarısız.");
        }
      })
      .then(result => {
        console.log(result);
        toast.success(` ${selectedList} id numaralı siparişlerin iş emri oluşturuldu.`, { theme: 'colored' });
        navigate('/e-commerce/orders/order-work');

      })
      .catch(error => {
        console.log('error', error);
      });
  }

  return { getOrderWork, setOrderId};
}