import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'


export default function useUserRegionList() {

 const [dataUser, setDataUser] = useState()

 const {myKey} = useContext(AuthContext)


 const getData = ()=>{
        var myHeaders = new Headers();

     
        const params = {
         
        };
            
          const request_url = Object.entries(params)
              .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
              .reduce(
                (url, [key, value]) => url + `&${key}=${value}`,
                `${BASE_URL}api/v1/users/user/list_warehouse_users/?`
      ); 
     
      
        
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setDataUser(result) 
    })
          .catch(error => console.log('error', error));        
 }

 







 useEffect(() => {
    getData();
  }, []);

 return {dataUser, setDataUser}
}
