import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import StatisticsCard from './StatisticsCard';
// import { statsData } from 'data/dashboard/saas';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import useEcomStatStock from 'hooks/useEcomStatStock';
import AppContext from 'context/Context';
import useEcomStatStockCount from 'hooks/useEcomStatStockCount';


const StatisticsCards = () => {


const {myDataStockDashboard} = useEcomStatStock()
const {myDataStockCount} = useEcomStatStockCount()

const {
  config: { isDark, isRTL },
  setConfig
} = useContext(AppContext);



  const statsData = [
    {
      title: 'Stok Değeri / Stok Adedi',
      value: (myDataStockDashboard?.total_stock_value || 0).toLocaleString('tr-TR', {style: 'currency', currency: 'TRY'}),
      count: myDataStockCount?.total_stock_count,
      decimal: true,
      // suffix: 'k',
      prefix: '',
      valueClassName: 'text-warning',
      badgeBg: 'warning',
      badgeText: '-0.23%',
      link: '/e-commerce/customers',
      linkText: 'See all',
      image: bg1
    }]

  return (
    <>
    {/* // <Row className="g-3 mb-3" > */}
      {statsData.map((stat, index) => (
        // <Col key={stat.title} md={12}>
          <StatisticsCard stat={stat}  />
        // </Col>
      ))}
    {/* // </Row> */}
    </>
  );
};

export default StatisticsCards;
