import CardDropdown from 'components/common/CardDropdown';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { Card, Form } from 'react-bootstrap';

import { months } from 'data/common';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { capitalize, getColor, rgbaColor } from 'helpers/utils';
import  TotalSalesContext  from '../../../context/TotalSalesContext';
import useTotalSales from 'hooks/useTotalSales';
import { Controller } from 'react-hook-form';
import {totalSales} from "data/dashboard/default"
import AppContext from 'context/Context';





echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);



const getOptions = (month, data, date, myData) => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: params => {
      const { name, value } = params[0];
      return `${month} ${name} : ${value}`;
    },
    transitionDuration: 0
  },
  xAxis: {
    type: 'category',
    // data: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23],
    data : date,
    boundaryGap: false,
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },

    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      formatter: value => `${capitalize(month.slice(0, 3))} ${value}`,
      margin: 15
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: [
    {
      type: 'line',
      data,
      // data: myData?.map(item => item.count),
      lineStyle: { color: getColor('primary') },
      itemStyle: {
        borderColor: getColor('primary'),
        borderWidth: 2
      },
      symbol: 'circle',
      symbolSize: 10,
      smooth: false,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { right: 10, left: 0, bottom: 0, top: 10, containLabel: true }
});




const TotalSalesCount = () => {

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);


const {myData, setMyData, loading, setLoading} = useTotalSales()


const data = [
  myData?.filter(item=> item.month == 1 ).map(item => item.count),
  myData?.filter(item=> item.month == 2 ).map(item => item.count),
  myData?.filter(item=> item.month == 3 ).map(item => item.count),
  myData?.filter(item=> item.month == 4 ).map(item => item.count),
  myData?.filter(item=> item.month == 5 ).map(item => item.count),
  myData?.filter(item=> item.month == 6 ).map(item => item.count),
  myData?.filter(item=> item.month == 7 ).map(item => item.count),
  myData?.filter(item=> item.month == 8 ).map(item => item.count),
  myData?.filter(item=> item.month == 9 ).map(item => item.count),
  myData?.filter(item=> item.month == 10 ).map(item => item.count),
  myData?.filter(item=> item.month == 11 ).map(item => item.count),
  myData?.filter(item=> item.month == 12 ).map(item => item.count)
] ?? totalSales


console.log("data", data)

// const fakeDate = [[1, 2, 3, 13, 14, 15, 21, 23, 24, 27, 28, 29]]

const date = [
  myData?.filter(item=> item.month == 1 ).map(item => item.day),
  myData?.filter(item=> item.month == 2 ).map(item => item.day),
  myData?.filter(item=> item.month == 3 ).map(item => item.day),
  myData?.filter(item=> item.month == 4 ).map(item => item.day),
  myData?.filter(item=> item.month == 5 ).map(item => item.day),
  myData?.filter(item=> item.month == 6 ).map(item => item.day),
  myData?.filter(item=> item.month == 7 ).map(item => item.day),
  myData?.filter(item=> item.month == 8 ).map(item => item.day),
  myData?.filter(item=> item.month == 9 ).map(item => item.day),
  myData?.filter(item=> item.month == 10 ).map(item => item.day),
  myData?.filter(item=> item.month == 11 ).map(item => item.day),
  myData?.filter(item=> item.month == 12 ).map(item => item.day)
] ?? totalSales


console.log("date", date)

const [month, setMonth] = useState(new Date().getMonth());


const currentMonthIndex = new Date().getMonth();
const prevMonth1 = (currentMonthIndex - 1 + 12) % 12;
const prevMonth2 = (currentMonthIndex - 2 + 12) % 12;
const filteredMonths = [months[prevMonth2], months[prevMonth1], months[currentMonthIndex]];
  

  return (

    <Card className="h-100" style={{backgroundColor:isDark ? "#0B1727" : "#F6FAFF", height:'900px'}}>
      <FalconCardHeader
        // title="Günlük Sipariş Adedi (adet/gün)"
        title=   {
          <div>
          <h6 className="mt-1" style={{color: isDark ? "#D8E2EF":"#025098"}}>Günlük Sipariş Adedi (adet/gün)</h6>
          </div>
      }
        titleTag="h6"
        className="pb-0"
        endEl={
          <Flex>
            <Form.Select
              size="sm"
              value={month}
              onChange={e => setMonth(e.target.value)}
              className="me-2"
            >
           
              {/* {months.map((month, index) => (
                <option value={index} key={month}>
                  {month}
                </option>
              ))} */}

              {filteredMonths.map((month, index) => (
                <option value={(currentMonthIndex - 2 + index + 12) % 12} key={month}>
                  {month}
                </option>
              ))}




            </Form.Select>
            {/* <CardDropdown /> */}
          </Flex>
        }
      />

      <Card.Body>
      <div style={{ 
               filter: loading ? 'blur(2px)' : 'none'
            }}>
         
        {loading ? 
        
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions(months[month], data[month], date[month],myData)}
    
          style={{ height: '31.25rem' }}
        /> :
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions(months[month], data[month], date[month],myData)}
    
          style={{ height: '31.25rem' }}
        />
        }
      </div>
      </Card.Body>
    </Card>
  );
};

TotalSalesCount.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number).isRequired)
    .isRequired
};

export default TotalSalesCount;
