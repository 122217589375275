import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useVendorList from './useVendorList';



export default function useBarcodeSku() {


 const {myKey, currentUser} = useContext(AuthContext)
 const [selectBarcode, setSelectBarcode] = useState()
 const [myBarcode, setMyBarcode] = useState()
 const {myDataVendor} = useVendorList()

 const [vendorIdBarcode, setVendorIdBarcode] = useState()
 
//  useEffect(() => {
//   if (myDataVendor?.length > 0) {
//     setVendorIdBarcode(myDataVendor[0].Id);
//   }
// }, [myDataVendor]);


 const getData = (barcode)=>{
        var myHeaders = new Headers();
    
            const params = {
              vendor_id : vendorIdBarcode,
              search : barcode

            };
                
              const request_url = Object.entries(params)
                  .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
                  .reduce(
                    (url, [key, value]) => url + `&${key}=${value}`,
                    `${BASE_URL}api/v1/order/barcode_sku/?`
          );      
                
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        return fetch(request_url, requestOptions)
          .then(response => response.json())
         
          .catch(error => console.log('error', error));         
 }





const getDataBarcode = (barcode)=>{
  var myHeaders = new Headers();

  console.log("barcodeXX", barcode)

  const params = {
    vendor_id : vendorIdBarcode,
    barcode : barcode

  };
      
    const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}api/v1/order/barcode_sku/?`
); 
          
  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
  .then(response => response.json())
  .then(result =>{
    setMyBarcode(result) 
})
  .catch(error => console.log('error', error));        
}


useEffect(() => {
  getData();
  
}, [vendorIdBarcode]);



 return { getData, selectBarcode, getDataBarcode, myBarcode, setVendorIdBarcode}
}
