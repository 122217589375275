import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import { toast } from 'react-toastify';
import {BASE_URL} from './baseUrl'


export default function useInventoryShelfChange() {

 const {myKey} = useContext(AuthContext)
 const [formData, setFormData] = useState({
        inventory_id : "",
        barcode: "",
        count : "",
        location_code_from : "",
        location_code_to : "",
        lot : "",
        expiration_date : ""
});


 

console.log("useForm",formData)


 const postData = ()=>{

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL+"wms/api/v1/inventory/replace/"
    

    var raw = JSON.stringify({
      "inventory_id" : formData.inventory_id,
      "barcode": formData.barcode,
      "count": formData.count,
      "location_code_from":  formData.location_code_from || "",
      "location_code_to" : formData.location_code_to,
      "lot" : formData.lot  || "",
      "expiration_date" : formData.expiration_date || ""
      
 });
    
  console.log("raw", raw)

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json().then(data => {
          toast.success(data.message || "İşlem başarıyla tamamlandı.", { theme: 'colored' });
        });
      } else {
        return response.json().then(err => {
          toast.error(err.error || "Bir hata oluştu.", { theme: 'colored' });
        });
      }
    })
    .catch(error => {
      console.log('error', error);
      toast.error("İşlem sırasında bir hata meydana geldi.", { theme: 'colored'});
    });

}

useEffect(() => {
  if(formData.location_code_to && formData.inventory_id){
    postData()
   }

}, [formData])






 return {setFormData, formData}
}
