import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useDebounce from './useDebounce';



export default function useOrderRefundList() {

  const [myData, setMyData] = useState()
  const [page, setPage] = useState(1)
  const {myKey, currentUser} = useContext(AuthContext)
  const [detail, setDetail] = useState()
  const [firstName, setFirstName] = useState()
  const [search, setSearch] = useState()
  const [cargo, setCargo] = useState()
  const date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.setDate(date.getDate() -7)));
  const [endDate, setEndDate] = useState(new Date(date.setDate(date.getDate() +8)));

  const [showOrderGif, setShowOrderGif] = useState(false);

  const [currentPage,setCurrentPage]=useState(1)
  const [followersPerPage, setFollowersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [vendorId,setVendorId] = useState()

  const [statusRefund,setStatusRefund] = useState()

  const debouncedSearch = useDebounce(search, 1500);





  let formattedStartDate = "";
  let formattedEndDate = "";

  if(startDate){
    formattedStartDate = startDate.toISOString().slice(0, 10);
  }
  if(endDate){
    formattedEndDate = endDate.toISOString().slice(0, 10);
  }
 
 const getData = ()=>{
        var myHeaders = new Headers();

        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;

        
        const params = {
          // page: page,
          offset :offset,
          limit :limit,
          cargo :cargo,
          begin_date: formattedStartDate,
          end_date: formattedEndDate,
          search : search,
          // vendor_username: currentUser,
          vendor_id : vendorId,
          status : statusRefund

        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/order/refund/refund_completed_list/?`
    );
      

      console.log("order",request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result)
            setShowOrderGif(false); 
            setTotalPages(Math.ceil(result.count / followersPerPage))
    })
          .catch(error => console.log('error', error));        
 }





 useEffect(() => {
    getData();
    setShowOrderGif(true)
  }, [startDate,endDate, cargo, debouncedSearch, currentPage, followersPerPage, vendorId, statusRefund]);

 return {myData, setPage, detail, getData, setMyData, startDate, setStartDate, endDate, setEndDate,  cargo, setCargo, setSearch,showOrderGif, currentPage, setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, setVendorId, setStatusRefund}
  
  
  
 
}
