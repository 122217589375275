import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from '../baseUrl'



export default function useReasonList() {

  const [myDataReason, setMyDataReason] = useState()

  const {myKey} = useContext(AuthContext)

  const [vendorIdReason, setVendorIdReason] = useState()


 
 const getData = ()=>{
        var myHeaders = new Headers();

        
        const params = {
          vendor_id :vendorIdReason
          };
  
          const request_url = Object.entries(params)
              .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
              .reduce(
                (url, [key, value]) => url + `&${key}=${value}`,
                `${BASE_URL}api/v1/refund/list_reason_all/?`
      );
      

        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyDataReason(result)
        
    })
          .catch(error => console.log('error', error));        
 }



 useEffect(() => {
    getData();

  }, [vendorIdReason]);

 return {myDataReason, setVendorIdReason}
}
