import React, { useState } from 'react';
import { Card, Form, Button, FormCheck, Row, Col } from 'react-bootstrap';  
import { Link, useLocation } from 'react-router-dom';
import { ImArrowLeft } from 'react-icons/im';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import useTaskRulePost from 'hooks/useTaskRulePost';
import { format } from 'date-fns';
import '../style.css'
import gifOrder from 'assets/img/gif_order.gif'

const TaskEdit = () => {
  const location = useLocation();
  const { item } = location.state || {};  
  const parameters = item?.parameters || {}; 
  const { postData, loading, setLoading } = useTaskRulePost();

  const [formValues, setFormValues] = useState(() => {
    const initialValues = {};
    Object.keys(parameters).forEach(key => {
      if (parameters[key].type === 'bool') {
        initialValues[key] = false; 
      } else if (parameters[key].type === 'int_list' || parameters[key].type === 'str_list') {
        initialValues[key] = ['']; 
      } else {
        initialValues[key] = ''; 
      }
    });
    return initialValues;
  });

  const handleInputChange = (key, value, type) => {
    let formattedValue;
    if (type === 'int') {
      formattedValue = parseInt(value, 10) || '';
    } else if (type === 'bool') {
      formattedValue = value; 
    } else if (type === 'date') {
      formattedValue = format(value, 'yyyy-MM-dd'); 
    } else if (type === 'datetime') {
      formattedValue = format(value, "yyyy-MM-dd'T'HH:mm:ssXXX"); 
    } else {
      formattedValue = value;
    }

    setFormValues(prev => ({
      ...prev,
      [key]: formattedValue
    }));
  };

  const handleListChange = (key, index, value, type) => {
    setFormValues(prev => {
      const updatedList = [...prev[key]];
      updatedList[index] = type === 'int_list' ? parseInt(value, 10) : value;
      return {
        ...prev,
        [key]: updatedList
      };
    });
  };

  const addToList = (key, type) => {
    setFormValues(prev => ({
      ...prev,
      [key]: [...prev[key], type === 'int_list' ? 0 : ''] 
    }));
  };

  const removeFromList = (key, index) => {
    setFormValues(prev => {
      const updatedList = [...prev[key]];
      updatedList.splice(index, 1); 
      return {
        ...prev,
        [key]: updatedList
      };
    });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
  
    let isValid = true; 
  
    const cleanedFormValues = {};
  
    Object.keys(formValues).forEach(key => {
      const value = formValues[key];
      const param = parameters[key];
  
      if ((param.type === 'date' || param.type === 'datetime') && param.required && !value) {
        isValid = false;
        alert(`${key} alanı zorunludur ve doldurulmalıdır.`);
      }
  
      if ((param.type === 'int_list' || param.type === 'str_list') && (value.length === 0 || (value.length === 1 && value[0] === ''))) {
        return; 
      }
  
      if (
        (Array.isArray(value) && value.length > 0 && value.some(item => item !== '')) || 
        (typeof value === 'boolean') || 
        (value !== '' && value !== null && value !== undefined) 
      ) {
        cleanedFormValues[key] = value;
      }
    });
  
    if (!isValid) {
      return; 
    }
  
    setLoading(true);
    postData(item.id, cleanedFormValues);  
  };
  
  

  const renderInputs = () => {
    return Object.keys(parameters).map(key => {
      const param = parameters[key];

      if (param.type === 'bool') {
        // Boolean tipi için Switch
        return (
          <Form.Group key={key} className="mb-3">
            <Form.Label>
              {key} {param.required && <span style={{ color: 'red' }}>*</span>}:
            </Form.Label>
            <FormCheck 
              type="switch"
              id={key}
              checked={formValues[key]}  
              onChange={(e) => handleInputChange(key, e.target.checked, 'bool')}
            />
        </Form.Group>
        );
      } else if (param.type === 'date') {
        // Date tipi için DatePicker
        return (
          <Form.Group key={key} className="mb-3">
            <Form.Label>
              {key} {param.required && <span style={{ color: 'red' }}>*</span>}:
            </Form.Label>
            <DatePicker
              selected={formValues[key] ? new Date(formValues[key]) : null}
              onChange={(date) => handleInputChange(key, date, 'date')}
              dateFormat="yyyy-MM-dd"
              className="form-control"
              placeholderText={`${key} tarihini seçiniz..`}
            />
          </Form.Group>
        );
      } else if (param.type === 'datetime') {
        // Datetime tipi için DatePicker
        return (
          <Form.Group key={key} className="mb-3">
            <Form.Label>
              {key} {param.required && <span style={{ color: 'red' }}>*</span>}:
            </Form.Label>
            <DatePicker
              selected={formValues[key] ? new Date(formValues[key]) : null}
              onChange={(date) => handleInputChange(key, date, 'datetime')}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="yyyy-MM-dd HH:mm"
              className="form-control"
              placeholderText={`${key} tarih ve saati seçiniz..`}
            />
          </Form.Group>
        );
      } else if (param.type === 'int_list' || param.type === 'str_list') {
        // int_list veya str_list için dinamik inputlar
        return (
          <Form.Group key={key} className="mb-3">
            <Form.Label>
              {key} {param.required && <span style={{ color: 'red' }}>*</span>}:
            </Form.Label>
            {formValues[key].map((value, index) => (
              <Row key={index} className="align-items-center mb-2">
                <Col xs={11}>
                  <Form.Control
                    type={param.type === 'int_list' ? 'number' : 'text'}
                    value={value}
                    onChange={(e) => handleListChange(key, index, e.target.value, param.type)}
                    placeholder={key}
                  />
                </Col>
                <Col xs={1} className="text-end">
                  {index > 0 && (
                    <Button variant="danger" onClick={() => removeFromList(key, index)}>
                      -
                    </Button>
                  )}
                  {index === 0 && (
                    <Button variant="success" onClick={() => addToList(key, param.type)}>
                      +
                    </Button>
                  )}
                </Col>
              </Row>
            ))}
          </Form.Group>
        );
      } else {
        const inputType = param.type === 'int' ? 'number' : 'text';
        return (
          <Form.Group key={key} className="mb-3">
            <Form.Label>
              {key} {param.required && <span style={{ color: 'red' }}>*</span>}:
            </Form.Label>
            <Form.Control
              type={inputType}
              value={formValues[key]}  
              required={param.required}
              onChange={(e) => handleInputChange(key, e.target.value, param.type)} 
              placeholder={`${key} giriniz..`} 
            />
          </Form.Group>
        );
      }
    });
  };

  return (
    <>
      <Link to="/integration/task-rules">
        <ImArrowLeft style={{ fontSize: '25px' }} />
      </Link>

      <Card className="mb-3 mt-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '40px', margin: '0 auto' }}>
        <div style={{ width: '100%' }}>
          <div className="mb-3 mt-3">
            <p><strong>Task Name:</strong> {item?.task_name}</p> 
            <p><strong>Task Register Name:</strong> {item?.task_register_name}</p> 
          </div>
          <hr />
          
          <Form onSubmit={handleSubmit} style={{paddingLeft:'100px', paddingRight:'100px'}}>
            {renderInputs()} 
            
            { Object.keys(parameters).length === 0 ? <p style={{textAlign:'center'}}>Henüz Parametre Oluşturulmamıştır.</p>  :
              <div className="d-flex justify-content-center"> 
               
                <Button
                  variant="primary"
                  type="submit"
                  className="button-container fixed-width-button"
                  disabled={loading}
                >
                  {loading ? <img src={gifOrder} alt="loading..." className="button-img" /> : "Onayla"}

                </Button>
              </div>
            }
          </Form>
        </div>
      </Card>
    </>
  );
};

export default TaskEdit;
