import React from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CardDropdown from 'components/common/CardDropdown';
import DealVSGoalChart from './DealVsGoalChart';
import { dealClosedVsGoalChart } from 'data/dashboard/crm';
import useStoreSalesDaily from 'hooks/useStoreSalesDaily';
import { addDays, format, subMonths } from 'date-fns';
import { Link } from 'react-router-dom';

const DealVsGoal = () => {

  const { myData, setStartDate, setEndDate } = useStoreSalesDaily();

  
  const today = new Date();

  const handleDateChange = (event) => {
    const selectedOption = event.target.value;
    if (selectedOption === '7days') {
      setStartDate(format(addDays(today, -7), 'yyyy-MM-dd'));
    } else if (selectedOption === '1month') {
      setStartDate(format(subMonths(today, 1), 'yyyy-MM-dd'));
    }
    setEndDate(format(today, 'yyyy-MM-dd'));
  };

  return (
    <Card className="h-90">
      <FalconCardHeader
        title="Mağaza Bazlı Adetler"
        titleTag="h6"
        className="py-2 mt-2"
        // endEl={<CardDropdown />}
      />
      <Card.Body>
        <DealVSGoalChart data={myData || []}   />
      </Card.Body>
      <Card.Footer className="bg-light py-2">
        <Row className="flex-between-center">
          <Col xs="auto">
              <Form.Select size="sm" onChange={handleDateChange}>
                  <option value="7days">Son 7 gün</option>
                  <option value="1month">Son Bir Ay</option>
                </Form.Select>
          </Col>
          <Col xs="auto">
            <Button variant="falcon-default" size="sm" as={Link} to="/store-sales-daily-detail">
              Detay
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default DealVsGoal;
