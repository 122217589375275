import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'


export default function useInventoryProductList() {

 const [myData, setMyData] = useState()

 const {myKey, currentUser} = useContext(AuthContext)

 const [vendorId, setVendorId] = useState()
 
 


 const getData = ()=>{
        var myHeaders = new Headers();

    
            const params = {
        
              // vendor_username: currentUser,
              vendor_id : vendorId
    
            };
    
            const request_url = Object.entries(params)
                .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
                .reduce(
                  (url, [key, value]) => url + `&${key}=${value}`,
                  `${BASE_URL}wms/api/v1/inventory/inventory_products/?`
        );
          
        
      console.log("wurl", request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result) 
    })
          .catch(error => console.log('error', error));        
 }

 







 useEffect(() => {
    getData();
  }, [vendorId]);

 return {myData, setVendorId}
}
