import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row, Table, Form, Modal, Button} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import AppContext from 'context/Context';
import useProductVendorStoreProductList from 'hooks/useProductVendorStoreProductList';
import { RiDeleteBin2Line } from "react-icons/ri";
import useVendorStoreProductLink from 'hooks/useVendorStoreProductLink';




const VendorStoreProductDetail = () => {

    const { vsProductData, getDataVs} = useProductVendorStoreProductList()
    const location = useLocation()
    const {id} = location.state || ""

    const { postUnLink}= useVendorStoreProductLink()


  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);


  useEffect(() => {
   if (id)  {
    getDataVs(id)} 

  }, [id ])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
  
    const id = params.get('id');

    if (id)  {
      getDataVs(id)} 
  }, [ id]);

  const [showCancel, setShowCancel] = useState(false);
  const handleCloseCancel = () => setShowCancel(false);

  const [selectedProductId, setSelectedProductId] = useState(null);


  const handleModalOpenCancel = (productId) => {
    setSelectedProductId(productId);
    setShowCancel(true);
  };

  
  const handleCancel = () => {
    postUnLink(selectedProductId);
    handleCloseCancel(); 
  };

    const ProductsData = vsProductData[0]?.vs_products.map((product, i) => ({
      id: product.Id ,
      name: product.Name,
      image: product.Image,
      sku: product.SKU,
      platform : product.vendor_store
    }));

    console.log("ProductsData", ProductsData)


  return (
    <>
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">


        <div style={{display:'flex', justifyContent:'space-between',marginBottom:"15px"}}>
          
          <div>
              <h6 >Platform Ürünleri</h6>
          </div>
       

      </div>
          <Table striped className="border-bottom">
          
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Ürün</th>
                <th className="border-0">İsim</th>
                <th className="border-0">SKU</th>
                <th className="border-0">Platform</th>
                <th className="border-0 text-center">Sil</th>
              </tr>
            </thead>
            <tbody>
            {ProductsData?.length === 0 && (
                  <tr>
                    <td colSpan="4" className="text-center">
                     Eşleştirilmiş Ürün Bulunmamaktadır.
                    </td>
                  </tr>
                )}
              {ProductsData?.length>0 &&
              ProductsData?.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle">
                  <img style={{width:"40px"}}src={product.image} alt="" />
                 
                  </td>

                  <td className="align-middle text-left" style={{whiteSpace: 'normal'}}
                   title={product.name}
                   onMouseOver={(e) => (e.currentTarget.title = product.name)}
                  >
                
                {product.name.length > 40
                      ? product.name.slice(0, 40).concat("...")
                      : product.name}
                  </td>
                  <td className="align-middle">
                    <h6 className="mb-0 text-nowrap">{product.sku}</h6>
                  </td>

                  <td className="align-middle">
                    <h6 className="mb-0 text-nowrap">{product.platform}</h6>
                  </td>
                
                  <td className="align-middle text-center">
                  <RiDeleteBin2Line  style={{fontSize:'20px'}} onClick={() => handleModalOpenCancel(product.id)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
             
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
   

    <Modal show={showCancel} onHide={handleCloseCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Bilgilendirme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Eşleştirmeyi kaldırmak istiyor musunuz?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCancel}>
            Hayır
          </Button>
          <Button variant="danger" onClick={handleCancel}>
            Evet
          </Button>
        </Modal.Footer>
      </Modal>

    

    </>
  );
};

VendorStoreProductDetail.propTypes = {};

export default VendorStoreProductDetail;
