import {createContext,  useState} from 'react'


export const DateContext = createContext();


const DateContextProvider = (props) =>{ 

  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(tomorrow.getDate() + 1);
  
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  

console.log("datecontext", startDate, endDate)

    let value = {
        startDate,
        endDate,
        setStartDate,
        setEndDate
    }

    return (
        <DateContext.Provider value={value}>
          {props.children}
        </DateContext.Provider>
      )

}

export default DateContextProvider