import { AuthContext } from 'context/AuthContext';
import React, {useContext} from 'react'
import {BASE_URL} from './baseUrl'
import { toast } from 'react-toastify';



export default function useOrderCancel() {

  const {myKey, currentUser} = useContext(AuthContext)


 
 const getCancelData = (id)=>{
       
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${myKey}`);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

        let request_url = BASE_URL+`api/v1/order/${id}/cancel/`

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
        };

      fetch(request_url, requestOptions)
      .then(response => {
        if (response.ok) {
            response.text()
            .then(result => {
                console.log(result)
                toast.success(` ${id} id numaralı sipariş başarılı şekilde iptal edildi.`, { theme: 'colored'});
            })
        } else {
            console.log("Post işlemi başarısız.")
    
        }
      })
      .catch(error => console.log('error', error));
    }

 return {getCancelData}
}
