import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import CardDropdown from 'components/common/CardDropdown';
import TotalSalesChart from './TotalSalesChart';
import useProductDetail from 'hooks/useProductDetail';
import DatePicker from  "react-datepicker";

const ProductTotalSales = () => {
  const chartRef = useRef(null);



  const location = useLocation()
  const {sku} = location.state || ""
  const {myDetail, getDetail, setStartDate, startDate, 
    setEndDate,endDate, setSku} = useProductDetail()





  useEffect(() => {
    if(sku){
      setSku(sku)
    }
  
  }, [sku, endDate, startDate ])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sku = params.get('sku');
    const id = params.get('id');

    if(sku){
      setSku(sku)
    }
  }, [sku, endDate, startDate ]);


  const data = {}

  data.satışTl = Array.isArray(myDetail) ? myDetail.map(item => item.count) : [];


  // const date = myDetail?.map(item => new Date(`${item.year}-${item.month}-${item.day}`).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }))

  const handleLegendToggle = name => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name
    });
  };
  const handleChangeDate = event => {
    setSelectedOption(event.target.value);
  
    let start, end;
  
    switch(event.target.value) {
      case "1": // Bugün
        start = new Date();
        end = new Date();
        break;
  
      case "2": // Bu hafta
        end = new Date();
        start = new Date();
        start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
        break;   
  
      case "3": // Bu ay
        end = new Date();
        start = new Date();
        start.setDate(1); // Ayın ilk günü
        break;
    
      default:
        // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
        break;
    }
  
    setStartDate(start);
    setEndDate(end);
  
   
  };
  
  const [selectedOption, setSelectedOption] = useState(null);
  
  
  useEffect(() => {
    if (selectedOption === "4") {
      setIsOpenDate(true);
    } else {
      setIsOpenDate(false);
    }
  }, [selectedOption]);
  
  
  const [isOpenDate, setIsOpenDate] = useState(true);
  
  const onChange = dates => {
  
    const [start, end] = dates;
  
    console.log("start", start);
    console.log("end", end);
  
  
    if (selectedOption === "4") {
      setStartDate(start);
      setEndDate(end);
  
   
    }
    if (start && end) {
      setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
    }
  };


  return (
    <Card>
      <Card.Header>
        <Row className="flex-between-center g-0">
         
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
            <h6 className="mb-0">Satış Adetleri</h6>
            </div>

          <div style={{display:"flex", justifyContent:'right'}}> 
                <div style={{ marginLeft:'10px', width:'300px'}}>

                <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                  <option value="" disabled selected>Tarih</option>
                  <option key={1} value="1">Bugün</option>
                  <option key={2} value="2">Bu hafta</option>
                  <option key={2} value="3">Bu ay</option>
                  <option key={3} value="4">Tarih seç</option>
                              </Form.Select> 

                {selectedOption === "4" && 
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    endDate={endDate}
                    selectsRange
                     dateFormat="MMM-dd"
                    className='form-control'
                    placeholderText='tarih giriniz'
                    open={isOpenDate}
                    onCalendarClose={() => setIsOpenDate(false)}
                    onCalendarOpen={() => setIsOpenDate(true)}
                  />
                }
                  </div>

            </div>

            </div>
          
        </Row>
      </Card.Header>
      <Card.Body className="pe-xxl-0">
        <TotalSalesChart
          data={data}
          myDetail={myDetail}
          ref={chartRef}
          style={{ height: '18.625rem' }}
        />
      </Card.Body>
    </Card>
  );
};

// TotalSales.propTypes = {
//   data: PropTypes.shape({
//     // lastMonth: PropTypes.array,
//     // previousYear: PropTypes.array
//   })
// };

export default ProductTotalSales;
