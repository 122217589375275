import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Dropdown, Form, Col, InputGroup, Alert, Row, Popover, Overlay} from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import OrdersTableHeader from './OrdersTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import useOrderList from 'hooks/useOrderList';
import Pagination from './PaginationOrder';
import { getDatasetAtEvent } from 'react-chartjs-2';
import DatePicker from  "react-datepicker";
import useShipmentCompany from 'hooks/useShipmentCompany';
import Accordion from 'react-bootstrap/Accordion';
import AsyncSelect from 'react-select/async';
import IconButton from 'components/common/IconButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useOrderExport from 'hooks/useOrderExport';
import useVendorStore from 'hooks/useVendorStore';
import { Search } from 'react-bootstrap-icons';
import gif from 'assets/img/gif_export.gif'
import gifOrder from 'assets/img/gif_order.gif'
import useOrderExportS3 from 'hooks/useOrderExportS3';
import useInventoryListFilter from 'hooks/useInventoryListFilter';
import {  PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import gif_s3 from 'assets/img/gif_export_s3.gif'
import { useOrderPdfUpload } from 'hooks/useOrderPdfUpload';
import { useOrderUpload } from 'hooks/useOrderUpload';
import useOrderCancel from 'hooks/useOrderCancel';
import useOrderWorkPost from 'hooks/useOrderWorkPost';
import useDeliveryList from 'hooks/useDeliveryList';
import useCourierList from 'hooks/useCourierList';
import useDeliveryExportS3 from 'hooks/useDeliveryExportS3';




const Delivery = () => {

const {getOrderWork}= useOrderWorkPost()

const {perms} = useContext(PermsContext)

const {myData, setPage, selectStatus, setSelectStatus, startDate, setStartDate, endDate, setEndDate, setCargo, cargo, getData, firstName, setFirstName, setStore, setSearch, setPaid, showOrderGif, currentPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, setInvoices, setInventoryRegion , sortAmount, setSortAmount, setVendorIdList, setWork, setIsLoading, isLoading,  getDetailWork, detailWork, setCourier, setDeliveryStatus} = useDeliveryList()


  const columns = [
    {
      accessor: 'name',
      Header: 'Sipariş',
      disableSortBy: true,
      headerProps: { className: 'text-left'},
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { id, name, email } = rowData.row.original;
        return (
          <>
            <Link to="/e-commerce/orders/order-delivery-details"state={{id, activePage}} >
                <strong>{id}</strong>
              </Link>
           
              {' '}
            by <strong>{name}</strong> <br />
            <Link to="/e-commerce/orders/order-delivery-details"state={{id, activePage}} >
            <a href="">{email}</a>
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Paket Tarihi',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      }
    },
    
    {
      accessor: 'status',
      Header: 'Durumu',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              
              success: status === '2',   // pending
              dark : status === '1'   // onhold
            })}
            className="d-block"
          >
            
            {status === '2' && 'Teslim Edildi'} 
            {status === '1' && 'Dağıtımda'}  
            <FontAwesomeIcon
              icon={classNames({
                check: status === '2',
                stream: status === '1'
              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'endDate',
      Header: 'Teslim Tarihi',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      }
    },
    {
      accessor: 'amount',
      // Header: 'Tutar',
      Header: () => (
        <div 
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
          className='text-center' 
          onClick={onHeaderClick}
        > Tutar
         
        </div>
      ),
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.amount}`
    },
   
    {
      accessor: 'none',
      Header: 'Mağaza',
      disableSortBy: true,
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { store} = rowData.row.original;
        return (
          <p>{store}</p>
        );
      }
    },
    {
      accessor: 'courier',
      Header: 'Kurye',
      disableSortBy: true,
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { courierUsername} = rowData.row.original;
        return (
          <p>{courierUsername}</p>
        );
      }
    },

];



const {setSelectStatusModal, setStartDateModal, setEndDateModal, setCargoModal, getDataExport, startDateModal, endDateModal, setStoreModal, fulfilmentModal, setFulfilmentModal, showGif, setShowGif, setInvoicesExport, invoicesExport, myDataS3,setMyDataS3, setRegionExport, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorExportId, setDeliveryStatusModal,setCouirerModal } = useDeliveryExportS3()

const {myDataVendor} = useVendorList()
const {myDataCourier} = useCourierList()

const {vendorStore, setVendorIdVendorStore} = useVendorStore()
const {myDataRegion, setVendorIdRegion} = useInventoryListFilter()
const {shipmentCompany, setVendorIdShipment} = useShipmentCompany()


const onHeaderClick = () => {
  setSortAmount((sortAmount % 3) + 1);
};

  
  // const [followersPerPage, setFollowersPerPage]=useState(10)
  // const [currentPage,setCurrentPage]=useState(1)
  const [orderList, setOrderList] = useState() 
  const [activePage, setActivePage] = useState(1)



  const location = useLocation();

  useEffect(() => {
    console.log("Active page from state:", activePage); // Debug için
    const savedActivePage = location.state?.activePage;
    if (savedActivePage) {
      console.log("Saved active page:", savedActivePage); // Debug için
      setActivePage(savedActivePage);
      setCurrentPage(savedActivePage);
    } else {
      setActivePage(1);
      setCurrentPage(1);
    }
  }, [location]);
  


  const [isOpen, setIsOpen] = useState(false);

  function hasPermission(permission) {
    return perms?.includes(permission);
  }
 

  // Handler
  const handleToggle = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };
  

  // modal
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setMyDataResult(false)
  }


  const handleShow = () => setShow(true);

  const onChangeModal = datesExport => {
    const [startExport, endExport] = datesExport;
    setStartDateModal(startExport);
    setEndDateModal(endExport);
  };


 

  useEffect(() => {
    if (myDataVendor && myDataVendor.length > 0) {
      const firstVendorId = myDataVendor[0].Id;
      setVendorIdVendorStore(firstVendorId);
      setVendorIdRegion(firstVendorId);
      setVendorIdShipment(firstVendorId);
      setVendorIdList(firstVendorId)
      setVendorExportId(firstVendorId)
    }
  }, [myDataVendor]);


  const handleChangeVendor = (e)=> {
    setVendorIdVendorStore(e.target.value); 
    setVendorIdRegion(e.target.value)
    setVendorIdShipment(e.target.value)
    setVendorIdList(e.target.value)
    
  }


  const handleChangeModal = (e)=> {
    setSelectStatusModal(+e.target.value); 
    console.log("type", typeof e.target.value)
  }

  const handleChangeCargoModal = (e)=> {
    setCargoModal(e.target.value); 
  }

  const handleChangeStoreModal = (e)=> {
    setStoreModal(e.target.value); 
  }
  
  const handleChangeVendorModal = (e)=> {
    setVendorExportId(e.target.value); 
    setVendorIdVendorStore(e.target.value); 
    setVendorIdRegion(e.target.value)
    setVendorIdShipment(e.target.value)
  }



  const handleChangeRegionModal = (e)=> {
    setRegionExport(e.target.value); 
  }

  

  
  const handleExport = ()=> { 
    getDataExport(); 
    setShowGif(true);
    // setShow(false)
    setCargoModal(null)
    setSelectStatusModal(null)
    setStartDateModal(null);
    setEndDateModal(null);
    setIsPreparing(true);
  }

  const handleDownload = () => {
    setIsPreparing(false);
    window.location.href = myDataResult[0]?.Value;
    setShow(false)
    setMyDataResult(false)
  }




  // useEffect(() => {
  //   setPage(activePage)
  // }, [activePage])
  




const deliveryStatus = [
  {value:"true", label:"Evet"},
  {value:"false", label:"Hayır"},
]

const handleChangeDelivery = (e)=> {
  setDeliveryStatus(e.target.value); 
  setDeliveryStatusModal(e.target.value)

}



const handleChangeCourier= (e)=> {
  setCourier(e.target.value); 
  setCouirerModal(e.target.value)

}


const handleChangeFulfilmentModal = (e)=> {
  setFulfilmentModal(+e.target.value); 

}




const handleChangePaid = (e)=> {
  setPaid(e.target.value); 

}


const handleChangeCargo = (e)=> {
  setCargo(e.target.value); 
}

const handleChangeStore = (e)=> {
  setStore(e.target.value); 
}

const handleChangeRegion = (e)=> {
  setInventoryRegion(e.target.value); 
}


const handleChangeinvoices = (e)=> {
  setInvoices(e.target.value); 
}

const handleChangeWork = (e)=> {
  setWork(e.target.value); 
}

const handleChangeExportinvoices = (e)=> {
  setInvoicesExport(e.target.value); 
}



useEffect(() => {
     
  const newList = myData?.results.map((item)=>{

      const date = new Date(item.StartedAt);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const CompletedDate = new Date(item.EndedAt);
      const yearEnd = CompletedDate.getFullYear();
      const monthEnd = CompletedDate.getMonth() + 1;
      const dayEnd = CompletedDate.getDate();

    return {
      id : "#"+item.Id,
      name: item.Order.ShippingAddress.FirstName+ " "+item.Order.ShippingAddress.LastName, 
      email : item.Order.OrderNumber === null ? "Sipariş No: -" : "Sipariş No: " + item.Order.OrderNumber,
      date: `${day}-${month}-${year}`,
      status: item.IsCompleted ? "2" : "1",
      amount : item.Order.OrderAmount,
      store : item.Order.VendorStore.Store.Name,
      endDate : item.EndedAt ? `${dayEnd}-${monthEnd}-${yearEnd}` : "Henüz teslim edilmemiştir",
      courierUsername : item.CourierUsername
      
  
    }
  })
  setOrderList(newList)

}, [myData, firstName])


// const totalPages = Math.ceil(myData?.count/followersPerPage)

// const onChange = dates => {
//   const [start, end] = dates;
//   setStartDate(start);
//   setEndDate(end);
// };
const handleChangeDate = event => {
  setSelectedOption(event.target.value);

  let start, end;

  switch(event.target.value) {
    case "1": // Bugün
      start = new Date();
      end = new Date();
      break;

    case "2": // Bu hafta
      end = new Date();
      start = new Date();
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
      break;   

    case "3": // Bu ay
      end = new Date();
      start = new Date();
      start.setDate(1); // Ayın ilk günü
      break;
  
    default:
      // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
      break;
  }

  setStartDate(start);
  setEndDate(end);
};

const [isOpenDate, setIsOpenDate] = useState(true);

const onChange = dates => {

  const [start, end] = dates;

  if (selectedOption === "4") {
    setStartDate(start);
    setEndDate(end);
  }
  if (start && end) {
    setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
  }
};




const [selectedOption, setSelectedOption] = useState(null);


useEffect(() => {
  if (selectedOption === "4") {
    setIsOpenDate(true);
  } else {
    setIsOpenDate(false);
  }
}, [selectedOption]);


const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedId, setSelectedId] = useState(null);
const [selectedFile, setSelectedFile] = useState(null);
const { uploadPDF, errorMessage } = useOrderPdfUpload();  // useHook

const handleModalClose = () => {
  setIsModalOpen(false);
};

const handleFileChange = (e) => {
  setSelectedFile(e.target.files[0]);
};

const handleFileUpload = () => {
  if (selectedFile) {
    uploadPDF(selectedFile, selectedId);  // Dosyayı ve id'yi useHook'a gönder
    handleModalClose(); // Modalı kapat
  }
};

const [showCancel, setShowCancel] = useState(false);

const handleCloseCancel = () => setShowCancel(false);
// const handleShowCancel  = () => setShowCancel(true);

const [cancelId, setCancelId] = useState()
const {getCancelData}  = useOrderCancel()


const handleCancel = () => {
  getCancelData(cancelId)

}

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      // data={orderList?.length>0 && orderList} 
      selection
      sortable
      pagination
      perPage={50}
    >
     
      <Card className="mb-3">
        <Card.Header>

      <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Kurye Teslimatları</h5>
            </div>


            <div style={{display:"flex", justifyContent:'right'}}>

            <div className="mb-3" style={{marginRight:"15px"}}>
                    <InputGroup>
                      <Form.Control  type="text" placeholder="search..."  onChange={(e)=>setSearch(e.target.value)}/>
                      <InputGroup.Text><Search/></InputGroup.Text>
                      </InputGroup>
            </div>

         

            { hasPermission('Dashboard Sipariş Export') ?
            <div style={{ marginRight:"8px"}}>
                <IconButton
                  variant="falcon-default"
                  size="m"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={handleShow}
                  style={{width:'145px', fontSize:'15px'}}
                >
                  <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
                </div>
          : ("") }
                

      <Dropdown  show={isOpen} onToggle={handleToggle}>
        <Dropdown.Toggle
           bsPrefix="toggle"
           as={Link}
          //  to=""
          onClick={handleToggle}
           className={classNames('px-0 nav-link')}>
     
            <IconButton
              variant="falcon-default"
              size="m"
              icon="fa-sharp fa-light fa-filter-list"
              transform="shrink-3"
              style={{width:'145px', fontSize:'15px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
              </svg>
                  <span className="d-none d-sm-inline-block ms-1">Filtre</span>
            </IconButton>
        </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret" style={{minWidth: '300px', width: '300px'}}>
          <Card className="shadow-none shadow-show-xl scrollbar">
            <Card.Header className="bg-light d-none d-xl-block">
              <h6 className="mb-0">Filtre</h6>
            </Card.Header>
              <Card.Body>
                <Form>
                  <div className="mb-2">
                  <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Tarih Seçimi</Form.Label>

                  <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                  <option value="" disabled selected>Tarih</option>
                  <option key={1} value="1">Bugün</option>
                  <option key={2} value="2">Bu hafta</option>
                  <option key={2} value="3">Bu ay</option>
                  <option key={3} value="4">Tarih seç</option>
                  </Form.Select> 

                {selectedOption === "4" && 
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    endDate={endDate}
                    selectsRange
                    dateFormat="MMM dd"
                    className='form-control'
                    placeholderText='tarih giriniz'
                    open={isOpenDate}
                    onCalendarClose={() => setIsOpenDate(false)}
                    onCalendarOpen={() => setIsOpenDate(true)}
                  />
                }

                  </div>

                  { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                  <div className="mb-2">
                  <Form.Label className="mb-2 fs--1" style={{color:"#5E6E82"}}>Satıcı</Form.Label>
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                     <option value="" disabled>Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
            : "" }

                  

                  <div style={{marginRight:'8px'}}>
                      <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Kurye Seçimi</Form.Label>
                        { myDataCourier?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeCourier}>
                        <option value="" >Kurye</option>
                            { myDataCourier?.map((item, i)=>{
                                  return <option key={i} value={item.Courier}>{item.Courier__username}</option>
                            })}       
                    </Form.Select>}
                  </div>

                  <div style={{marginRight:'8px', marginTop:'10px'}}>
                      <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Teslim Durumu</Form.Label>
                        { deliveryStatus?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDelivery}>
                        <option value="" >Durumu</option>
                            { deliveryStatus?.map((item, i)=>{
                                  return <option key={i} value={item.value}>{item.label}</option>
                            })}       
                    </Form.Select>}
                  </div>
                  
                             
                  <div className="mb-9">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Depolar</Form.Label>
                    { vendorStore?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeRegion}>
                            <option value="" >Depo</option>
                                { myDataRegion?.map((item, i)=>{
                                      return <option key={i} value={item.Region__Name}>{item.Region__Name}</option>
                                })}       
                        </Form.Select>}
                  </div>
                  
              </Form>
            </Card.Body>
        </Card>
      </Dropdown.Menu>
      </Dropdown>
    </div>     
  </div>
</Card.Header>

       
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />


        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showOrderGif && <img src={gifOrder} style={{width:'50%'}} alt="loading gif" />}
          </div>
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showGif && <img src={gifOrder} style={{width:'50%'}} alt="export gif" />}
          </div>
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <Pagination pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage} setIsLoading={setIsLoading}/>
        </Card.Footer>
       
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}>Sipariş sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>


      <Modal 
      show={show} 
      onHide={handleClose}
      size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Teslimat Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        
          
        <div style={{display:'flex', justifyContent:'center'}} >
       
        <div style={{marginRight:'8px'}}>
                    { myDataVendor?.length> 1 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeVendorModal}>
                     <option value="">Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
   
          <div style={{marginRight:"8px", fontSize:"14px"}}>
                <DatePicker
                showIcon
                selected={startDateModal}
                onChange={onChangeModal}
                startDate={startDateModal}
                formatWeekDay={day => day.slice(0, 3)}
                endDate={endDateModal}
                selectsRange
                dateFormat="MMM dd"
                className='form-control'
                placeholderText='tarih giriniz'
                />
          </div>
          

          <div style={{marginRight:'8px'}}>
                    { myDataCourier?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeCourier}>
                     <option value="" >Kurye</option>
                        { myDataCourier?.map((item, i)=>{
                              return <option key={i} value={item.Courier}>{item.Courier__username}</option>
                        })}       
                </Form.Select>}
            </div>

            <div style={{marginRight:'8px'}}>
                    { vendorStore?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeStoreModal}>
                     <option value="" >Mağaza</option>
                        { vendorStore?.map((item, i)=>{
                              return <option key={i} value={item.Store__Name}>{item.Store__Name}</option>
                        })}       
                </Form.Select>}
            </div>


          <div style={{marginRight:"8px"}}>
              <Form.Select  className='form-control' style={{fontSize:"15px", width:"130px"}} onChange={handleChangeDelivery}>
                  <option value=""  disabled selected>Teslim Durumu</option>
                      {deliveryStatus?.map((item, i)=>{
                            return <option  key={i} value={item.value}>{item.label}</option>
                      })}
              </Form.Select> 
          </div>

          <div style={{marginLeft:"8px"}}>
                  { shipmentCompany?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"112px"}} onChange={handleChangeRegionModal}>
                  <option value="" >Depo</option>
                      { myDataRegion?.map((item, i)=>{
                            return <option key={i} value={item.Region__Name}>{item.Region__Name}</option>
                      })}       
              </Form.Select>}
          </div>

         
</div>
               
          </Modal.Body>
          <Modal.Footer>
          <Row style={{width:'100%'}}>      
  <Row>
  { myDataVendor?.length> 1 ? <p>Satıcı ismi seçilmesi ZORUNLUDUR!</p> : ""}
    <p>Tarih aralığı geniş sipariş listesinin indirilmesi 1-2 dk bulabilir.</p>
    <p>Teslimatlarınız hazırlanıyor yazısından sonra tekrar Hazırla butonuna basmayınız.</p>

    <Col className="d-flex justify-content-end mb-3">
      <Button variant="secondary" onClick={handleClose} style={{marginRight:'10px'}}>
        Vazgeç
      </Button>
      <Button variant="primary" onClick={handleExport}>
        Hazırla
      </Button>
    </Col>
  </Row>
  {isPreparing && (!myDataResult || !myDataResult.error) && 
  
  <div>
  <div>Teslimat listeniz hazırlanıyor, lütfen bekleyin...</div>
  <img src={gif_s3} style={{width:'50%'}} alt="export gif" />
  </div>
  
  }
  {myDataResult && myDataResult[0]?.Value?.length > 0  && 
  <Row className="justify-content-center mt-5" style={{width:'100%'}}>
    <Col style={{display: "flex", justifyContent: "center"}}>
    <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        {/* {myDataS3?.message} */}
        Teslimat listesiniz hazır indirebilirsiniz.
      </Alert>
      <Button 
  variant="primary" 
  style={{width:'150px', height:'40px'}}
  // onClick={() => window.location.href = myDataS3?.download_url}
  onClick={handleDownload}
>
  İndir
</Button>

    </Col>
  </Row>

  }
   </Row>
      </Modal.Footer>
      </Modal>

  
    
    </AdvanceTableWrapper>
  );
};

export default Delivery;





