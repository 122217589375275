import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import { toast } from 'react-toastify';
import {BASE_URL} from './baseUrl'


export default function useInventoryShelfCountChange() {

 const {myKey} = useContext(AuthContext)

 const [inventoryProductId, setInventoryProductId] = useState()
 const [barcode, setBarcode] = useState()
 const [newCout, setNewCount] = useState()


 const postCount = ()=>{

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");


  const request_url = `${BASE_URL}wms/api/v1/inventory_product/${inventoryProductId}/update_count/?barcode=${barcode}&new_count=${newCout}`
   


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json().then(data => {
          toast.success(data.message || "İşlem başarıyla tamamlandı.", { theme: 'colored' });
        });
      } else {
        return response.json().then(err => {
          toast.error(err.detail || "Bir hata oluştu.", { theme: 'colored' });
        });
      }
    })
    .catch(error => {
      console.log('error', error);
      toast.error("İşlem sırasında bir hata meydana geldi.", { theme: 'colored'});
    });

}




 return {setInventoryProductId, setBarcode, setNewCount, postCount, newCout}
}
