import React , { useContext, useEffect, useRef, useState} from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import useProductForecast from 'hooks/useProductForecast';
import useBarcodeSku from 'hooks/useBarcodeSku';
import Page from './HeatMapChart';
import ChartOptions from './HeatMapChart';
import useForecastData from 'hooks/useForecastData';
import ForecastDetailsPage from './ForecastDetailsPage';
import {  Card } from 'react-bootstrap';
import AppContext from 'context/Context';
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import useVendorList from 'hooks/useVendorList';
import { PermsContext } from 'context/PermsContext';
import { HeatmapChart } from 'echarts/charts';
import useForecastDataSelect from 'hooks/useForecastDataSelect';

const PriceTotalForecast = () => {

    const chartRef = useRef(null);

    const {myData, selectBarcode, setSelectBarcode, setSelectStore, setSelectVendorId} = useProductForecast()
    const {myForecastData, setForecastBarcode, setVendorStore, forecastBarcode, vendorStore,setVendorId} = useForecastData()
    const {mySelectData} = useForecastDataSelect()
    // const {mySku} = useBarcodeSku()

    const {myDataVendor} = useVendorList()
    const {perms} = useContext(PermsContext)


    const {
      config: { isDark, isRTL },
      setConfig
    } = useContext(AppContext);


    const datefilter = myForecastData && myForecastData.length > 0
    ? new Date(myForecastData[0]?.DateTime).getTime() -86400000: []
    

    console.log("myForecastData", myForecastData)
    console.log("myForecastData Date", datefilter)


    const data = myData?.filter(item => new Date(`${item.month}.${item.day}.${item.year}`).getTime() < datefilter)
          .map(item => ({
            date: new Date(`${item.month}.${item.day}.${item.year}`).getTime(),
            value: item.count
          }));
  
      console.log("data date", data)


      const handleChange = (e)=> {
        setSelectBarcode(e.target.value); 
        setForecastBarcode(e.target.value);
        
      }
      

      const handleChangeStore = (e)=> {
       
        setVendorStore(e.target.value);
        setSelectStore(e.target.value)
        
      }

      


      // console.log("forcasting", mySku)
  
      useEffect(() => {
     
        const root = am5.Root.new(chartRef.current);
        // Set themes
            // https://www.amcharts.com/docs/v5/concepts/themes/

            isDark ?  root.setThemes([am5themes_Dark.new(root)]) :  root.setThemes([am5themes_Animated.new(root) ]);
              
    
              // Create chart
              // https://www.amcharts.com/docs/v5/charts/xy-chart/
              var chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                  focusable: true,
                  panX: true,
                  panY: true,
                  wheelX: "panX",
                  wheelY: "zoomX"
                })
              );
              
              var easing = am5.ease.linear;
              
              // Create axes
              // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
              var xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                  maxDeviation: 0.1,
                  groupData: false,
                  baseInterval: {
                    timeUnit: "day",
                    count: 1
                  },
                  renderer: am5xy.AxisRendererX.new(root, {
                    minGridDistance: 50
                  }),
                  tooltip: am5.Tooltip.new(root, {})
                })
              );
              
              var yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                  maxDeviation: 0.1,
                  renderer: am5xy.AxisRendererY.new(root, {})
                })
              );
              
              // Add series
              // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
              var series = chart.series.push(
                am5xy.LineSeries.new(root, {
                  minBulletDistance: 10,
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: "value",
                  valueXField: "date",
            
                  tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "{valueY}"
                  })
                })
              );

              var bulletTemplate = am5.Template.new({
                // This will be default fill for bullets that do not have
                // it set via templateField
                fill: am5.color(0x6771dc)
              });
           
          
              // line üzerindeki noktalar
              // series.bullets.push(function() {
              //   var circle = am5.Circle.new(root, {
              //     radius: 4,   // line üzerindeki nokta genişliği
              //     // fill: am5.color(0x50b300),
              //     // fill: am5.color(0x6771dc),  // line üzerindeki nokta rengi
              //     strokeWidth: 3,
                  
              //   });
              //   return am5.Bullet.new(root, {
              //     sprite: circle,
              //   });
              // });

              series.bullets.push(function() {
                return am5.Bullet.new(root, {
                  sprite: am5.Circle.new(root, {
                    radius: 4,
                    // fill: am5.color(0x6771dc),
                    templateField: "bulletSettings"
                  }, bulletTemplate)
                });
              });


          
          
          
              // add series2 
              var series2 = chart.series.push(am5xy.LineSeries.new(root, {
                name: "Series 2",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value2",
                valueXField: "date"
              }));
              series2.strokes.template.setAll({
                strokeDasharray: [10, 5],
                strokeWidth: 4,
              });
          
              // line üzerindeki noktalar
              series2.bullets.push(function() {
                var circle = am5.Circle.new(root, {
                  radius: 3, // noktanın kalınlığını artırır
                  // fill: series2.get("fill"),
                  fill: series2.get(0xb30000),

                  strokeWidth: 9, 
                
                });
          
                return am5.Bullet.new(root, {
                  sprite: circle
                });
              });
          
        
             // line kalınlık
              series.strokes.template.setAll({
                strokeWidth: 3,
                templateField: "strokeSettings",
              });

          
          
              // series.set("stroke", am5.color(0x68dc76))
              series.set("stroke", am5.color(0x6771dc))
              // series2.set("stroke", am5.color(0x68dc76))


              // series.set("fill", am5.color(0x50b300	))
              // series.set("fill", am5.color("#00ff00"))

          
          
              // forecast data 
              // const newData = myForecastData && myForecastData.length > 0
              //       ? myForecastData[0]?.Result?.replace('[', '')?.replace(']', '')?.split(',')?.map(value => parseInt(value))?.map((value, index) => ({
              //         date: new Date(myForecastData[0]?.DateTime).getTime() + index * 86400000,
              //         value: value
              //       })) || []
              //       : [];

                  // yukarıdaki newData ilk indexlenen forecast verisine göre line hazırlıyordu, aşağıdaki işlemler hepsi store seçildiğinde seçilen sku ya göre sonuç veriyor

                  // 1. Tüm Result değerlerini ayrı bir diziye çıkarttık, ve her değeri sayıya dönüştürün.
              const resultArrays = myForecastData?.map(forecast => JSON.parse(forecast.Result));

                  // 2. Tüm Result dizilerinin aynı indeksli elemanlarının topladık.
              const summedResults = resultArrays?.reduce((sums, result) => {
                result?.forEach((value, index) => {
                  sums[index] = (sums[index] || 0) + value;
                });
                return sums;
              }, []);

                  // 3. Her Result için ayrı bir tarih serisi oluşturduk ve her günün tarihini bir gün artırarak hesapladık.
              const newData = summedResults?.map((value, index) => ({
                date: new Date(myForecastData[0]?.DateTime).getTime() + index * 86400000,
                value: value
              }));
            

                    console.log("forecast data",newData)
          
          
              // campaign data
          
              var offerData = [{
              
              //     date: new Date(2022, 12, 10).getTime(),
              //     value2: 3
                  
              //   }, {
              
              //   date: new Date(2022, 12, 12).getTime(),
              //   value2: 14
                
              // }, {
              //   date: new Date(2022, 12, 14).getTime(),
              //   value2: 16
              // }, {
              //   date: new Date(2022, 12, 16).getTime(),
              //   value2: 17
              }];
          
               
        //   forecasting data style değişim yeri, line rengi
          if (data?.length > 0) {
            data[data?.length - 1].strokeSettings= {
              stroke: am5.color(0xFF621F),
              strokeWidth: 3,
              strokeDasharray : [10, 5],
              // fill: am5.color(0xb30000) çizgi altını dolduruyor 
              
             } 
             
      
          }

          // if (data?.length > 0) {
          //   // data[data?.length - 1].bulletSettings= {
          //   //   fill: am5.color(0xb30000)
          //   // }
          //   bulletSettings= {
          //     fill: am5.color(0xb30000)
          //   }
          // }
          
          for (let item of newData || []) {
            data?.push(item);
            // ikinci line ait nokta rengi değişimi
            newData.forEach(function(data, index) {
              data.bulletSettings = {
                fill: am5.color(0xFF621F)
              };
            });
          }

         
          
            
              series.data.setAll(data || []);
              series2.data.setAll(offerData);
              
              // Add cursor
              // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
              var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                xAxis: xAxis
              }));
              cursor.lineY.set("visible", false);
              
              // add scrollbar
              chart.set("scrollbarX", am5.Scrollbar.new(root, {
                orientation: "horizontal"
              }));
          
              // Make stuff animate on load
              // https://www.amcharts.com/docs/v5/concepts/animations/
              series.appear(1000, 100);
              series2.appear(1000);
              chart.appear(1000, 100);
              // end am5.ready()
    
      return () => {
        if (root) {
          root.dispose();
        }
      }
    }, [myData, myForecastData, isDark]);
  // }, [myData]);


    const uniqueValues = new Set();
mySelectData?.forEach(item => {
  uniqueValues.add(item.VendorStore__Store__Name);
});

const barcodeSet = new Set();


function hasPermission(permission) {
  return perms?.includes(permission);
}


useEffect(() => {
  if (myDataVendor && myDataVendor.length > 0) {
    const firstVendorId = myDataVendor[0].Id;
    setVendorId(firstVendorId);
    setSelectVendorId(firstVendorId)
   
  }
}, [myDataVendor]);




const handleChangeVendor = (e)=> {
  setVendorId(e.target.value); 
  setSelectVendorId(e.target.value)
}






  
  return (
    <>
     <Card className="mb-3">
    <div style={{  borderRadius: '7px'}} >
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <h6 style={{marginLeft :'30px', color: isDark ? "#9DA9BB" : 'black' , marginTop:'15px', fontSize:'14px', fontWeight:'600'}}>Satış Tahmin(adet/gün)</h6>
        <div style={{marginTop:'10px', marginRight:"10px"}}>
        <form action="">

        { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
          <>
        <span style={{fontSize:'14px', color: isDark ? "#9DA9BB" : 'black' , fontWeight:'600'}}>Satıcı:</span>
          <select name="" id=""  placeholder="ürün seçimi" onChange={handleChangeVendor} style={{border:'none', borderBottom:'1px solid #7F7F7F', fontSize:'13px', backgroundColor:isDark ? "#0B1727" : "white", color: isDark ? "#9DA9BB" : 'black', marginRight:'10px' }}>
            
         <option value=""  style={{backgroundColor:isDark ? "#0B1727" : "white", color: isDark ? "#9DA9BB" : 'black' }}>Hepsi</option>
         { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })} 
          </select>
          </> : ""}
                


        <span style={{fontSize:'14px', color: isDark ? "#9DA9BB" : 'black' , fontWeight:'600'}}>Mağaza:</span>
          <select name="" id=""  placeholder="ürün seçimi" onChange={handleChangeStore} style={{border:'none', borderBottom:'1px solid #7F7F7F', fontSize:'13px', backgroundColor:isDark ? "#0B1727" : "white", color: isDark ? "#9DA9BB" : 'black' }}>
            
         <option value=""  style={{backgroundColor:isDark ? "#0B1727" : "white", color: isDark ? "#9DA9BB" : 'black' }}>Hepsi</option>
              {Array.from(new Set(mySelectData?.map((item) => item.VendorStore__Store__Name))).map((storeName, i) => (
                  <option key={i} value={storeName}  style={{color: isDark ? "#9DA9BB" : 'black' }}>{storeName}</option>
                ))}

          </select>

                  <span style={{fontSize:'14px', color: isDark ? "#9DA9BB" : 'black' , fontWeight:'600', marginLeft:'10px'}}>Ürün(SKU):</span>
<select name="" id=""  placeholder="ürün seçimi" onChange={handleChange} style={{border:'none', borderBottom:'1px solid #7F7F7F', fontSize:'13px', backgroundColor:isDark ? "#0B1727" : "white", color: isDark ? "#9DA9BB" : 'black' }}>
{/*             
         {mySelectData?.map((item, i)=>
            <option key={i} value={item.Product__barcodes__Barcode}>{item.Product__SKU}</option>
         )} */}

{mySelectData?.map((item, i) => {
  
    if (!barcodeSet.has(item.Product__barcodes__Barcode)) {
        barcodeSet.add(item.Product__barcodes__Barcode);
        return <option key={i} value={item.Product__barcodes__Barcode}>{item.Product__SKU}</option>;
    }
})}
          </select>
          
        </form>
        </div>
        </div>
        <div ref={chartRef} style={{ width: "100%", height: "500px" }}>
        </div>


        <p style={{textAlign:'center',marginTop:'10px', fontSize:'14px'}}>
            <span style={{fontFamily:"'Poppins', sans-serif'", color:'#6771DC', marginLeft :'10px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8"/></svg> reel satış
            </span>
            <span style={{fontFamily:"'Poppins', sans-serif'", color:'#F36222', marginLeft :'10px'}} >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8"/> </svg> tahmin satış
            </span>
            {/* <span style={{fontFamily:"'Poppins', sans-serif'", color:'#79A1E0', marginLeft :'10px'}}> 
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8"/> </svg> kampanya 
            </span> */}
        </p>

       
    </div>  
    {/* <div style={{ backgroundColor: 'white', borderRadius: '7px', border:'solid white', marginTop:"30px", padding:"20px"}} >
     <ChartOptions/> 

     </div> */}
     </Card>
     <div style={{marginTop:"25px"}}>
     <ForecastDetailsPage/>
     </div>

     <div >
      <Card style={{marginTop:"25px", paddingTop:'30px'}}>
      <ChartOptions/>
      </Card>
    
     </div>

     
     
     </>
  )
}

export default PriceTotalForecast