import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table, Button, Form, Tab, InputGroup } from 'react-bootstrap';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import PaginationOrder from './Pagination';
import useStorageSKUDetail from 'hooks/Costs/useStorageSKUDetail';
import { Search } from 'react-bootstrap-icons';





const StorageFeesDetail = () => {


  const navigate = useNavigate();
  const location = useLocation()
  const {id, inventory_id, daily_storage } = location.state || ""

  
  const detailData = daily_storage?.map(item => ({
    id: item.Id,
    date: item.Date,
    EndOfDayCount: item.EndOfDayCount || "-",
    EndOfDayVolume: item.EndOfDayVolume || "-",
  
  })) || [];


 

const handleBackClick = () => {
    navigate('/costs-region');
 
};

const {getData, myData, totalPages, setCurrentPage,currentPage, followersPerPage, setFollowersPerPage, setSearch, search} = useStorageSKUDetail()

const [activePage, setActivePage] = useState(1)



 
useEffect(() => {
  if(inventory_id){
  getData(inventory_id, id)
  }

}, [inventory_id, id, search, currentPage, followersPerPage])



const detailDataSku = myData?.map(item => ({
  id: item.Id,
  sku: item.SKU,
  TotalCountDayByDay: item.TotalCountDayByDay || "-",
  ProductVolume: item.ProductVolume || "-",
  SKUTotalVolumeByDailySum: item.SKUTotalVolumeByDailySum || "-",
  daily_fees : item.daily_fees
})) || [];


  return (
    <>
      <span onClick={handleBackClick} style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
    
    <Card className="mb-3 mt-1">
      <div style={{marginLeft:'18px', marginTop:'15px'}}>
        <h6>Envanter Günlük Maliyetleri</h6>
      </div>
   
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Id</th>
                <th className="border-0">Tarih</th>
                <th className="border-0 text-center">Gün Sonu Adet</th>
                <th className="border-0 text-center">Gün Sonu Hacim</th>
              </tr>
            </thead>
            <tbody>
              {detailData?.length>0 && detailData?.map(data => (
                <tr key={data.id} className="border-200">
                  <td className="align-middle">
                    {data.id}
                  </td>

                  <td className="align-middle">
                    {data.date}
                  </td>

                  <td className="align-middle text-center">
                    {data.EndOfDayCount}
                  </td>

                  <td className="align-middle text-center">
                    {data.EndOfDayVolume}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  <tr>
                    <td className="fw-semi-bold">
                
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
    <Card className="mb-3 mt-1">

<div style={{display:"flex", justifyContent:"space-between"}}>

    <div style={{marginLeft:'18px', marginTop:'15px'}}>
      <h6>SKU Hesaplanan Desi</h6>
    </div>

  <div className="mb-3" style={{marginRight:"15px", marginTop:'15px'}}>
      <InputGroup>
          <Form.Control  type="text" placeholder="sku.."  onChange={(e)=>setSearch(e.target.value)}/>
          <InputGroup.Text><Search/></InputGroup.Text>
      </InputGroup>
    </div>

</div>


<Card.Body>
  <div className="table-responsive fs--1">
    <Table striped className="border-bottom">
      <thead className="bg-200 text-900">
        <tr>
          <th className="border-0">SKU</th>
          <th className="border-0 text-center">Her Günün Toplam Adeti</th>
          <th className="border-0 text-center">Ürün Desi</th>
          <th className="border-0 text-center">Toplam Desi Gün Bazlı</th>
        </tr>
      </thead>
      <tbody>
        {detailDataSku?.length>0 && detailDataSku?.map(data => (
          <tr key={data.id} className="border-200">
            

            <td className="align-middle">
              <Link  to="/costs-storage-fees-sku-detail" state={{ daily_fees: data.daily_fees, id, inventory_id, daily_storage }}>
              {data.sku}
              </Link>
             
            </td>

            <td className="align-middle text-center">
              {data.TotalCountDayByDay}
            </td>

            <td className="align-middle text-center">
              {data.ProductVolume}
            </td>

            <td className="align-middle text-center">
            {data.SKUTotalVolumeByDailySum}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <Row className="g-0 justify-content-end">
      <Col xs="auto">
        <Table borderless size="sm" className="fs--1 text-end">
          <tbody>
            <tr>
              <td className="fw-semi-bold">
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  </div>

</Card.Body>

      <Card.Footer>
        <PaginationOrder pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage}/>
      </Card.Footer>
    </Card>
    </>
  );
};

StorageFeesDetail.propTypes = {};

export default StorageFeesDetail;
