import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row, Table, Button, Form, Tab, Nav } from 'react-bootstrap';
// import { orderedProductsData } from 'data/ecommerce/OrderDetailsData';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import useInventoryProductAll from 'hooks/useInventoryProductAll';
import useInventoryShelfChange from 'hooks/useInventoryShelfChange';
import useInventoryShelfCountChange from 'hooks/useInventoryShelfCountChange';

const InventoryProductDetail = () => {


  const {detail, getDetail} = useInventoryProductAll()
  const {setFormData, formData} = useInventoryShelfChange()

  const {setInventoryProductId, setBarcode, setNewCount, postCount, newCout} = useInventoryShelfCountChange()


  const location = useLocation()
  const {id,inventory_id, barcode, lotField, sktField, stock, shelfField} = location.state || ""

  const [codeTo, setCodeTo] = useState()

  const params = new URLSearchParams(location.search);
  const id_params = +params.get('id');
  const inventory_id_params = +params.get('inventory_id')
  const barcode_params = params.get('barcode')

  const lotField_params = params.get('lotField')
  const sktField_params = params.get('sktField')
  const stock_params = +params.get('stock')
  const shelfField_params = params.get('shelfField')






  useEffect(() => {
    const effectiveId = id_params || id; 
    if (effectiveId) {
      getDetail(effectiveId);
      setInventoryProductId(effectiveId);
      setBarcode(barcode_params || barcode);
    }
  }, [id, id_params, detail]);






  const orderedProductsData = [{
    id: detail?.Id, 
    name: detail?.Name,
    stock: detail?.Count,
    barcode: detail?.Barcode,
    region: detail?.Inventory?.inventory_name || "-",
    image: detail?.product?.image || "",
    sku: detail?.SKU,
    skt: detail?.ExpirationDate ? new Date(detail.ExpirationDate).toLocaleDateString() : "-",
    lot: detail?.Lot || "-",
    shelfName: detail?.shelf_name || "-"
  }];
  


 


const navigate = useNavigate();

const handleBackClick = () => {
  if (location.state && location.state.from) {
    navigate(location.state.from);
  } else {
    // Varsayılan sayfaya dön
    navigate('/e-commerce/inventory-product-all');
  }
};




const handleSubmitShelf = (e) => {
  e.preventDefault();
  setFormData({
    ...formData,   "inventory_id" : inventory_id || inventory_id_params,
                    "barcode": barcode || barcode_params,
                    "count": stock || stock_params,
                    "location_code_from":  shelfField || shelfField_params,
                    "location_code_to" : codeTo,
                    "lot" : lotField || lotField_params,
                    "expiration_date" : sktField || sktField_params
    })  



e.target.reset();
setCodeTo(null)
if (id) {
  getDetail(id);
} else {
  getDetail(id_params);
}

}


const handleSubmitStock = (e) => {
  e.preventDefault();
  
  postCount();

  if (id) {
    getDetail(id);
  } else {
    getDetail(id_params);
  }

  setNewCount("");
};
 



  return (
    <>
    {/* <Link to="/e-commerce/product/product-all"> */}
      <span onClick={handleBackClick} style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      {/* </Link> */}
      {/* <InventoryProductList/> */}
    <Card className="mb-3 mt-1">
   
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Ürün</th>
                <th className="border-0">İsim</th>
                <th className="border-0 text-center">Barkod-SKU</th>
                <th className="border-0 text-center">SKT-LOT</th>
                <th className="border-0 text-end">Depo</th>
                <th className="border-0 text-end">Raf</th>
                <th className="border-0 text-end">Stok</th>
              </tr>
            </thead>
            <tbody>
              {orderedProductsData?.length>0 && orderedProductsData?.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle">
                    <img style={{width:"40px"}}src={product.image} alt="" />
                 
                  </td>
                  <td className="align-middle"
                  title={product?.name}
                  onMouseOver={(e) => (e.currentTarget.title = product?.name)}
                  >
                    {product?.name?.length > 40
                      ? product?.name.slice(0, 40).concat("...")
                      : product?.name}

                  </td>
                  <td className="align-middle text-center">
                  {product.barcode} - {product.sku}
                  </td>

                  <td className="align-middle text-center">
                  {product.skt} - {product.lot}
                  </td>

                  <td className="align-middle text-center">
                    {product.region}
                  </td>
                  <td className="align-middle text-end">
                    {product.shelfName}
                  </td>

                  <td className="align-middle text-end">
                    {product.stock || 0}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  <tr>
                    {/* <th className="text-900">Tax 5%:</th> */}
                    <td className="fw-semi-bold">
                
                    </td>
                  </tr>
                  {/* <tr className="border-top">
                    <th className="text-900">Toplam:</th>
                    <td className="fw-semi-bold">
                      {subtotal}
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>





      </Card.Body>
    </Card>

<Card className="mb-3 mt-1">
      <Card.Body>
      <Table striped className="border-bottom">
          <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Raf ve Stok Değiştirme</th>
              </tr>
            </thead>
       </Table>
        <Tab.Container defaultActiveKey="shelfChange">
          <Row>
            <Col sm={12}>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="shelfChange">Raf Değiştir</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="stockChange">Stok Değiştir</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="shelfChange">
                  <Form onSubmit={handleSubmitShelf}>
                    <div style={{ marginBottom: '50px', display: 'flex', alignItems: 'center',    justifyContent:'left', marginTop:'60px' }}>
                        <div style={{ marginRight: '10px', width: '50%' }}>
                          <Form.Control type="text" placeholder="Raf ismi giriniz" onChange={(e) => setCodeTo(e.target.value)} />
                        </div>
                    <div>
                      <Button variant="primary" type="submit">
                        Onayla
                      </Button>
                    </div>
                  </div>
                </Form>
                </Tab.Pane>
                <Tab.Pane eventKey="stockChange">
                  <Form onSubmit={handleSubmitStock}>
                  <div style={{ marginBottom: '50px', display: 'flex', alignItems: 'center',    justifyContent:'left', marginTop:'60px' }}>
                        <div style={{ marginRight: '10px', width: '50%' }}>
                          <Form.Control type="text" value={newCout} placeholder="Stok miktarı giriniz" onChange={(e) => setNewCount(e.target.value)} />
                        </div>
                    <div>
                      <Button variant="primary" type="submit">
                        Onayla
                      </Button>
                    </div>
                  </div>
                </Form>

                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Card.Body>
    </Card>


    
    </>
  );
};

InventoryProductDetail.propTypes = {};

export default InventoryProductDetail;
