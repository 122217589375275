import IconButton from 'components/common/IconButton';
import { PermsContext } from 'context/PermsContext';
import useOrderList from 'hooks/useOrderList';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const OrdersTableHeader = ({ selectedRowIds }) => {

  const navigate = useNavigate()

  const {perms} = useContext(PermsContext)



  function hasPermission(permission) {
    return perms?.includes(permission);
  }
  console.log("selectedRowIds", selectedRowIds)

  return (
   
    
      <p>{Object.keys(selectedRowIds).length}</p>


      
  );
};

OrdersTableHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default OrdersTableHeader;
