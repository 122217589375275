import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import { toast } from 'react-toastify';
import {BASE_URL} from './baseUrl'
import useVendorList from './useVendorList';
import { useNavigate } from 'react-router-dom';
import useVendorStoreListAuthorized from './useVendorStoreListAuthorized';


export default function useOrderAdd() {

 const {myKey, currentUser} = useContext(AuthContext)

 const {myDataVendor} = useVendorList()

 const [formData, setFormData] = useState({
        customerName: '',
        customerLastName: '',
        customerPhone: '',
        customerEmail: '',
        date: '',
        time: '',
        addressName: '',
        addressLastName: '',
        adsressPhone: '',
        addressEmail: '',
        addressText: '',
        addressCity: '',
        addressDistrict: '',
        adsressCompany: '',
        adsressIndentityNumber: '',
        adsressTaxNumber: '',
        adsressTaxOffice: '',
        customerPhone: '',
        Products:'',
        Invoice : '',
        orderNumber :'',
        orderNote : '',
        currency : ''
    });
 


const [sameAddress, setSameAddress] = useState(false);

const [vendorId, setVendorId] = useState();
const [vendorStoreId, setVendorStoreId] = useState()
   
useEffect(() => {
  if (myDataVendor?.length === 1) {
    setVendorId(myDataVendor[0].Id);
  }
}, [myDataVendor]);




const [status, setStatus] = useState(null);




 const postData = (navigateCallback)=>{

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = `${BASE_URL}api/v1/store/integration/${vendorStoreId}/order_create/`
    

    // var raw = JSON.stringify({...formData})
    

    var productsArray = Array.isArray(formData.Products) ? formData.Products : Object.values(formData.Products);

    

    function toUpperCase(str) {
      return str.replace(/ğ/g, "Ğ")
                .replace(/ü/g, "Ü")
                .replace(/ş/g, "Ş")
                .replace(/ı/g, "I")
                .replace(/i/g, "İ")
                .replace(/ö/g, "Ö")
                .replace(/ç/g, "Ç")
                .toUpperCase();
    }

  
    var raw = JSON.stringify({
      "Customer": {
        "FirstName": formData.customerName,
        "LastName": formData.customerLastName,
        "Phone": formData.customerPhone,
        "Email": formData.customerEmail,
      },
         
      "ShippingAddress": {
        "AddressText": formData.addressText,
        "City": toUpperCase(formData.addressCity),
        "District": toUpperCase(formData.addressDistrict),
        "Email": formData.addressEmail  || formData.customerEmail,
        "FirstName": formData.addressName || formData.customerName,
        "LastName": formData.addressLastName || formData.customerLastName,
        "Phone": formData.addressPhone || formData.customerPhone,
      },
      "InvoiceAddress": {
        "AddressText": formData.deliveryText || formData.addressText,
        "City": formData.deliveryCity || formData.addressCity,
        "District": formData.deliveryDistrict || formData.addressDistrict,
        "Email": formData.deliveryEmail || formData.addressEmail || formData.customerEmail,
        "Phone": formData.deliveryPhone || formData.addressPhone || formData.customerPhone,
        "Company": formData.deliveryCompany,
        "IdentityNumber": formData.deliveryIdentityNumber,
        "TaxNumber": formData.deliveryTaxNumber,
        "TaxOffice": formData.deliveryTaxOffice,
        "FirstName": formData.addressName || formData.customerName,
        "LastName": formData.addressLastName || formData.customerLastName,

      },
      "OrderDate": formData.date,
      "Status": "1",
      "OrderNumber": formData.orderNumber,
      "OrderAmount": formData.OrderAmount,
      "Note" : formData.orderNote,
      "Currency" :formData.currency,
      "OrderProducts": productsArray.map(p=>({
          "Name": p.name,
          "Barcode": p.barcode,
          "Count": Number(p.count),
          "BasePrice" : p.basePrice,
          "SKU": p.sku
        }))
   
  });
    
 console.log("raw",raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
  .then(response => {
    if (response.ok) {
        response.text()
        .then(result => {
            console.log(result)
            toast.success(`başarılı şekilde gönderildi.`, { theme: 'colored'});
            setStatus('success');
            if (navigateCallback) {
              navigateCallback("/e-commerce/orders/order-list");
            }
        })
    } else {
        console.log("Post işlemi başarısız.")
        setStatus('error');
        toast.error(` işlem başarısız.`, { theme: 'colored'});

    }
  })
  .catch(error => console.log('error', error));

}

useEffect(() => {
 if(formData.customerName && formData.addressCity){
  postData()
 }
// postData()

}, [formData])


 

 return {setFormData, formData, postData, setSameAddress, sameAddress, setVendorId, vendorId, status, setVendorStoreId}
}
