import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Form, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Pagination from './PaginationOrder';
import DatePicker from  "react-datepicker";
import useShipmentCompany from 'hooks/useShipmentCompany';
import Accordion from 'react-bootstrap/Accordion';
import useOrderReturned from 'hooks/useOrderReturned';
import { Search } from 'react-bootstrap-icons';
import IconButton from 'components/common/IconButton';
import gifOrder from 'assets/img/gif_order.gif'


const columns = [
  {
    accessor: 'name',
    Header: 'Sipariş',
    headerProps: { className: 'text-left'},
    cellProps: {
      className: 'text-left'
    },
    Cell: rowData => {
      const { id, name, email } = rowData.row.original;
      return (
        <>
          <Link to="/e-commerce/orders/order-details" state={{id}}>
            <strong>{id}</strong>
          </Link>{' '}
          by <strong>{name}</strong> <br />
          <Link to="/e-commerce/orders/order-details"state={{id}} >
          <a href="">{email}</a>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'date',
    Header: 'Tarih',
    headerProps: {
      className: 'text-left'
    },
    cellProps: {
      className: 'text-left'
    }
  },
  {
    accessor: 'address',
    Header: 'Şehir ve Kargo Bilgileri',
    headerProps: {
      className: 'text-left'
    },
    cellProps: {
      className: 'text-left'
    },
    Cell: rowData => {
      const { address, shippingType } = rowData.row.original;
      return (
        <>
            {address}
          <p className="mb-0 text-500">{shippingType}</p>
        </>
      );
    }
  },
  {
    accessor: 'amount',
    Header: 'Tutar',
    headerProps: {
      className: 'text-left'
    },
    cellProps: {
      className: 'text-left'
    },
    Cell: rowData => `${rowData.row.original.amount}`
  },
  {
    accessor: 'none',
    Header: 'Mağaza',
    disableSortBy: true,
    cellProps: {
      className: 'text-left'
    },
    Cell: rowData => {
      const { store} = rowData.row.original;
      return (
        <p>{store}</p>
      );
    }
  },
];

const OrdersReturned = () => {

const {myData, setPage, startDate, setStartDate, endDate, setEndDate, setCargo, firstName, setSearch, showOrderGif} = useOrderReturned()


  const {shipmentCompany} = useShipmentCompany()
  const [followersPerPage]=useState(10)
  const [currentPage,setCurrentPage]=useState(1)
  const [activePage, setActivePage] = useState(1)
  const [orderList, setOrderList] = useState() 


  useEffect(() => {
    setPage(activePage)
  }, [activePage])
  
  const [isOpen, setIsOpen] = useState(false);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };


const handleChangeCargo = (e)=> {
  setCargo(e.target.value); 
}


useEffect(() => {
     
  const newList = myData?.results.map((item)=>{

    const date = new Date(item.OrderDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
    return {
      id : "#"+item.Id,
      name: item.Customer.FirstName+ " "+item.Customer.LastName, 
      email : item.OrderNumber === null ? "Sipariş No: -" : "Sipariş No: " + item.OrderNumber,
      date: `${day}-${month}-${year}`,
      address : item.ShippingAddress.City,
      shippingType: ((item.ShipmentCompanyText && item.ShipmentCompanyText.includes("Marketplace")) ? item.ShipmentCompanyText.replace('Marketplace', '').trim() : item.ShipmentCompanyText ? item.ShipmentCompanyText : "Kargolamayı bekliyor") + " " + (item.ShippingTrackingNumber ? item.ShippingTrackingNumber : "Henüz Kargolanmadı"),
      status: item.Status,
      amount : item.OrderAmount,
      store : item.VendorStore.Store.Name
  
    }
  })
  setOrderList(newList)


  

}, [myData, firstName])


const totalPages = Math.ceil(myData?.count/followersPerPage)

const onChange = dates => {
  const [start, end] = dates;
  setStartDate(start);
  setEndDate(end);
};

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      // data={orderList?.length>0 && orderList} 
      selection
      sortable
      pagination
      perPage={10}
    >
      <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showOrderGif && <img src={gifOrder} style={{width:'50%'}} alt="loading gif" />}
          </div>
     
      <Card className="mb-3">
        <Card.Header>

      <div style={{display:"flex", justifyContent:"space-between"}}>
        <div>
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">İade Siparişler</h5>
        </div>

        <Dropdown  show={isOpen} onToggle={handleToggle}>
        <Dropdown.Toggle
           bsPrefix="toggle"
           as={Link}
          //  to=""
           className={classNames('px-0 nav-link')}>
     
            <IconButton
              variant="falcon-default"
              size="m"
              icon="fa-sharp fa-light fa-filter-list"
              transform="shrink-3"
              style={{width:'145px', fontSize:'15px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
              </svg>
                  <span className="d-none d-sm-inline-block ms-1">Filtre</span>
            </IconButton>

        </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret" style={{minWidth: '300px', width: '300px'}}>

          <Card className="shadow-none shadow-show-xl scrollbar">
            <Card.Header className="bg-light d-none d-xl-block">
              <h6 className="mb-0">Filtre</h6>
            </Card.Header>
              <Card.Body>
                <Form>


                  <div className="mb-3">
                  <InputGroup>
                    <Form.Control  type="text" placeholder="search..."  onChange={(e)=>setSearch(e.target.value)}/>
                    <InputGroup.Text><Search/></InputGroup.Text>
              </InputGroup>
                  </div>

                  <div className="mb-2">
                    <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Tarih Seçimi</Form.Label>
                    <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        formatWeekDay={day => day.slice(0, 3)}
                        endDate={endDate}
                        selectsRange
                        dateFormat="MMM dd"
                        className='form-control'
                        placeholderText='tarih giriniz'
                        />
                  </div>
                  <div className="mb-2">
                  <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Kargo Firması</Form.Label>
                  { shipmentCompany?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"17px"}} onChange={handleChangeCargo}>
                     <option value="" >Kargo</option>
                        { shipmentCompany?.map((item, i)=>{
                              return <option key={i} value={item.ShipmentCompanyText}>{item.ShipmentCompanyText}</option>
                        })}       
                </Form.Select>}
                  </div>
               
              </Form>
            </Card.Body>
        </Card>
      </Dropdown.Menu>
      </Dropdown>
  
      </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <Pagination pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage}/>
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default OrdersReturned;





