import React from 'react';
import { Button } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
import L from 'leaflet';
import 'leaflet.tilelayer.colorfilter';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import AppContext from 'context/Context';
import MarkerClusterGroup from '@changey/react-leaflet-markercluster';
// import { markers } from 'data/dashboard/projectManagement';
import useInventoryMap from 'hooks/useInventoryMap';
import logo from 'assets/img/mobile.png';
import './styles.css';

const exampleCode = `
function LayerComponent() {
  const map = useMap();
  const { config } = useContext(AppContext);
  const { isDark } = config;
  const filter = isDark
    ? [
        'invert:98%',
        'grayscale:69%',
        'bright:89%',
        'contrast:111%',
        'hue:205deg',
        'saturate:1000%'
      ]
    : ['bright:101%', 'contrast:101%', 'hue:23deg', 'saturate:225%'];


  useEffect(() => {
    map.invalidateSize();
  }, [config]);

  useEffect(() => {
    if (map) {
      L.tileLayer
      .colorFilter(
        'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
        {
          attribution: null,
          transparent: true,
          filter: filter
        }
      )
      .addTo(map);
     
    }
  }, [isDark]);


  

  return (
    <>
    <TileLayer
        
    attribution={null}
    url={'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'}
  />
        {markers.map(marker => (
          <Marker
          
            key={marker.id}
            position={[marker.lat, marker.long]}
            icon={L.icon({
              iconUrl: logo,
              iconAnchor: [15, 25],
              iconSize: [20, 20],
              className: 'my-icon'
            })}
          >
          <Popup open>
          <h6 className="marker-name">{marker.name}</h6>
          <p className="marker-detail">
            Mevcut Ürün Çeşidi : {marker.available_product_count} 
          </p>
          <p className="marker-detail">
            Toplam Ürün Çeşidi : {marker.product_type_count} 
          </p>
        </Popup>
          </Marker>
        ))}
   
 
    </>
  );
}

function LeafletMap() {
  const position = [40.7, 29]; 
  const {
    config: { isRTL }
  } = useContext(AppContext);

  return (
    <div dir="ltr">
      <MapContainer
        zoom={isRTL ? 1.8 : 8.3}
        minZoom={isRTL ? 1.8 : 1.3}
        zoomSnap={0.5}
        center={position}
        radius={200}
        style={{ height: '600px', width: '100%' }}
     
      >
    
        <LayerComponent />
      </MapContainer>
    </div>

  );
}
render(<LeafletMap/>)
`;

const WarehouseMap = () => {
  
  const {myData, getData} = useInventoryMap()


  const markers = myData?.inventory_products?.map((product, i) =>{
    return {
    id : i+1,
    name: product.Region?.Name,
    product_type_count: product.product_type_count,
    available_product_count: product.available_product_count,
    lat : product?.Region?.Latitude,
    long : product?.Region?.Longitude,


 
  }});

  return (
    <>
    
      <FalconComponentCard>
        {/* <FalconComponentCard.Header title="Depo Bölgeleri"  style={{fontSize:"15px"}}/> */}
        <h6 style={{marginLeft:"15px", marginBottom:"30px", marginTop:"20px"}}>Depo Bölgeleri</h6>
        <FalconComponentCard.Body
          code={exampleCode}
          language="jsx"
          noInline
          scope={{
            L,
            MapContainer,
            TileLayer,
            Marker,
            Popup,
            useMap,
            MarkerClusterGroup,
            markers,
            AppContext,
            logo
          }}
        />
      </FalconComponentCard>
    </>
  );
};

export default WarehouseMap;
