import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'


export default function useForecastData() {



 const [myForecastData, setMyForecastData] = useState()
 const [mySelectData, setMySelectData] = useState()
 const [myDataTable, setMyDataTable] = useState()
 

 

 const {myKey, currentUser} = useContext(AuthContext)
 const [forecastBarcode, setForecastBarcode] = useState()
 const [vendorStore, setVendorStore] = useState()
 const [storeTablo, setStoreTablo] = useState()
 const [barcodeTablo, setBarcodeTablo] = useState()
 const [vendorId, setVendorId] = useState()



//  useEffect(() => {
//   if (mySelectData && mySelectData.length > 0) {
//     setForecastBarcode(mySelectData[0].Product__barcodes__Barcode);
//     // setVendorStore(mySelectData[0].VendorStore__Store__Name)
//     // tüm tahinleri alabilmek için setVendorStore yoruma aldık
// }


// }, [mySelectData])

 const getData = ()=>{
        var myHeaders = new Headers();

    
        const params = {
          barcode: forecastBarcode,
          vendor_store :vendorStore,
          vendor_id: vendorId,
         
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/order/forecasting/?`
    );


        console.log("forecst url", request_url)

        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyForecastData(result) 
            
    })
          .catch(error => console.log('error', error));        
 }


 const selectData = ()=>{
  var myHeaders = new Headers();


   if (!vendorId) return;

  const params = {
    vendor_store :storeTablo,
    vendor_id : vendorId
   
  };

  const request_url = Object.entries(params)
      .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
      .reduce(
        (url, [key, value]) => url + `&${key}=${value}`,
        `${BASE_URL}api/v1/order/forecasting/?`
);



  
  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMySelectData(result) 
})
    .catch(error => console.log('error', error));        
}






useEffect(() => {
  getData();

}, [forecastBarcode, vendorStore, vendorId]);




useEffect(() => {
  selectData();

}, [vendorId]);



 return {myForecastData, setForecastBarcode, forecastBarcode, vendorStore, setVendorStore, setMySelectData, mySelectData, storeTablo, setStoreTablo, myDataTable, setBarcodeTablo, setVendorId}
}
