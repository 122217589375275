import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import useTotalSales from 'hooks/useTotalSales';
import AppContext from 'context/Context';
import TotalStockChart from './TotalStockChart';
import { Link } from 'react-router-dom';
import useInventoryListFilter from 'hooks/useInventoryListFilter';
import useTotalStock from 'hooks/useTotalStock';
import useInventoryRegion from 'hooks/useInventoryRegion';
import { useEffect } from 'react';
import { addDays, format, subMonths } from 'date-fns';
import { PermsContext } from 'context/PermsContext';



const TotalStock = () => {


  const chartRef = useRef(null);
  const {myData, setInventoryId, setStartDate, setEndDate, inventoryId} = useTotalStock()
  const { setInventoryContext, inventoryContext} = useContext(PermsContext)
  const {regionData} = useInventoryRegion()


  console.log("inventoryContext1", inventoryContext)

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  const data = {}

  data.satışTl = myData?.map(item => item.EndOfDayCount) || []

  const date = myData?.map(item => new Date(`${item.year}-${item.month}-${item.day}`).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }))

  const handleLegendToggle = name => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name
    });
  };

  const handleChangeInventory = (e)=> {
    setInventoryContext(e.target.value); 
  }


  const today = new Date();

  const handleDateChange = (event) => {
    const selectedOption = event.target.value;
    if (selectedOption === '7days') {
      setStartDate(format(addDays(today, -7), 'yyyy-MM-dd'));
    } else if (selectedOption === '1month') {
      setStartDate(format(subMonths(today, 1), 'yyyy-MM-dd'));
    }
    setEndDate(format(today, 'yyyy-MM-dd'));
  };

  



  return (
    <Card className="h-80">

      <Card.Header>

      <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
              <h6 className="mb-0" style={{color: isDark ? "#D8E2EF":"#025098"}}>Günlük Stok</h6>
            </div>

            <div style={{display:"flex", justifyContent:'right'}}>

            <div style={{marginTop:'10px'}}>
                        { regionData?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} 
                  onChange={handleChangeInventory}
                  value={inventoryContext}
                  >
                <option value="">Depo</option>
                  { regionData?.map((item, i)=>{
                      return <option key={i} value={item.Id}>{item.name}</option>
                    })}       
                  </Form.Select>}
                </div>

            </div>

      </div>   
    </Card.Header>
    <Card.Body >
        {myData && myData.length > 0 ? (
          <TotalStockChart
            data={data}
            myData={myData}
            ref={chartRef}
            style={{ height: '20rem' }}
          />
        ) : (
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center',alignItems: 'center', height: '14rem' }}>
            Seçilen depo ve tarihde stok hesaplaması yapılmamıştır.
          </div>
        )}
      </Card.Body>

    
        <Card.Footer className="bg-light py-2">
        <Row className="flex-between-center">
          <Col xs="auto">
              <Form.Select size="sm" 
              onChange={handleDateChange}
              >
                  <option value="7days">Son 7 gün</option>
                  <option value="1month">Son Bir Ay</option>
                </Form.Select>
          </Col>
          <Col xs="auto">
            <Button variant="falcon-default" size="sm" 
            as={Link} 
            to={{
              pathname: "/report-stock-detail",
            }}
            >
              Detay
            </Button>
          </Col>
        </Row>
        </Card.Footer>
        
    </Card>
  );
};



export default TotalStock;
