import React, { useEffect } from 'react';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { useLocation, Link } from 'react-router-dom';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Button } from 'react-bootstrap';
import useOrderRefund from 'hooks/useOrderRefund';
import OrderedRefundProducts from './OrderedRefundProducts';

const OrderRefundDetails = () => {

  const location = useLocation()
  const {id, activePage} = location.state || ""
  const {detail, getDetail} = useOrderRefund()

  
useEffect(() => {
  if(id){
  getDetail(id.slice(1))

  }

}, [id])


const status =()=>{
  const orderDate = new Date(detail?.ProcessedDate);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

    return {
      order_Date : orderDate.toLocaleDateString('tr-TR', options),
      cargo : detail?.ShipmentCompanyText,
      store : detail?.Store?.Name,
      fullName : detail?.FullName,
      address : detail?.FullAddress,
      cargo : detail?.ShipmentCompanyText
    }
}

const statusResult = status();

console.log("status1", status())


return (
    <>
     <Link to="/e-commerce/orders/order-list-refund" state={{ activePage }}>
      <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      </Link>
      <PageHeader  titleTag="h5" className="mb-3 mt-1">
            <h4 className="mt-2 mb-3">İade Detayları :{id}</h4>
        <h5 className="mt-2 mb-2"> İade Kabul Tarihi : {statusResult?.order_Date}</h5>
        <div>
          <strong className="me-2">Mağaza: </strong>
          <SoftBadge pill bg="success" className="fs--2">
            {' '}
            {statusResult?.store}
            {/* <FontAwesomeIcon
              icon="check"
              className="ms-1"
              transform="shrink-2"
            /> */}
          </SoftBadge>  
          

        </div>

        <div style={{marginTop:"10px"}}>
        <strong className="me-2">İsim Soyisim: {statusResult.fullName} </strong>
        </div>
        {/* <div style={{marginTop:"10px"}}>
        <strong className="me-2">Adres: {statusResult.address} </strong>
        </div> */}
        <div style={{marginTop:"10px"}}>
        <strong className="me-2">Kargo: {statusResult.cargo} </strong>
        </div>

      </PageHeader>
 
      <OrderedRefundProducts />

     
    </>
  );
};

export default OrderRefundDetails;
