import { AuthContext } from 'context/AuthContext';
import React, {useContext, useState} from 'react'
import {BASE_URL} from './baseUrl'
import { toast } from 'react-toastify';



export default function useOrderWorkPost() {

  const {myKey, currentUser} = useContext(AuthContext)

  const [orderId, setOrderId] = useState()


       
  const getOrderWork = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL + 'api/v1/order/work_order/';

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "order_ids": [id]
      }),
      redirect: 'follow'
    };

    fetch(request_url, requestOptions)
  .then(response => {
    if (response.ok) {
      return response.json(); 
    } else {
      console.log("Post işlemi başarısız.");
      throw new Error("Post işlemi başarısız.");
    }
  })
  .then(data => {
    console.log(data.message);
    toast.success(data.message, { theme: 'colored' }); 
  })
  .catch(error => {
    console.log('error', error);
  });

  }

  return { getOrderWork, setOrderId };
}