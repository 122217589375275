import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'




export default function useOrderExampleExport() {

  const [myData, setMyData] = useState()
  const {myKey, currentUser} = useContext(AuthContext)


 const getDataExport = async ()=>{
      var myHeaders = new Headers();


      const params = {

  
      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/export_example_order/?`
  );


    
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.download = `orders.xlsx`

    const handleSuccess = (response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        anchor.href = url;
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    };

    const handleError = (error) => {
      console.log("error", error);

    };

    var requestOptions = {
            method: 'GET',
            headers: {"Authorization": "Token "+myKey},
            redirect: 'follow'
          }
    fetch(request_url, requestOptions)
    .then(handleSuccess)
    .catch(handleError);
};





 return {myData,setMyData, getDataExport}
}
