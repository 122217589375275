import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row, Table } from 'react-bootstrap';
// import { orderedProductsData } from 'data/ecommerce/OrderDetailsData';
import { getPercentage } from 'helpers/utils';
import { useLocation } from 'react-router-dom';
import useOrderList from 'hooks/useOrderList';
import useOrderB2BList from 'hooks/useOrderB2BList';

const OrderedProducts = () => {

  const location = useLocation()
  const {id} = location.state || ""
  const {detail, getDetail} = useOrderB2BList()

  useEffect(() => {
    if(id){
    getDetail(id.slice(1))
    }
  
  }, [id,orderedProductsData ])


const orderedProductsData = detail?.map(item => item.OrderProducts?.map((product, i) => ({
  id : i+1,
  name: product.Name,
  quantity: product.Count,
  rate : product.BasePrice,
  image: product.product_image
  })));




  const subtotal = orderedProductsData?.length>0 && orderedProductsData[0]?.reduce(
    (acc, curr) => curr.quantity * curr.rate + acc,
    0
  );
 
  // const tax = getPercentage(subtotal, 5);

  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Ürün</th>
                <th className="border-0">İsim</th>
                <th className="border-0 text-center">Adet</th>
                <th className="border-0 text-end">Fiyat</th>
                <th className="border-0 text-end">Tutar</th>
              </tr>
            </thead>
            <tbody>
              {orderedProductsData?.length>0 && orderedProductsData[0]?.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle">
                    <img style={{width:"40px"}}src={product.image} alt="" />
                 
                  </td>
                  <td className="align-middle" title={product.name}
                  onMouseOver={(e) => (e.currentTarget.title = product.name)}>
                 <h6 className="mb-0 text-nowrap">
                  {product.name.length > 40
                      ? product.name.slice(0, 40).concat("...")
                      : product.name}
                    </h6>
                  </td>
                  <td className="align-middle text-center">
                    {product.quantity}
                  </td>
                  <td className="align-middle text-end">
                    
                    {parseFloat(product.rate).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </td>
                  <td className="align-middle text-end">
                    
                    {parseFloat(product.quantity * product.rate).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  <tr>
                    <th className="text-900">Subtotal:</th>
                    <td className="fw-semi-bold">
                      
                      {parseFloat(subtotal).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                  <tr>
                    {/* <th className="text-900">Tax 5%:</th> */}
                    <td className="fw-semi-bold">
                      {/* $
                      {parseFloat(tax).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })} */}
                    </td>
                  </tr>
                  <tr className="border-top">
                    <th className="text-900">Total:</th>
                    <td className="fw-semi-bold">
                
                      {parseFloat(subtotal).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
       
      </Card.Body>
    </Card>
  );
};

OrderedProducts.propTypes = {};

export default OrderedProducts;
