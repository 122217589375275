import React, { useContext, useEffect, useState } from 'react'
import { Form, Button, Card } from 'react-bootstrap';
import useIntegrationIkas from 'hooks/useIntegrationIkas';
import { Link } from 'react-router-dom';





const IntegrationIkas = () => {


  const {formData, setFormData} = useIntegrationIkas()


  const [apiSecret, setApiSecret] = useState()
  const [apiKey, setApiKey] = useState()






const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,  "apiKey": apiKey,
                    "apiSecret": apiSecret,
   
      })  


setApiSecret(null);
setApiKey(null);
setVendorId("")
setPassword(null)
e.target.reset();

}


  return (
    <>
    <Link to="/e-commerce/integration">
    <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
    </svg> &nbsp; 
    Geri Dön
    </span>
    </Link>

    <Card className="mb-3 mt-1" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
    <div className="mb-3 mt-3" style={{width:"95%"}}>
      <h6 >Entegrasyon Oluşturma</h6>
      <hr />
      </div>
    <Form.Group style={{ border: "0px solid gray",  padding:"10px", borderRadius:"10px", width:"80%" }}>
       
    <Form onSubmit={handleSubmit}>
    
   
   <div>
    <p>Ikas entegrasyonu öncesinde bizimle irtibata geçiniz.</p>
   </div>
        


    <div style={{display:'flex', flexWrap:'nowrap'}}>
        
    <div  style={{marginRight:'10px', width:'50%'}}>
    <Form.Label>Client Id</Form.Label>
    <Form.Control type="text" placeholder="ClientName"  onChange={(e)=>setApiKey(e.target.value)}/>

    </div>
  
    <div  style={{marginRight:'10px', width:'50%'}}>
    <Form.Label>Client Secret</Form.Label>
    <Form.Control type="text" placeholder="ClientSecretKey"  onChange={(e)=>setApiSecret(e.target.value)}/>
    </div>

</div>


  <Button variant="primary" type="submit" className="d-flex mx-auto mt-5 mb-2" >
    Onayla
  </Button>
</Form>
</Form.Group>
</Card>
</>
  )
}

export default IntegrationIkas