import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'

import { toast } from 'react-toastify';
import useVendorList from './useVendorList';

export default function useProductAddVendorStore() {



 const {myKey, currentUser} = useContext(AuthContext)
 const [vsProductId, setVSProductId] = useState()


 const [formData, setFormData] = useState({
  vendor_id: "",
  Title: "",
  SKU: "",
  Brand: "",
  category_name: "",
  Volume: "",
  Weight: "",
  Price: "",
  Image: "",
  Barcode: ""
});

const [productId, setProductId] = useState("");

// Ürün bağlantısını oluşturma fonksiyonu
const postLink2 = (id) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Token ${myKey}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({
      "product_id": id,
    }),
    redirect: 'follow'
  };

  const request_url = `${BASE_URL}api/v1/product/vendorstore_product/${vsProductId}/link_product/`;

  console.log("request_url", request_url);

  fetch(request_url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json().then(result => {
          toast.success(`Ürün bağlantısı başarıyla oluşturuldu.`, { theme: 'colored' });
        });
      } else {
        toast.error(`Ürün bağlantısı oluşturma işlemi başarısız.`, { theme: 'colored' });
        return response.json().then(result => {
          console.log('Hata detayı:', result);
        });
      }
    })
    .catch(error => {
      console.log('error', error);
      toast.error(`Bir hata oluştu: ${error.message}`, { theme: 'colored' });
    });
};

const postData = () => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Token ${myKey}`);
  myHeaders.append("Content-Type", "application/json");


  var raw = JSON.stringify({
    "vendor_id" : formData.vendorId,
    "Title": formData.Title,
    "SKU": formData.SKU,
    "Brand": formData.Brand,
    "category_name": formData.category_name || formData.new_category,
    "Volume": formData.Volume,
    "Weight": formData.Weight,
    "Price": formData.Price,
    "Image": formData.Image,
    "Barcode": formData.Barcode,

});

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const request_url = BASE_URL + "api/v1/product/product/create_product/";

  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(data => {
      if (data.product_id) {
        toast.success(`Ürün başarıyla oluşturuldu.`, { theme: 'colored' });
        setProductId(data.product_id); // Ürün ID'sini kaydet
        postLink2(data.product_id); 
      } else {
        // Hata yönetimi
        const errorMessage = data.error || "Bilinmeyen bir hata oluştu";
        console.log("işlemi başarısız:", errorMessage);
        toast.error(`işlemi başarısız: ${errorMessage}`, { theme: 'colored' });
      }
    })
    .catch(error => {
      console.log('Hata', error);
      toast.error(`Bir hata oluştu: ${error.message}`, { theme: 'colored' });
    });
};


useEffect(() => {
 if(formData.Barcode){
  postData()
 }

}, [formData])





 return {setFormData, formData, postData, postLink2, setVSProductId}
}
