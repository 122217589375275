import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';

import AuthCardLayout from 'layouts/AuthCardLayout';

const Login = () => {
  return (
    <AuthCardLayout>
     
      {/* <h3 style={{marginBottom:'80px', translate:'0 -75px', fontSize:'25px'}}>ShipShack'e Hoşgeldiniz</h3> */}
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
