import { AuthContext } from 'context/AuthContext';
import React, {useContext, useEffect, useState} from 'react'
import {BASE_URL} from './baseUrl'
import { toast } from 'react-toastify';



export default function useOrderAddressUpdate() {

  const {myKey} = useContext(AuthContext)

  const [orderId, setOrderId] = useState()

  const [formData, setFormData] = useState({
 
    City: '',
    AddressText: '',
    Phone: '',
    FirstName: '',
    LastName: '',
    Email: '',
    District: ''

   

});



       
  const updateAddress = (data, callback) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
   
    let request_url = `${BASE_URL}api/v1/order/${orderId}/update_address/`;
    let raw = JSON.stringify({
      "address": data
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(request_url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json(); 
      } else {
        return response.json().then(errorData => {
          throw new Error(errorData.error || 'Bilinmeyen bir hata oluştu.');
        });
      }
    })
    .then(data => {
      console.log(data.messages);
      toast.success(data.messages || 'Başarılı bir şekilde güncellendi.', { theme: 'colored' });
      callback(true);
    })
    .catch(error => {
      console.log('error', error.message); 
      toast.error(`Bir hata oluştu: ${error.message}`, { theme: 'colored' }); 
      callback(false);
    });
  

  }



  return { formData, setFormData, setOrderId, updateAddress };
}