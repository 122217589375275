import React, { useEffect, useState } from 'react';
import useOrderList from 'hooks/useOrderList';
import { useLocation, Link } from 'react-router-dom';
import OrderInvoiceInfo from './OrderInvoiceInfo';
import useOrderInvoiceList from 'hooks/useOrderInvoiceList';
import OrderedProducts from './OrderedProducts';

const OrderInvoiceDetails = () => {

  const location = useLocation()
  const {id} = location.state || ""
  const {detail, getDetail} = useOrderInvoiceList()
 
 
useEffect(() => {
  if(id){
  getDetail(id.slice(1))

  }

}, [id])


const status = detail?.map((item)=>{
  const orderDate = new Date(item.OrderDate);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const OrderStatus = ["", 20, 40, 60, 80, "", "" ,100, ""]
  
  const OrderStatuses = ["", 'Beklemede', 'Hazırlanıyor', 'Paketlendi', 'Kargoya verildi', 'İade', 'İptal', 'Teslim Edildi', 'Teslim edilemedi']
  const hub_date_ready = new Date(item.ChargedDate)
  const hub_date_packet = new Date(item.WaybillPrintDate)
  const shippmentDate = new Date(item.OrderShipmentDate)
  const deliveryDate = new Date(item.DeliverResultDate)

  return {
    hub_date_packet : hub_date_packet.toLocaleDateString('tr-TR', options),
    hub_date_ready : hub_date_ready.toLocaleDateString('tr-TR', options),
    order_Date : orderDate.toLocaleDateString('tr-TR', options),
    order_status_bar : OrderStatus[item.Status],
    shippment_date : shippmentDate.toLocaleDateString('tr-TR', options),
    charged_Date : item.ChargedDate,
    waybil_date :item.WaybillPrintDate,
    shippmentDate : item.OrderShipmentDate,
    statusText: OrderStatuses[item.Status],
    status: item.Status,
    deliveryDate : item.DeliverResultDate,
    delivery_date : deliveryDate.toLocaleDateString('tr-TR', options),
    order_number : item.OrderNumber || "--",
    store : item.VendorStore.Store.Name
  }
})

console.log("status1", status)


return (
    <>
     <Link to="/e-commerce/orders/order-list-invoices">
      <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      </Link>
      
    
     
      <OrderInvoiceInfo />
      <OrderedProducts />

    </>
  );
};

export default OrderInvoiceDetails;
