import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import { toast } from 'react-toastify';
import {BASE_URL} from './baseUrl'
import useProductListAll from './useProductListAll';


export default function useSubProductAdd() {

 const {myKey, currentUser} = useContext(AuthContext)
//  const {vendorId} = useProductListAll()

 const [vendorIdSubProduct, setVendorIdSubProduct] = useState()

 const [formData, setFormData] = useState([{
        Count : "",
        SetProduct: "",
        SubProduct : ""
}]);
 




 const postData = ()=>{

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL+"api/v1/product/sub-products/?vendor_id="+vendorIdSubProduct
    

    var raw = JSON.stringify(formData);
    
  console.log("raw", raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
  .then(response => {
    if (response.ok) {
        response.text()
        .then(result => {
            console.log(result)
            toast.success(`başarılı şekilde gönderildi.`, { theme: 'colored'});
        })
    } else {
        console.log("Post işlemi başarısız.")

    }
  })
  .catch(error => console.log('error', error));

}

useEffect(() => {
  if(formData[0].Count && formData[0].SubProduct){
    postData()
   }

// postData()

}, [formData])


 

 return {setFormData, formData, postData, setVendorIdSubProduct}
}
