import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useDebounce from './useDebounce';


export default function useInventoryProductAll() {

 const [myData, setMyData] = useState()
 const [detail, setDetail] = useState()

 const {myKey, currentUser} = useContext(AuthContext)
 
 const [page, setPage] = useState(1)
 const [search, setSearch] = useState()
 const [stock, setStock] = useState()
 const [sortState, setSortState] = useState(3)

 const [currentPage,setCurrentPage]=useState(1)
 const [followersPerPage, setFollowersPerPage] = useState(10);
 const [totalPages, setTotalPages] = useState(0);
 const [vendorId, setVendorId ] = useState()
 const [region, setRegion ] = useState()
 const [count, setCount ] = useState()


 const debouncedSearch = useDebounce(search, 1500);

 const date = new Date();
const oneDay = 1000 * 60 * 60 * 24;
const twoDay = 1000 * 60 * 60 * 24 *2;

const [startDate, setStartDate] = useState();
const [endDate, setEndDate] = useState();

let formattedStartDate = "";
let formattedEndDate = "";

if(startDate){
  formattedStartDate = new Date(startDate.getTime()  + oneDay).toISOString().slice(0, 10);
}
if(endDate){
  formattedEndDate = new Date(endDate.getTime() + twoDay).toISOString().slice(0, 10);
}



 
 const getData = ()=>{
        var myHeaders = new Headers();
        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;


      if(!vendorId) return;
      
        const params = {
          offset :offset,
          limit :limit,
          // page: page,
          // vendor_username: currentUser,
          search : search,
          region : region,
          // count: stock, 
          // sort_by_stock : sortState,
          vendor_id : vendorId,
          count : count,
          begin_date: formattedStartDate,
          end_date: formattedEndDate,
        };
    
        const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}wms/api/v1/inventory_product/inventory_product_list/?`
        );

            
      console.log("wurl", request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            console.log("p_result", result)
            setMyData(result) 
            setTotalPages(Math.ceil(result.count / followersPerPage))
    })
          .catch(error => console.log('error', error));        
 }


 const getDetail= (id)=>{
  var myHeaders = new Headers();

  let request_url;

      request_url = `${BASE_URL}wms/api/v1/inventory_product/${id}/`

      console.log("request_url", request_url)

  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setDetail(result) 
})
    .catch(error => console.log('error', error));        
}




 useEffect(() => {
    getData();
  }, [page, debouncedSearch, stock, currentPage, followersPerPage,sortState, vendorId, region, count, startDate, endDate] );

 return {myData, setPage, setSearch, setStock, stock, currentPage, setCurrentPage, followersPerPage,setFollowersPerPage, totalPages, sortState, setSortState, setVendorId, vendorId, detail, setDetail, getDetail, setRegion, setCount, startDate, setStartDate, endDate, setEndDate}
 
}


