import React, { useContext, useEffect, useState } from 'react'
import { Form, Button, Card, Table, Row, Col} from 'react-bootstrap';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';

import useBarcodeSku from 'hooks/useBarcodeSku'
import useInventoryList from 'hooks/useInventoryList'
import useProductDeliveryPanel from 'hooks/useProductDeliveryPanel';
import AppContext from 'context/Context';
import { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import useInventoryExampleExport from 'hooks/useInventoryExampleExport';
import { useInventoryUpload } from 'hooks/useInventoryUpload';
import IconButton from 'components/common/IconButton';
import useVendorList from 'hooks/useVendorList';
import useShipment from 'hooks/useShipment';





const ProductDeliveryPanel = () => {

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);


  const {getData, setVendorIdBarcode} = useBarcodeSku()

  const {myDataVendor} =useVendorList()

  const {myData, setVendorIdInventoryList} = useInventoryList()
  const {shipment} = useShipment()
  const {formData, setFormData, postData, setVendorId} = useProductDeliveryPanel()

  const [inventoryId, setInventoryId]= useState() 
  const [shipmentId, setShipmentId] = useState() 
  const [trackingNumber, setTrackingNumber] = useState()
  const [count, setCount] = useState()
  const [stockStatus, setStockStatus] = useState()


  const [products, setProducts] = useState([]);
  const [barcode, setBarcode] = useState("")
  const [selectedValue, setSelectedValue] = useState("");



  const {getDataInventoryExport} = useInventoryExampleExport()

  const { uploadFile, errorMessage, setVendorIdUpload } = useInventoryUpload()
  const fileInput = useRef(null);
  const [fileName, setFileName] = useState('');


// modal
  
const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const handleExport = ()=> { 
  getDataInventoryExport(); 

}


const handleUploadClick = () => {
  if (fileInput.current.files.length > 0) {
      setFileName(fileInput.current.files[0].name);
      uploadFile(fileInput.current.files[0]);
      setShow(false)
    
  } else {
      console.log("No file chosen!");
  }
  setFileName("")
};



  const handleInputChange = value => {
    setBarcode(value);
    // setSelectedValue({ Title: value.Title, Barcode: value.Barcode, SKU: value.SKU, Image: value.Image });

  };
 
  const handleChange = value => {
    setSelectedValue(value);
    console.log("handleChange",value)
  }

  const [showTable, setShowTable] = useState(false);

 
  

const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      ...formData, "Inventory": inventoryId.value, 
                    "ShipmentCompany": shipmentId.value,
                    "ShipmentTrackingNumber": trackingNumber,
                    // "InboundStockRealtime" : stockStatus.value,
                    "Products": products
      })  


// postData()
setInventoryId(null);
setShipmentId(null);
setTrackingNumber("");
setCount(null);
setSelectedValue(null)
 setShowTable(false)
 e.target.reset();
 setProducts("")
}

const handleAddProduct = (e) => {
  e.preventDefault();
  setProducts([...products, {name: selectedValue.Title, count: count, barcode:selectedValue.barcodes__Barcode, sku: selectedValue.SKU, image :selectedValue.Image }])
  setCount(null);
  setShowTable(true);
  document.getElementById("count").value = "";
}




    const depo = myData?.map((item)=>{
        return{
            value: item.Id,
            label: item.Region__Name
        }
    })

    // const stock = [
    //   {
    //         value: false,
    //         label: "Hayır"
    //   },
    //   {
    //     value: true,
    //     label: "Evet"

    //   }]
  

 
  
    const shipment_data = shipment?.map((item)=>{
        return{
            value: item.Id,
            label: item.Name
        }
    })



    function handleDeleteProduct(index) {
      const updatedProducts = [...products];
      updatedProducts.splice(index, 1);
      setProducts(updatedProducts);
      setSelectedValue(updatedProducts)
      
    }


    const handleChangeVendor = (e)=> {
      setVendorId(e.target.value); 
      setVendorIdUpload(e.target.value)
      setVendorIdBarcode(e.target.value)
      setVendorIdInventoryList(e.target.value)
    }

  return (
    <>
     <Link to="/e-commerce/customer-details">
      <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      </Link>
    <Card className="mb-3 mt-1" style={{display: "flex", justifyContent: "center", alignItems: "center" }}>
    <div className="mb-3 mt-3" style={{width:"95%", display:"flex", flexDirection: "column"}}>
      <div style={{display:"flex", justifyContent: "space-between"}}>
        <h6>Depo Ürün Teslimatı</h6>
        <div style={{marginLeft:"8px", marginRight:"8px"}}>
                <IconButton
                  variant="falcon-default"
                  size="m"
                  icon="plus"
                  transform="shrink-3"
                  onClick={handleShow}
                  style={{width:'200px', fontSize:'15px'}}
                >
                  <span className="d-none d-sm-inline-block ms-1">Toplu Teslimat Ekle</span>
                </IconButton>
                </div>
      </div>
      <hr />
      </div>

    <Form.Group style={{ border: "0px solid gray",  padding:"10px", borderRadius:"10px", width:"80%" }} className="card-bg-dark">
       

    <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
  { myDataVendor?.length > 1 &&    
    <Form.Select 
      className='form-control' 
      style={{fontSize:"15px", width:"145px"}} 
      onChange={handleChangeVendor}
    
    >
      <option value="" >Satıcı</option>
      {myDataVendor?.map((item, i) => {
        return <option key={i} value={item.Id}>{item.vendor_name}</option>
      })}       
    </Form.Select>
  }
</div>

<Form onSubmit={handleSubmit}>
  <Form.Group controlId="formDepo" className="mb-3"  >
    <Form.Label>Depo</Form.Label>
   
    <Select options={depo} isSearchable={true} placeholder="depo seçimi" onChange={setInventoryId} value={inventoryId}
     styles={{control: (baseStyles) =>({
      ...baseStyles, 
      backgroundColor:isDark ? "#0B1727" : "white"
    }),}}
    />

  </Form.Group>

  {/* <Form.Group controlId="formDepo" className="mb-3"  >
    <Form.Label>Mal Kabul Stok Anlık mı</Form.Label>
   
    <Select options={stock} isSearchable={true} placeholder="stok durumu" onChange={setStockStatus} value={stockStatus}
     styles={{control: (baseStyles) =>({
      ...baseStyles, 
      backgroundColor:isDark ? "#0B1727" : "white"
    }),}}
    />

  </Form.Group> */}

  {/* <Form.Group controlId="timezone">
              <Form.Label>Timezone</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="timeZone"
                {...register('timeZone')}
              >
                {timezones.map(item => (
                  <option
                    value={`${item.offset}/${item.name}`}
                    key={`${item.offset}/${item.name}`}
                  >
                    {`${item.offset}/${item.name}`}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> */}


  <Form.Group controlId="kargo" className="mb-3">
    <Form.Label>Kargo Seçimi</Form.Label>
    <Select options={shipment_data} isSearchable={true} placeholder="kargo firması" onChange={setShipmentId} value={shipmentId}
     styles={{control: (baseStyles) =>({
      ...baseStyles, 
      backgroundColor:isDark ? "#0B1727" : "white"
    }),}}
    />
  </Form.Group>

  <Form.Group controlId="formKargoTakipNumarasi" className="mb-3"  >
    <Form.Label>Kargo Takip Numarası</Form.Label>
    <Form.Control type="text" placeholder="takip numarası"  onChange={(e)=>setTrackingNumber(e.target.value)}/>
  </Form.Group>

 
  <Form.Group controlId="ürün" className="mb-3"  >
    <Form.Label>Ürün</Form.Label>
    {/* <Select options={product} isSearchable={true} placeholder="ürün seçimi" className="mb-3" value={productId} 
    onChange={setProductId}/> */}

<AsyncSelect 
        loadOptions={getData}
        placeholder="isim, barkod, sku numarası" 
        className="mb-3" 
        onInputChange={handleInputChange}
        onChange={handleChange}
        value={selectedValue}
        getOptionLabel={e => e.Title}
        getOptionValue={e => e.Barcode}
        styles={{control: (baseStyles) =>({
          ...baseStyles, 
          backgroundColor:isDark ? "#0B1727" : "white"
        }),}}
      /> 
  
    <Form.Label>Ürün Sayısı</Form.Label>
    <Form.Control type="number" placeholder="ürün sayısı" onChange={(e)=>setCount(e.target.value)} id="count" />
  </Form.Group>


<div>
  <div style={{display:"flex", justifyContent:"flex-end"}}>
    <Button variant="secondary" type="button" onClick={handleAddProduct} style={{marginBottom:"10px"}}>
      Ekle
    </Button>
    </div>
    {showTable && (
  <Card className="mb-3">
  <Card.Body>
    <div className="table-responsive fs--1">
      <Table striped className="border-bottom">
        <thead className="bg-200 text-900">
          <tr>
          <th className="border-0"></th>

            <th className="border-0">Ürün</th>
            <th className="border-0">İsim</th>
            <th className="border-0 text-center">Barkodu</th>
            <th className="border-0 text-end">Adet</th>

          </tr>
        </thead>
        <tbody>
        {products.map((product, i) => (
            <tr key={i} className="border-200">
              <td style={{marginRight:"0"}}>        
              <svg  onClick={() => handleDeleteProduct(i)} xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
              </td>
                <td className="align-middle" style={{marginLeft:"0"}}>
                  <img style={{width:"40px"}}src={product.image} alt="" />
                </td>

                <td className="align-middle">
                  <h6 className="mb-0 text-nowrap">{product.name}</h6>
                </td>

                <td className="align-middle text-center">
                  {product.barcode}
                </td>

                <td className="align-middle text-end">
                {product.count}
                </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  </Card.Body>
</Card>
)}
  </div>



  <Button variant="primary" type="submit" className="d-flex mx-auto mt-5 mb-2" >
    Onayla
  </Button>
</Form>
</Form.Group>

</Card>
<Modal 
      show={show} 
      onHide={handleClose}
      size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Teslimat Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
  { myDataVendor?.length > 1 &&    
    <Form.Select 
      className='form-control' 
      style={{fontSize:"15px", width:"145px"}} 
      onChange={handleChangeVendor}
    
    >
      <option value="" >Satıcı</option>
      {myDataVendor?.map((item, i) => {
        return <option key={i} value={item.Id}>{item.vendor_name}</option>
      })}       
    </Form.Select>
  }
</div>

            <p>- İndir butonunu kullanarak örnek excel dosyasını indiriniz.</p>
            <p>- Excel dosyasında belirtilen sütunları değiştirmeyiniz.</p>
            <p>- Inventory sütunu zorunludur.</p>
            <p>- Bir teslimatta birden farklı ürün bulunması durumunda sadece ürün bilgilrinin olduğu product sütunları doldurulması yeterlidir.</p>

        </Modal.Body>

        <Modal.Footer>
            <Form style={{width:"100%"}}>
              <Form.Group style={{ display: "flex", justifyContent: "space-between", width:"100%"}}>
                <div>
                  <Form.Control type="file" ref={fileInput} style={{border: 'none'}} />
                </div>
                <div>

                <Button variant="primary" onClick={handleExport}>
                    Örnek excel indir
                  </Button>
                  <Button style={{marginLeft:"5px"}} variant="secondary" onClick={handleClose}>
                    Vazgeç
                  </Button>
                 
                </div>
              </Form.Group>

              <div style={{ display: "flex", justifyContent: "center", marginTop:"60px" }}>
                <Button variant="primary" onClick={handleUploadClick}>Yükle</Button>
              </div>

              {fileName && <p style={{ textAlign: "center" }}>Selected file: {fileName}</p>}
            </Form>
                  {/* {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} */}
            </Modal.Footer>

      </Modal>




      </>
  )
}

export default ProductDeliveryPanel