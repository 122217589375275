import React, { useContext, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row, Table, Form} from 'react-bootstrap';
// import { orderedProductsData } from 'data/ecommerce/OrderDetailsData';
import { getPercentage } from 'helpers/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import useCampaingList from 'hooks/useCampaingList';
import { PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';

const CampaingListPage = () => {


  const navigate = useNavigate();
  const {campaingData, setVendorStore, campaingSelectData, setVendorId} = useCampaingList()


  const handleChange = (e)=> {
    setVendorStore(e.target.value); 
    
  }

  const {perms} = useContext(PermsContext)
  const {myDataVendor} = useVendorList()

  function hasPermission(permission) {
    return perms?.includes(permission);
  }

  useEffect(() => {
    if (myDataVendor && myDataVendor.length > 0) {
      const firstVendorId = myDataVendor[0].Id;
      setVendorId(firstVendorId)


    }
  }, [myDataVendor]);

  const handleChangeVendor = (e)=> {
   
    setVendorId(e.target.value)
    
  }





const orderedProductsData = campaingData?.results.map((product, i) =>{

  return {
  id : i+1,
  name: product.Name,
  date: product.StartDate,
  endDate: product.EndDate,
  discount : product.DiscountAmount,
  vendorStore: product.VendorStore.Store.Name,   
}});

console.log("data",orderedProductsData )

  return (
    <>
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
        <div style={{display:'flex', justifyContent:'space-between',marginBottom:"15px"}}>
          
        <h6 >Kampanyalar</h6>
        <div style={{display:'flex'}}>




        { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                  <div  style={{ marginRight:'10px'}}>
                  
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", marginRight:'10px'}} onChange={handleChangeVendor}>
                     <option value="" disabled>Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
            : "" }
       
        <div style={{marginRight:"10px"}}>
          <Form.Select  className='form-control'  onChange={handleChange}>
          
                     <option value="">Hepsi</option>
                     {Array.from(new Set(campaingSelectData?.results.map((item) => item.VendorStore.Store.Name))).map((storeName, i) => (
                <option key={i} value={storeName}>{storeName}</option>
              ))}
                </Form.Select> 
                </div>

     <div id="orders-actions">
           <IconButton
              variant="falcon-default"
              size="m"
              icon="plus"
              transform="shrink-3"
              onClick = {()=>navigate("/dashboard/analytics/campaing")}
            >
              <span className="ms-1">Kampanya Oluştur</span>
            </IconButton>
            </div>
        </div>

        </div>
          <Table striped className="border-bottom">
          
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Mağaza</th>
                <th className="border-0">İsim</th>
                <th className="border-0">İndirim</th>
                <th className="border-0 text-center">Başlangıç Tarihi</th>
                <th className="border-0 ml-3 text-center">Bitiş Tarihi</th>
              </tr>
            </thead>
            <tbody>
              {orderedProductsData?.length>0 && orderedProductsData?.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle">
                    {product.vendorStore} 
                 
                  </td>
                  <td className="align-middle text-left" style={{whiteSpace: 'normal'}}>
                
                    {product.name}
                  </td>
                  <td className="align-middle">
                  
                    <h6 className="mb-0 text-nowrap">%{product.discount}</h6>
                  </td>
                
                  <td className="align-middle text-center">
                    
                    {product.date}
                  </td>
                
                  <td className="align-middle text-center" >
                  {product.endDate}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
             
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
   
    </>
  );
};

CampaingListPage.propTypes = {};

export default CampaingListPage;
