import React, { useEffect } from 'react';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import OrderInfo from './OrderInfo';
// import OrderedProducts from './OrderedProducts';
import SoftBadge from 'components/common/SoftBadge';
import { useLocation, Link } from 'react-router-dom';
import InventoryProduct from './InventoryProduct';
import useInventoryShipmentList from 'hooks/useInventoryShipmentList';
import { Button } from 'react-bootstrap';
import './ProgressStyle.css'

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const InventoryDetails = () => {

  const location = useLocation()
  const {id_nu} = location.state || ""
  const {detail, getDetail} = useInventoryShipmentList()

useEffect(() => {
  if(id_nu){
  getDetail(id_nu)
  }

}, [id_nu])

// const date = new Date(detail.);
const getStatus = ['','Beklemede', 'Onaylandı', 'İptal', 'Başarısız', 'Mal kabul yapılmakta']

  const data = {
   
     date : "Teslimat Tarihi :"+ detail?.EstimatedArrivalDate,
     status: getStatus[detail?.Status],
     comparisons : detail?.Comparisons

  }


  const OrderStatus = {
    1: 33,
    2: 100,
    5: 66,
  }

  const state =  {
    status: OrderStatus[detail?.Status] || 0,
  }


  return (
    <>
    <Link to="/e-commerce/customer-details">
      <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      </Link>
      <PageHeader  titleTag="h5" className="mb-3 mt-1">
            <h5>Teslimat Detayları :#{id_nu}</h5>
        <p className="fs--1 mt-1">{data?.date }</p>
        <div>

          <div>
            <strong className="me-2">Durumu: </strong>
            <SoftBadge pill bg="success" className="fs--2">
              {' '}
              {data?.status }
              {/* <FontAwesomeIcon
                icon="check"
                className="ms-1"
                transform="shrink-2"
              /> */}
            </SoftBadge>
          </div>
          
          {data?.status === "Onaylandı" && data?.comparisons.length == 0
          &&

          <div style={{marginTop:'10px'}}> 
            <strong className="me-2">Mal Kabul Durumu: </strong>
            <SoftBadge pill bg="info" className="fs--2">
              {' '}
              {"Manuel Mal Kabul Yapılmıştır"}
            </SoftBadge>
          </div>
          
          
          }
          
          
    
{/* <div className="main_container w-100">
 
   <div class="card-body">

     <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">

       <div class="step completed">
         <div class="step-icon-wrap">
           <div class="step-icon"><i class="pe-7s-cart"></i></div>
         </div>
         <h4 class="step-title">Confirmed Order</h4>
       </div>
       <div class="step completed">
         <div class="step-icon-wrap">
           <div class="step-icon"><i class="pe-7s-config"></i></div>
         </div>
         <h4 class="step-title">Processing Order</h4>
       </div>
       <div class="step completed">
         <div class="step-icon-wrap">
           <div class="step-icon"><i class="pe-7s-medal"></i></div>
         </div>
         <h4 class="step-title">Quality Check</h4>
       </div>
       <div class="step">
         <div class="step-icon-wrap">
           <div class="step-icon"><i class="pe-7s-car"></i></div>
         </div>
         <h4 class="step-title">Product Dispatched</h4>
       </div>
       <div class="step">
         <div class="step-icon-wrap">
           <div class="step-icon"><i class="pe-7s-home"></i></div>
         </div>
         <h4 class="step-title">Product Delivered</h4>
       </div>
     </div>
 

</div>
</div> */}

<div style={{margin:"50px", marginLeft:"90px", width:"90%"}}>

<ProgressBar percent={state.status} >
  <Step>
    {({ accomplished, index }) => (
      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-stop-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z"/>
</svg>
        </div> 
        <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', fontWeight:'bold'}}>Beklemede</p>
        </div>
    </div>
    )}
  </Step>
    
  <Step>
    {({ accomplished, index }) => (
   <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}` }
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-boxes" viewBox="0 0 16 16">
  <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z"/>
</svg>
      </div>
      <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', fontWeight:'bold'}}> <span style={{marginLeft:"-20px"}} >Mal kabulu yapılmakta</span> </p>
        </div>
      </div>


    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (
      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}` }
      >
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-house-check" viewBox="0 0 16 16">
  <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.708L8 2.207l-5 5V13.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 2 13.5V8.207l-.646.647a.5.5 0 1 1-.708-.708L7.293 1.5Z"/>
  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.707l.547.547 1.17-1.951a.5.5 0 1 1 .858.514Z"/>
  </svg>
      </div>
      <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', fontWeight:'bold'}}>Onaylandı</p>
        </div>
      </div>
    )}
  </Step>
 

</ProgressBar>

</div>
        </div>
      </PageHeader>
      {/* <OrderInfo /> */}
      <InventoryProduct />
    </>
  );
};

export default InventoryDetails;
