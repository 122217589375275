import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useInventoryRegion from './useInventoryRegion';
import { toast } from 'react-toastify';


export default function useProductActivity() {

  const {regionData} = useInventoryRegion()


const [inventoryId, setInventoryId] = useState(regionData?.[1]?.Id || null)
const {myKey, currentUser} = useContext(AuthContext)
const [dataActivity, setDataActivity] = useState([])
const [skuCurrent, setSkuCurrent] = useState(); 

const [type, setType] = useState()

const [currentPage,setCurrentPage]=useState(1)
const [followersPerPage, setFollowersPerPage] = useState(10);
const [totalPages, setTotalPages] = useState(0);
const [page, setPage] = useState(1)


 const getData = (skuValue)=>{
  if (!skuValue || !inventoryId) return;

        var myHeaders = new Headers();

        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;

        const params = {

          offset :offset,
          limit :limit,
         
          sku :  skuValue,
          inventory_id : inventoryId,
          type : type
          
        };

        const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}wms/api/v1/inventory/change_activity/?`
    );
                  
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setDataActivity(result.results) 
            setTotalPages(Math.ceil(result.count / followersPerPage))
            if (result.length === 0) {
              // Eğer sonuç boş bir array ise, toast mesajı göster
              toast.warn("Seçilen ürün aktivitesi bulunmadı.", { theme: 'colored' });
            }
          })
          .catch(error => {
            console.log('error', error);
            toast.error("Bir hata oluştu.", { theme: 'colored'});
          });       
 }

 


 useEffect(() => {
  
    getData(skuCurrent);

  }, [inventoryId, skuCurrent, type, currentPage, followersPerPage]);

 return {setInventoryId, dataActivity, getData, setSkuCurrent, setType, setCurrentPage, followersPerPage,setFollowersPerPage, totalPages}
}
