import React from 'react';
import ReactECharts from 'echarts-for-react';
import ReactEChartsCore from 'echarts-for-react/lib/core';

import * as echarts from 'echarts/core';

import {
    HeatmapChart
  } from 'echarts/charts';

  function ChartOptions() {
  
  
    var option;
    

        // prettier-ignore
        const hours = [
            'Today', '01/10', '01/11', '01/12', '01/13', '01/14','01/15', '+2w', '+3w', '+4w', '+5w'
        ];
        // prettier-ignore
        const days = [
            'After Rebalance', 'Before Rebalance'
        ];
        // prettier-ignore
        const data = [[0,0, 140],[0,1,130],[0,2,120],[0,3,110], [0,3,110],[0,4,110],[0,5,110],[0,6,110],[0,7,300],[0,8,200],[0,9,150],,[0,10,150],
              [1,0,10],[1,1,-10],[1,2,-20],[1,3,-30],[1,4,-30],[1,5,-30],[1,6,-30],[1,7,2000],[1,8,1900],[1,9,1850],[1,10,1850]]
            .map(function (item) {
            return [item[1], item[0], item[2] || '-'];
        });
        option = {
          title : {
                text:"Projected Inventory",
                x:'center'
        },
          tooltip: {
            position: 'top'
          },
          grid: {
            height: '50%',
            top: '20%',
            bottom:5
          },
          xAxis: {
            type: 'category',
            data: hours,
            margin: 8,
            splitArea: {
              show: true
            }
          },
          yAxis: {
            type: 'category',
            data: days,
            margin: 8,
            splitArea: {
              show: true
            }
          },
           visualMap: {
            dimension: 2,
            seriesIndex: 0,
            top: 20,
            right: 20,
            pieces: [
                {
                    min: 100,
                    max: 1000,
                    color: "rgb(107, 203, 119)",
                    label: "Ideal",
                },
                {
                    min: 1000,
                    max: 5000,
                    color: "rgb(77, 150, 255)",
                    label: "Excess Inventory",
                },
                {
                    min: -50,
                    max: 99,
                    color: "rgb(255, 107, 107)",
                    label: "Stockout Risk",
                },
            ]},
          series: [
            {
              name: 'Inventory Position',
              type: 'heatmap',
              data: data,
              label: {
                show: true
              },
             itemStyle: {
              borderWidth: 5,
              borderType: 'solid',
              borderColor: '#ffffff',
              borderRadius: 10,
               
          },
              emphasis: {
                itemStyle: {
                  shadowBlur: 1,
                  shadowColor: 'rgb(0,0,0)',
                },
              }
            }
          ]
          


    };


      return (
        <ReactEChartsCore
          echarts={echarts}
          option={option}
          style={{ height: '18.75rem' }}
        />
      );
    }
  
      

export default ChartOptions;