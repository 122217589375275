import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Card, Col, Row, OverlayTrigger, Tooltip} from 'react-bootstrap';

import BasicECharts from 'components/common/BasicEChart';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import useEcomStatCompany from 'hooks/useEcomStatCompany';
import AppContext from 'context/Context';



echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const getOptions = (data, radius, myData) => ({
  color: data?.map(item=>getColor(item?.color)),

  tooltip: {
    padding: [7, 10],
    transitionDuration: 0,
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: params =>
      `<strong>${params.data.name}:</strong> ${params.percent}%`
  },
  series: [
    {
      name: myData?.map((item) => item.count).reduce((accumulator, currentValue) => accumulator + currentValue, 0),
      type: 'pie',
      radius,
      avoidLabelOverlap: false,
      emphasis: {
        scale: false
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: true,
        position: 'center',
        formatter: '{a}',
        fontSize: 14,
        color: getColor('dark')
      },
      data
    }
  ]
});

const CargoShareItem = ({ item, index, total }) => {


  const { name, color, value } = item;
  // const percentage = ((value * 100) / total).toFixed(0);
  const percentage = value



  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={`fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
      style={{ maxWidth: '300px' }}
    >
      <p className="mb-1">
        <FontAwesomeIcon
          icon="circle"
          className={`me-2 text-${color.split('-')[1] || color || 'text-info'}`}
        />
        {name}
      </p>
      <div > {percentage}</div>
    </Flex>
  );
};

const CargoShare = ({radius }) => {

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);


  const {myData} = useEcomStatCompany()


  const colors = [ 'info', 'success', 'primary',  'warning','danger'];

  // const data = myData?.map((item, index) => {
     
  //     return ({
  //         id : index + 1,
  //         value: item.count,
  //         name: item.ShipmentCompanyText,
  //         color: colors[index]
  //     })
  //   }
  // )

  const data = myData
  ? myData.map((item, index) => ({
      id: index + 1,
      value: item.count,
      name: item.ShipmentCompanyText,
      color: colors[index % colors.length]
    }))
  : [];


const total = myData?.map((item) => item.count).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
// const totalTl = total?.toLocaleString('tr-TR', {style: 'currency', currency: 'TRY'});

  return (
    <Card className="h-md-100" style={{ paddingLeft:'15px', backgroundColor:isDark ? "#0B1727" : "#F6FAFF", marginLeft:"10px", marginLeft:"10px", paddingTop:'10px'}}>
      <Card.Body>
        <Row className="justify-content-between g-0">
          <Col xs={5} sm={6} xxl className="pe-2">
            <h6 className="mt-1" style={{color: isDark ? "#D8E2EF":"#025098"}}>Kargolar
            <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>Günlük kargoya verilen adetler</Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>
            </h6>

            {data.length === 0 ?  <p style={{marginTop:"30px", fontSize:'13px'}}>Henüz kargoya verilen ürün bulunmamaktadır.</p>   :   data?.map((item, index) => (
              <CargoShareItem
                item={item} // id, name, value, total
                index={index}
                key={index}
                total={total}
              />
            ))}
          </Col>
          <Col xs="auto" style={{paddingTop:'40px', marginRight:"13px"}}>
            <div className="ps-0">
              <BasicECharts
                echarts={echarts}
                options={getOptions(data, radius, myData)}
                style={{ width: '7.2rem', height: '7.2rem' }}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CargoShareItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }),
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

CargoShare.propTypes = {
  // data: PropTypes.array.isRequired,
  radius: PropTypes.array.isRequired
};

export default CargoShare;
