import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import { toast } from 'react-toastify';
import {BASE_URL} from './baseUrl'
import useVendorList from './useVendorList';


export default function useOrderB2BAdd() {

 const {myKey, currentUser} = useContext(AuthContext)
 const {myDataVendor} = useVendorList()

 const [formData, setFormData] = useState({
        customerName: '',
        customerLastName: '',
        customerPhone: '',
        customerEmail: '',
        date: '',
        time: '',
        addressName: '',
        addressLastName: '',
        adsressPhone: '',
        addressEmail: '',
        addressText: '',
        addressCity: '',
        addressDistrict: '',
        adsressCompany: '',
        adsressIndentityNumber: '',
        adsressTaxNumber: '',
        adsressTaxOffice: '',
        customerPhone: '',
        Products:'',
        Invoice : '',
        orderNumber :'',
        orderNote : '',
        InventoryRegion :''
    });
 

    const [vendorId, setVendorId] = useState("");
   





const [sameAddress, setSameAddress] = useState(false);

 const postData = ()=>{

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL+"api/v1/order/order_b2b_create/?vendor_id="+vendorId
    
    
    // var raw = JSON.stringify({...formData})
    

    var productsArray = Array.isArray(formData.Products) ? formData.Products : Object.values(formData.Products);


    console.log("productsArray", productsArray)

  
    var raw = JSON.stringify({
      "Customer": {
        "FirstName": formData.customerName,
        "LastName": formData.customerName,
        "Phone": formData.customerPhone,
        "Email": formData.customerEmail,
      },
         
      "ShippingAddress": {
        "AddressText": formData.addressText,
        "City": formData.addressCity,
        "District": formData.addressDistrict,
        "Email": formData.addressEmail,
        "FirstName": formData.addressName,
        "LastName": formData.addressLastName,
        "Phone": formData.addressPhone,
      },
      "InvoiceAddress": {
        "AddressText": formData.deliveryText || formData.addressText,
        "City": formData.deliveryCity || formData.addressCity,
        "District": formData.deliveryDistrict || formData.addressDistrict,
        "Email": formData.deliveryEmail || formData.addressEmail,
        "Phone": formData.deliveryPhone || formData.addressPhone,
        "Company": formData.deliveryCompany,
        "IdentityNumber": formData.deliveryIdentityNumber,
        "TaxNumber": formData.deliveryTaxNumber,
        "TaxOffice": formData.deliveryTaxOffice,
        "FirstName": formData.addressName,
        "LastName": formData.addressLastName,

      },
      "OrderDate": formData.date,
      "Status": "1",
      "OrderNumber": formData.orderNumber,
      "OrderAmount": formData.OrderAmount,
      "Note" : formData.orderNote,
      "InventoryRegion" : formData.InventoryRegion,
      "OrderProducts": productsArray.map(p=>({
          "Name": p.name,
          "Barcode": p.barcode,
          "Count": Number(p.count),
          "BasePrice" : p.basePrice
        }))
   
  });
    
 console.log("raw",raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
  .then(response => {
    if (response.ok) {
        response.text()
        .then(result => {
            console.log(result)
            toast.success(`başarılı şekilde gönderildi.`, { theme: 'colored'});
        })
    } else {
        console.log("Post işlemi başarısız.")

    }
  })
  .catch(error => console.log('error', error));

}

useEffect(() => {
 if(formData.customerName && formData.addressCity){
  postData()
 }
// postData()

}, [formData])



 

 return {setFormData, formData, postData, setSameAddress, sameAddress,  setVendorId }
}
