import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Card, OverlayTrigger, Tooltip, Overlay  } from 'react-bootstrap';
import bg1 from 'assets/img/icons/spot-illustrations/corner-2.png';
import useRefundStatistics from 'hooks/useRefundStatistics';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import Background from 'components/common/Background';
import '../style.css'
import AppContext from 'context/Context';

const StatisticsCardsRefendRate = () => {


const {myData} = useRefundStatistics()


const {
  config: { isDark, isRTL },
  setConfig
} = useContext(AppContext);


  const statsData = [
    {
      title: 'İade Oranı',
      value: (myData?.refund_rate || 0).toFixed(2) + '%',
      // value: myDataStock?.total_stock_value,
      decimal: true,
      // suffix: 'k',
      prefix: '',
      valueClassName: 'text-warning',
      badgeBg: 'warning',
      badgeText: '-0.23%',
      link: '/e-commerce/customers',
      linkText: 'See all',
      image: bg1
    }
  ]

  return (
    <>
   <Card style={{height:"165px", backgroundColor:isDark ? "#0B1727" : "#F6FAFF"}} >
      {/* <Background image={statsData[0]?.image}  style={{pointerEvents: "none"}}/> */}
<FalconCardHeader
  title={
    <div className="d-flex align-items-center" style={{color: isDark ? "#D8E2EF":"#025098"}}>
      {statsData[0]?.title}
      {/* <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`} bsPrefix='my-tooltip-class'>
            Son 30 günlük iade oranı
          </Tooltip>
        }
      >
        <span>
          <FontAwesomeIcon
            icon={['far', 'question-circle']}
            transform="shrink-1"
            className="ms-1 text-400"
            id="weeklySalesTooltip"
          />
        </span>
      </OverlayTrigger> */}
    </div>
  }
  titleTag="h6"
  className="pb-0 mt-2"
/>

      <Card.Body >
      <h6 className="mb-5">
       
        </h6>
        <div
        
        >
            <p className='mb-0' style={{fontSize:"25px",color: isDark ? "#D8E2EF":"#5E6E82"}} >{statsData[0]?.value}</p>
        </div>
     
      </Card.Body>
    </Card>

    </>
  );
};

export default StatisticsCardsRefendRate;
