import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationOrder = ({ pages, setCurrentPage, activePage, setActivePage, setIsLoading}) => {


  let items = [];

  const breakPoint = 3;


  for (let number = 1; number <= pages; number++) {
    if (number === 1 || number === pages || (number >= activePage - breakPoint && number <= activePage + breakPoint)) {
      items.push(
        <Pagination.Item 
        // key={number} active={number === activePage} onClick={() => setActivePage(number)}
        key={number} 
        active={number === activePage} 
        onClick={() => {
          console.log("Trying to set active page to:", number); // debug
          setActivePage(number);
          setCurrentPage(number); 
          console.log("Setting isLoading to true");
          setIsLoading(true)
        }}
        
        >
          {number}
        </Pagination.Item>,
      );
    }
    else if (number === activePage - breakPoint - 1) {
      items.push(<Pagination.Ellipsis key={number} />);
    }
    else if (number === activePage + breakPoint + 1) {
      items.push(<Pagination.Ellipsis key={number} />);
    }
  }

  React.useEffect(() => {
    setCurrentPage(activePage);
  }, [activePage, setCurrentPage]);

  return (
    <div>
      <Pagination  size="sm" style={{justifyContent:"center"}}>
        <Pagination.First onClick={() => setActivePage(1)} />
        <Pagination.Prev disabled={activePage === 1} onClick={() => setActivePage(activePage === 1 ? activePage : activePage - 1)}/>
        {items}
        <Pagination.Next disabled={activePage === pages} onClick={() => setActivePage(activePage === pages ? activePage : activePage + 1)}/>
        <Pagination.Last onClick={() => setActivePage(pages)} />
      </Pagination>
    </div>
  );
};

export default PaginationOrder;
