import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import useVendorList from './useVendorList';

export default function useProductCatalog() {

 const [catalogData, setCatalogData] = useState()
 const {myKey, currentUser} = useContext(AuthContext)
 
 const [vendorIdCatalog, setVendorIdCatalog] = useState()
 const {myDataVendor} = useVendorList()

 useEffect(() => {
  if (myDataVendor?.length === 1) {
    setVendorIdCatalog(myDataVendor[0].Id);
  }
}, [myDataVendor]);

 const getData = ()=>{
  var myHeaders = new Headers();


  const params = {
    vendor_id: vendorIdCatalog
  };

  

  const request_url = Object.entries(params)
      .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
      .reduce(
        (url, [key, value]) => url + `&${key}=${value}`,
        `${BASE_URL}api/v1/product/product/catalog_product_list/?`
  );

  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setCatalogData(result) 
})
    .catch(error => console.log('error', error));        
}



 useEffect(() => {
    getData();
 
  }, [vendorIdCatalog]);

 return { catalogData,setVendorIdCatalog }
}
