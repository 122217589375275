import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'

export default function useShipmentCompany() {

 const [shipmentCompany, setShipmentCompany] = useState([])


 const {myKey, currentUser} = useContext(AuthContext)
 
 const [vendorIdShipment, setVendorIdShipment] = useState()




 const getDataShipment = ()=>{
  var myHeaders = new Headers();


  // if(!vendorIdShipment) return;
  
  const params = {
    vendor_id: vendorIdShipment
  };

  

  const request_url = Object.entries(params)
      .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
      .reduce(
        (url, [key, value]) => url + `&${key}=${value}`,
        `${BASE_URL}api/v1/order/order_shipment_company/?`
  );

  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setShipmentCompany(result) 
})
    .catch(error => console.log('error', error));        
}



 useEffect(() => {
    getDataShipment();
  }, [vendorIdShipment]);

 return {shipmentCompany, setVendorIdShipment }
}
