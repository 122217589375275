import { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from 'context/AuthContext';
import { toast } from 'react-toastify';
import {BASE_URL} from './baseUrl'
import useVendorList from './useVendorList';

export const useOrderUploadBasic = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const {myKey} = useContext(AuthContext)
    const [vendorStoreIdUploadBasic, setVendorStoreIdUploadBasic] = useState()

  

    const uploadFileBasic = async (file) => {
        if (!file.type.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            setErrorMessage("Invalid file type. Please upload an Excel file.");
            toast.error("Invalid file type. Please upload an Excel file."); 
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${BASE_URL}api/v1/order/order_import_simplified/?vendor_store_id=${vendorStoreIdUploadBasic}`, formData, {
                headers: {"Authorization": "Token "+myKey},
            });
        
            console.log("import",response.data);
            setErrorMessage("");
        
            const { successful_orders, failed_orders_list } = response.data;
        
            let toastMessage = `Başarılı Sipariş Sayısı: ${successful_orders}`;
        
            // Eğer başarısız sipariş listesi boş değilse, bu bilgiyi mesaja ekleyin.
            if (failed_orders_list && failed_orders_list.length > 0) {
                const failedOrderNumbers = failed_orders_list.join(", ");
                toastMessage += `, Başarısız Sipariş Numaraları: ${failedOrderNumbers}`;
            }
        
            toast.success(toastMessage);
        
        } catch (error) {
            setErrorMessage(`Error: ${error.message}`);
            toast.error(`Error: ${error.message}`); 
        }
        
    };

    return { uploadFileBasic, errorMessage, setVendorStoreIdUploadBasic };
};
