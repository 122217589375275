import axios from 'axios';
import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BASE_URL } from '../hooks/baseUrl';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [currentUser, setCurrentUser] = useState(localStorage.getItem('username') || null);
  const [myKey, setMyKey] = useState(() => {
    const state = localStorage.getItem('state');
    return state ? window.atob(state) : null;
  });

  const signIn = async (username, password, navigate) => {
    try {
      const res = await axios.post(`${BASE_URL}users/login/`, {
        username,
        password,
      });
      if (res.data.token) {
        const state = res.data.token;
        setMyKey(state);
        setCurrentUser(username);
        localStorage.setItem('username', username);
        localStorage.setItem('state', window.btoa(state));
        navigate('/dashboard');
        toast.success(`Giriş başarılı ${username}`, { theme: 'colored' });
      }
    } catch (error) {
      console.log(error);
      toast.error('Kullanıcı adı veya parola yanlış', { theme: 'colored' });
    }
  };

  const logOut = () => {
    localStorage.clear();
    setMyKey(null);
    setCurrentUser(null);
    toast.success('Çıkış yapıldı', { theme: 'colored' });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setMyKey(window.atob(token));
    }
  }, []);

  const value = {
    currentUser,
    myKey,
    signIn,
    logOut,
  };

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export default AuthContextProvider;
