import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from '../baseUrl'
import useDebounce from 'hooks/useDebounce';



export default function useActionHistoryList() {

  const {myKey, currentUser} = useContext(AuthContext)
  const [myData, setMyData] = useState()
  const [page, setPage] = useState(1)


  const [showGif, setShowGif] = useState(false);

  const [currentPage,setCurrentPage]=useState(1)
  const [followersPerPage, setFollowersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);


  const [vendorId, setVendorId] = useState()

  const [type, setType] = useState()
  const [user, setUser] = useState()
  const [objId, setObjId] = useState()

  const debouncedObjId = useDebounce(objId, 1500);
  

  const date = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const twoDay = 1000 * 60 * 60 * 24 *2;
  
  const [startDate, setStartDate] = useState(new Date(date.getTime() - oneDay * 7));
  const [endDate, setEndDate] = useState(new Date(date.getTime() + oneDay * 2));
  
  let formattedStartDate = "";
  let formattedEndDate = "";
  
  if(startDate){
    formattedStartDate = new Date(startDate.getTime()  + oneDay).toISOString().slice(0, 10);
  }
  if(endDate){
    formattedEndDate = new Date(endDate.getTime() + twoDay).toISOString().slice(0, 10);
  }

 

 const getData = ()=>{
        var myHeaders = new Headers();
        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;


        const params = {
          offset :offset,
          limit :limit,
          vendor_id: vendorId,
          type_id :  type,
          begin_date: formattedStartDate,
          end_date: formattedEndDate,
          user_id : user,
          obj_id : objId
        };

        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) 
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/users/action_history/?`
    );

 
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result) 
            setShowGif(false);
            setTotalPages(Math.ceil(result.count / followersPerPage))
    })
          .catch(error => console.log('error', error));        
 }






 useEffect(() => {
    getData();
    setShowGif(true)
  }, [currentPage, followersPerPage,page,startDate, endDate, vendorId, type, user, debouncedObjId ]);

 return {myData, getData, setMyData, currentPage,
          setCurrentPage,
          followersPerPage,
          setFollowersPerPage,
          totalPages, setPage, startDate, setStartDate, endDate, setEndDate, setVendorId, showGif, setShowGif, setType, setUser, setObjId}

        }
