import React, { useEffect, useState } from 'react'
import { Form, Button, Card, Table, Row , Col, Alert, Tab, Nav} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import DatePicker from  "react-datepicker";
import IconButton from 'components/common/IconButton';

import Select, { createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import useBarcodeSku from 'hooks/useBarcodeSku'
import useOrderAdd from 'hooks/useOrderAdd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react';
import AppContext from 'context/Context';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import useOrderExampleExport from 'hooks/useOrderExampleExport';
import { useRef } from 'react';
import { useOrderUpload } from 'hooks/useOrderUpload';
import useVendorStore from 'hooks/useVendorStore';
import useVendorList from 'hooks/useVendorList';
import {city_list} from 'data/dashboard/city_list'
import useVendorStoreListAuthorized from 'hooks/useVendorStoreListAuthorized';
import { useOrderUploadBasic } from 'hooks/useOrderUploadBasic';
import { PermsContext } from 'context/PermsContext';
import useOrderExampleExportBasic from 'hooks/useOrderExampleExportBasic';


const OrderAdd = () => {

  const {getData, setVendorIdBarcode} = useBarcodeSku()
  const {formData, setFormData, setSameAddress, sameAddress, setVendorId, vendorId, status, postData, setVendorStoreId} = useOrderAdd()
 
  const {myDataVendor} =useVendorList()
  const {getDataExport} = useOrderExampleExport()
  const {getDataExportBasic} = useOrderExampleExportBasic()

  const {perms} = useContext(PermsContext)

  const { uploadFile, errorMessage, setVendorStoreIdUpload  } = useOrderUpload()
  const { uploadFileBasic, setVendorStoreIdUploadBasic  } = useOrderUploadBasic()

  const fileInput = useRef(null);
  const [fileName, setFileName] = useState('');

  const fileInputBasic = useRef(null);
  const [fileNameBasic, setFileNameBasic] = useState('');

  const {myDataVendorStore, setVendorIdVS, vendorIdVS} = useVendorStoreListAuthorized()

  const navigate = useNavigate();

// modal
  
const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const handleExport = ()=> { 
  getDataExport(); 

}

const handleExportBasic = ()=> { 
  getDataExportBasic(); 

}


useEffect(() => {
  if (myDataVendorStore?.length === 1) {
    setVendorStoreId(myDataVendorStore[0].Id);
    setVendorStoreIdUpload(myDataVendorStore[0].Id)
    setVendorStoreIdUploadBasic(myDataVendorStore[0].Id)
  }
}, [myDataVendorStore]);




function hasPermission(permission) {
  if (Array.isArray(perms)) {
    return perms.includes(permission);
  }
  return false; // 
}


  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);


  const handleUploadClick = () => {
    if (fileInput.current.files.length > 0) {
        setFileName(fileInput.current.files[0].name);
        uploadFile(fileInput.current.files[0]);
        setShow(false)
      
    } else {
        console.log("No file chosen!");
    }
    setFileName("")
};


const handleUploadClickBasic = () => {
  if (fileInputBasic.current.files.length > 0) {
      setFileNameBasic(fileInputBasic.current.files[0].name);
      uploadFileBasic(fileInputBasic.current.files[0]);
      setShow(false)
    
  } else {
      console.log("No file chosen!");
  }
  setFileNameBasic("")
};





  const [sameName, setSameName] = useState(false);
  const [customerName, setCustomerName] = useState()
  const [customerLastName, setCustomerLastName] = useState()
  const [customerPhone, setCustomerPhone] = useState()
  const [customerEmail, setCustomerEmail] = useState()

  const [date, setDate] = useState(new Date());
  const [orderNumber, setOrderNumber] = useState()
  const [orderNote, setOrderNote] = useState()

  const [addressName,setAddressName] =useState()
  const [addressLastName,setAddressLastName] =useState()
  const [addressPhone,setAddressPhone] =useState()
  const [addressEmail,setAddressEmail] =useState()
  const [addressText,setAddressText] =useState()
  const [addressCity,setAddressCity] =useState()
  const [addressDistrict,setAddressDistrict] =useState()
  const [addressCompany,setAddressCompany] =useState()
  const [addressIndentityNumber,setAddressIdentityNumber] =useState()
  const [addressTaxNumber,setAddressTaxNumber] =useState()
  const [addressTaxOffice,setAddressTaxOffice] =useState()
  const [price,setPrice] =useState(0)
  const [count,setCount] =useState()
  const [currency, setCurrency] = useState(0)


  const [deliveryName, setDeliveryName] = useState();
  const [deliveryLastName, setDeliveryLastName] = useState();
  const [deliveryPhone, setDeliveryPhone] = useState();
  const [deliveryEmail, setDeliveryEmail] = useState();
  const [deliveryText, setDeliveryText] = useState();
  const [deliveryDistrict, setDeliveryDistrict] = useState();
  const [deliveryCity, setDeliveryCity] = useState();

  const [deliveryCompany, setDeliveryCompany] = useState();
  const [deliveryIdentityNumber, setDeliveryIdentityNumber] = useState();
  const [deliveryTaxNumber, setDeliveryTaxNumber] = useState();
  const [deliveryTaxOffice, setDeliveryTaxOffice] = useState();

  const [products, setProducts] = useState([]);
  const [barcode, setBarcode] = useState("")
  const [selectedValue, setSelectedValue] = useState("");

  const [discount, setDiscount] = useState(0);
  const [showTable, setShowTable] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = value => {
    setBarcode(value);
  };
 
  const handleChange = value => {
    setSelectedValue(value);
    console.log("handleChange",value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  console.log("products", products)


  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      ...formData, "customerName": customerName, 
                    "customerLastName": customerLastName,
                    "customerPhone": customerPhone,
                    "customerEmail": customerEmail,
                    "date": date,

                    "addressName": addressName,
                    "addressLastName": addressLastName,
                    "addressPhone": addressPhone,
                    "addressEmail": addressEmail,
                    "addressText": addressText,
                    "addressCity": addressCity,
                    "addressDistrict": addressDistrict,

                    "deliveryCompany":deliveryCompany,
                    "deliveryIdentityNumber":deliveryIdentityNumber,
                    "deliveryTaxNumber":deliveryTaxNumber,
                    "deliveryTaxOffice":deliveryTaxOffice,
                    "deliveryPhone":deliveryPhone,
                    "deliveryEmail":deliveryEmail,
                    "deliveryText":deliveryText,
                    "deliveryDistrict":deliveryDistrict,
                    "deliveryCity":deliveryCity,
                    "orderNumber" : orderNumber,
                    "OrderAmount": total,
                    "orderNote": orderNote,
                    "currency" : currency,
                    "Products": products,
      })  
     
}


useEffect((e) => {
  console.log("Status:", status);
  if (status === 'success') {

    navigate("/e-commerce/orders/order-list")
    
    setCustomerName(null)
    setCustomerLastName(null)
    setCustomerPhone(null)
    setCustomerEmail(null)

    setOrderNumber(null)
    setAddressName(null)
    setAddressLastName(null)
    setAddressPhone(null)
    setAddressEmail(null)

    setAddressText(null)
    setAddressCity(null)
    setAddressDistrict(null)

    setOrderNote(null)

    setDeliveryName(null)
    setDeliveryLastName(null)
    
    setCount(null);
    setSelectedValue(null)
    setShowTable(false)
    setDeliveryPhone(null)
    setDeliveryEmail(null)
    setDeliveryText(null)
    setDeliveryDistrict(null)
    setDeliveryCity(null)
    setDeliveryCompany(null)
    setDeliveryIdentityNumber(null)
    setDeliveryTaxNumber(null)
    setDeliveryTaxOffice(null)
    setProducts([])
    setSameAddress("")
  
    setAddressCity(null)
    setSubmitted(true)
  }
}, [status]);




const handlePriceChange = (e) => {
  const value = e.target.value;
  setPrice(value ? parseFloat(value) : 0);
};

const handleAddProduct = (e) => {
  e.preventDefault();
  if (!selectedValue) {
    return;
  }

  const validPrice = isNaN(price) ? 0 : price;
  console.log('Added price:', validPrice);

  setProducts([...products, {
    name: selectedValue.Title,
    count: count,
    barcode: selectedValue.barcodes__Barcode,
    sku: selectedValue.SKU,
    image: selectedValue.Image,
    basePrice: getParsedPrice(validPrice)
  }]);

  setShowTable(true);
  setPrice(0);
  setCount(null);
  setBarcode(null);
  setSelectedValue(null);
};

function getParsedPrice(price) {
  if (typeof price !== 'string') {
    price = price.toString();  // Veriyi string formatına dönüştür
  }

  const normalizedPrice = price.replace(',', '.');
  return parseFloat(normalizedPrice);
}

const total = parseFloat(
  products.reduce((acc, p) => acc + (getParsedPrice(p.basePrice) * (p.count || 0)), 0) - discount
).toFixed(2);



function handleDeleteProduct(index) {
  const updatedProducts = [...products];
  updatedProducts.splice(index, 1);
  setProducts(updatedProducts);
  setSelectedValue(updatedProducts)
      
  }

const handleDiscountChange = (e) => {
  setDiscount(e.target.value);
  }





const handleSameAddress = (e) => {
  setSameAddress(e.target.checked);
  }

  const handleSameName = (e) => {
    setSameName(e.target.checked);
    }

  const handleChangeVendor = (e)=> {
    setVendorId(e.target.value); 
    setVendorIdBarcode(e.target.value)
    setVendorIdVS(e.target.value)
  }


  const handleChangeVendorStore = (e)=> {
    setVendorStoreId(e.target.value); 
    setVendorStoreIdUpload(e.target.value)
    setVendorStoreIdUploadBasic(e.target.value)
  }

  


  function getCustomStyles(isDark) {
    return {
      control: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: isDark ? "#0B1727" : "white",
        // width: '50%'
      }),
      option: (provided, state) => ({
        ...provided,
        color: isDark ? 'white' : 'black',
        backgroundColor: isDark ? '#0B1727' : 'white',
      }),
      placeholder: (defaultStyles) => ({
        ...defaultStyles,
        color: isDark ? 'white' : 'black',
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: isDark ? 'white' : 'black',
      }),
  
      menu: (provided, state) => ({
        ...provided,
        // width: '160px'
      }),
      menuList: (provided, state) => ({
        ...provided,
        maxHeight: '150px',  
        overflow: 'auto' ,
        // width: '160px' 
      }),
  
    };
  }

  const [selectedOptionCity, setSelectedOptionCity] = useState(null);




const handleChangeCity = (selectedCity) => {
  setAddressCity(selectedCity ? selectedCity.value : null); 
  setSelectedOptionCity(selectedCity);
};

const [selectedOptionDistrict, setSelectedOptionDistrict] = useState(null);


const handleChangeDistrict = (e)=> {
  setAddressDistrict(e ? e.value : null); 
  setSelectedOptionDistrict(e)
}


const cityList = city_list?.map((item, index)=>{
  return {

    value : item.il,
    label : item.il
  }
})

const districtList = selectedOptionCity
    ? city_list
        .find((item) => item.il === selectedOptionCity.value)
        ?.ilceleri.map((district) => ({
          value: district,
          label: district
        }))
    : [];

   

    function normalizeTurkishString(str) {
      return str.toLocaleLowerCase('tr-TR')
        .replace(/ı/g, 'i')
        .replace(/ş/g, 's')
        .replace(/ç/g, 'c')
        .replace(/ğ/g, 'g')
        .replace(/ü/g, 'u')
        .replace(/ö/g, 'o');
    }

    const customFilter = (option, inputValue) => {
      const normalizedOption = normalizeTurkishString(option.label);
      const normalizedInput = normalizeTurkishString(inputValue);
      return normalizedOption.includes(normalizedInput);
    };

    const [showEmptyProductModal, setShowEmptyProductModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);


    const handleConfirmSubmit = (e) => {
      setShowEmptyProductModal(false);
      setShowConfirmModal(false);
      handleSubmit(e); 
    };
  
    const handleFormSubmit = (e) => {
      e.preventDefault();
      if (products.length === 0) {
        setShowEmptyProductModal(true);
      } else {
        setShowConfirmModal(true);
      }
    };


    const handleChangeCurreny = (e)=> {
      setCurrency(parseInt(e.target.value, 10)); 
    }

    const currenyList = [
      {value : 0, label : "TRY"},
      {value : 1, label : "USD"},
      {value : 2, label : "EUR"}

    ]

    const getCurrencyCode = (value) => {
      switch (value) {
        case 0:
          return 'TRY';
        case 1:
          return 'USD';
        case 2:
          return 'EUR';
        default:
          return 'TRY';
      }
    };

    const formatCurrency = (amount) => {
      const currencyCode = getCurrencyCode(currency);
      let locale;
      switch (currencyCode) {
        case 'TRY':
          locale = 'tr-TR';
          break;
        case 'USD':
          locale = 'en-US';
          break;
        case 'EUR':
          locale = 'de-DE';
          break;
        default:
          locale = 'tr-TR';
          break;
      }
  
      return Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode
      }).format(amount);
    };
  
console.log("perms", hasPermission('Sipariş sade import'))

  return (
    <>
    <Link to="/e-commerce/orders/order-list">
      <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      </Link>
      
      <Card style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <div className="mb-3 mt-3" style={{width:"95%", display:"flex", flexDirection: "column"}}>
      <div style={{display:"flex", justifyContent: "space-between"}}>
        <h6>Sipariş Ekle</h6>
        <div style={{marginLeft:"8px", marginRight:"8px"}}>
                <IconButton
                  variant="falcon-default"
                  size="m"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={handleShow}
                  style={{width:'195px', fontSize:'15px'}}
                >
                  <span className="d-none d-sm-inline-block ms-1">Toplu Sipariş Ekle</span>
                </IconButton>
                </div>
      </div>
      <hr />
      </div>
    <Form.Group style={{ border: "0px solid gray",  padding:"10px", borderRadius:"10px", width:"80%"}}>

    <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
  { myDataVendor?.length > 1 &&    
    <Form.Select 
      className='form-control' 
      style={{fontSize:"15px", width:"145px"}} 
      onChange={handleChangeVendor}
    
    >
      <option value="">Satıcı</option>
      {myDataVendor?.map((item, i) => {
        return <option key={i} value={item.Id}>{item.vendor_name}</option>
      })}       
    </Form.Select>
  }
</div>

<div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px", marginTop:'10px'}}>
  { myDataVendorStore?.length > 1 &&    
    <Form.Select 
      className='form-control' 
      style={{fontSize:"15px", width:"145px"}} 
      onChange={handleChangeVendorStore}
    >
      <option value="">Mağaza</option>
      {myDataVendorStore?.map((item, i) => {
        return <option key={i} value={item.Id}>{item.Vendor_Store}</option>
      })}       
    </Form.Select>
  }
</div>
     
<Form onSubmit={handleFormSubmit} onKeyDown={handleKeyDown}>
  <Form.Group controlId="formDepo" className="mb-3"  >

    <Form.Label>Müşteri Bilgileri</Form.Label>
    <div style={{display:'flex', justifyContent:'space-between'}}>

      <Form.Control required placeholder="müşteri ismi*" style={{marginRight:'10px', marginBottom:'10px'}}   
        onChange={(e)=>setCustomerName(e.target.value)}
        value={customerName || ''}
      ></Form.Control>

      <Form.Control required placeholder="müşteri soyisim*"  style={{ marginBottom:'10px'}} 
        onChange={(e)=>setCustomerLastName(e.target.value)}
        value={customerLastName || ''}
      ></Form.Control>
    </div>
    <div style={{display:'flex', justifyContent:'space-between'}}>

      <Form.Control placeholder="müşteri telefon" style={{marginRight:'10px'}} 
        onChange={(e)=>setCustomerPhone(e.target.value)}
        value={customerPhone || ''}
      ></Form.Control>


      <Form.Control type="email" placeholder="name@example.com" 
        onChange={(e)=>setCustomerEmail(e.target.value)}
        value={customerEmail || ''}
      ></Form.Control>
    </div>


    <Form.Label style={{marginTop:"15px"}}>Sipariş Tarihi</Form.Label>
<div style={{display:'flex', flexWrap:'nowrap'}}>

    <div  style={{marginRight:'10px', width:'50%'}}>
          <DatePicker
              selected={date}
              onChange={(date)=>setDate(date)}
              formatWeekDay={day => day.slice(0, 3)}
              className='form-control'
              placeholderText="Select Date"
          />
    </div>

    <div style={{width:'50%'}}>
      <Form.Control required placeholder="sipariş numarası*" style={{marginRight:'10px'}} onChange={(e)=>setOrderNumber(e.target.value)}
        value={orderNumber || ''}
      ></Form.Control>
    </div>
    </div>
    
  </Form.Group>


  <Accordion defaultActiveKey="0" style={{marginBottom:'10px'}}>
  <Accordion.Item eventKey="1">
    <Accordion.Header>Teslimat Bilgileri</Accordion.Header>
      <Accordion.Body>
   
        <Form.Group>
          <Form.Label>Teslimat Bilgileri</Form.Label>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <Form.Control required placeholder="müşteri ismi*" style={{marginRight:'10px', marginBottom:'10px'}} onChange={(e)=>setAddressName(e.target.value)}
                value={(sameName ? customerName : addressName  ) || ''}
                disabled={sameName}
                ></Form.Control>

                <Form.Control required placeholder="müşteri soyisim*"  style={{ marginBottom:'10px'}} onChange={(e)=>setAddressLastName(e.target.value)}
                value={(sameName ? customerLastName : addressLastName )  || ''}
                disabled={sameName}
                ></Form.Control>
            </div>

            <div style={{display:'flex', justifyContent:'space-between'}}>
                <Form.Control required placeholder="müşteri telefon*" style={{marginRight:'10px', marginBottom:'10px'}} onChange={(e)=>setAddressPhone(e.target.value)}
                value={(sameName ?  customerPhone : addressPhone )  || ''}
                disabled={sameName}
                ></Form.Control>

                <Form.Control type="email" placeholder="name@example.com" style={{ marginBottom:'10px'}} onChange={(e)=>setAddressEmail(e.target.value)}
                value={(sameName ? customerEmail : addressEmail)  || ''}
                disabled={sameName}
                ></Form.Control>
            </div>
        </Form.Group>

        <Form.Check type="checkbox" label="müşteri bilgileri aynı" onChange={handleSameName} />


        <Form.Group>
            <Form.Label>Adres Bilgileri</Form.Label>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                  <Form.Control required placeholder="adres*" style={{ marginBottom:'10px'}} onChange={(e)=>setAddressText(e.target.value)}
                value={addressText || ''}
                  ></Form.Control>
                </div>

                <div style={{display:'flex', justifyContent:'space-between'}}>
       
                  <div style={{ width: '50%', marginBottom:'10px',marginRight:'10px' }}>
                <Select
                      value={selectedOptionCity}
                      onChange={handleChangeCity}
                      options={cityList}
                      placeholder='İl seçiniz...'
                      styles={getCustomStyles(isDark)}
                      filterOption={customFilter}
                      isClearable={true}
                    />
                  </div>

                  <div style={{ width: '50%'}}>
                <Select
                      value={selectedOptionDistrict}
                      onChange={handleChangeDistrict}
                      options={districtList}
                      placeholder='İlçe seçiniz...'
                      styles={getCustomStyles(isDark)}
                      isDisabled={!selectedOptionCity}
                      filterOption={customFilter}
                      isClearable={true}
                    />
                  </div>
                </div>

    <Form.Check type="checkbox" label="Fatura adresi aynı" onChange={handleSameAddress} />

        <Form.Group>
              <Form.Label>Fatura Bilgileri</Form.Label>

      {sameAddress ?  (<div style={{display:'flex', justifyContent:'space-between'}}>
                <Form.Control placeholder="müşteri ismi" style={{marginRight:'10px', marginBottom:'10px'}} 
                value={(sameAddress ?  addressName : deliveryName) || customerName} 
                onChange={(e)=>setDeliveryName(e.target.value)} disabled={sameAddress}
                
                
                ></Form.Control>

                <Form.Control
                  placeholder="müşteri soyisim" style={{ marginBottom:'10px'}} value={(sameAddress ? addressLastName : deliveryLastName) || customerLastName} onChange={(e)=>setDeliveryLastName(e.target.value)} disabled={sameAddress}></Form.Control>
            </div>
                  ) : ( <Form.Group>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                  <Form.Control  placeholder="şirket Adı" style={{marginRight:'10px', marginBottom:'10px'}} onChange={(e)=>setDeliveryCompany(e.target.value)}
                  value = {deliveryCompany || ""}
                  ></Form.Control>
                  <Form.Control placeholder="TC Kimlik Nu."  style={{marginBottom:'10px'}} onChange={(e)=>setDeliveryIdentityNumber(e.target.value)}
                  value = { deliveryIdentityNumber || ""}
                  
                  ></Form.Control>
                </div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                <Form.Control placeholder="vergi numarası" style={{marginRight:'10px', marginBottom:'10px'}} onChange={(e)=>setDeliveryTaxNumber(e.target.value)}
                 value = {deliveryTaxNumber  || ""}
                ></Form.Control>
                <Form.Control placeholder="vergi dairesi" style={{marginBottom:'10px'}} onChange={(e)=>setDeliveryTaxOffice(e.target.value)}
                 value = {deliveryTaxOffice  || ""}
                ></Form.Control>
                </div>
                </Form.Group>)
}
                   
            <div style={{display:'flex', justifyContent:'space-between'}}>

                <Form.Control placeholder="telefon" style={{marginRight:'10px', marginBottom:'10px'}} value={(sameAddress ?  addressPhone : deliveryPhone) || customerPhone} onChange={(e)=>setDeliveryPhone(e.target.value)} disabled={sameAddress}></Form.Control>
      
                <Form.Control type="email" placeholder="name@example.com" style={{ marginBottom:'10px'}} value={(sameAddress ? addressEmail : deliveryEmail) || customerEmail} onChange={(e)=>setDeliveryEmail(e.target.value)} disabled={sameAddress}></Form.Control>

            </div>


            <div style={{display:'flex', justifyContent:'space-between'}}>

                <Form.Control placeholder="adres" style={{marginBottom:'10px'}} value={(sameAddress ? addressText : deliveryText) || ""} onChange={(e)=>setDeliveryText(e.target.value)} disabled={sameAddress}></Form.Control>

            </div>

            <div style={{display:'flex', justifyContent:'space-between'}}>

                <Form.Control placeholder="ilçe" style={{ marginBottom:'10px',marginRight:'10px'}} value={(sameAddress ?  addressDistrict : deliveryDistrict) || ""} onChange={(e)=>setDeliveryDistrict(e.target.value)} disabled={sameAddress}></Form.Control>

                <Form.Control placeholder="il" style={{marginBottom:'10px'}} value={(sameAddress ?  addressCity : deliveryCity) || ""} onChange={(e)=>setDeliveryCity(e.target.value)} disabled={sameAddress}></Form.Control>
            </div>
        </Form.Group>
      </Form.Group>
    </Accordion.Body>
  </Accordion.Item>
</Accordion>


      <Form.Group style={{marginTop:"15px"}}>
          <Form.Label>Sipariş Notu</Form.Label>
                <Form.Control placeholder="sipariş notu" onChange={(e)=>setOrderNote(e.target.value)}
                value={orderNote || ''}
                
                ></Form.Control>
      </Form.Group>


  <Form.Group controlId="ürün" className="mb-3 mt-3"  >
    <Form.Label>Ürün</Form.Label>
    <div style={{display:'flex',  justifyContent:'space-between'}}>

    <div style={{width:'42%'}}> 

<AsyncSelect 
        loadOptions={getData}
        placeholder="isim, barkod, sku numarası" 
        className="mb-3" 
        onInputChange={handleInputChange}
        onChange={handleChange}
        value={selectedValue || ""}
        getOptionLabel={e => e.Title}
        getOptionValue={e => e.Barcode}
        // unstyled={true}
        styles={{control: (baseStyles) =>({
          ...baseStyles, 
          backgroundColor:isDark ? "#0B1727" : "white"
        }),}}
      /> 

      </div> 
  
   

    <div style={{width:'23%'}}>
        <Form.Control value={count || ''}   placeholder="ürün sayısı" onChange={(e)=>setCount(e.target.value)}/>
    </div>

    <div style={{width:'23%'}}>
        <Form.Control value={price} placeholder="fiyatı"  type="number" min="0" step="0.5" onChange={handlePriceChange}/>
    </div>

    <div style={{width:'9%'}}>
        <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeCurreny}>
              {currenyList?.map((item, i)=>{
                return <option  key={i} value={item.value}>{item.label}</option>
              })}
        </Form.Select> 
    </div>

    </div>
  </Form.Group>



<div>
  <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
    <Button variant="secondary" type="button" onClick={handleAddProduct} style={{marginBottom:"10px"}}>
      Ekle
    </Button>
    </div>

    {showTable && (
  <Card className="mb-3">
  <Card.Body>
    <div className="table-responsive fs--1">
      <Table striped className="border-bottom">
        <thead className="bg-200 text-900">
          <tr>
          <th className="border-0"></th>

            <th className="border-0">Ürün</th>
            <th className="border-0">İsim</th>
            <th className="border-0 text-center">Adet</th>
            <th className="border-0 text-end">Fiyat</th>

          </tr>
        </thead>
        <tbody>
        {products.map((product, i) => (
            <tr key={i} className="border-200">
              <td style={{marginRight:"0"}}>        
              <svg  onClick={() => handleDeleteProduct(i)} xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
              </td>
                <td className="align-middle" style={{marginLeft:"0"}}>
                  <img style={{width:"40px"}}src={product.image} alt="" />
                </td>

                <td className="align-middle">
                  <h6 className="mb-0 text-nowrap">{product.name}</h6>
                </td>

                <td className="align-middle text-center">
                  {product.count}
                </td>

                <td className="align-middle text-end">
                    {/* {Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(getParsedPrice(product.basePrice) * product.count)} */}

                    {formatCurrency((product.basePrice || 0) * (product.count || 0))}
                </td>

            </tr>
          ))}
        </tbody>
      </Table>
      <Row className="g-0 justify-content-end">
        <Col xs="auto">
          <Table borderless size="sm" className="fs--1 text-end">
            <tbody>
              <tr>
                <th className="text-900">İndirim:</th>
                <td className="fw-semi-bold">
                <input type="number" value={discount} onChange={handleDiscountChange} style={{textAlign:'end',borderRadius:'5px', color:"#5E6E82", width:"50px", border:"1px solid #5E6E82"}} />
                
                </td>
              </tr>
             
              <tr className="border-top" style={{textAligin:"end"}}>
                <th className="text-900" style={{textAligin:"center"}}>Total: </th>
                <td className="fw-semi-bold">
            
                {formatCurrency(total)}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  </Card.Body>
</Card>
)}
</div>

  <Button variant="primary" type="submit" className="d-flex mx-auto mt-5 mb-2" >
    Onayla
  </Button>
  <p style={{fontSize:"13px"}}>* zorunlu alanlar</p>
</Form>
</Form.Group>
</Card>

 {/* Boş ürün modali */}
 <Modal show={showEmptyProductModal} onHide={() => setShowEmptyProductModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Uyarı!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Ürün eklemeden devam etmek istediğinize emin misiniz?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEmptyProductModal(false)}>
            İptal
          </Button>
          <Button variant="primary" onClick={handleConfirmSubmit}>
            Onayla
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Bilgiler doğruluğu modali */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Uyarı!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Girilen bilgilerin doğruluğunu onaylıyor musunuz?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            İptal
          </Button>
          <Button variant="primary" onClick={handleConfirmSubmit}>
            Onayla
          </Button>
        </Modal.Footer>
      </Modal>




    <Modal 
      show={show} 
      onHide={handleClose}
      size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Sipariş Ekle</Modal.Title>
        </Modal.Header>
      
        {hasPermission("Sipariş sade import") ? 
        <Tab.Container defaultActiveKey="normalImport">
          <Row>
            <Col sm={12}>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="normalImport">Detaylı Ekle</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="simpleImport">Basitleştirilmiş Ekle</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="normalImport">
                <Modal.Body>
                    <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
                        <div style={{ marginRight: "8px", marginTop:'10px'}}>
                          { myDataVendor?.length > 1 &&    
                            <Form.Select 
                              className='form-control' 
                              style={{fontSize:"15px", width:"145px"}} 
                              onChange={handleChangeVendor}
                            
                            >
                              <option value="" >Satıcı</option>
                              {myDataVendor?.map((item, i) => {
                                return <option key={i} value={item.Id}>{item.vendor_name}</option>
                              })}       
                            </Form.Select>
                          }
                          </div>

                            <div style={{ marginRight: "8px", marginTop:'10px'}}>
                              { myDataVendorStore?.length > 1 &&    
                                <Form.Select 
                                  className='form-control' 
                                  style={{fontSize:"15px", width:"145px"}} 
                                  onChange={handleChangeVendorStore}
                                >
                                  <option value="">Mağaza</option>
                                  {myDataVendorStore?.map((item, i) => {
                                    return <option key={i} value={item.Id}>{item.Vendor_Store}</option>
                                  })}       
                                </Form.Select>
                              }
                            </div>
                        </div>

                        <p>- İndir butonunu kullanarak örnek excel dosyasını indiriniz.</p>
                        <p>- Excel dosyasında belirtilen sütunları değiştirmeyiniz.</p>
                        <p>- OrderNumber sütunu zorunludur.</p>
                        <p>- Bir siparişte birden farklı ürün bulunması durumunda sadece ürün bilgilerinin olduğu product sütunları doldurulması yeterlidir.</p>
                      
                      {myDataVendor?.length > 1 ?  <p>- Satıcı seçimi zorunludur.</p> : ""}

                      </Modal.Body>

                <Modal.Footer>
                    <Form style={{width:"100%"}}>
                      <Form.Group style={{ display: "flex", justifyContent: "space-between", width:"100%"}}>
                         
                          <div>
                            <Form.Control type="file" ref={fileInput} style={{border: 'none'}} />
                          </div>
                      
                        <div>
                       
                          <Button variant="primary" onClick={handleExport}>
                            Örnek excel indir
                          </Button>
                        
                          <Button style={{marginLeft:"5px"}} variant="secondary" onClick={handleClose}>
                            Vazgeç
                          </Button>
                        </div>
                      </Form.Group>

                      <div style={{ display: "flex", justifyContent: "center", marginTop:"60px" }}>
                        <Button variant="primary" onClick={handleUploadClick}>Yükle</Button>
                      </div>
                  
                    </Form>
                    </Modal.Footer>

                </Tab.Pane>
                <Tab.Pane eventKey="simpleImport">


                <Modal.Body>
                    <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
                        <div style={{ marginRight: "8px", marginTop:'10px'}}>
                          { myDataVendor?.length > 1 &&    
                            <Form.Select 
                              className='form-control' 
                              style={{fontSize:"15px", width:"145px"}} 
                              onChange={handleChangeVendor}
                            
                            >
                              <option value="" >Satıcı</option>
                              {myDataVendor?.map((item, i) => {
                                return <option key={i} value={item.Id}>{item.vendor_name}</option>
                              })}       
                            </Form.Select>
                          }
                          </div>

                            <div style={{ marginRight: "8px", marginTop:'10px'}}>
                              { myDataVendorStore?.length > 1 &&    
                                <Form.Select 
                                  className='form-control' 
                                  style={{fontSize:"15px", width:"145px"}} 
                                  onChange={handleChangeVendorStore}
                                >
                                  <option value="">Mağaza</option>
                                  {myDataVendorStore?.map((item, i) => {
                                    return <option key={i} value={item.Id}>{item.Vendor_Store}</option>
                                  })}       
                                </Form.Select>
                              }
                            </div>
                        </div>

                        <p>- İndir butonunu kullanarak örnek excel dosyasını indiriniz.</p>
                        <p>- Excel dosyasında belirtilen sütunları değiştirmeyiniz.</p>
                        <p>- OrderNumber sütunu zorunludur.</p>
                        <p>- Sipariş ürünü kadar SKU ve Count sütunu eklenerek import sağlanabilir.</p>
                  
                        

                      {myDataVendor?.length > 1 ?  <p>- Satıcı seçimi zorunludur.</p> : ""}

                      </Modal.Body>

                <Modal.Footer>
                    <Form style={{width:"100%"}}>
                      <Form.Group style={{ display: "flex", justifyContent: "space-between", width:"100%"}}>
                          <div>
                            <Form.Control type="file" ref={fileInputBasic} style={{border: 'none'}} />
                          </div>
                      
                        <div>
                          <Button variant="primary" onClick={handleExportBasic}>
                            Örnek excel indir
                          </Button>
                        
                          <Button style={{marginLeft:"5px"}} variant="secondary" onClick={handleClose}>
                            Vazgeç
                          </Button>
                        </div>
                      </Form.Group>
                      <div style={{ display: "flex", justifyContent: "center", marginTop:"60px" }}>
                        <Button variant="primary" onClick={handleUploadClickBasic}>Yükle</Button>
                      </div>
                    </Form>
                    </Modal.Footer>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
:

       <div>
            <Modal.Body>
                    <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
                        <div style={{ marginRight: "8px", marginTop:'10px'}}>
                          { myDataVendor?.length > 1 &&    
                            <Form.Select 
                              className='form-control' 
                              style={{fontSize:"15px", width:"145px"}} 
                              onChange={handleChangeVendor}
                            
                            >
                              <option value="" >Satıcı</option>
                              {myDataVendor?.map((item, i) => {
                                return <option key={i} value={item.Id}>{item.vendor_name}</option>
                              })}       
                            </Form.Select>
                          }
                          </div>

                            <div style={{ marginRight: "8px", marginTop:'10px'}}>
                              { myDataVendorStore?.length > 1 &&    
                                <Form.Select 
                                  className='form-control' 
                                  style={{fontSize:"15px", width:"145px"}} 
                                  onChange={handleChangeVendorStore}
                                >
                                  <option value="">Mağaza</option>
                                  {myDataVendorStore?.map((item, i) => {
                                    return <option key={i} value={item.Id}>{item.Vendor_Store}</option>
                                  })}       
                                </Form.Select>
                              }
                            </div>
                        </div>

                        <p>- İndir butonunu kullanarak örnek excel dosyasını indiriniz.</p>
                        <p>- Excel dosyasında belirtilen sütunları değiştirmeyiniz.</p>
                        <p>- OrderNumber sütunu zorunludur.</p>
                        <p>- Bir siparişte birden farklı ürün bulunması durumunda sadece ürün bilgilerinin olduğu product sütunları doldurulması yeterlidir.</p>
                      
                      {myDataVendor?.length > 1 ?  <p>- Satıcı seçimi zorunludur.</p> : ""}

                      </Modal.Body>

                <Modal.Footer>
                    <Form style={{width:"100%"}}>
                      <Form.Group style={{ display: "flex", justifyContent: "space-between", width:"100%"}}>
                         
                          <div>
                            <Form.Control type="file" ref={fileInput} style={{border: 'none'}} />
                          </div>
                      
                        <div>
                       
                          <Button variant="primary" onClick={handleExport}>
                            Örnek excel indir
                          </Button>
                        
                          <Button style={{marginLeft:"5px"}} variant="secondary" onClick={handleClose}>
                            Vazgeç
                          </Button>
                        </div>
                      </Form.Group>

                      <div style={{ display: "flex", justifyContent: "center", marginTop:"60px" }}>
                        <Button variant="primary" onClick={handleUploadClick}>Yükle</Button>
                      </div>
                  
                    </Form>
                    </Modal.Footer>
                    </div>  
          }

         </Modal>


      </>
  )
}

export default OrderAdd