import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'

import { toast } from 'react-toastify';
import useVendorList from './useVendorList';

export default function useProductAdd() {



 const {myKey, currentUser} = useContext(AuthContext)





const [formData, setFormData] = useState({
  vendorId: "",
  Title: "",
  SKU: "",
  Brand: "",
  category_name: "",
  Volume: "",
  Weight: "",
  Price: "",
  Image: "",
  Barcode: ""
    });
 

 const postData = ()=>{



    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL+"api/v1/product/product/create_product/"
    
    
    // var raw = JSON.stringify({...formData})
    
    var raw = JSON.stringify({
                    "vendor_id" : formData.vendorId,
                    "Title": formData.Title,
                    "SKU": formData.SKU,
                    "Brand": formData.Brand,
                    "category_name": formData.category_name || formData.new_category,
                    "Volume": formData.Volume,
                    "Weight": formData.Weight,
                    "Price": formData.Price,
                    "Image": formData.Image,
                    "Barcode": formData.Barcode,

    });
    
 console.log("raw",raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
  .then(response => {
    return response.json().then(data => {
      if (response.ok) {
        toast.success(`Başarılı şekilde gönderildi.`, { theme: 'colored' });
      } else {
        // Sunucudan dönen hata mesajını burada yakalayabilirsiniz.
        const errorMessage = data.error || "Bilinmeyen bir hata oluştu";
        console.log("işlemi başarısız:", errorMessage);
        toast.error(`işlemi başarısız: ${errorMessage}`, { theme: 'colored' });
      }
    });
  })
  .catch(error => {
    console.log('Hata', error);
    toast.error(`Bir hata oluştu: ${error.message}`, { theme: 'colored' });
  });


}

useEffect(() => {
 if(formData.Barcode){
  postData()
 }

}, [formData])


 

 return {setFormData, formData, postData}
}
