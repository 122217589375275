import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useDebounce from './useDebounce';


export default function useTaskRulusList() {

 const [myData, setMyData] = useState()

 const {myKey, currentUser} = useContext(AuthContext)
 
 const [page, setPage] = useState(1)

 const [currentPage,setCurrentPage]=useState(1)
 const [followersPerPage, setFollowersPerPage] = useState(10);
 const [totalPages, setTotalPages] = useState(0);




 const getData = ()=>{
        var myHeaders = new Headers();
        const offset = (currentPage - 1) * followersPerPage;
        const limit = followersPerPage;

 

        const params = {
         
        };
    
        const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}api/v1/task/?`
        );

            
      console.log("wurl", request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            console.log("p_result", result)
            setMyData(result) 
            setTotalPages(Math.ceil(result.count / followersPerPage))
    })
          .catch(error => console.log('error', error));        
 }

 useEffect(() => {
    getData();
  }, [page, currentPage, followersPerPage]);

 return {myData, setPage, currentPage, setCurrentPage, getData, followersPerPage, setFollowersPerPage, totalPages }
 
  
  
  
}


