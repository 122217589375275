import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext, useRef } from 'react'
import {BASE_URL} from './baseUrl'
import { toast } from 'react-toastify';




export default function useVendorStoreProduct() {


  const {myKey} = useContext(AuthContext)
  const [myData, setMyData] = useState()
  const isInitialRender = useRef(true);

  const [vendorId, setVendorId] = useState()
  const [search, setSearch] = useState()
  const [vendorStore, setVendorStore] = useState()


  const [currentPage,setCurrentPage]=useState(1)
  const [followersPerPage, setFollowersPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1)

  const [searchName,  setSearchName] = useState()

  const [showGif, setShowGif] = useState(false);

  const [click, setClick] = useState("1")

  const [status, setStatus] = useState()







 const getDataProduct = async (showToast = true)=>{
      var myHeaders = new Headers();
      const offset = (currentPage - 1) * followersPerPage;
      const limit = followersPerPage;

      myHeaders.append("Authorization", "Token " + myKey);


      const params = {

        offset :offset,
        limit :limit,

        vendor_id: vendorId,
        vendor_store : vendorStore,
        search : searchName,
        link_product: status
        

      };




    const request_url = Object.entries(params)
    .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
    .reduce(
      (url, [key, value]) => url + `&${key}=${value}`,
      `${BASE_URL}api/v1/product/vendorstore_product/?`
);

    console.log("request_url", request_url)

var requestOptions = {
  method: 'GET',
  headers: {"Authorization": "Token " + myKey},
  redirect: 'follow'
};


fetch(request_url, requestOptions)
  .then(response => response.json())
  .then(result => {
    
    setMyData(result);
    console.log("result", result)
    setShowGif(false);
    setTotalPages(Math.ceil(result.count / followersPerPage))

    if (showToast)  {

      if (result.count > 0) {
        toast.success(`${result.count} adet ürün listeleniyor.`);
       
      } else {
        toast.warning('İstenilen kriterlerde sipariş bulunmamaktadır.');
      }
    }
   
   
  })
  .catch(error => {
    console.log('error', error);
   
      toast.error('Bir hata oluştu.');
   
  });




}




useEffect(() => {
  if (isInitialRender.current) {
    isInitialRender.current = false; 
  } else {
    getDataProduct(false); 
  }
}, [currentPage, searchName, click, status]);





 return {myData,setMyData, getDataProduct, currentPage,
  setCurrentPage,
  followersPerPage,
  setFollowersPerPage,
  totalPages,setVendorId, showGif, setShowGif, setVendorStore, vendorId, setTotalPages, setPage, setSearchName, setClick, setStatus}
  
  
}
