import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, Col, InputGroup, Alert, Row} from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import OrdersTableHeader from './OrdersTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import useOrderList from 'hooks/useOrderList';
import Pagination from './PaginationOrder';
import { getDatasetAtEvent } from 'react-chartjs-2';
import DatePicker from  "react-datepicker";
import useShipmentCompany from 'hooks/useShipmentCompany';
import Accordion from 'react-bootstrap/Accordion';
import AsyncSelect from 'react-select/async';
import IconButton from 'components/common/IconButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useOrderExport from 'hooks/useOrderExport';
import useVendorStore from 'hooks/useVendorStore';
import { Search } from 'react-bootstrap-icons';
import gif from 'assets/img/gif_export.gif'
import gifOrder from 'assets/img/gif_order.gif'
import useOrderExportS3 from 'hooks/useOrderExportS3';
import useInventoryListFilter from 'hooks/useInventoryListFilter';
import {  PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import gif_s3 from 'assets/img/gif_export_s3.gif'
import useOrderWorkList from 'hooks/useOrderWorkList';


// const columns = [
//   {
//     accessor: 'name',
//     Header: 'Sipariş',
//     disableSortBy: true,
//     headerProps: { className: 'text-left'},
//     cellProps: {
//       className: 'text-left'
//     },
//     Cell: rowData => {
//       const { id, name, email } = rowData.row.original;
//       return (
//         <>
//           <Link to="/e-commerce/orders/order-details" state={{id}}>
//             <strong>{id}</strong>
//           </Link>{' '}
//           by <strong>{name}</strong> <br />
//           <Link to="/e-commerce/orders/order-details"state={{id}} >
//           <a href="">{email}</a>
//           </Link>
//         </>
//       );
//     }
//   },
//   {
//     accessor: 'date',
//     Header: 'Tarih',
//     disableSortBy: true,
//     headerProps: {
//       className: 'text-left'
//     },
//     cellProps: {
//       className: 'text-left'
//     }
//   },
//   {
//     accessor: 'address',
//     Header: 'Şehir ve Kargo Bilgileri',
//     disableSortBy: true,
//     headerProps: {
//       className: 'text-left'
//     },
//     cellProps: {
//       className: 'text-left'
//     },
//     Cell: rowData => {
//       const { address, shippingType, trackingLink } = rowData.row.original;
//       return (
//         <>
//           {address}
//           {trackingLink ? (
//             <p>
//            <a href={trackingLink} target="_blank" rel="noopener noreferrer" className="mb-0 text-500" >
//            <strong style={{color:"#2C7AE5"}} >{shippingType}</strong>
//               </a></p>
//           ) : (
//             <p className="mb-0 text-500" onClick={() => alert('Şu an takip linki oluşmamışdır.')}>
//               {shippingType}
//             </p>
//           )}
//         </>
//       );
//     }
    
//   },
//   {
//     accessor: 'status',
//     Header: 'Durumu',
//     disableSortBy: true,
//     headerProps: {
//       className: 'text-center'
//     },
//     cellProps: {
//       className: 'text-left'
//     },
//     Cell: rowData => {
//       const { status } = rowData.row.original;
//       return (
//         <SoftBadge
//           pill
//           bg={classNames({
//             secondary : status === '8', // delivered
//             success: status === '7', // delivered
//             danger: status === '6', // delivered
//             light: status === '5',   // returned
//             primary: status === '4',   // completed
//             info: status === '3',  // processing
//             warning: status === '2',   // pending
//             dark : status === '1'   // onhold
//           })}
//           className="d-block"
//         >
//           {status === '8' && 'Teslim Edilmedi'} 
//           {status === '7' && 'Teslim Edildi'} 
//           {status === '6' && 'İptal Edildi'} 
//           {status === '5' && 'İade Edildi'} 
//           {status === '4' && 'Kargoya Verildi'}  
//           {status === '3' && 'Paketlendi'} 
//           {status === '2' && 'Hazırlanıyor'} 
//           {status === '1' && 'Beklemede'}  
//           <FontAwesomeIcon
//             icon={classNames({
//               check: status === '7',
//               check: status === '4',
//               redo: status === '3',
//               stream: status === '2',
//               ban: status === '1'
//             })}
//             transform="shrink-2"
//             className="ms-1"
//           />
//         </SoftBadge>
//       );
//     }
//   },
//   {
//     accessor: 'amount',
//     // Header: 'Tutar',
//     Header: () => (
//       <div 
//         style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
//         className='text-center' 
//         onClick={onHeaderClick}
//       >
//        Tutar &nbsp; 
//           {
//             sortState === 1 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9660;</span> 
//           }
//           {
//             sortState === 2 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9650;</span> 
//           }
//           {
//             sortState === 3 && (
//               <div>
//                 <span style={{display: 'block', marginBottom:"-5px", fontSize:"8px", color:"#B5C2D1"}}>&#9650;</span> 
//                 <span style={{display: 'block', fontSize:"8px", color:"#B5C2D1"}}>&#9660;</span> 
//               </div>
//             )
//   }
//       </div>
//     ),
//     disableSortBy: true,
//     headerProps: {
//       className: 'text-left'
//     },
//     cellProps: {
//       className: 'text-left'
//     },
//     Cell: rowData => `${rowData.row.original.amount}`
//   },
//   {
//     accessor: 'none',
//     Header: 'Mağaza',
//     disableSortBy: true,
//     cellProps: {
//       className: 'text-left'
//     },
//     Cell: rowData => {
//       const { store} = rowData.row.original;
//       return (
//         <p>{store}</p>
//       );
//     }
//   },
//   {

//     Header: 'Fatura',
//     disableSortBy: true,
//     headerProps: {
//       className: 'text-end'
//     },
//     cellProps: {
//       className: 'text-end'
//     },
//     Cell: rowData => {
//       const {invoice} = rowData.row.original;
//       return (
//         <p style={{marginRight:"5px"}}>{invoice}</p>
//       );
//     }
//   }
// ];

const OrderWorkListDetail = () => {

  const columns = [
    {
      accessor: 'name',
      Header: 'Sipariş',
      disableSortBy: true,
      headerProps: { className: 'text-left'},
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { id, name, email } = rowData.row.original;
        return (
          <>
            <Link to="/e-commerce/orders/order-details" state={{id}}>
              <strong>{id}</strong>
            </Link>{' '}
            by <strong>{name}</strong> <br />
            <Link to="/e-commerce/orders/order-details"state={{id}} >
            <a href="">{email}</a>
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Tarih',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      }
    },
    {
      accessor: 'address',
      Header: 'Şehir ve Kargo Bilgileri',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { address, shippingType, trackingLink } = rowData.row.original;
        return (
          <>
            {address}
            {trackingLink ? (
              <p>
             <a href={trackingLink} target="_blank" rel="noopener noreferrer" className="mb-0 text-500" >
             <strong style={{color:"#2C7AE5"}} >{shippingType}</strong>
                </a></p>
            ) : (
              <p className="mb-0 text-500" onClick={() => alert('Şu an takip linki oluşmamışdır.')}>
                {shippingType}
              </p>
            )}
          </>
        );
      }
      
    },
    {
      accessor: 'status',
      Header: 'Durumu',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              secondary : status === '8', // delivered
              success: status === '7', // delivered
              danger: status === '6', // delivered
              light: status === '5',   // returned
              primary: status === '4',   // completed
              info: status === '3',  // processing
              warning: status === '2',   // pending
              dark : status === '1'   // onhold
            })}
            className="d-block"
          >
            {status === '8' && 'Teslim Edilmedi'} 
            {status === '7' && 'Teslim Edildi'} 
            {status === '6' && 'İptal Edildi'} 
            {status === '5' && 'İade Edildi'} 
            {status === '4' && 'Kargoya Verildi'}  
            {status === '3' && 'Paketlendi'} 
            {status === '2' && 'Hazırlanıyor'} 
            {status === '1' && 'Beklemede'}  
            <FontAwesomeIcon
              icon={classNames({
                check: status === '7',
                check: status === '4',
                redo: status === '3',
                stream: status === '2',
                ban: status === '1'
              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'amount',
      // Header: 'Tutar',
      Header: () => (
        <div 
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
          className='text-center' 
          onClick={onHeaderClick}
        >
         Tutar &nbsp; 
            {
              sortAmount === 1 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9660;</span> 
            }
            {
              sortAmount === 2 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9650;</span> 
            }
            {
              sortAmount === 3 && (
                <div>
                  <span style={{display: 'block', marginBottom:"-5px", fontSize:"8px", color:"#B5C2D1"}}>&#9650;</span> 
                  <span style={{display: 'block', fontSize:"8px", color:"#B5C2D1"}}>&#9660;</span> 
                </div>
              )
    }
        </div>
      ),
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.amount}`
    },
    {
      accessor: 'none',
      Header: 'Mağaza',
      disableSortBy: true,
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { store} = rowData.row.original;
        return (
          <p>{store}</p>
        );
      }
    },
    {
  
      Header: 'Fatura',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const {invoice} = rowData.row.original;
        return (
          <p style={{marginRight:"5px"}}>{invoice}</p>
        );
      }
    }
  ];


  const location = useLocation()
  const {id} = location.state || ""
  const {detail, getDetail} = useOrderWorkList()

  useEffect(() => {
    if(id){
    getDetail(id.slice(1))
    }
  
  }, [id ])









const {perms} = useContext(PermsContext)

const {myData, setPage, selectStatus, setSelectStatus, startDate, setStartDate, endDate, setEndDate, setCargo, cargo, getData, firstName, setFirstName, setStore, setSearch, setPaid, showOrderGif, currentPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, setInvoices, setInventoryRegion , sortAmount, setSortAmount, setVendorIdList} = useOrderList()
 

const {setSelectStatusModal, setStartDateModal, setEndDateModal, setCargoModal, getDataExport, startDateModal, endDateModal, setStoreModal, fulfilmentModal, setFulfilmentModal, showGif, setShowGif, setInvoicesExport, invoicesExport, myDataS3,setMyDataS3, setRegionExport, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorExportId} = useOrderExportS3()

const {myDataVendor} = useVendorList()

const {vendorStore, setVendorIdVendorStore} = useVendorStore()
const {myDataRegion, setVendorIdRegion} = useInventoryListFilter()
const {shipmentCompany, setVendorIdShipment} = useShipmentCompany()


const onHeaderClick = () => {
  setSortAmount((sortAmount % 3) + 1);
};

  
  // const [followersPerPage, setFollowersPerPage]=useState(10)
  // const [currentPage,setCurrentPage]=useState(1)
  const [activePage, setActivePage] = useState(1)
  const [orderList, setOrderList] = useState() 

  const [isOpen, setIsOpen] = useState(false);

  function hasPermission(permission) {
    return perms?.includes(permission);
  }
 

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };



  // modal
  
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setMyDataResult(false)
  }


  const handleShow = () => setShow(true);

  const onChangeModal = datesExport => {
    const [startExport, endExport] = datesExport;
    setStartDateModal(startExport);
    setEndDateModal(endExport);
  };


 

  useEffect(() => {
    if (myDataVendor && myDataVendor.length > 0) {
      const firstVendorId = myDataVendor[0].Id;
      setVendorIdVendorStore(firstVendorId);
      setVendorIdRegion(firstVendorId);
      setVendorIdShipment(firstVendorId);
      setVendorIdList(firstVendorId)
      setVendorExportId(firstVendorId)
    }
  }, [myDataVendor]);


  const handleChangeVendor = (e)=> {
    setVendorIdVendorStore(e.target.value); 
    setVendorIdRegion(e.target.value)
    setVendorIdShipment(e.target.value)
    setVendorIdList(e.target.value)
    
  }




  const handleChangeModal = (e)=> {
    setSelectStatusModal(+e.target.value); 
    console.log("type", typeof e.target.value)
  }

  const handleChangeCargoModal = (e)=> {
    setCargoModal(e.target.value); 
  }

  const handleChangeStoreModal = (e)=> {
    setStoreModal(e.target.value); 
  }
  
  const handleChangeVendorModal = (e)=> {
    setVendorExportId(e.target.value); 
    setVendorIdVendorStore(e.target.value); 
    setVendorIdRegion(e.target.value)
    setVendorIdShipment(e.target.value)
  }



  const handleChangeRegionModal = (e)=> {
    setRegionExport(e.target.value); 
  }

  

  
  const handleExport = ()=> { 
    getDataExport(); 
    setShowGif(true);
    // setShow(false)
    setCargoModal(null)
    setSelectStatusModal(null)
    setStartDateModal(null);
    setEndDateModal(null);
    setIsPreparing(true);
  }

  const handleDownload = () => {
    setIsPreparing(false);
    window.location.href = myDataResult[0]?.Value;
    setShow(false)
    setMyDataResult(false)
  }




  useEffect(() => {
    setPage(activePage)
  }, [activePage])
  
  const orderStatus = [
    {value:0, label:"Hepsi"},
    {value:1, label:"Beklemede"},
    {value:2, label:"Hazırlanıyor"},
    {value:3, label:"Paketlendi"},
    {value:4, label:"Kargoya verildi"},
    {value:5, label:"İade"},
    {value:6, label:"İptal"},
    {value:7, label:"Teslim Edildi"},
    {value:8, label:"Teslim Edilmedi"},

]

const orderPaid = [
  {value : "True", label : "Ödendi"},
  {value : "False", label : "Ödenmedi"}
]

const fulfilmentStatus = [
  {value:0, label:"Hepsi"},
  {value:1, label:"Gönderilmedi"},
  {value:4, label:"Gönderildi"},

]

const invoicesStatus = [
  {value:"true", label:"Faturalı"},
  {value:"false", label:"Faturasız"},
]


const handleChangeFulfilmentModal = (e)=> {
  setFulfilmentModal(+e.target.value); 

}


const handleChange = (e)=> {
  setSelectStatus(+e.target.value); 

}

const handleChangePaid = (e)=> {
  setPaid(e.target.value); 

}


const handleChangeCargo = (e)=> {
  setCargo(e.target.value); 
}

const handleChangeStore = (e)=> {
  setStore(e.target.value); 
}

const handleChangeRegion = (e)=> {
  setInventoryRegion(e.target.value); 
}


const handleChangeinvoices = (e)=> {
  setInvoices(e.target.value); 
}

const handleChangeExportinvoices = (e)=> {
  setInvoicesExport(e.target.value); 
}



useEffect(() => {
     
  const newList = detail?.orders?.map((item)=>{

    const date = new Date(item.OrderDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
    return {
      id : "#"+item.Id,
      name: item.Customer.FirstName+ " "+item.Customer.LastName, 
      email : item.OrderNumber === null ? "Sipariş No: -" : "Sipariş No: " + item.OrderNumber,
      date: `${day}-${month}-${year}`,
      address : item.ShippingAddress.City,
      shippingType: ((item.ShipmentCompanyText && item.ShipmentCompanyText.includes("Marketplace")) ? item.ShipmentCompanyText.replace('Marketplace', '').trim() : item.ShipmentCompanyText ? item.ShipmentCompanyText : "Kargolamayı bekliyor") + " " + (item.ShippingTrackingNumber ? item.ShippingTrackingNumber : "Henüz Kargolanmadı"),
      status: item.Status,
      amount : item.OrderAmount,
      store : item.VendorStore.Store.Name,
      trackingLink :item.ShipmentTrackingLink,
      invoice : item.Invoices[0]?.CreatedAt ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/></svg>
  
    }
  })
  setOrderList(newList)

}, [myData, firstName])


// const totalPages = Math.ceil(myData?.count/followersPerPage)

// const onChange = dates => {
//   const [start, end] = dates;
//   setStartDate(start);
//   setEndDate(end);
// };
const handleChangeDate = event => {
  setSelectedOption(event.target.value);

  let start, end;

  switch(event.target.value) {
    case "1": // Bugün
      start = new Date();
      end = new Date();
      break;

    case "2": // Bu hafta
      end = new Date();
      start = new Date();
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
      break;   

    case "3": // Bu ay
      end = new Date();
      start = new Date();
      start.setDate(1); // Ayın ilk günü
      break;
  
    default:
      // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
      break;
  }

  setStartDate(start);
  setEndDate(end);
};

const [isOpenDate, setIsOpenDate] = useState(true);

const onChange = dates => {

  const [start, end] = dates;

  if (selectedOption === "4") {
    setStartDate(start);
    setEndDate(end);
  }
  if (start && end) {
    setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
  }
};




const [selectedOption, setSelectedOption] = useState(null);


useEffect(() => {
  if (selectedOption === "4") {
    setIsOpenDate(true);
  } else {
    setIsOpenDate(false);
  }
}, [selectedOption]);


  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      // data={orderList?.length>0 && orderList} 
      selection
      sortable
      pagination
      perPage={50}
    >
     
      <Card className="mb-3">
        <Card.Header>

      <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Siparişler</h5>
            </div>


            <div style={{display:"flex", justifyContent:'right'}}>

           
            <div >
              <OrdersTableHeader table />
            </div>
    </div>     
  </div>
</Card.Header>

       
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />


        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showOrderGif && <img src={gifOrder} style={{width:'50%'}} alt="loading gif" />}
          </div>
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showGif && <img src={gifOrder} style={{width:'50%'}} alt="export gif" />}
          </div>
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <Pagination pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage}/>
        </Card.Footer>
       
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}>Sipariş sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>


      <Modal 
      show={show} 
      onHide={handleClose}
      size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Sipariş Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        
          
        <div style={{display:'flex', justifyContent:'center'}} >
       
        <div style={{marginRight:'8px'}}>
                    { myDataVendor?.length> 1 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeVendorModal}>
                     <option value="">Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
   
          <div style={{marginRight:"8px", fontSize:"14px"}}>
                <DatePicker
                showIcon
                selected={startDateModal}
                onChange={onChangeModal}
                startDate={startDateModal}
                formatWeekDay={day => day.slice(0, 3)}
                endDate={endDateModal}
                selectsRange
                dateFormat="MMM dd"
                className='form-control'
                placeholderText='tarih giriniz'
                />
          </div>

          <div style={{marginRight:'8px'}}>
                    { vendorStore?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeStoreModal}>
                     <option value="" >Mağaza</option>
                        { vendorStore?.map((item, i)=>{
                              return <option key={i} value={item.Store__Name}>{item.Store__Name}</option>
                        })}       
                </Form.Select>}
            </div>


          <div style={{marginRight:"8px"}}>
              <Form.Select  className='form-control' style={{fontSize:"15px", width:"130px"}} onChange={handleChangeModal}>
                  <option value=""  disabled selected>Durumu</option>
                      {orderStatus?.map((item, i)=>{
                            return <option  key={i} value={item.value}>{item.label}</option>
                      })}
              </Form.Select> 
          </div>

          <div style={{marginRight:"8px"}}>
              <Form.Select  className='form-control' style={{fontSize:"15px", width:"130px"}} onChange={handleChangeFulfilmentModal}>
                  <option value=""  disabled selected>Durumu</option>
                      {fulfilmentStatus?.map((item, i)=>{
                            return <option  key={i} value={item.value}>{item.label}</option>
                      })}
              </Form.Select> 
          </div>

          <div style={{marginRight:"8px"}}>
                  { shipmentCompany?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"112px"}} onChange={handleChangeCargoModal}>
                  <option value="" >Kargo</option>
                      { shipmentCompany?.map((item, i)=>{
                            return <option key={i} value={item.ShipmentCompanyText}>{item.ShipmentCompanyText}</option>
                      })}       
              </Form.Select>}
          </div>
          <div className="mb-2">
                 
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeExportinvoices}>
                            <option value="">Fatura</option>
                                {invoicesStatus?.map((item, i)=>{
                                      return <option  key={i} value={item.value}>{item.label}</option>
                                })}
                        </Form.Select> 
                  </div>
                  <div style={{marginLeft:"8px"}}>
                  { shipmentCompany?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"112px"}} onChange={handleChangeRegionModal}>
                  <option value="" >Depo</option>
                      { myDataRegion?.map((item, i)=>{
                            return <option key={i} value={item.Region__Name}>{item.Region__Name}</option>
                      })}       
              </Form.Select>}
          </div>

</div>
               
          </Modal.Body>
          <Modal.Footer>
          <Row style={{width:'100%'}}>      
  <Row>
  { myDataVendor?.length> 1 ? <p>Satıcı ismi seçilmesi ZORUNLUDUR!</p> : ""}
    <p>Tarih aralığı geniş sipariş listesinin indirilmesi 1-2 dk bulabilir.</p>
    <p>Siparişiniz hazırlanıyor yazısından sonra tekrar Hazırla butonuna basmayınız.</p>

    <Col className="d-flex justify-content-end mb-3">
      <Button variant="secondary" onClick={handleClose} style={{marginRight:'10px'}}>
        Vazgeç
      </Button>
      <Button variant="primary" onClick={handleExport}>
        Hazırla
      </Button>
    </Col>
  </Row>
  {isPreparing && (!myDataResult || !myDataResult.error) && 
  
  <div>
  <div>Sipariş listeniz hazırlanıyor, lütfen bekleyin...</div>
  <img src={gif_s3} style={{width:'50%'}} alt="export gif" />
  </div>
  
  }
  {myDataResult && myDataResult[0]?.Value?.length > 0  && 
  <Row className="justify-content-center mt-5" style={{width:'100%'}}>
    <Col style={{display: "flex", justifyContent: "center"}}>
    <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        {/* {myDataS3?.message} */}
        Sipariş listesiniz hazır indirebilirsiniz.
      </Alert>
      <Button 
  variant="primary" 
  style={{width:'150px', height:'40px'}}
  // onClick={() => window.location.href = myDataS3?.download_url}
  onClick={handleDownload}
>
  İndir
</Button>

    </Col>
  </Row>

  }
   </Row>
      </Modal.Footer>
      </Modal>
    
    </AdvanceTableWrapper>
  );
};

export default OrderWorkListDetail;





