import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';


import AuthContextProvider from 'context/AuthContext';
import DateContextProvider from 'context/DateContext';
import PermsContextProvider from 'context/PermsContext';



const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <Main>
    <AuthContextProvider>
    <PermsContextProvider>
      <DateContextProvider>
        <App />
      </DateContextProvider>
      </PermsContextProvider>
    </AuthContextProvider>
  </Main>
);
