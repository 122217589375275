import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';

import bgShape from 'assets/img/illustrations/bg-shape.png';
import shape1 from 'assets/img/illustrations/shape-1.png';
import halfCircle from 'assets/img/illustrations/half-circle.png';
import logo from 'assets/img/illustrations/shipshack.png';
import logoDark from 'assets/img/illustrations/shipshack-dark.png';
import AppContext from 'context/Context';

const AuthCardLayout = ({ leftSideContent, children, footer = true }) => {

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);



  return (
    <Section fluid className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={8} xxl={5} className="py-3 position-relative">
          <img
            className="bg-auth-circle-shape"
            src={bgShape}
            alt=""
            width="250"
          />
          <img
            className="bg-auth-circle-shape-2"
            src={shape1}
            alt=""
            width="150"
          />
          <Card className="overflow-hidden z-index-1">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                <Col md={5} className="text-white text-center bg-card-white">
                  <div className="position-relative p-4 pt-md-5 pb-md-7">
                    <Background
                      // image={halfCircle}
                      className="bg-auth-card-white"
                    />
                    <div className="z-index-1 position-relative light">
                   
                        {/* ShipShack */}
                        {/* <div style={{backgroundColor:'white', width:'124%', marginLeft:'-30px', translate:'0 -50px'}}> */}
                     <img style={{ marginBottom:'70px', marginTop:'10px'}} src={isDark ? logoDark : logo} alt="Logo" width={240} />
                     {/* </div> */}

                      <p className="opacity-75" style={{color:isDark ? "white" : "black"}} >
                      Kusursuz E-Lojistik ile Satışlarınızı Her Yerde Artırın!
                   
                      </p>
                      <p className="opacity-75" style={{color:isDark ? "white" : "black"}} >
                  
                      ShipShack; online mağazalarınızdan gelen siparişlerinizi otomatik karşılar, paketler ve ışık hızında kargolar.
                      </p>
                    </div>
                  </div>
                  <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
                    {leftSideContent}

                    {footer && (
                      <p className="mb-0 mt-4 mt-md-5 fs--1 fw-semi-bold opacity-75" style={{color:isDark ? "white" : "black"}}>
                        {/* Read our{' '} */}
                        <Link
                          className="text-decoration-underline"
                          style={{color:isDark ? "white" : "black"}}
                          to="#!"
                        >
                          {/* terms */}
                        </Link>{' '}
                        {/* and{' '} */}
                        <Link
                          className="text-decoration-underline"
                          style={{color:isDark ? "white" : "black"}}
                          to="#!"
                        >
                          {/* conditions{' '} */}
                        </Link>
                      </p>
                    )}
                  </div>
                </Col>
                <Col
                  md={7}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                >
                  <div className="p-4 p-md-5 flex-grow-1">{children}</div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};
AuthCardLayout.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool
};

export default AuthCardLayout;
