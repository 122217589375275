import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row, Table, Button, Form, Tab, Nav } from 'react-bootstrap';
// import { orderedProductsData } from 'data/ecommerce/OrderDetailsData';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import useInventoryProductAll from 'hooks/useInventoryProductAll';
import useInventoryShelfChange from 'hooks/useInventoryShelfChange';
import useInventoryShelfCountChange from 'hooks/useInventoryShelfCountChange';

const ActionHistoryDetail = () => {



  const location = useLocation()
  const {item} = location.state || ""

 

  const actionData = item.instances.map(instance => ({
    obj_id: instance.Id,
    user: item.user_name,
    tip: item.type,
    dateTime: new Date(item.DateTime).toLocaleString('tr-TR'),
    name: instance.name,
    type: instance.type
  }));


 


const navigate = useNavigate();

const handleBackClick = () => {
  if (location.state && location.state.from) {
    navigate(location.state.from);
  } else {
    // Varsayılan sayfaya dön
    navigate('/user/actions-history');
  }
};




  return (
    <>
    {/* <Link to="/e-commerce/product/product-all"> */}
      <span onClick={handleBackClick} style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      {/* </Link> */}
      {/* <InventoryProductList/> */}
    <Card className="mb-3 mt-1">
   
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Obje Id</th>
                <th className="border-0">Kullanıcı</th>
                <th className="border-0 text-center">Tip</th>
                <th className="border-0 text-center">Tarih</th>
                <th className="border-0 text-center">Obje</th>
                <th className="border-0 text-end">Sonuç</th>
              </tr>
            </thead>
            <tbody>
              {actionData?.length>0 && actionData?.map(data => (
                <tr key={data.id} className="border-200">
                  <td className="align-middle">
                    {data.obj_id}
                  </td>

                  <td className="align-middle">
                    {data.user}
                  </td>

                  <td className="align-middle text-center">
                    {data.type}
                  </td>

                  <td className="align-middle text-center">
                    {data.dateTime}
                  </td>

                  <td className="align-middle text-center">
                  {data.name}
                  </td>

                  <td className="align-middle text-end">
                    {data.type}
                  </td>

                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  <tr>
                    {/* <th className="text-900">Tax 5%:</th> */}
                    <td className="fw-semi-bold">
                
                    </td>
                  </tr>
                  {/* <tr className="border-top">
                    <th className="text-900">Toplam:</th>
                    <td className="fw-semi-bold">
                      {subtotal}
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>

      </Card.Body>
    </Card>


    
    </>
  );
};

ActionHistoryDetail.propTypes = {};

export default ActionHistoryDetail;
