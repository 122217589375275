import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, Col, InputGroup } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import useOrderList from 'hooks/useOrderList';
import Pagination from './PaginationOrderInvoice';
import Button from 'react-bootstrap/Button';
import { Search } from 'react-bootstrap-icons';
import gif from 'assets/img/gif_export.gif'
import gifOrder from 'assets/img/gif_order.gif'
import OrderInvoiceTableHeader from './OrderInvoiceTableHeader';
import useOrderInvoiceList from 'hooks/useOrderInvoiceList';
import IconButton from 'components/common/IconButton';
import useVendorList from 'hooks/useVendorList';
import { PermsContext } from 'context/PermsContext';
import DatePicker from  "react-datepicker";
import useVendorStore from 'hooks/useVendorStore';




const OrderInvoice = () => {

  const columns = [
    {
      accessor: 'name',
      Header: 'Sipariş',
      disableSortBy: true,
      headerProps: { className: 'text-left'},
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <>
            <Link to="/e-commerce/orders/order-details-invoice" state={{id}}>
              <strong>{id}</strong>
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'address',
      Header: 'Mağaza',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { store } = rowData.row.original;
        return (
          <>
            {store}
          </>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Sipariş Numarası',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const {order_number} = rowData.row.original;
        return (
          <>
        {order_number}
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Sipariş Tarihi',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      }
    },
   
    
    {
      accessor: 'amount',
      Header: 'Fatura Oluşma Tarihi',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => `${rowData.row.original.created_date}`
    },
    // {
    //   accessor: 'none',
    //   Header: 'Fatura Numarası',
    //   disableSortBy: true,
    //   headerProps: {
    //       className: 'text-center'
    //     },
    //   cellProps: {
    //     className: 'text-center'
    //   },
    //   Cell: rowData => {
    //     const { invoice_number} = rowData.row.original;
    //     return (
    //       <p>{invoice_number}</p>
    //     );
    //   }
    // },
    {
      accessor: 'invoice',
      // Header: 'Tutar',
      Header: () => (
        <div 
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
          className='text-center' 
          onClick={onHeaderDeadLineInvoiceClick}
        >
         Fatura Numarası &nbsp; 
            {
              ascInvoiceDetail === 1 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9660;</span> 
            }
            {
              ascInvoiceDetail === 2 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9650;</span> 
            }
            {
              ascInvoiceDetail === 3 && (
                <div>
                  <span style={{display: 'block', marginBottom:"-5px", fontSize:"8px", color:"#B5C2D1"}}>&#9650;</span> 
                  <span style={{display: 'block', fontSize:"8px", color:"#B5C2D1"}}>&#9660;</span> 
                </div>
              )
    }
        </div>
      ),
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.invoice_number}`
    },
  ];

const {myData, setPage, showOrderGif, currentPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, startDate, setStartDate, endDate, setEndDate, setVendorId, setStore, setAscInvoiceDetail, ascInvoiceDetail, setSearch} = useOrderInvoiceList()
 
const [orderList, setOrderList] = useState() 

const {myDataVendor} = useVendorList()

const {perms} = useContext(PermsContext)
const {vendorStore, setVendorIdVendorStore} = useVendorStore()


function hasPermission(permission) {
  return perms?.includes(permission);
}



const [activePage, setActivePage] = useState(1)

  useEffect(() => {
    setPage(activePage)
  }, [activePage])


  useEffect(() => {
    if (myDataVendor && myDataVendor.length > 0) {
      const firstVendorId = myDataVendor[0].Id;
      setVendorId(firstVendorId)
      setVendorIdVendorStore(firstVendorId);
    }
  }, [myDataVendor]);

  const handleChangeVendor = (e)=> {
    setVendorId(e.target.value)
    setVendorIdVendorStore(e.target.value)
  }
  

useEffect(() => {
     
  const newList = myData?.results.map((item)=>{

    const date = new Date(item.OrderDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const invoiceDate = new Date(item.Invoices[0]?.CreatedAt);
    const invoiceYear = invoiceDate.getFullYear();
    const invoiceMonth = invoiceDate.getMonth() + 1;
    const invoiceDay = invoiceDate.getDate();


    return {
      id : "#"+item.Id,
      date: `${day}-${month}-${year}`,
      store : item.VendorStore?.Store.Name,
      order_number : item.OrderNumber || "-",
      created_date : `${invoiceDay}-${invoiceMonth}-${invoiceYear}`,
      invoice_number : item.Invoices[0]?.Details || "-"
    }
  })
  setOrderList(newList)

}, [myData])

const [isOpen, setIsOpen] = useState(false);

const handleToggle = (event) => {
  // event.preventDefault();
  setIsOpen(!isOpen);
};


const handleChangeDate = event => {
  setSelectedOption(event.target.value);

  let start, end;

  switch(event.target.value) {
    case "1": // Bugün
      start = new Date();
      end = new Date();
      break;

    case "2": // Bu hafta
      end = new Date();
      start = new Date();
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
      break;   

    case "3": // Bu ay
      end = new Date();
      start = new Date();
      start.setDate(1); // Ayın ilk günü
      break;
  
    default:
      // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
      break;
  }

  setStartDate(start);
  setEndDate(end);


};

const [isOpenDate, setIsOpenDate] = useState(true);

const onChange = dates => {

  const [start, end] = dates;

  if (selectedOption === "4") {
    setStartDate(start);
    setEndDate(end);

  }
  if (start && end) {
    setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
  }
};

const [selectedOption, setSelectedOption] = useState(null);


useEffect(() => {
  if (selectedOption === "4") {
    setIsOpenDate(true);
  } else {
    setIsOpenDate(false);
  }
}, [selectedOption]);

const handleChangeStore = (e)=> {
  setStore(e.target.value);
}

const onHeaderDeadLineInvoiceClick = () => {
  setAscInvoiceDetail((ascInvoiceDetail % 3) + 1);
};




  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      // data={orderList?.length>0 && orderList} 
      selection
      sortable
      pagination
      perPage={50}
    >
     
      <Card className="mb-3">
        <Card.Header>

        <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Sipariş Faturaları</h5>
            </div>


           
            <div style={{display:"flex", justifyContent:'right'}}>
            <div className="mb-3" style={{marginRight:"15px"}}>
                    <InputGroup>
                      <Form.Control  type="text" placeholder="id, isim, numarası..."  onChange={(e)=>setSearch(e.target.value)}/>
                      <InputGroup.Text><Search/></InputGroup.Text>
                    </InputGroup>
            </div>


              <Dropdown  show={isOpen} onToggle={handleToggle}>
                <Dropdown.Toggle
                  bsPrefix="toggle"
                  as={Link}
                  //  to=""
                  onClick={handleToggle}
                  className={classNames('px-0 nav-link')}>
            
                    <IconButton
                      variant="falcon-default"
                      size="m"
                      icon="fa-sharp fa-light fa-filter-list"
                      transform="shrink-3"
                      style={{width:'145px', fontSize:'15px'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                        <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
                      </svg>
                          <span className="d-none d-sm-inline-block ms-1">Filtre</span>
                    </IconButton>
                </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret" style={{minWidth: '300px', width: '300px'}}>
                  <Card className="shadow-none shadow-show-xl scrollbar">
                    <Card.Header className="bg-light d-none d-xl-block">
                      <h6 className="mb-0">Filtre</h6>
                    </Card.Header>
                      <Card.Body>
                        <Form>

                        { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                          <div className="mb-3" >
                          <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Satıcı</Form.Label>
                            { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                            <option value="" disabled>Satıcı</option>
                                { myDataVendor?.map((item, i)=>{
                                      return <option key={i} value={item.Id}>{item.vendor_name}</option>
                                })}       
                        </Form.Select>}
                    </div>
                    : "" }
                          <div className="mb-3">
                          <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Tarih Seçimi</Form.Label>

                          <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                          <option value="" disabled selected>Tarih</option>
                          <option key={1} value="1">Bugün</option>
                          <option key={2} value="2">Bu hafta</option>
                          <option key={2} value="3">Bu ay</option>
                          <option key={3} value="4">Tarih seç</option>
                                      </Form.Select> 

                        {selectedOption === "4" && 
                          <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            formatWeekDay={day => day.slice(0, 3)}
                            endDate={endDate}
                            selectsRange
                            dateFormat="MMM dd"
                            className='form-control'
                            placeholderText='tarih giriniz'
                            open={isOpenDate}
                            onCalendarClose={() => setIsOpenDate(false)}
                            onCalendarOpen={() => setIsOpenDate(true)}
                          />
                        }
                    </div>

                    <div >
                    <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Mağaza</Form.Label>
                            { vendorStore?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeStore}>
                            <option value="" >Mağaza</option>
                                { vendorStore?.map((item, i)=>{
                                      return <option key={i} value={item.Store__Name}>{item.Store__Name}</option>
                                })}       
                        </Form.Select>}
                     </div>
                    </Form>
                    </Card.Body>
                </Card>
                </Dropdown.Menu>
              </Dropdown>

              </div>
        </div>

    </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />


        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showOrderGif && <img src={gifOrder} style={{width:'50%'}} alt="loading gif" />}
          </div>
        
        </Card.Body>
        <Card.Footer>
          <Pagination pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage}/>
        </Card.Footer>
       
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}>Sipariş sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>

    </AdvanceTableWrapper>
  );
};

export default OrderInvoice;
