import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import { PermsContext } from 'context/PermsContext';



export default function useTopProducts() {

 const [myData, setMyData] = useState()
 const [showOrderGif, setShowOrderGif] = useState(false);

 const {myKey, currentUser} = useContext(AuthContext)
 const {vendorIdContext} = useContext(PermsContext)
 const getData = ()=>{

        var myHeaders = new Headers();

    
        const params = {
          vendor_id: vendorIdContext,
          // begin_date :"2024-05-01",
          // end_date : "2024-06-30"
        };
  
        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/vendor/${vendorIdContext}/best_seller_products/?`
        );

        myHeaders.append("Authorization", `Token ${myKey}`);

        console.log("request_url", request_url)

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };  
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result) 
            setShowOrderGif(false);
            console.log("result", result)
    })
          .catch(error => console.log('error', error));
 }

 useEffect(() => {
    getData();
  }, [vendorIdContext]);

 return {myData, showOrderGif}
}
