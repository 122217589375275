import React, { useState, useEffect } from 'react';
import { Card, Form, Button, FormCheck } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import useIntegrationUpdate from 'hooks/useIntegrationUpdate';

const UpdateIntegrationForm = () => {
  const location = useLocation();
  const { item } = location.state || {};
  const { updateData } = useIntegrationUpdate();

  const [formValues, setFormValues] = useState({});
  const [initialFormValues, setInitialFormValues] = useState({});

  useEffect(() => {
    const initialValues = {};
    
    [...item.Store.required_keys, ...item.Store.optional_keys, ...item.Store.private_required_keys, ...item.Store.private_optional_keys].forEach(keyObj => {
      const keyName = keyObj.name;
      const variable = [...item.required_variables, ...item.optional_variables, ...item.private_required_variables, ...item.private_optional_variables]
                        .find(v => Object.keys(v)[0] === keyName);
      initialValues[keyName] = variable ? variable[keyName] : '';
    });
  
    item.Store.radio_keys.forEach(keyObj => {
      const keyName = keyObj.name;
      const variable = item.radio_variables.find(v => Object.keys(v)[0] === keyName);
      initialValues[keyName] = variable ? variable[keyName].toString().toLowerCase() === "true" : false;
    });
  
    setFormValues(initialValues);
    setInitialFormValues(initialValues);
  }, [item]);
  
  

  const handleInputChange = (key, event, isSwitch = false) => {
    const newValue = isSwitch ? event.target.checked : event.target.value;
    setFormValues(prev => ({
      ...prev,
      [key]: newValue,
    }));
  };

  const renderFormInput = (keyObj) => {
    const isSwitch = item.Store.radio_keys.some(r => r.name === keyObj.name);
    return (
      <Form.Group controlId={`form${keyObj.name}`} key={keyObj.name} >
        <Form.Label style={{marginTop:"25px"}}>{keyObj.name}</Form.Label>
        {isSwitch ? (
          <FormCheck
            type="switch"
            label={keyObj.name}
            checked={!!formValues[keyObj.name]}
            onChange={(e) => handleInputChange(keyObj.name, e, true)}
            disabled={keyObj.readonly}
            
          />
        ) : (
          <Form.Control
            type="text"
            placeholder={keyObj.name}
            value={formValues[keyObj.name] || ''}
            onChange={(e) => handleInputChange(keyObj.name, e)}
            disabled={keyObj.readonly}
          />
        )}
      </Form.Group>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedVariables = Object.keys(formValues).reduce((acc, key) => {
      if (formValues[key] !== initialFormValues[key]) {
        acc.push({ key, value: formValues[key] });
      }
      return acc;
    }, []);
  
    if (updatedVariables.length > 0) {
      updateData(item.Id, { variables: updatedVariables });
    }
  };
  

  return (
    <>
      <Link to="/e-commerce/integration-available">
        <span style={{ color: "#5E6E82", fontWeight: "500", marginBottom: "5px", fontSize: "13px" }}>
          <BsArrowLeft /> &nbsp; Geri Dön
        </span>
      </Link>
      <Card className="mb-3 mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className="mb-3 mt-3" style={{ width: "95%" }}>
          <h6>{item?.Vendor.Name} - {item.Store.Name} Entegrasyonu Güncelleme</h6>
          <hr />
        </div>
        <Form onSubmit={handleSubmit} style={{ border: "0px solid gray", padding: "10px", borderRadius: "10px", width: "80%" }}>
          {[...item.Store.required_keys, ...item.Store.optional_keys, ...item.Store.private_required_keys, ...item.Store.private_optional_keys, ...item.Store.radio_keys].map(renderFormInput)}
          <Button variant="primary" type="submit" className="d-flex mx-auto mt-5 mb-2">
            Güncelle
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default UpdateIntegrationForm;
