import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import { toast } from 'react-toastify';
import useVendorList from './useVendorList';




export default function useInventoryExportS3
() {

  const [myDataS3, setMyDataS3] = useState()
  const {myKey, currentUser} = useContext(AuthContext)
  
  const [myDataResult, setMyDataResult] = useState()

  const [showGif, setShowGif] = useState(false);
 

  const [randomNumber, setRandomNumber] = useState()
  const [vendorId, setVendorId] = useState()
  const [isPreparing, setIsPreparing] = useState(false);

  const todayDay = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const timezoneOffset = todayDay .getTimezoneOffset(); 
  const [startDateModal, setStartDateModal] = useState(new Date(todayDay.getTime() - oneDay * 7));
  const [endDateModal, setEndDateModal] = useState(new Date(todayDay.getTime() + oneDay * 8));


  const [vendorIdExport, setVendorIdExport] = useState()
  const {myDataVendor} = useVendorList()

  useEffect(() => {
    if (myDataVendor?.length === 1) {
      setVendorIdExport(myDataVendor[0].Id);
    }
  }, [myDataVendor]);

  let formattedStartDate = "";
  let formattedEndDate = "";

  if(startDateModal){
    formattedStartDate = new Date(startDateModal.getTime()).toISOString().slice(0, 10);
  }
  if(endDateModal){
    formattedEndDate = new Date(endDateModal.getTime() + oneDay).toISOString().slice(0, 10);
  }



 const getDataExport = async ()=>{
      var myHeaders = new Headers();


      const params = {

        vendor_id: vendorIdExport,
        begin_date: formattedStartDate,
        end_date: formattedEndDate,

      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}wms/api/v1/inventory_shipment/export_inventory_list_excel_threading/?`
  );


    var requestOptions = {
      method: 'GET',
      headers: {"Authorization": "Token "+myKey},
      redirect: 'follow'
    };
    fetch(request_url, requestOptions)
      .then(response => response.json())
      .then(result =>{
        setMyDataS3(result) 
        setRandomNumber(result.random_number)
        setVendorId(result.vendor)
        setShowGif(false);

})
      .catch(error => console.log('error', error)); 
      toast.error(`Error: ${error}`);     

};


const getDataExportReturn = async () => {
  var myHeaders = new Headers();

  const params = {
    random_number: randomNumber,
    vendor_id: vendorId,
  };

  const request_url = Object.entries(params)
    .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
    .reduce(
      (url, [key, value]) => url + `&${key}=${value}`,
      `${BASE_URL}wms/api/v1/inventory_shipment/export_to_inventory_excel_variable/?`
    );

  var requestOptions = {
    method: 'GET',
    headers: { Authorization: "Token " + myKey },
    redirect: 'follow'
  };

  const interval = setInterval(async () => {
    try {
      const response = await fetch(request_url, requestOptions);
      const result = await response.json();

      if (response.status === 200) {
        setMyDataResult(result);
        setIsPreparing(false);
        clearInterval(interval); // İstek başarılı olunca interval'ı durdur
      }

    } catch (error) {
      console.log('error', error);
      toast.error(`Error: ${error}`);
    }
  }, 5000); // 5 saniyede bir istek at
};




useEffect(() => {
  let interval;
  if (randomNumber && vendorId) {
    interval = getDataExportReturn();
  }

  return () => {
    clearInterval(interval);
  };
}, [randomNumber, vendorId]);







 return {myDataS3,setMyDataS3, getDataExport, showGif, setShowGif, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorIdExport, setStartDateModal, setEndDateModal, startDateModal, endDateModal, }
}
