import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'


export default function useIntegrationMetaDataForm() {


  const {myKey} = useContext(AuthContext)

  const [metaData, setMetaData] = useState({});
  const [loading, setLoading] = useState(true);


 const getData = (id)=>{
        var myHeaders = new Headers();
   
        const params = {
         
        };
    
        const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}api/v1/metadata/${id}/metadata_to_form/?`
        );

            
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            console.log("meta", result)
            setMetaData(result) 
            setLoading(false)
    })
          .catch(error => console.log('error', error));        
 }





 return {metaData, loading, getData, setLoading}
 
  
  
  
}


