import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row, Table, Button,Form } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import useInventoryProductList from 'hooks/useInventoryProductList';
import useEcomStatStock from 'hooks/useEcomStatStock';
import WarehouseMap from './WarehouseMap';
import { PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import useInventoryMap from 'hooks/useInventoryMap';



const InventoryProductList = () => {


  const {myData, getData, setVendorId} = useInventoryProductList()
  const {setVendorIdMap} = useInventoryMap()
  const {myDataStock, setVendorIdStok} = useEcomStatStock()
  const {perms} = useContext(PermsContext)

const {myDataVendor} = useVendorList()

  function hasPermission(permission) {
    return perms?.includes(permission);
  }

  useEffect(() => {
    if (myDataVendor && myDataVendor.length > 0) {
      const firstVendorId = myDataVendor[0].Id;
      
      setVendorId(firstVendorId)
      setVendorIdMap(firstVendorId)
      setVendorIdStok(firstVendorId)
    }
  }, [myDataVendor]);

  const handleChangeVendor = (e)=> {
  
    setVendorId(e.target.value)
    setVendorIdMap(e.target.value)
    setVendorIdStok(e.target.value)
  }
  

  const stock = (myDataStock?.total_stock_value || 0).toLocaleString('tr-TR', {style: 'currency', currency: 'TRY'})


  const orderedProductsData = myData?.inventory_products.map((product, i) =>{
    return {
    id : i+1,
    name: product.region_name,
    product_type_count: product.product_type_count || 0,
    total_product_count : product.total_product_count || 0,
    available_product_count: product.available_product_count || 0,
    // total_stock_value : parseFloat(product.total_stock_value).toLocaleString('tr-TR', {style: 'currency', currency: 'TRY'})
 
  }});

 


  return (
    <>
     <div style={{display:"flex", justifyContent:"end"}}>
     { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                  <div style={{marginBottom:'10px'}}>
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                     <option value="" disabled>Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
            : "" }
            </div>
    <Card className="mb-3 mt-1">
      <div style={{display:'flex', justifyContent: 'space-between'}}>
      <div>
    <h6 style={{marginLeft:'18px', marginTop:'15px'}}>Depo Durumu</h6>
    </div>
    <div style={{marginRight:'22px', marginTop:'15px'}}>
      
      <h6 style={{marginLeft:'30px', marginTop:'15px'}}>Toplam Stok Değeri : {stock}</h6>
    </div>
    </div>




      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Depo Bölgesi</th>
                <th className="border-0 text-center">Mevcut Ürün Adedi</th>
                <th className="border-0 text-center">Mevcut Ürün Çeşidi</th>
                <th className="border-0 text-center">Toplam Ürün Çeşidi</th>
                {/* <th className="border-0 text-center">Stok Değeri</th> */}
              </tr>
            </thead>
            <tbody>
              {orderedProductsData?.length>0 && orderedProductsData?.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle">
                          {product.name}
                  </td>
                  <td className="align-middle text-center">
                  {product.total_product_count}

                  </td>
                  <td className="align-middle text-center">
                  {product.available_product_count}
                  </td>
                  <td className="align-middle text-center">
                    
                    {product.product_type_count}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
    <WarehouseMap/>
    </>
  );
};

InventoryProductList.propTypes = {};

export default InventoryProductList;
