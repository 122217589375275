import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import BasicECharts from 'components/common/BasicEChart';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import useWeeklySales from 'hooks/useWeeklySales';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {weeklySalesData} from 'data/dashboard/default'
import CountUp from 'react-countup';
import AppContext from 'context/Context';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);



  
const getOptions = (data, myData) => ({
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    formatter: '{b0} : {c0}',
    transitionDuration: 0,
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1
  },
  xAxis: {
    type: 'category',
    // data: myData?.map((item) => {
    //   const weekday = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar']
    // return weekday[(new Date(`${item.year}-${item.month}-${item.day}`).getDay() - 1 + 7) % 7]
    // }),

    data: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
    boundaryGap: false,
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  yAxis: {
    type: 'value',
    splitLine: { show: false },
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  series: [
    {
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        borderRadius: 10
      },
      barWidth: '5px',
      itemStyle: {
        borderRadius: 10,
        color: getColor('primary')
      },
      data,
      z: 10
    }
  ],
  grid: { right: 5, left: 10, top: 0, bottom: 0 }
});

const WeeklySales = ({ width, amountClassName}) => {

  const {myData, setMyData, loading, setLoading} = useWeeklySales()

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
 

//   const data = myData?.map((item) => {

//   return item.total
// }
// )

// myData?.map((item) => {
//     const weekday = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar']
//   return weekday[(new Date(`${item.year}-${item.month}-${item.day}`).getDay() - 1 + 7) % 7]
//   })


const data = [];
const weekdays = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];

for (let i = 0; i < 7; i++) {
  const filtered = myData?.find((item) => weekdays[(new Date(`${item.year}-${item.month}-${item.day}`).getDay() - 1 + 7) % 7] === weekdays[i]);
  if (filtered) {
    data.push(filtered.total) || [100, 85, 150];
  } else {
    data.push(0);
  }
}

// haftalık toplam satış tutarı TL cinsinden
const total = myData?.map((item) => item.total).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
const totalTl = total?.toLocaleString('tr-TR', {style: 'currency', currency: 'TRY', maximumFractionDigits: 2});


// myData dan dönen tarihe gün eklendi
const date = myData?.map((item) => {
  const weekday = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar']
return {
  date:  weekday[(new Date(`${item.year}-${item.month}-${item.day}`).getDay() - 1 + 7) % 7],
  total : item.total,
  day : item.day
}
}
)

let gün = myData?.map((item) => {
  const weekday = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar']
return weekday[(new Date(`${item.year}-${item.month}-${item.day}`).getDay() - 1 + 7) % 7]
})

  return (
   
    <Card className="h-md-100" style={{backgroundColor:isDark ? "#0B1727" : "#F6FAFF"}}>
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2" style={{color: isDark ? "#D8E2EF":"#025098"}}>
          Haftalık Satış
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>İçinde bulunduğunuz haftanın toplam satışı</Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>
        </h6>
      </Card.Header>
      

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <div>
        <div style={{ filter: loading ? 'blur(3px)' : 'none' }}>
        {loading ? 
        
        <h5>
          <CountUp
          start={0}
          end={34765264}
          duration={6}
          className="text-700"
          // separator=","
        />
          
          </h5> 
        
        
        
        : 
          <h2
            className={classNames(
              amountClassName,
              'mb-1 text-700 fw-normal lh-1'
            )}
            style={{fontSize:"25px"}}
          >
          {/* $47K */}
          {totalTl}
          </h2>
                }
                </div>
          <br />
          {/* <SoftBadge pill bg="success" className="me-2 fs--2">
           +3.5% 
          </SoftBadge> */}
  
        </div>

        <div style={{ filter: loading ? 'blur(5px)' : 'none' }}>
        {loading ? 
        
        <BasicECharts
          echarts={echarts}
          options={getOptions(data, myData)}
          style={{ width: width || '8.5rem', height: 60 }}
        /> :  
        <BasicECharts
          echarts={echarts}
          options={getOptions(data, myData)}
          style={{ width: width || '8.5rem', height: 60 }}
        />
      }
      </div>
      </Card.Body>
  
    </Card>
  );
};

WeeklySales.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.string,
  amountClassName: PropTypes.string
};

export default WeeklySales;
