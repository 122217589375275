import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import useBarcodeSku from './useBarcodeSku';
import {BASE_URL} from './baseUrl'
import { PermsContext } from 'context/PermsContext';
import { endWith } from 'is_js';


export default function useProductDetail() {



 const [myData, setMyData] = useState()
 const [myDetail, setMyDetail] = useState()

 const {vendorIdContext} = useContext(PermsContext)
 const {myKey, currentUser} = useContext(AuthContext)







 const getData = (barcode)=>{
        var myHeaders = new Headers();

        let vendor_id;
        let request_url;
        if(vendor_id && barcode)
                request_url = BASE_URL+"api/v1/order/product_list/?vendor_id="+vendor_id+"&barcode="+barcode
        else if(vendor_id)
            request_url = BASE_URL+"api/v1/order/product_list/?vendor_id="+vendor_id
        else if(barcode)
            request_url = BASE_URL+"api/v1/order/product_list/?vendor_username="+currentUser+"&barcode="+barcode
        else
            request_url = BASE_URL+"api/v1/order/product_list/?vendor_username="+currentUser
    



        console.log("url", request_url)    

        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result) 
    })
          .catch(error => console.log('error', error));        
 }


 const date = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const twoDay = 1000 * 60 * 60 * 24 *2;
  const [startDate, setStartDate] = useState(new Date(date.getTime() - oneDay * 15));
  const [endDate, setEndDate] = useState(new Date(date.getTime() + oneDay * 2));
  
  let formattedStartDate = "";
  let formattedEndDate = "";
  
  if(startDate){
    formattedStartDate = new Date(startDate.getTime()  + oneDay).toISOString().slice(0, 10);
  }
  if(endDate){
    formattedEndDate = new Date(endDate.getTime() + twoDay).toISOString().slice(0, 10);
  }
  

  const [sku, setSku] = useState()
  const [vendorId, setVendorId] = useState()





 const getDetail = ()=>{
  var myHeaders = new Headers();

  if (!sku) return;


    const params = {
      sku: sku,
      begin_date: formattedStartDate,
      end_date: formattedEndDate,
    };
  
      
    const request_url = Object.entries(params)
        .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
        .reduce(
          (url, [key, value]) => url + `&${key}=${value}`,
          `${BASE_URL}api/v1/order/daily_sales_by_pickings/?`
    );


console.log("request_url",request_url)
 
  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMyDetail(result) 
})
    .catch(error => console.log('error', error));        
}

 useEffect(() => {
  setVendorId(vendorIdContext);

  }, []);



 useEffect(() => {
  getDetail();

  }, [startDate, endDate, sku, vendorId]);

 return {myData, getData, setMyDetail, myDetail, getDetail, setStartDate, startDate, 
  setEndDate,endDate, sku, setSku, setVendorId}
}
