import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import { DateContext } from 'context/DateContext';
import { PermsContext } from 'context/PermsContext';
import { addDays, format } from 'date-fns';



export default function useStoreSalesReport() {

 const [myData, setMyData] = useState()
 const {myKey, currentUser} = useContext(AuthContext)
 const [loading, setLoading] = useState(true);
 const {vendorIdContext} = useContext(PermsContext)

 const today = new Date();
 const oneWeekAgo = addDays(today, -7);

 const formattedToday = format(today, 'yyyy-MM-dd');
 const formattedOneWeekAgo = format(oneWeekAgo, 'yyyy-MM-dd');

 const [startDate, setStartDate] = useState(formattedOneWeekAgo);
 const [endDate, setEndDate] = useState(formattedToday);




 const getData = ()=>{

        var myHeaders = new Headers();
      

          const params = {
            begin_date: startDate,
            end_date: endDate,
            vendor_id : vendorIdContext,
          };
    
          const request_url = Object.entries(params)
              .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
              .reduce(
                (url, [key, value]) => url + `&${key}=${value}`,
                `${BASE_URL}api/v1/order/store_sales/?`
      );


        myHeaders.append("Authorization", `Token ${myKey}`);    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result)
            setLoading(false);  
    })
          .catch(error => console.log('error', error));
 }
 useEffect(() => {
    getData();
  }, [startDate, endDate, vendorIdContext ]);

 return {myData, setStartDate, setEndDate}
}


