import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function useCampaingAdd() {

const {myKey, currentUser} = useContext(AuthContext)
const navigate = useNavigate();

const [formData, setFormData] = useState({
        Name: '',
        Type: '',
        DiscountType: '',
        DiscountAmount:'',
        StartDate :'',
        EndDate :''
    });


 

 const postData = ()=>{

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL+"api/v1/analysis/campaing/"
    
    
    const date = new Date(formData.StartDate);
    const timezoneOffset = date.getTimezoneOffset(); // dakika cinsinden yerel saat dilimi farkı
    const localDate = new Date(date.getTime() - timezoneOffset * 60 * 1000); // yerel saat dilimine göre tarih
    const isoDate = localDate.toISOString().slice(0,10);
 

    const endDate = new Date(formData.EndDate);
    const timezoneOffsetEnd = endDate.getTimezoneOffset(); // dakika cinsinden yerel saat dilimi farkı
    const localDateEnd = new Date(endDate.getTime() - timezoneOffsetEnd * 60 * 1000); // yerel saat dilimine göre tarih
    const isoEndDate = localDateEnd.toISOString().slice(0,10);
    

    var raw = JSON.stringify({
      "Name": formData.Name,
      "Type": "1",
      "DiscountType": "0",
      "DiscountAmount": Number(formData.DiscountAmount),
      "StartDate": isoDate,
      "EndDate": isoEndDate,
      "VendorStore": formData.VendorStore
      
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
  .then(response => {
    if (response.ok) {
        response.text()
        .then(result => {
            console.log(result)
            toast.success(`başarılı şekilde gönderildi.`, { theme: 'colored'});
            navigate("/dashboard/analytics/campaing/list")
        })
    } else {
        console.log("Post işlemi başarısız.")
    }
  })
  .catch(error => console.log('error', error));

}

useEffect(() => {
 if(formData.Name){
  postData()
 }

}, [formData])


 

 return {setFormData, formData, postData}
}
