import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'


export default function useUserChargeBy() {


 const [myDataUser, setMyDataUser] = useState()
 const {myKey, currentUser} = useContext(AuthContext)
 
 const [userVendorId, setUserVendorId] = useState()

const getDataUser = ()=>{
  var myHeaders = new Headers();

  if (!userVendorId) return;
 
      const params = {

        vendor_id :userVendorId,

      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/list_charged_by/?`
      );




  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMyDataUser(result) 
})
    .catch(error => console.log('error', error));        
}




 useEffect(() => {
 
  getDataUser();
  }, [userVendorId ]);

 return {myDataUser, setUserVendorId}
}