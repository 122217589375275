import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row, Table, Button } from 'react-bootstrap';
// import { orderedProductsData } from 'data/ecommerce/OrderDetailsData';
import { getPercentage } from 'helpers/utils';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import useProductDetail from 'hooks/useProductDetail';
import useProductInventory from 'hooks/useProductInventory';
import VendorStoreProductDetail from '../product-list/product-details-all/VendorStoreProductDetail';
import ProductTotalSales from '../product-list/product-details-all/totalsales/ProductTotalSales';
import SubProductList from '../product-list/product-details-all/SubProductList';
import ProductActivity from '../product-list/product-details-all/product-activity/ProductActivity';
import ProductInventoyChart from '../product-list/product-details-all/inventorytotal-chart/ProductInventoyChart';

const ProductInventoryDetail = () => {


  const {myData, getData} = useProductInventory()

  const location = useLocation()
  const {barcode} = location.state || ""
  const {sku} = location.state || ""

  console.log("SKU", sku)

  useEffect(() => {
    if (sku) {
      getData(sku, null);  
    } else {
      getData(null, barcode);  
    }
  
  }, [barcode,orderedProductsData, sku ])


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const barcode = params.get('barcode');
    const sku = params.get('sku');
    const id = params.get('id');



    if (sku) {
      getData(sku, null);  
    } else {
      getData(null, barcode);  
    }
  }, [barcode,orderedProductsData, sku ]);


const mappedData = myData?.product_list?.flatMap((product, i) => {
  return product?.inventory_products?.map(inventory_product => {
    return {
      id: i + 1,
      name: inventory_product.Name,
      stock: inventory_product.Count,
      barcode: inventory_product.Barcode,
      region: inventory_product.Inventory.Region,
      image: product?.Image,
      sku : inventory_product.SKU,
      skt : inventory_product.ExpirationDate || "-",
      lot : inventory_product.Lot || "-",

    };
  });
});


const reducedData = mappedData?.reduce((acc, curr) => {
  const uniqueKey = `${curr.region}_${curr.barcode}_${curr.lot || 'nolot'}_${curr.skt || 'noskt'}`;

  const existingProductIndex = acc.findIndex(item => 
    `${item.region}_${item.barcode}_${item.lot || 'nolot'}_${item.skt || 'noskt'}` === uniqueKey
  );

  if (existingProductIndex > -1) {
    acc[existingProductIndex].stock += curr.stock;
  } else {
    acc.push(curr);
  }

  return acc;
}, []);


const orderedProductsData = reducedData ;


 
const subtotal = orderedProductsData?.reduce(
  (acc, curr) => acc + curr.stock,
  0
);

const navigate = useNavigate();

const handleBackClick = () => {
    navigate('/e-commerce/product/product-inventory');
};
 


  return (
    <>
    {/* <Link to="/e-commerce/product/product-all"> */}
      <span onClick={handleBackClick} style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      {/* </Link> */}
      {/* <InventoryProductList/> */}
    <Card className="mb-3 mt-1">
   
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Ürün</th>
                <th className="border-0">İsim</th>
                <th className="border-0 text-center">Barkod-SKU</th>
                <th className="border-0 text-center">SKT-LOT</th>
                <th className="border-0 text-end">Depo</th>
                <th className="border-0 text-end">Stok</th>
              </tr>
            </thead>
            <tbody>
            {orderedProductsData?.length > 0 && orderedProductsData
                    .filter(product => product.stock > 0) // stock değeri 0'dan büyük olanları filtrele
                    .map(product => (
                      <tr key={product.id} className="border-200">
                        <td className="align-middle">
                          <img style={{width:"40px"}} src={product.image} alt="" />
                        </td>
                        <td className="align-middle"
                          title={product.name}
                          onMouseOver={(e) => (e.currentTarget.title = product?.name)}
                        >
                          {product?.name.length > 40
                            ? product.name.slice(0, 40).concat("...")
                            : product.name}
                        </td>
                        <td className="align-middle text-center">
                          {product.barcode} - {product.sku}
                        </td>
                        <td className="align-middle text-center">
                          {product.skt} - {product.lot}
                        </td>
                        <td className="align-middle text-end">
                          {product.region}
                        </td>
                        <td className="align-middle text-end">
                          {product.stock || 0}
                        </td>
                      </tr>
                  ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-end">
                <tbody>
                  <tr>
                    {/* <th className="text-900">Tax 5%:</th> */}
                    <td className="fw-semi-bold">
                
                    </td>
                  </tr>
                  <tr className="border-top">
                    <th className="text-900">Toplam:</th>
                    <td className="fw-semi-bold">
                      {subtotal}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>

    <ProductActivity/>

    


      <div style={{marginBottom:'15px'}}>
        <ProductInventoyChart/>
      </div>
 
    <ProductTotalSales/>



    <div  style={{marginTop:'15px'}}>
    <VendorStoreProductDetail/>
    </div>

    <div  style={{marginTop:'15px'}}>
    <SubProductList/>
    </div>
    </>
  );
};

ProductInventoryDetail.propTypes = {};

export default ProductInventoryDetail;
