import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, InputGroup, Row, Col, Alert } from 'react-bootstrap';
import { orderList } from 'data/ecommerce/orderList';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
// import OrdersTableHeader from './OrdersTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import useProductListAll from 'hooks/useProductListAll';
import IconButton from 'components/common/IconButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useProductExport from 'hooks/useProductExport';
import Accordion from 'react-bootstrap/Accordion';
import { Search } from 'react-bootstrap-icons';
import { useProductUpload } from 'hooks/useProductUpload';
import { useRef } from 'react';
import useProductExampleExport from 'hooks/useProductExampleExport';
import useProductExportS3 from 'hooks/useProductExportS3';
import { useNavigate } from "react-router-dom";
import { PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import useBarcodeSku from 'hooks/useBarcodeSku';
import gif from 'assets/img/gif_export_s3.gif'
import useInventoryProductAll from 'hooks/useInventoryProductAll';
import useInventoryProductExportS3 from 'hooks/useInventoryProductExportS3';
import useInventoryListFilter from 'hooks/useInventoryListFilter';
import { TableHeader } from 'semantic-ui-react';
import PaginationStock from './PaginationStock';
import useDailySkuFees from 'hooks/useDailySkuFees';
import TotalStockSKU from '../stock-detail-chart/TotalStockSKU';
import useTotalStockSKU from 'hooks/useTotalStockSKU';


const StockDetailTable = ({myData, setCurrentPage, followersPerPage, setFollowersPerPage, totalPages }) => {

  const columns = [
   
    {
      accessor: 'status',
      disableSortBy: true,
      Header: 'SKU',
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left fs-0'
      },
      Cell: rowData => {
        const { sku } = rowData.row.original;
        return (
          <>
         <p style={{ fontSize: "15px" }} >{ sku }</p>
        
          </>
        )
      }
    },
    
    {
      accessor: 'date',
      Header: 'Tarih',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fs-0'
      },
      Cell: rowData => {
        const { date } = rowData.row.original;
        return (
          <>
      
         <p style={{ fontSize: "15px" }} >{ date }</p>
          </>
        )
      }
    },

    { accessor: 'valume',
      Header: 'Ürün Desisi',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.product_volume}`
    },
    {
      accessor: 'total_volume',
      Header: 'Toplam Desi',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.total_volume}`
    },
    {
      accessor: 'fees',
      Header: 'Maliyeti',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.fees}`
    }
  ];

// const { setSearch, currentPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages} = useTotalStockSKU()

const {perms} = useContext(PermsContext)





function hasPermission(permission) {
  return perms?.includes(permission);
}



const [activePage, setActivePage] = useState(1)


console.log("MayData", myData)


const orderList = myData?.map((item)=>{

  return {

    stock : item.EndOfDayCount,
    sku : item.SKU,
    date : item.Date,
    product_volume : item.ProductVolume,
    total_volume : item.EndOfDayVolume,
    fees : item.InventoryStorageFee

  }
}) || []






  return (


<>
    
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      selection
      sortable
      pagination
      perPage={50}
      
    >
      <Card className="mb-3">
        <Card.Header>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div>
              <TableHeader table />
          </div>
            <div style={{display:"flex", justifyContent:'right'}}>
                
            </div>
        </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
           
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <PaginationStock pages={totalPages} activePage={activePage} setActivePage={setActivePage} setCurrentPage={setCurrentPage}/>
        </Card.Footer>
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}>Ürün sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>

   
    </AdvanceTableWrapper>

    </>
  );
};

export default StockDetailTable;