import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table, Modal, Carousel } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdOutlineCancelPresentation } from "react-icons/md";

const OrderedProducts = ({detailData}) => {

  const location = useLocation()
  const {id} = location.state || ""

  const [showModal, setShowModal] = useState({});

  const handleCloseModal = (productId) => {
    setShowModal(prevState => ({ ...prevState, [productId]: false }));
  };

  const handleOpenModal = (productId) => {
    setShowModal(prevState => ({ ...prevState, [productId]: true }));
  };
  
  
const orderedProductsData = detailData?.claims?.map((product, i) => ({
  id : i+1,
  name: product.SubjectOrderProducts[0].Name || "",
  quantity: product.SubjectOrderProducts[0].Count || "",
  rate : product.SubjectOrderProducts[0].BasePrice || "",
  image: product.SubjectOrderProducts[0].image || "",
  note : product.CustomerNote || "-",
  reason : product.Reason ?   product.Reason.Name : product.type, 
  refund_image : product.documents[0]?.Url
  


  }));


  const subtotal = orderedProductsData?.length>0 && orderedProductsData?.reduce(
    (acc, curr) => curr.quantity * curr.rate + acc,
    0
  );
 


  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
          <Table striped className="border-bottom">
            <thead className="bg-200 text-900">
              <tr>
              <th className="column-urun border-0">Ürün</th>
              <th className="column-isim border-0">İsim</th>
              <th className="column-adet border-0 text-center">Adet</th>
              <th className="column-not border-0 text-center">Not</th>
              <th className="column-sebep border-0 text-center">İade Sebebi</th>
              <th className="column-picture border-0 text-end">Resim</th>
              <th className="column-tutar border-0 text-end">Tutar</th>
              <th className="column-onay border-0 text-end">Onay</th>
              </tr>
            </thead>
            <tbody>
              {orderedProductsData?.length>0 && orderedProductsData?.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle">
                    <img style={{width:"40px"}}src={product.image} alt="" />
                 
                  </td>
                  <td className="align-middle" title={product.name}
                  onMouseOver={(e) => (e.currentTarget.title = product.name)}>
                 <h6 className="mb-0 text-nowrap">
                  {product.name.length > 40
                      ? product.name.slice(0, 40).concat("...")
                      : product.name}
                    </h6>
                  </td>
                  <td className="align-middle text-center">
                    {product.quantity}
                  </td>

                  <td className="align-middle text-center">
                    {product.note}
                  </td>

                  <td className="align-middle text-center">
                    {product.reason}
                  </td>
                  <td className="align-middle text-center" style={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}>
                  <div style={{ position: 'relative', height: '80px' }}>
                                {product.refund_image ? (
                                    <div
                                        key={product.id}
                                        style={{
                                            position: 'absolute',
                                        }}
                                        onClick={() => handleOpenModal(product.id)}
                                    >
                                        <img
                                            style={{
                                                border: '1px solid gray',
                                                borderRadius: '5px',
                                                width: '100px',
                                                height: '60px'
                                            }}
                                            src={product.refund_image}
                                            alt=""
                                        />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: '100px',
                                            height: '60px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            // border: '1px solid gray',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        Fotoğraf yüklenmemiştir
                                    </div>
                                )}
                            </div>
                            {product.refund_image && (
                                <Modal show={showModal[product?.id]} onHide={() => handleCloseModal(product?.id)} centered>
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Body>
                                        <Carousel>
                                            <Carousel.Item >
                                                <img
                                                    src={product.refund_image}
                                                    alt=""
                                                    style={{ width: '100%', height: 'auto' }}
                                                />
                                            </Carousel.Item>
                                        </Carousel>
                                    </Modal.Body>
                                </Modal>
                            )}
                        </td>
                  <td className="align-middle text-end">
                    
                    {parseFloat(product.quantity * product.rate).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    )}


                  </td>

                  <td className="align-middle text-end">
                    <IoMdCheckboxOutline size={25} style={{ margin: '10px' }}  />
                    <MdOutlineCancelPresentation size={25} />
                  </td>
                </tr>
                
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
              <Table borderless size="sm" className="fs--1 text-center">
                <tbody>
                  <tr>
                    <th className="text-900">Ara toplam:</th>
                    <td className="fw-semi-bold">
                      
                      {parseFloat(subtotal).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                  <tr>
                    {/* <th className="text-900">Tax 5%:</th> */}
                    <td className="fw-semi-bold">
                      {/* $
                      {parseFloat(tax).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })} */}
                    </td>
                  </tr>
                  <tr className="border-top">
                    <th className="text-900">Toplam:</th>
                    <td className="fw-semi-bold">
                
                      {parseFloat(subtotal).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
       
      </Card.Body>
    </Card>
  );
};

OrderedProducts.propTypes = {};

export default OrderedProducts;
