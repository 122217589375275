import React, { useContext, useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderInfo from './OrderInfo';
import OrderedProducts from './OrderedProducts';
import SoftBadge from 'components/common/SoftBadge';
import useOrderList from 'hooks/useOrderList';
import { useLocation, Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import './ProgressStyle.css'

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Button } from 'react-bootstrap';
import useOrderCancel from 'hooks/useOrderCancel';
import { PermsContext } from 'context/PermsContext';
import useOrderWorkPost from 'hooks/useOrderWorkPost';

const OrderDetails = () => {

  const location = useLocation()
  const {id, activePage } = location.state || {};


  const {detail, getDetail,  getDetailWork, detailWork} = useOrderList()
  const {getCancelData}  = useOrderCancel()
 
  const {perms} = useContext(PermsContext)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {getOrderWork}= useOrderWorkPost()


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const activePage = params.get('activePage');

    if (id && activePage) {
      getDetail(id)
      getDetailWork(id)
    }
  }, []);

 
  const handleCancel = () => {
    getCancelData(id)
  }


useEffect(() => {
  if(id){
  getDetail(id)
  getDetailWork(id)

  }
 
}, [])




const handleWork = () => {
  getOrderWork(id); 
};

function hasPermission(permission) {
  return perms?.includes(permission);
}





const status = detail?.map((item)=>{
  const orderDate = new Date(item.OrderDate);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const OrderStatus = ["", 20, 40, 60, 80, "", "" ,100, ""]
  
  const OrderStatuses = ["", 'Beklemede', 'Hazırlanıyor', 'Paketlendi', 'Kargoya verildi', 'İade', 'İptal', 'Teslim Edildi', 'Teslim edilemedi']
  const hub_date_ready = new Date(item.ChargedDate)
  const hub_date_packet = new Date(item.WaybillPrintDate)
  const shippmentDate = new Date(item.OrderShipmentDate)
  const deliveryDate = new Date(item.DeliverResultDate)

  return {
    hub_date_packet : hub_date_packet.toLocaleDateString('tr-TR', options),
    hub_date_ready : hub_date_ready.toLocaleDateString('tr-TR', options),
    order_Date : orderDate.toLocaleDateString('tr-TR', options),
    order_status_bar : OrderStatus[item.Status],
    shippment_date : shippmentDate.toLocaleDateString('tr-TR', options),
    charged_Date : item.ChargedDate || "" ,
    waybil_date :item.WaybillPrintDate || "",
    shippmentDate : item.OrderShipmentDate || "", 
    statusText: OrderStatuses[item.Status],
    status: item.Status,
    deliveryDate : item.DeliverResultDate || "",
    delivery_date : deliveryDate.toLocaleDateString('tr-TR', options) || "",
    order_number : item.OrderNumber || "--",
    store : item.VendorStore.Store.Name,
    region : item.InventoryRegion?.Name

  }
})




return (
    <>
    <div style={{display:'flex', justifyContent:'space-between', marginBottom:'10px'}} >
      
    <Link to="/e-commerce/orders/order-list" state={{ activePage }}>

      <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> 
      </svg> &nbsp; 
      Geri Dön
      </span>
      </Link>
{/* 
{hasPermission('Sipariş İş Emri Dashboard')&& detailWork?.length > 0 &&(
        <Button 
        onClick={handleWork}
        >
          İş emri talebi oluştur
        </Button>
      )} */}

    </div>      
      <PageHeader  titleTag="h5" className="mb-3 mt-1" handleShow={handleShow} id={id}>
            <h5 style={{marrginTop:'-200px', marginBottom:'10px'}}>Siparış Detayları :{id}</h5>
        <p className="fs--1 mt-1"> Sipariş Tarihi : {status?.length >0 && status[0]?.order_Date }</p>
        <p className="fs--1 mt-1"> Sipariş Numarası : {status?.length >0 && status[0]?.order_number }</p>
        <p className="fs--1 mt-1"> Mağaza : {status?.length >0 && status[0]?.store }</p>
        <p className="fs--1 mt-1"> Çıkış Deposu : {status?.length >0 && status[0]?.region}</p>
        <div>
          <strong className="me-2">Durumu: </strong>
          <SoftBadge pill bg="success" className="fs--2">
            {' '}
            {status?.length >0 && status[0]?.statusText }
            {/* <FontAwesomeIcon
              icon="check"
              className="ms-1"
              transform="shrink-2"
            /> */}
          </SoftBadge>          
        </div>
        <div style={{margin:"50px", marginLeft:"90px", width:"88%"}}>

<ProgressBar percent={status?.length >0 && status[0]?.order_status_bar} >
  <Step>
    {({ accomplished, index }) => (
      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
        style={{marginTop:'45px'}}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-stop-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z"/>
</svg>
      </div>

      <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', fontWeight:'bold'}}>Beklemede</p>
        <p style={{fontSize:'10px', marginTop:'0px'}}>{status?.length >0 && status[0]?.order_Date }</p>
        
      </div>

      </div>
    )}
  </Step>

  <Step>
    {({ accomplished, index }) => (
      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
        style={{marginTop:'45px'}}
      >
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-cart4" viewBox="0 0 16 16">
  <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
</svg>
      </div>

        <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', fontWeight:'bold'}}>Hazırlanıyor</p>
        <p style={{fontSize:'10px', marginTop:'0px'}}>{ 
        status?.length > 0 && (status[0]?.status === "2" || status[0]?.status === "3" || status[0]?.status === "4") && !status[0]?.charged_Date ? '---' : (status?.length > 0 && status[0]?.charged_Date ? status[0]?.hub_date_ready : 'Hazırlanıyor')}</p>
        </div>


      </div>
    )}
  </Step>
  <Step>
    {({ accomplished, index }) => (

      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
        style={{marginTop:'45px'}}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-boxes" viewBox="0 0 16 16">
  <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z"/>
</svg>
      </div>
      <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', fontWeight:'bold'}}>Paketlendi</p>
        <p style={{fontSize:'10px', marginTop:'0px'}}>{ 
        status?.length > 0 && (status[0]?.status === "3" || status[0]?.status === "4") && !status[0]?.waybil_date ? '---' : (status?.length > 0 && status[0]?.waybil_date ? status[0]?.hub_date_packet : 'henüz paketlenmedi')}</p>
     
        </div>
      </div>
    )}
  </Step>

  <Step>
    {({ accomplished, index }) => (

      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
        style={{marginTop:'45px'}}
      >
         <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-truck" viewBox="0 0 16 16">
  <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
</svg>
      </div>

      <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', width:'90px', fontWeight:'bold'}}>Kargoya Verildi</p>
        <p style={{fontSize:'10px', marginTop:'0px', width:'120px'}}>{status?.length > 0 && status[0]?.status === "4" && !status[0]?.shippmentDate ? '---' : (status?.length > 0 && status[0]?.shippmentDate ? status[0]?.shippment_date : 'henüz kargoya verilmedi')}</p>
        </div>
      </div>
    )}
  </Step>

  <Step>
    {({ accomplished, index }) => (

      <div>
      <div
        className={`indexedStep ${accomplished ? "accomplished" : null}`}
        style={{marginTop:'45px'}}
      >
         <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-house-check" viewBox="0 0 16 16">
  <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.708L8 2.207l-5 5V13.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 2 13.5V8.207l-.646.647a.5.5 0 1 1-.708-.708L7.293 1.5Z"/>
  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.707l.547.547 1.17-1.951a.5.5 0 1 1 .858.514Z"/>
</svg>
      </div>

      <div>
        <p style={{fontSize:'10px', paddingTop:'5px', marginBottom:'5px', width:'90px', fontWeight:'bold'}}>Teslim Edildi</p>
        <p style={{fontSize:'10px', marginTop:'0px', width:'120px'}}>{status?.length > 0 && status[0]?.status === "7" && !status[0]?.deliveryDate ? '---' : (status?.length > 0 && status[0]?.deliveryDate ? status[0]?.delivery_date : 'henüz teslim edilmedi')}</p>
        </div>
      </div>
    )}
  </Step>





</ProgressBar>

</div>
      </PageHeader>
      <OrderInfo />
      <OrderedProducts />
      {/* {hasPermission('Sipariş Durumu: İptal Etme') && (
      <Button variant="danger" onClick={handleShow}>
        Siparişi İptal Et
      </Button>
      )} */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bilgilendirme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          İptal etmek istediğinize emin misiniz?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Hayır
          </Button>
          <Button variant="danger" onClick={handleCancel}>
            İptal Et
          </Button>
        </Modal.Footer>
      </Modal>
     
    </>
  );
};

export default OrderDetails;
