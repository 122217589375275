import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import AppContext from 'context/Context';

const NavbarVerticalMenuItem = ({ route }) => {

  const {
    config: {isDark },

  } = useContext(AppContext);

  const [hover, setHover] = useState(false);

  const hoverStyle = {
    color: hover ? '#5F9BEB' : (isDark ? '#C5D4EC' : '#022767'),
    fontWeight:"bold",
    marginTop : "7px",
    fontSize :"14px"
  };



  return (
    <Flex alignItems="center">
      {route.icon && (
        <span className="nav-link-icon"
        style={hoverStyle}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        >
          <FontAwesomeIcon icon={route.icon} />
        </span>
      )}
        
      <span className="nav-link-text ps-1" 
      style={hoverStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
     
      > {route.name2}</span>
      
    </Flex>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired
};

export default React.memo(NavbarVerticalMenuItem);
