import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Row } from 'react-bootstrap';
import classNames from 'classnames';
import Background from 'components/common/Background';
import SoftBadge from 'components/common/SoftBadge';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import AppContext from 'context/Context';

const StatisticsCard = ({ stat, ...rest }) => {
  const {
    title,
    value,
    count,
    decimal,
    // suffix,
    prefix,
    valueClassName,
    linkText,
    link,
    badgeText,
    badgeBg,
    image,
    className
  } = stat;

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  return (
    <Card className="h-md-100" style={{backgroundColor:isDark ? "#0B1727" : "#F6FAFF"}}>
      {/* <Background image={image} /> */}
      <Card.Body >
        <h6 className="mb-5" style={{color: isDark ? "#D8E2EF":"#025098"}}>
          {title}
        </h6>
      
  
        <h2 className="fw-normal text-700 mb-1 lh-1" 
            style={{fontSize:"25px", color: isDark ? "#D8E2EF":"#5E6E82"}}
          >
          {/* $47K */}
          {value} / {count}
          </h2>
     
      </Card.Body>
    </Card>
  );
};

// StatisticsCard.propTypes = {
//   stat: PropTypes.shape({
//     title: PropTypes.string.isRequired,
//     value: PropTypes.number.isRequired,
//     decimal: PropTypes.bool,
//     suffix: PropTypes.string.isRequired,
//     prefix: PropTypes.string.isRequired,
//     valueClassName: PropTypes.string,
//     linkText: PropTypes.string,
//     link: PropTypes.string,
//     badgeText: PropTypes.string,
//     badgeBg: PropTypes.string,
//     image: PropTypes.string,
//     className: PropTypes.string
//   })
// };

export default StatisticsCard;
