import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { PermsContext } from 'context/PermsContext';
import useInventoryRegion from './useInventoryRegion';
import { addDays, format } from 'date-fns';


export default function useTotalStock() {

 const [myData, setMyData] = useState()

 const {myKey, currentUser} = useContext(AuthContext)
 const {inventoryContext} = useContext(PermsContext)

 const [inventoryId, setInventoryId] = useState()

 const [loading, setLoading] = useState(true);

 const {regionData} = useInventoryRegion()

 const today = new Date();
 const oneWeekAgo = addDays(today, -7);

 const formattedToday = format(today, 'yyyy-MM-dd');
 const formattedOneWeekAgo = format(oneWeekAgo, 'yyyy-MM-dd');

 const [startDate, setStartDate] = useState(formattedOneWeekAgo);
 const [endDate, setEndDate] = useState(formattedToday);


  useEffect(() => {
    if (regionData?.length > 0) {
      setInventoryId(regionData[0].Id);
    }
  }, [regionData]);



 const getData = ()=>{

        var myHeaders = new Headers();
        if (!inventoryContext) return


          const params = {
            // vendor_id: vendorIdContext
            begin_date : startDate,
            end_date   : endDate

          };
    
          const request_url = Object.entries(params)
              .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
              .reduce(
                (url, [key, value]) => url + `&${key}=${value}`,
                `${BASE_URL}wms/api/v1/inventory/${inventoryContext}/daily_fees/?`
          );
        
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result.results)
            setLoading(false) 
    })
          .catch(error => console.log('error', error));
        
 }










 

 useEffect(() => {
    getData();
  }, [inventoryContext, endDate, startDate]);

 return {myData, loading, setLoading, setInventoryId, setStartDate, setEndDate, inventoryId}
}
