import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import  Form  from '@rjsf/bootstrap-4';
import { Link } from 'react-router-dom';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Spinner, Card } from 'react-bootstrap';
import { BsArrowLeft } from "react-icons/bs";
import useIntegrationStoreSchemeForm from 'hooks/useIntegrationStoreSchemeForm';
import useIntegrationCreate from 'hooks/useIntegrationCreate';
import '../style.css'
import { MdAdd, MdDelete } from "react-icons/md";


const ArrayFieldTemplate = (props) => {
  return (
    <div className="array-field-template">
      {props.items.map((element) => (
        <div 
          key={element.index} 
          className="d-flex align-items-center mb-2"
        >
          <div style={{ flex: 1 }}>
            {element.children} {/* Her array elemanının inputu */}
          </div>
          {element.hasRemove && (
            <button 
              type="button" 
              className="btn btn-danger btn-sm ms-2" 
              style={{ alignSelf: 'center', marginTop: '25px' }} // Dikey hizalama ayarı
              onClick={element.onDropIndexClick(element.index)}
            >
              <MdDelete />
            </button>
          )}
        </div>
      ))}
       {props.canAdd && (
        <div className="d-flex justify-content-end mt-2">
          <button 
            type="button" 
            className="btn btn-primary btn-sm" 
            style={{ width: '40px' }} // Genişlik ayarı
            onClick={props.onAddClick}
          >
          <MdAdd />
          </button>
        </div>
      )}
    </div>
  );
};

const StoreDetail = () => {


  const {schemaData, loading, getData} = useIntegrationStoreSchemeForm()
  const { postData } = useIntegrationCreate();

  const location = useLocation();
  const { name, item } = location.state || {}; 
  // const { storeId } = useParams();
  const storeId = item?.schema_set[0].id
  const schema = schemaData?.schema

  const uiSchema = {
    ...schemaData?.uiSchema,
    myArrayField: {
      "ui:options": {
        classNames: "custom-add-item-button" // Özel CSS sınıfı
      }
    },
    "ui:submitButtonOptions": {
      norender: true // Varsayılan submit butonunu gizliyoruz
    }
  };
 

  useEffect(() => {
    getData(storeId)

  }, [])
  


  const handleSubmit = ({ formData }) => {
    const updatedFormData = {
      metadata : formData,
      name: item?.Name 
    };
  
    postData(updatedFormData);
    console.log('Form Data:', updatedFormData);
  };

  return (

<>
<Link to="/e-commerce/integration">
  <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
    <BsArrowLeft /> &nbsp; Geri Dön
  </span>
</Link>
<Card className="mb-3 mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom:'5%' }}>
  <div className="mb-3 mt-3" style={{width:"95%"}}>
    <h6>{name} Entegrasyonu Oluşturma</h6>
    <hr />
  </div>
  {loading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        schema ? (  
          <div style={{ width: "70%", margin: "0 auto" }}> {/* Sağ ve sol %10 boşluk bırakıyoruz */}
          <Form 
              schema={schema} 
              uiSchema={uiSchema}
              validator={validator} 
              onSubmit={handleSubmit} 
              templates={{ ArrayFieldTemplate }}
            >
              <div className="d-flex justify-content-center mt-3">
                <button type="submit" className="btn btn-primary">Onayla</button>
              </div>
            </Form>
        </div>
        ) : (
          <p>Schema verisi bulunamadı.</p>
        )
      )}
</Card>
</>
  );
};

export default StoreDetail;