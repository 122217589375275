import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'


export default function useInventoryListFilter() {

 const [myDataRegion, setMyDataRegion] = useState()

 const {myKey, currentUser} = useContext(AuthContext)
 const [vendorIdRegion, setVendorIdRegion] = useState()



 const getData = ()=>{
        var myHeaders = new Headers();

        // if (!vendorIdRegion) return;
     
            const params = {
              vendor_id: vendorIdRegion
            };
          
            const request_url = Object.entries(params)
                .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
                .reduce(
                  (url, [key, value]) => url + `&${key}=${value}`,
                  `${BASE_URL}api/v1/order/inventory_list/?`
            );
        
      console.log("wurl", request_url)
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyDataRegion(result) 
    })
          .catch(error => console.log('error', error));        
 }

 







 useEffect(() => {
    getData();
  }, [vendorIdRegion]);

 return {myDataRegion, setVendorIdRegion}
}
