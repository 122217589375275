import React, { useEffect, useState, forwardRef, useContext } from 'react';
import { Card, Col, Row, Table, Button, Form, Tab, Nav } from 'react-bootstrap';
import DatePicker from  "react-datepicker";
import { registerLocale } from "react-datepicker";
import tr from 'date-fns/locale/tr';
import { startOfMonth } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import "./style.css"
import { PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import useOrderFeeCalculation from 'hooks/CostsCalculation/useOrderFeeCalculation';
import useStorageFeeCalculation from 'hooks/CostsCalculation/useStorageFeeCalculation';
import useOrderFeeAdd from 'hooks/CostsCalculation/useOrderFeeAdd';
import useStorageFeeAdd from 'hooks/CostsCalculation/useStorageFeeAdd';
import useInboundFeeCalculation from 'hooks/CostsCalculation/useInboundFeeCalculation';
import useInboundFeeAdd from 'hooks/CostsCalculation/useInboundFeeAdd';
import useRefundFeeCalculation from 'hooks/CostsCalculation/useRefundFeeCalculation';
import useRefundFeeAdd from 'hooks/CostsCalculation/useRefundFeeAdd';
import useStorageVolume from 'hooks/CostsCalculation/useStorageVolume';
import useOrderProductCount from 'hooks/CostsCalculation/useOrderProductCount';
import useRefundCount from 'hooks/CostsCalculation/useRefundCount';
import useInboundCount from 'hooks/CostsCalculation/useInboundCount';





const CalculationFees = () => {



  const {perms} = useContext(PermsContext)
  const {myDataVendor} = useVendorList()

  const {myDataOrder, dateOfOrder, setDateOfOrder,  setOrderVendorId } = useOrderFeeCalculation()
  const {setVendorOrderFeeAdd, setFormData, formData } = useOrderFeeAdd()
  const {myDataOrderProduct, setDateOfOrderProduct,setOrderProductVendorId} = useOrderProductCount()

  const {myDataStorage, dateOfStorage, setDateOfStorage,  setStorageVendorId } = useStorageFeeCalculation()
  const {setVendorStorageFeeAdd, setFormDataStorage, formDataStorage } =  useStorageFeeAdd()
  const {myDataStorageVolume, setDateOfStorageVolume,setStorageVolumeVendorId} = useStorageVolume()

  const {myDataInbound, dateOfInbound, setDateOfInbound, setInboundVendorId} = useInboundFeeCalculation() 
  const {setVendorInboundFeeAdd, setFormDataInbound, formDataInbound} = useInboundFeeAdd()
  const {myDataInboundCount, setDateOfInboundCount, setInbountCountVendorId} = useInboundCount()

  const {myDataRefund, dateOfRefund, setDateOfRefund,  setRefundVendorId, refundVendorId } = useRefundFeeCalculation()
  const {setVendorRefundFeeAdd, setFormDataRefund, formDataRefund} = useRefundFeeAdd()
  const {myDataRefundCount, setDateOfRefundCount,setRefundCountVendorId} = useRefundCount()


  
  function hasPermission(permission) {
    return perms?.includes(permission);
  }


  useEffect(() => {
    if (myDataVendor && myDataVendor.length > 0) {
      const firstVendorId = myDataVendor[0].Id;
      
      setOrderVendorId(firstVendorId)
      setVendorOrderFeeAdd(firstVendorId)
      setOrderProductVendorId(firstVendorId)

      setStorageVendorId(firstVendorId)
      setVendorStorageFeeAdd(firstVendorId)
      setStorageVolumeVendorId(firstVendorId)

      setInboundVendorId(firstVendorId)
      setVendorInboundFeeAdd(firstVendorId)
      setInbountCountVendorId(firstVendorId)



      setRefundVendorId(firstVendorId)
      setVendorRefundFeeAdd(firstVendorId)
      setRefundCountVendorId(firstVendorId)

    }

  }, [myDataVendor]);


  const handleChangeVendor = (e)=> {
  
    setOrderVendorId(e.target.value)
    setVendorOrderFeeAdd(e.target.value);
    setOrderProductVendorId(e.target.value)


    setStorageVendorId(e.target.value)
    setVendorStorageFeeAdd(e.target.value)
    setStorageVolumeVendorId(e.target.value)

    setInboundVendorId(e.target.value)
    setVendorInboundFeeAdd(e.target.value)
    setInbountCountVendorId(e.target.value)

    setRefundVendorId(e.target.value)
    setVendorRefundFeeAdd(e.target.value)
    setRefundCountVendorId(e.target.value)

  }




// order fee
  const [orderCount, setOrderCount] = useState(myDataOrder?.FulfilledProductCount || myDataOrderProduct?.month_order_products_count || "");
  const [perCountOrder, setPerCountOrder] = useState(myDataOrder?.EmployeeNumber || "");
  const [perCostOrder, setPerCostOrder] = useState(myDataOrder?.MonthlyEmployeeSalary || "");
 
  useEffect(() => {
    if (myDataOrder) {
      setOrderCount(myDataOrder.FulfilledProductCount || myDataOrderProduct?.month_order_products_count || "");
      setPerCountOrder(myDataOrder?.EmployeeNumber || "")
      setPerCostOrder(myDataOrder?.MonthlyEmployeeSalary || "")
    }
  }, [myDataOrder, myDataOrderProduct]);

  const handleSubmitOrder = (e) => {
    e.preventDefault();
    setFormData({
      ...formData, "MonthlyEmployeeSalary": perCostOrder, 
                    "EmployeeNumber": perCountOrder,
                    "FulfilledProductCount": orderCount,
                   
    })
};



// storage fee

const [storageVolume, setStorageVolume] = useState(myDataStorage?.TotalVolume || myDataStorageVolume?.month_total_storage_volume || "");
const [rentStorage, setRentStorage] = useState(myDataStorage?.WarehouseRent || "");
const [otherFeeStorage, setOtherFeeStorage] = useState(myDataStorage?.OtherFee || "");


  useEffect(() => {
    if (myDataStorage) {
      setStorageVolume(myDataStorage?.TotalVolume || myDataStorageVolume?.month_total_storage_volume || "");
      setRentStorage(myDataStorage?.WarehouseRent || "")
      setOtherFeeStorage(myDataStorage?.OtherFee || "")

    }
  }, [myDataStorage, myDataStorageVolume]);


  const handleSubmitStorage = (e) => {
    e.preventDefault();
    setFormDataStorage({
      ...formDataStorage,   "TotalVolume": storageVolume, 
                            "WarehouseRent": rentStorage,
                            "OtherFee": otherFeeStorage,
                   
    })
};

//  inbound fee

const [inboundFulFilledVolume, setInboundFulFilledCount] = useState(myDataInbound?.FulfilledProductCount  || myDataInboundCount?.month_total_inbound_product_count || "");
const [perCountInbound, setPerCountInbound] = useState(myDataInbound?.EmployeeNumber || "");
const [perCostInbound, setPerCostInbound] = useState(myDataInbound?.MonthlyEmployeeSalary || "");


  useEffect(() => {
    if (myDataInbound) {
      setInboundFulFilledCount(myDataInbound?.FulfilledProductCount || myDataInboundCount?.month_total_inbound_product_count ||  "");
      setPerCountInbound(myDataInbound?.EmployeeNumber || "")
      setPerCostInbound(myDataInbound?.MonthlyEmployeeSalary || "")

    }
  }, [myDataInbound]);


  const handleSubmitInbound = (e) => {
    e.preventDefault();
    setFormDataInbound({
      ...formDataInbound,   "FulfilledProductCount": inboundFulFilledVolume, 
                            "EmployeeNumber": perCountInbound,
                            "MonthlyEmployeeSalary": perCostInbound,
                   
    })
};

// refund fee


const [refundCount, setRefundCount] = useState(myDataRefund?.FulfilledProductCount || myDataRefundCount?.month_total_refunded_product_count || "");
const [perCountRefund, setPerCountRefund] = useState(myDataRefund?.EmployeeNumber || "");
const [perCostRefund, setPerCostRefund] = useState(myDataRefund?.MonthlyEmployeeSalary || "");


  useEffect(() => {
    if (myDataRefund) {
      setRefundCount(myDataRefund?.FulfilledProductCount || myDataRefundCount?.month_total_refunded_product_count || "");
      setPerCountRefund(myDataRefund?.EmployeeNumber || "")
      setPerCostRefund(myDataRefund?.MonthlyEmployeeSalary || "")

    }
  }, [myDataRefund]);


  const handleSubmitRefund = (e) => {
    e.preventDefault();
    setFormDataRefund({
      ...formDataRefund,   "FulfilledProductCount": refundCount, 
                            "EmployeeNumber": perCountRefund,
                            "MonthlyEmployeeSalary": perCostRefund,
                   
    })
};




const onChangeDate = (date) => {

  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
  const start = startOfMonth(utcDate);
  setDateOfOrder(start);
  setDateOfStorage(start)
  setDateOfInbound(start)
  setDateOfRefund(start)

  setDateOfStorageVolume(start)
  setDateOfOrderProduct(start)
  setDateOfRefundCount(start)
  setDateOfInboundCount(start)
  
};
 
  registerLocale('tr', tr);




  return (
    <>
    
<Card className="mb-3 mt-1">
      <Card.Body>
      <Table striped className="border-bottom">
          <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Maliyet Hesaplamaları</th>
              </tr>
            </thead>
       </Table>
        <Tab.Container defaultActiveKey="storageFee">
          <Row>
            <Col sm={12}>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="storageFee">Depolama Maliyeti</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="orderFee">Sipariş Karşılama  Maliyeti</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="inboundFee">Mal Kabul Maliyeti</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="refundFee">İade Maliyeti</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="storageFee">

            <Form onSubmit={handleSubmitStorage}>
              <div style={{ marginTop:'40px', width:'100%', display:'flex', justifyContent:'center'}}>
                <div style={{ marginTop:'40px', width:'60%', display:'flex', justifyContent:'flex-end', flexDirection: 'column'}}>
                    <div style={{ marginBottom: '15px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}> 
                          { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                        <div style={{ width: '18%'}}>
                          { myDataVendor?.length> 1 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                          <option value="" disabled>Satıcı</option>
                              { myDataVendor?.map((item, i)=>{
                                    return <option key={i} value={item.Id}>{item.vendor_name}</option>
                              })}       
                      </Form.Select>}
                      </div>
                      : "" }
                    </div>


                    <div style={{ marginBottom: '15px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <div style={{ width: 'fit-content', width: '18%' }}> 
                          <DatePicker
                            selected={dateOfStorage}
                            onChange={onChangeDate}
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                            locale="tr"
                            className='form-control'
                            placeholderText='Ay ve Yıl Seçiniz'
                          />
                        </div>
                    </div> 

                  <div style={{display:'flex', justifyContent:'space-between'}}>
                      <p style={{width:'30%', display: 'flex', justifyContent: 'space-between'}}>
                        Aylık Depolanan Desi 
                        <span>:</span>
                        </p>
                      <Form.Control  placeholder="" 
                       style={{ marginBottom:'10px', width:'60%', textAlign: 'right'}} 
                       value={ storageVolume }
                       onChange={(e)=>setStorageVolume(e.target.value)}
                      ></Form.Control>
                    </div>

                    <div style={{display:'flex', justifyContent:'space-between'}}>
                      <p style={{width:'30%', display: 'flex', justifyContent: 'space-between'}}>
                        Aylık Kira Maliyeti  
                      <span>:</span>
                      </p>
                      <Form.Control  placeholder="" 
                       style={{ marginBottom:'10px', width:'60%', textAlign: 'right'}} 
                       value={rentStorage}
                       onChange={(e)=>setRentStorage(e.target.value)}
                      ></Form.Control>
                    </div>

                    <div style={{display:'flex', justifyContent:'space-between'}}>
                      <p style={{width:'30%', display: 'flex', justifyContent: 'space-between' }}>
                        Fatura ve Diğer Giderler 
                        <span>:</span>
                        </p>
                      <Form.Control  placeholder="" 
                       style={{ marginBottom:'10px', width:'60%', textAlign: 'right'}} 
                       value={otherFeeStorage}
                       onChange={(e)=>setOtherFeeStorage(e.target.value)}
                      ></Form.Control>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', marginTop:'40px'}}>
                      <Button variant="primary" type="submit">
                        Hesapla
                      </Button>
                    </div>
                      
                 
                  </div>     
                </div>  
                    
                  
                </Form>
                </Tab.Pane>
                  <Tab.Pane eventKey="orderFee">
                    <Form onSubmit={handleSubmitOrder}>

                    <div style={{ marginTop:'40px', width:'100%', display:'flex', justifyContent:'center'}}>
                      <div style={{ marginTop:'40px', width:'60%', display:'flex', justifyContent:'flex-end', flexDirection: 'column'}}>
                            <div style={{ marginBottom: '15px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}> 
                                  { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                                <div style={{ width: '18%'}}>
                                  { myDataVendor?.length> 1 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                                  <option value="" disabled>Satıcı</option>
                                      { myDataVendor?.map((item, i)=>{
                                            return <option key={i} value={item.Id}>{item.vendor_name}</option>
                                      })}       
                              </Form.Select>}
                              </div>
                              : "" }
                            </div>

                            <div style={{ marginBottom: '15px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                              <div style={{ width: 'fit-content', width: '18%' }}> 
                                <DatePicker
                                  selected={dateOfOrder}
                                  onChange={onChangeDate}
                                  dateFormat="MMMM yyyy"
                                  showMonthYearPicker
                                  locale="tr"
                                  className='form-control'
                                  placeholderText='Ay ve Yıl Seçiniz'
                                />
                              </div>
                            </div> 

                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <p style={{width:'30%', display: 'flex', justifyContent: 'space-between'}}>
                              Aylık Çıkarılan Sipariş Sayısı 
                              <span>:</span>
                              </p>
                            <Form.Control  placeholder="" 
                            style={{ marginBottom:'10px', width:'60%', textAlign: 'right'}} 
                            // disabled="true"
                            value={orderCount}
                            onChange={(e)=>setOrderCount(e.target.value)}
                            ></Form.Control>
                          </div>

                          <div style={{display:'flex', justifyContent:'space-between'}}>
                            <p style={{width:'30%', display: 'flex', justifyContent: 'space-between'}}>
                              Aylık Personel Maliyeti  
                            <span>:</span>
                            </p>
                            <Form.Control  placeholder="" 
                            style={{ marginBottom:'10px', width:'60%', textAlign: 'right'}} 
                            value={perCostOrder}
                            onChange={(e)=>setPerCostOrder(e.target.value)}
                            ></Form.Control>
                          </div>

                          <div style={{display:'flex', justifyContent:'space-between'}}>
                            <p style={{width:'30%', display: 'flex', justifyContent: 'space-between' }}>
                              Personel Sayısı 
                              <span>:</span>
                              </p>
                            <Form.Control  placeholder="" 
                            style={{ marginBottom:'10px', width:'60%', textAlign: 'right'}} 
                            value={perCountOrder}
                            onChange={(e)=>setPerCountOrder(e.target.value)}
                            ></Form.Control>
                          </div>

                          <div style={{display: 'flex', justifyContent: 'center', marginTop:'40px'}}>
                            <Button variant="primary" type="submit">
                              Hesapla
                            </Button>
                          </div>
                            
                      
                        </div>     
                      </div>  
                          
                        
                      </Form>

                </Tab.Pane>
                <Tab.Pane eventKey="inboundFee">
                <Form onSubmit={handleSubmitInbound}>

                    <div style={{ marginTop:'40px', width:'100%', display:'flex', justifyContent:'center'}}>
                        
                      <div style={{ marginTop:'40px', width:'60%', display:'flex', justifyContent:'flex-end', flexDirection: 'column'}}>

                          <div style={{ marginBottom: '15px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}> 
                                  { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                                <div style={{ width: '18%'}}>
                                  { myDataVendor?.length> 1 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                                  <option value="" disabled>Satıcı</option>
                                      { myDataVendor?.map((item, i)=>{
                                            return <option key={i} value={item.Id}>{item.vendor_name}</option>
                                      })}       
                              </Form.Select>}
                              </div>
                              : "" }
                            </div>

                      <div style={{ marginBottom: '15px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <div style={{ width: 'fit-content', width: '18%' }}> 
                          <DatePicker
                            selected={dateOfInbound}
                            onChange={onChangeDate}
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                            locale="tr"
                            className='form-control'
                            placeholderText='Ay ve Yıl Seçiniz'
                          />
                        </div>
                      </div> 

                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <p style={{width:'30%', display: 'flex', justifyContent: 'space-between'}}>
                              Aylık Kabul Edilen Ürün Sayısı 
                              <span>:</span>
                              </p>
                            <Form.Control  placeholder="" 
                            style={{ marginBottom:'10px', width:'60%', textAlign: 'right'}} 
                            value={inboundFulFilledVolume}
                            onChange={(e)=>setInboundFulFilledCount(e.target.value)}
                            ></Form.Control>
                          </div>

                          <div style={{display:'flex', justifyContent:'space-between'}}>
                            <p style={{width:'30%', display: 'flex', justifyContent: 'space-between'}}>
                              Aylık Personel Maliyeti  
                            <span>:</span>
                            </p>
                            <Form.Control  placeholder="" 
                            style={{ marginBottom:'10px', width:'60%', textAlign: 'right'}} 
                            value={perCostInbound}
                            onChange={(e)=>setPerCostInbound(e.target.value)} 
                            ></Form.Control>
                          </div>

                          <div style={{display:'flex', justifyContent:'space-between'}}>
                            <p style={{width:'30%', display: 'flex', justifyContent: 'space-between' }}>
                              Personel Sayısı 
                              <span>:</span>
                              </p>
                            <Form.Control  placeholder="" 
                            style={{ marginBottom:'10px', width:'60%', textAlign: 'right'}} 
                            value={perCountInbound}
                            onChange={(e)=>setPerCountInbound(e.target.value)}
                            ></Form.Control>
                          </div>

                          <div style={{display: 'flex', justifyContent: 'center', marginTop:'40px'}}>
                            <Button variant="primary" type="submit">
                              Hesapla
                            </Button>
                          </div>
                            
                      
                        </div>     
                      </div>  
                          
                        
                      </Form>

                </Tab.Pane>

                <Tab.Pane eventKey="refundFee">
                <Form onSubmit={handleSubmitRefund}>

                <div style={{ marginTop:'40px', width:'100%', display:'flex', justifyContent:'center'}}>
                    
                  <div style={{ marginTop:'40px', width:'60%', display:'flex', justifyContent:'flex-end', flexDirection: 'column'}}>

                  <div style={{ marginBottom: '15px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}> 
                                  { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                                <div style={{ width: '18%'}}>
                                  { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                                  <option value="" disabled>Satıcı</option>
                                      { myDataVendor?.map((item, i)=>{
                                            return <option key={i} value={item.Id}>{item.vendor_name}</option>
                                      })}       
                              </Form.Select>}
                              </div>
                              : "" }
                            </div>

                  <div style={{ marginBottom: '15px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <div style={{ width: 'fit-content', width: '18%' }}> 
                      <DatePicker
                        selected={dateOfRefund}
                        onChange={onChangeDate}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        locale="tr"
                        className='form-control'
                        placeholderText='Ay ve Yıl Seçiniz'
                      />
                    </div>
                  </div> 

                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <p style={{width:'30%', display: 'flex', justifyContent: 'space-between'}}>
                          Aylık İade Ürün Sayısı 
                          <span>:</span>
                          </p>
                        <Form.Control  placeholder="" 
                        style={{ marginBottom:'10px', width:'60%', textAlign: 'right'}} 
                        value={refundCount}
                        onChange={(e)=>setRefundCount(e.target.value)}
                        ></Form.Control>
                      </div>

                      <div style={{display:'flex', justifyContent:'space-between'}}>
                        <p style={{width:'30%', display: 'flex', justifyContent: 'space-between'}}>
                          Aylık Personel Maliyeti  
                        <span>:</span>
                        </p>
                        <Form.Control  placeholder="" 
                        style={{ marginBottom:'10px', width:'60%', textAlign: 'right'}} 
                        value={perCostRefund}
                        onChange={(e)=>setPerCostRefund(e.target.value)}
                        ></Form.Control>
                      </div>

                      <div style={{display:'flex', justifyContent:'space-between'}}>
                        <p style={{width:'30%', display: 'flex', justifyContent: 'space-between' }}>
                          Personel Sayısı 
                          <span>:</span>
                          </p>
                        <Form.Control  placeholder="" 
                        style={{ marginBottom:'10px', width:'60%', textAlign: 'right'}} 
                        value={perCountRefund}
                        onChange={(e)=>setPerCountRefund(e.target.value)}
                        ></Form.Control>
                      </div>

                      <div style={{display: 'flex', justifyContent: 'center', marginTop:'40px'}}>
                        <Button variant="primary" type="submit">
                          Hesapla
                        </Button>
                      </div>
                        
                  
                    </div>     
                  </div>  
                      
                    
                  </Form>

                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Card.Body>
    </Card>


    
    </>
  );
};

CalculationFees.propTypes = {};

export default CalculationFees;
